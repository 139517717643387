import { UseFormReturn } from 'react-hook-form';
import { IDocumentAttachmentsTable } from '@models/Forms/IForms';
import './AttachmentsTable.scss';
import { IFieldElem } from '@models/IFormData';
import React, { useEffect } from 'react';
import { TableTab } from '@models/document/IDocumentScheme';
import DocumentAttachTable from '@organisms/document/DocumentTabBuilder/tabs/DocumentTabAttach/DocumentAttachTable';

export interface IAttachmentsTableProps<TFieldValues extends object = object> {
    table: IDocumentAttachmentsTable;
    tables: TableTab[];
    formMethods: UseFormReturn<TFieldValues>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    docId?: string;
}

const AttachmentsTable = <TFieldValues extends object = object>({
    table,
    tables,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IAttachmentsTableProps<TFieldValues>) => {
    const activated = React.useRef(false);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    const attachmentTable = tables.find((t) => t.key === table.key);

    return table && table.key && docId && attachmentTable && !isEdit && !isNew ? (
        <DocumentAttachTable id={table.id} attachTable={attachmentTable} docId={docId} />
    ) : (
        <></>
    );
};

export default AttachmentsTable;
