import React, { FC, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './TreeModeControl.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import DevExpressTreeList from '@atoms/DevExpress/TreeList/DevExpressTreeList';
import TreeList, {
    Column,
    ColumnFixing,
    FilterRow,
    Paging,
    RemoteOperations,
    Scrolling,
    Selection,
} from 'devextreme-react/ui/tree-list';
import { simulateMouseClick } from '@utils/helpers';
import { IDictSettings } from '@atoms/Dictpicker/IDictSettings';

import DataSource from 'devextreme/data/data_source';
import {
    ContentReadyEvent,
    EditorPreparingEvent,
    InitializedEvent,
    Node,
    NodesInitializedEvent,
} from 'devextreme/ui/tree_list';
import { getLoadOptionsQuery, onCellHoverChanged } from '@utils/dataGridUtils';
import { IDictpickerRefActions } from '@atoms/Dictpicker/Dictpicker';

export interface ITreeModeControlProp extends IDictSettings {
    onSelectedRowItems: (value: IDictionaryData[]) => void;
    onSelectedKeys: (value: string[]) => void;
    dictAttributes: string[];
    filterResponse: IDictFilter;
    maxStructLevel: number;
    controlRef: RefObject<IDictpickerRefActions>;
}

const TreeModeControl: FC<ITreeModeControlProp> = (p: ITreeModeControlProp) => {
    const tree = () => {
        return treeRef?.current?.instance;
    };

    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            treeRef?.current?.instance.refresh();
        },
        setSelected: (items: string[]) => {
            //setSelectedItems(items);
        },
    }));

    const filterPredicate = (currentItem: IDictionaryData, filter: any) => {
        var value = filter.columnName
            ? currentItem.fields.find((x) => x.name === filter.columnName)?.value
            : currentItem.code;

        if (filter.exlude && filter.exlude?.codes?.indexOf(value) !== -1) return false;

        if (filter.codes?.indexOf(value) !== -1) return filter.isShow;

        return !filter.isShow;
    };

    const filterResponseVoid = (originalItems: IDictionaryData[], filter: IDictFilter) => {
        if (filter.filter && filter.filter != '') {
            var fObj = JSON.parse(filter.filter.replace(/\'/g, '"'));
            var filteredObject = [] as IDictionaryData[];
            for (var j = 0; j < originalItems.length; j++) {
                var item = originalItems[j];
                var filteredChild = filterPredicate(item, fObj);
                if (filteredChild) filteredObject.push(item);
            }

            return filteredObject;
        } else {
            return originalItems;
        }
    };

    /**
     * TODO Удалить после правки бэкенда
     * TODO Костыль для раскрытия узлов при откртыии словаря с учётом предиката (fix перед релизом)
     */
    const [isFirstLoadReady, setIsFirstLoadReady] = useState<boolean>(false);

    useEffect(() => {
        /**
         * TODO Удалить после правки бэкенда
         */
        if (isFirstLoadReady) {
            setIsFirstLoadReady(false);
        }

        // const expandAndSelectItems = async (selected: IDictionaryData[]) => {
        //     selected.forEach((item) => {
        //         let ids = item.fullId.split('/');
        //         if (ids.length >= 2) {
        //             ids.pop();
        //             ids.forEach((id) => {
        //                 tree()
        //                     ?.expandRow(id)
        //                     .then((_) => {
        //                         tree()?.selectRows(
        //                             selected.map((item) => item.id),
        //                             true
        //                         );
        //                     });
        //             });
        //         }
        //     });
        // };

        // const getTreeItemsByCode = async () => {
        //     DictionariesService.getTreeItemsByCode(p.dictName, p.selected).then((response) => {
        //         let selected = response.data;
        //         expandAndSelectItems(selected);
        //     });
        // };

        // if (p.dictAttributes) {
        //     if (p.maxStructLevel !== undefined && p.selected && p.selected.length > 0) {
        //         getTreeItemsByCode().catch(console.error);
        //     }
        // }
    }, [p.dictName]);

    const getUrlParam = (loadOptions: any) => {
        let params = '?';
        params += getLoadOptionsQuery(loadOptions);
        return params;
    };

    const treeSource = useMemo(() => {
        return new DataSource<IDictionaryData, string>({
            /**
             * TODO Перенести фильтрацию из метода load в пост-обработку (корректно работают внутренние счётчики)
             * */
            //postProcess: treePostProcessFunction,

            /**
             * TODO Для TreeList перестаёт работать раскрытие узлов при применении фильтра, починить
             * */
            //filter: filterRemoteQuery.length > 0 ? filterRemoteQuery : null,

            requireTotalCount: false,
            key: 'id',
            async load(loadOptions: any) {
                let params = getUrlParam(loadOptions);
                let formValues = p.getFormValuesAsync && p.formValues && (await p.getFormValuesAsync(p.formValues!));
                if (formValues) {
                    params += '&formValues=' + JSON.stringify(formValues);
                } else {
                    params += '&formValues=""';
                }

                if (p.predicatesCache) {
                    params += '&predicates=' + p.predicatesCache;
                }
                if (p.loadMode) {
                    params += '&loadMode=' + p.loadMode;
                }
                const parentIdsParam = loadOptions.parentIds ? loadOptions.parentIds : '';
                return DictionariesService.getTreeItems(p.dictName, parentIdsParam, params).then((response) => {
                    if (p.filterResponse) {
                        response.data.data = filterResponseVoid(response.data.data, p.filterResponse);
                    }
                    return response.data;
                });
            },
        });
    }, [p.dictName, p.filterResponse]);

    /**
     * Фильтрация элементов и управление видимостью элементов
     * */
    const filterNodesByScript = (nodes: Node[], script: string) => {
        nodes?.forEach((child, index) => {
            child.visible = filterNodeByScript(child, script)!;
            setNodesVisibility(child.children, child.visible);
        });
    };

    /**
     *
     * */
    const filterNodeByScript = (node: Node<IDictionaryData, string>, script: string) => {
        /**
         * WARNING! Begin sections of functions for templates, do not rename
         * */
        let subFunc = ` 
        function code (n = node){
            return n.data?.code;
        };
    
        function field (name, n = node) {
            return getFieldValue(n, name);
        };
        
        function childrens (n = node) {
            return n.children;
        };
    
        function intersect (array1, array2){
            return array1 && array2 && array1.some((item) => array2.includes(item));
        };
        `;
        /**
         * WARNING! End sections of functions for templates
         * */

        return eval(subFunc + script);
    };

    const getFieldValue = (node: Node<IDictionaryData, string>, name: string) => {
        return node.data?.fields.find((field) => field.name === name)?.value;
    };

    const checkIsRowSelectable = (data?: IDictionaryData) => {
        if (p.selectableLevels) {
            if (p.selectableLevels === 'last') {
                return !data?.hasChild;
            }
            if (p.selectableLevels === 'first') {
                return data?.parent === '';
            }
        }
        return true;
    };

    const onTreeInitialized = useCallback((e: InitializedEvent<IDictionaryData, string>) => {}, []);

    const setNodesVisibility = (nodes?: Node[], visibility?: boolean) => {
        nodes?.forEach((node) => {
            setNodesVisibility(node.children, visibility);
            node.visible = visibility ?? false;
        });
    };

    const onTreeNodesInitialized = useCallback(
        (e: NodesInitializedEvent<IDictionaryData, string>) => {
            if (p.filterResponse?.script) {
                filterNodesByScript(e.root.children!, p.filterResponse?.script);
            }

            /**
             * TODO VisibleLevels поддерживает только первый уровень отображения, расширить функционал
             * */
            if (p.visibleLevels && p.visibleLevels === 'first') {
                e.root.children?.forEach((node) => {
                    // Скрываем контрол раскрытия узла, необходимо учитывать при последующих проверках
                    node.hasChildren = false;
                });
            }
        },
        [p.filterResponse, p.visibleLevels],
    );

    const onTreeContentReady = useCallback((e: ContentReadyEvent<IDictionaryData, string>) => {
        /**
         * TODO Удалить после правки бэкенда
         */
        setIsFirstLoadReady(true);
    }, []);

    /**
     * TODO Удалить после правки бэкенда
     */
    useEffect(() => {
        const expandAndSelectItems = async (selected: IDictionaryData[]) => {
            selected.forEach((item) => {
                let ids = item.fullId.split('/');
                if (ids.length >= 2) {
                    ids.pop();
                    ids.forEach((id) => {
                        tree()
                            ?.expandRow(id)
                            .then((_) => {
                                tree()
                                    ?.selectRows(
                                        selected.map((item) => item.id),
                                        true,
                                    )
                                    .then(() => {
                                        // перерисовываем грид, т.к. не проставляются чекбоксы
                                        tree()?.repaint();
                                    });
                            });
                    });
                } else {
                    tree()
                        ?.selectRows([item.fullId], true)
                        .then(() => {
                            // перерисовываем грид, т.к. не проставляются чекбоксы
                            tree()?.repaint();
                        });
                }
            });
        };

        const getTreeItemsByCode = async () => {
            if (p.selected) {
                DictionariesService.getTreeItemsByCode(p.dictName, p.selected).then((response) => {
                    let selected = response.data;
                    expandAndSelectItems(selected);
                });
            }
        };

        if (isFirstLoadReady) {
            if (p.dictAttributes) {
                if (p.maxStructLevel !== undefined && p.selected && p.selected.length > 0) {
                    getTreeItemsByCode().catch(console.error);
                }
            }
        }
    }, [isFirstLoadReady]);

    const onTreeEditorPreparing = useCallback((e: EditorPreparingEvent<IDictionaryData, string>) => {
        if (e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
        if (e.parentType === 'dataRow') {
            e.editorOptions.disabled = !checkIsRowSelectable(e.row?.data);
        }
    }, []);

    const onSelectionChanged = useCallback((e: any) => {
        let property = 'id';
        if (!p.isMultiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                let item = e.selectedRowsData.pop();
                e.component.selectRows([item[property]], false);
                p.onSelectedKeys([item[property]]);
                p.onSelectedRowItems([item!]);
            } else if (e.selectedRowKeys.length == 0) {
                p.onSelectedKeys([]);
                p.onSelectedRowItems([]);
            }
        } else {
            p.onSelectedKeys(e.selectedRowKeys);
            p.onSelectedRowItems(e.selectedRowsData);
        }
    }, []);

    const treeRef = useRef<TreeList>(null);

    const getCodeColumn = useCallback(() => {
        let attr = p.gridAttribute?.attrs.find((obj) => {
            return obj.key === 'code';
        });
        return attr ? (
            <Column key={'code'} dataField={'code'} caption={attr.name} width={attr.width} />
        ) : p.gridAttribute == undefined ? (
            <Column key={'code'} dataField={'code'} caption="Код" width="auto" />
        ) : (
            <></>
        );
    }, []);

    const getWidth = useCallback(() => {
        let w = 100;
        p.gridAttribute?.attrs.forEach((x) => {
            w = w + x.width;
        });
        return w;
    }, []);

    const cellUrlRender = (param: any) => {
        if (p.isFormData && param.data.url && param.data.urlAttributes.indexOf(param.column.caption) !== -1) {
            return (
                <a target="_blank" href={param.data.url}>
                    {param.value}
                </a>
            );
        } else {
            return <>{param.value}</>;
        }
    };

    const onRowClick = useCallback((e: any) => {
        if (checkIsRowSelectable(e.data)) {
            if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
                e.component.deselectRows([e.key]);
            } else {
                e.component.selectRows([e.key], true);
            }
        }
    }, []);

    // сортируем столбцы в порядке как они заданы в шаблоне
    const gridAttributesKeys = p.gridAttribute?.attrs.map((val) => val.key) ?? [];
    const sortedDictAttributes = [...p.dictAttributes]?.sort(
        (a, b) =>
            (gridAttributesKeys.indexOf(a) === -1 ? Infinity : gridAttributesKeys.indexOf(a)) -
            (gridAttributesKeys.indexOf(b) === -1 ? Infinity : gridAttributesKeys.indexOf(b)),
    );

    return sortedDictAttributes && p.maxStructLevel !== undefined ? (
        <DevExpressTreeList
            key={'tree'}
            width={getWidth}
            dataSource={treeSource}
            hoverStateEnabled={true}
            columnHidingEnabled={false}
            showColumnHeaders={true}
            columnAutoWidth={true}
            columnMinWidth={30}
            allowColumnReordering={false}
            allowColumnResizing={true}
            columnResizingMode="widget"
            noDataText={'Нет строк'}
            ref={treeRef}
            onEditorPreparing={onTreeEditorPreparing}
            onInitialized={onTreeInitialized}
            onSelectionChanged={onSelectionChanged}
            onNodesInitialized={onTreeNodesInitialized}
            onContentReady={onTreeContentReady}
            // defaultSelectedRowKeys={selectedDefTreeKeys}
            parentIdExpr="parent"
            hasItemsExpr="hasChild"
            keyExpr="id"
            rootValue=""
            onRowClick={onRowClick}
            onCellHoverChanged={onCellHoverChanged}
        >
            <Selection mode="multiple" allowSelectAll={false} />

            {getCodeColumn()}

            {sortedDictAttributes.map((schemeColumn, i) => {
                let attr = p.gridAttribute?.attrs.find((obj) => {
                    return !obj.joined && obj.key === schemeColumn;
                });
                const index = p.dictAttributes.indexOf(schemeColumn);

                return attr === undefined ? (
                    p.gridAttribute === undefined ? (
                        <Column
                            key={`col_${index}`}
                            allowFiltering={true}
                            caption={schemeColumn}
                            dataField={`fields[${index}].value`}
                            dataType={'string'}
                            visible={true}
                            allowSorting={true}
                            filterOperations={['contains']}
                            encodeHtml={true}
                        />
                    ) : (
                        <React.Fragment key={index}></React.Fragment>
                    )
                ) : (
                    <Column
                        key={`col_${index}`}
                        allowFiltering={true}
                        caption={attr?.name}
                        dataField={`fields[${index}].value`}
                        dataType={'string'}
                        width={attr?.width}
                        visible={true}
                        allowSorting={true}
                        filterOperations={['contains']}
                        encodeHtml={true}
                        cellRender={cellUrlRender}
                    />
                );
            })}

            {p.gridAttribute?.attrs
                .filter((attr) => attr.joined)
                .map((attr, i) => {
                    return (
                        <Column
                            key={`col_${i}`}
                            allowFiltering={true}
                            caption={attr?.name}
                            dataField={`joined[${attr.collection}][${attr.name}]`}
                            dataType={'string'}
                            width={attr?.width}
                            visible={true}
                            allowSorting={true}
                            filterOperations={['contains']}
                            encodeHtml={true}
                            cellRender={cellUrlRender}
                        />
                    );
                })}

            <ColumnFixing enabled={true} />
            <FilterRow showOperationChooser={false} visible={true} applyFilter={'onClick'} />
            <RemoteOperations filtering={true} sorting={false} />
            <Scrolling mode="virtual" />
            <Paging enabled={true} defaultPageSize={10} />
        </DevExpressTreeList>
    ) : (
        <></>
    );
};

export default TreeModeControl;
