import React, { useEffect, useMemo, useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import './SendToEisExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { useForm } from 'react-hook-form';
import { ISendToEisModel } from '@models/document/actionsExecution/ISendToEisModel';
import InputControl from '@controls/InputControl/InputControl';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { ModalSize } from '@atoms/Modal/Modal';
import { ActionResultStatus } from '@/models/actions/ActionResultStatus';
import { DocumentLoadEisLogService } from '@/services/DocumentLoadEisLogService';
import Hint from '@/components/atoms/Hint';
import { IEisAsyncSendStatusModel } from '@/models/document/eis/IEisAsyncSendStatusModel';
import { EisAsyncSendStatus } from '@/models/document/eis/EisAsyncSendStatus';

import { API_URL } from '@/http';
import * as signalR from '@microsoft/signalr';

export class SendToEisExecutor extends DocBaseActionExecutor {
    private _modalProps?: ISendToEisExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            message: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <SendToEisExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ISendToEisExecutorProps {
    docId: string;
    actionKey: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const hubMethodName = 'eisSendCompleted';

const SendToEisExecutorModal: React.FC<ISendToEisExecutorProps> = (props: ISendToEisExecutorProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [successText, setSuccessText] = useState<string>();
    const [authRequired, SetAuthRequired] = useState<boolean>();
    const formState = useForm<ISendToEisFormData>({
        mode: 'onBlur', // "onChange"
    });
    const [asyncSendExecuteFlag, setAsyncSendExecuteFlag] = useState<boolean>(false);
    const [asyncSendFlag, setAsyncSendFlag] = useState<boolean>(false);
    const [asyncSendModel, setAsyncSendModel] = useState<IEisAsyncSendStatusModel>();

    const service = new DocumentExecutionService(props.docId);
    const eisService = new DocumentLoadEisLogService();

    const hubConn = useMemo(() => {
        let eisSedningGuidAtStart: string = '';
        let connection = new signalR.HubConnectionBuilder().withUrl(`${API_URL}/autotaskHub`).build();
        let setTimeoutCounter = 0;

        const signalrIdHandler = (eisSendingGuid: string, success: boolean, status: string, message: string) => {
            if (!hubConn.eisSedningGuidAtStart && setTimeoutCounter < 100) {
                setTimeoutCounter++;
                setTimeout(() => signalrIdHandler(eisSendingGuid, success, status, message), 1000);
                return;
            }

            if ((eisSendingGuid as string) == hubConn.eisSedningGuidAtStart) {
                connection?.stop();
                hubConn.eisSedningGuidAtStart = '';
                setTimeoutCounter = 0;

                if (success) {
                    setSuccessText(message);
                    setSuccess(true);
                    if (status === ActionResultStatus.Executing) {
                        setAsyncSendFlag(true);
                        setAsyncSendExecuteFlag(true);
                    }
                } else {
                    setErrorText(message);
                }

                setLoading(false);
            }
        };

        connection.on(hubMethodName, signalrIdHandler);

        return { connection, eisSedningGuidAtStart };
    }, []);

    useEffect(() => {
        // Модель данных
        setLoading(true);
        service
            .getSendToEisModel(props.actionKey)
            .then((result) => {
                //hubConn.connection.start();

                if (result.data) {
                    SetAuthRequired(result.data.authRequired);
                    formState.reset({
                        login: result.data.login,
                        password: result.data.password,
                    } as ISendToEisFormData);
                }
            })
            .catch((error) => setErrorText(error))
            .finally(() => setLoading(false));
    }, []);

    const onSubmit = async (form: ISendToEisFormData) => {
        // Отправить данные
        setLoading(true);
        setErrorText('');
        setSuccessText('');
        let sendToEisData: ISendToEisModel = {
            login: form.login,
            password: form.password,
        };

        await hubConn.connection.start();
        return service
            .sendToEis(props.actionKey, sendToEisData)
            .then((dto) => {
                hubConn.eisSedningGuidAtStart = dto.data.message; //асинхронное выполнение, просто записываем ид выполняющейся задачи
            })
            .catch((error) => {
                hubConn.connection?.stop();
                setErrorText(error);
                setLoading(false);
            })
            .finally(() => {});
    };

    let interval: number;

    const processEisAsyncSendStatus = () => {
        eisService.getEisAsyncSendStatus(props.docId).then((response) => {
            if (
                response.data.statusEnum === EisAsyncSendStatus.Success ||
                response.data.statusEnum === EisAsyncSendStatus.Error
            ) {
                setAsyncSendExecuteFlag(false);
            }
            setAsyncSendModel(response.data);
        });
    };

    const startEisAsyncSendStatusMonitoring = () => {
        interval = window.setInterval(() => {
            processEisAsyncSendStatus();
        }, 1000);
    };

    const stopEisAsyncSendStatusMonitoring = () => {
        clearInterval(interval);
    };

    useEffect(() => {
        if (asyncSendExecuteFlag) {
            startEisAsyncSendStatusMonitoring();
        } else {
            stopEisAsyncSendStatusMonitoring();
        }
        return () => stopEisAsyncSendStatusMonitoring();
    }, [asyncSendExecuteFlag]);

    const modalContainerProps: IModalContainerProps = {
        header: props.message,
        size: props.modalSize,
        showOkButton: !success,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        cancelClick: () => {
            hubConn.connection.stop();
            props.completeHandler && props.completeHandler(success);
        },
        errorText: errorText,
        successText: successText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
        isBusy: loading,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            {!success && authRequired && (
                <InputControl
                    label="Логин"
                    name="login"
                    autoComplete="off"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {!success && authRequired && (
                <InputControl
                    label="Пароль"
                    name="password"
                    autoComplete="off"
                    type="password"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.InProgress && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="blue" maxWidth="100%" />
            )}
            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.Error && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="red" maxWidth="100%" />
            )}
            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.Success && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="green" maxWidth="100%" />
            )}
            {asyncSendFlag && asyncSendModel?.statusEnum === EisAsyncSendStatus.Queued && (
                <Hint icon="info" title={`${asyncSendModel?.statusText}`} variant="blue" maxWidth="100%" />
            )}
        </ModalContainer>
    );
};

interface ISendToEisFormData {
    login: string;
    password: string;
}
