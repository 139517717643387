import { AuthService } from '@services/AuthService';
import { AuthSlice, IAuthState } from '@/store/reducers/AuthReducer';
import { AppDispatch } from '@/store/index';
import history from '@/router/history';
import { UserService } from '@services/UserService';
import { RouteNames } from '@/router';
import { getReturnUrl } from '@utils/helpers';

export const AuthActionCreators = {
    login: (username: string, password: string, remember: boolean) => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoggingIn(true));

            const response = await AuthService.login(username, password);

            let returnUrl = getReturnUrl();

            localStorage.setItem('frontAccessToken', response.data.accessToken);

            if (remember) {
                localStorage.setItem('frontRefreshToken', response.data.refreshToken);
            } else {
                localStorage.removeItem('frontRefreshToken');
            }

            dispatch(
                AuthSlice.actions.successLogin({
                    accessToken: response.data.accessToken,
                    refreshToken: response.data.refreshToken,
                    user: response.data.user,
                    returnUrl: returnUrl,
                } as IAuthState),
            );
        } catch (e) {
            console.log(e);
            let error = e as string;
            dispatch(AuthSlice.actions.setError(error));
        } finally {
            dispatch(AuthSlice.actions.setIsLoggingIn(false));
        }
    },

    loginByHeader: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoggingIn(true));

            const response = await AuthService.loginByHeader();

            if (!response) {
                throw 'Не удалось выполнить вход';
            }

            let returnUrl = getReturnUrl();

            localStorage.setItem('frontAccessToken', response.data.accessToken);
            localStorage.setItem('frontRefreshToken', response.data.refreshToken);
            dispatch(
                AuthSlice.actions.successLogin({
                    accessToken: response.data.accessToken,
                    refreshToken: response.data.refreshToken,
                    user: response.data.user,
                    returnUrl: returnUrl,
                } as IAuthState),
            );
        } catch (e) {
            console.log(e);
            let error = e as string;
            dispatch(AuthSlice.actions.setError(error));
        } finally {
            dispatch(AuthSlice.actions.setIsLoggingIn(false));
        }
    },

    logout: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoading(true));
            await AuthService.logout();
            localStorage.removeItem('frontAccessToken');
            localStorage.removeItem('frontRefreshToken');
            dispatch(AuthSlice.actions.successLogout());
            history.push(RouteNames.LOGIN);
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(AuthSlice.actions.setIsLoading(false));
        }
    },

    getCurrentUser: (accessToken: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoading(true));
            const response = await UserService.getCurrentUser();
            if (response) {
                dispatch(
                    AuthSlice.actions.successLogin({
                        accessToken: accessToken,
                        user: response.data,
                    } as IAuthState),
                );
            }
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(AuthSlice.actions.setIsLoading(false));
        }
    },

    setIsLoading: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(AuthSlice.actions.setIsLoading(flag));
    },

    setIsLoggingIn: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(AuthSlice.actions.setIsLoggingIn(flag));
    },

    resetReturnUrl: () => async (dispatch: AppDispatch) => {
        dispatch(AuthSlice.actions.resetReturnUrl());
    },
};
