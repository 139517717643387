import React, { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentBlock } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import Box from '@atoms/Box';
import Row from '../Row/Row';
import './Block.scss';
import { handlerFieldWatch } from '@utils/documentUtils';
import { IUniqueKey } from '@molecules/formbuilder/interfaces/IUniqueKey';
import { IErrorMessage } from '@/components/molecules/Errors/Errors';

export interface IBlockProps<TFieldValues extends object = object> extends IUniqueKey {
    block?: IDocumentBlock;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: IErrorMessage) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId?: string;
}

const DocumentBlock = <TFieldValues extends object = object>({
    block,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    docId,
    uniqueKey,
    ...props
}: IBlockProps<TFieldValues>) => {
    let rules = block?.visibilityRules;
    const visibilityMng = new FormulaManager(rules!);
    visibilityMng.Init(fields, formMethods);
    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
    };
    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watch = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watch, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watch, isEdit, isNew]);

    return block && block.id && block.name && visibility ? (
        <Box header={block.name} id={block.id}>
            {block.rows.row.map((item, i) => (
                <Row
                    row={item}
                    formMethods={formMethods}
                    docId={docId}
                    setError={setError}
                    fields={fields}
                    isEdit={isEdit}
                    isNew={isNew}
                    key={i}
                    includedFields={includedFields}
                    uniqueKey={uniqueKey}
                />
            ))}
        </Box>
    ) : (
        <></>
    );
};

export default DocumentBlock;
