import React, { forwardRef, ReactNode } from 'react';

import '../styles/iproc-scheme.css';

import TreeList, { ITreeListOptions } from 'devextreme-react/ui/tree-list';

import ruMessages from 'devextreme/localization/messages/ru.json';
import { loadMessages, locale } from 'devextreme/localization';

export interface IDevExpressTreeListOptions extends ITreeListOptions {
    children?: ReactNode;
}
const DevExpressTreeList = forwardRef<TreeList, IDevExpressTreeListOptions>(
    ({ children, ...props }: IDevExpressTreeListOptions, ref: React.Ref<TreeList>) => {
        loadMessages(ruMessages);
        locale('ru'); //locale(navigator.language);

        return (
            <TreeList {...props} ref={ref}>
                {children}
            </TreeList>
        );
    },
);

export default DevExpressTreeList;
