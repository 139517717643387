import React, { FC, useEffect, useRef, useState } from 'react';

import './DictpickerSelect.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import { Node } from 'devextreme/ui/tree_list';
import SelectMenu from '@atoms/Select/Select.Menu';
import Dropdown from '@atoms/Dropdown';
import { IOption } from '@/types';
import { IDictSettings } from '../IDictSettings';

export interface ISelectFromDictDialogProp extends IDictSettings {
    widthDropdown?: number;
    onCloseModal: () => void;
    onSubmitModal: (data: IDictionaryData[]) => void;
}

const DictpickerSelect: FC<ISelectFromDictDialogProp> = (p: ISelectFromDictDialogProp) => {
    const toggleRef = useRef<HTMLDivElement>(null);
    const selectDropDownRef = useRef<HTMLUListElement>(null);
    const [dictAttributes, setDictAttributes] = useState<string[]>();
    const [highlightedStr, setHighlightedStr] = useState<string>('');
    const [options, setOptions] = useState<IOption[]>();
    const [selectedOptions, setSelectedOptions] = useState<IOption[]>();
    const [filterResponse, setFilterResponse] = useState<IDictFilter>();

    const getFilter = async () => {
        p.getFiltersAsync ? setFilterResponse(await p.getFiltersAsync()) : setFilterResponse({} as IDictFilter);
    };

    const filterPredicate = (currentItem: IDictionaryData, filter: any) => {
        var value = filter.columnName
            ? currentItem.fields.find((x) => x.name === filter.columnName)?.value
            : currentItem.code;

        if (filter.exlude && filter.exlude?.codes?.indexOf(value) !== -1) return false;

        if (filter.codes?.indexOf(value) !== -1) return filter.isShow;

        return !filter.isShow;
    };

    const filterResponseVoid = (originalItems: IDictionaryData[], filter: IDictFilter) => {
        if (filter.filter && filter.filter != '') {
            var fObj = JSON.parse(filter.filter.replace(/\'/g, '"'));
            var filteredObject = [] as IDictionaryData[];
            for (var j = 0; j < originalItems.length; j++) {
                var item = originalItems[j];
                var filteredChild = filterPredicate(item, fObj);
                if (filteredChild) filteredObject.push(item);
            }

            return filteredObject;
        } else {
            return originalItems;
        }
    };

    useEffect(() => {
        if (!dictAttributes) {
            getFilter().then(() => {
                if (p.isFormData) {
                    if (p.dictName) {
                        DictionariesService.fetchFormdataDictionaryGridScheme(p.dictName).then((response) => {
                            setDictAttributes(response.data.attributes);
                        });
                    } else {
                        let coll = p.gridAttribute?.attrs.map((item) => {
                            return item.key;
                        });
                        setDictAttributes(coll);
                    }
                } else {
                    DictionariesService.fetchDictionaryGridScheme(p.dictName).then((response) => {
                        setDictAttributes(response.data.attributes);
                    });
                }
            });
        }
    }, [p.dictName]);

    const InitOptions = async () => {
        let params = '';
        let formValues = p.getFormValuesAsync && p.formValues && (await p.getFormValuesAsync(p.formValues!));
        if (formValues) params += '?formValues=' + JSON.stringify(formValues);
        let response: IDictionaryData[] = [];
        let result: IOption[] = [];
        let selected: IOption[] = [];
        if (p.isFormData) {
            response = await DictionariesService.getGridFormdataItems(p.docId!, p.dictName, params).then(
                async (response) => {
                    if (filterResponse) {
                        let _data: IDictionaryData[] = [];
                        _data = filterResponseVoid(response.data.data, filterResponse);
                        if (filterResponse?.script) {
                            _data = filterGridRowsByScript(_data, filterResponse?.script);
                        }
                        response.data.data = _data;
                    }
                    let exDs = p.getExternalDataSource && (await p.getExternalDataSource(undefined));
                    if (exDs && exDs.length > 0) {
                        let data: IDictionaryData[] = [];
                        for (let index = 0; index < response.data.data.length; index++) {
                            const element = response.data.data[index];
                            if (
                                exDs.findIndex((x) => {
                                    return x.code == element.code;
                                }) == -1
                            ) {
                                data.push(element);
                            }
                        }
                        exDs.forEach((element) => {
                            data.push(element);
                        });
                        response.data.data = data;
                    }
                    return response.data.data;
                },
            );
        } else {
            response = await DictionariesService.getGridItems(p.dictName, params).then((response) => {
                if (filterResponse) {
                    let _data: IDictionaryData[] = [];
                    _data = filterResponseVoid(response.data.data, filterResponse);
                    if (filterResponse?.script) {
                        _data = filterGridRowsByScript(_data, filterResponse?.script);
                    }
                    response.data.data = _data;
                }
                return response.data.data;
            });
        }
        response.forEach((item) => {
            let name =
                item.fields.find((x) => x.name === 'Наименование') === undefined
                    ? item.code
                    : item.fields.find((x) => x.name === 'Наименование')?.value;
            if (p.selected && p.selected.indexOf(item.code) > -1) {
                selected.push({
                    label: name,
                    value: item.code,
                    payload: item,
                } as IOption);
                setHighlightedStr(name as string);
            }
            result.push({
                label: name,
                value: item.code,
                payload: item,
            } as IOption);
        });
        setOptions(result);
        setSelectedOptions(selected);
    };

    useEffect(() => {
        if (dictAttributes) {
            InitOptions();
        }
    }, [dictAttributes]);

    /**
     *
     * */
    const filterGridRowsByScript = (data: IDictionaryData[], script: string) => {
        let _data: IDictionaryData[] = [];
        data.forEach((value) => {
            let result = filterGridRowByScript(value, script);
            if (result) {
                _data.push(value);
            }
        });
        return _data;
    };

    /**
     *
     * */
    const filterGridRowByScript = (data: IDictionaryData, script: string) => {
        /**
         * WARNING! Begin sections of functions for templates, do not rename
         * */
        let subFunc = ` 
        function code (){
            return data.code;
        };
    
        function field (name) {
            let value = data.fields.find((field) => field.name === name)?.value;
            return value;
        };
        `;
        /**
         * WARNING! End sections of functions for templates
         * */

        return eval(subFunc + script);
    };

    const setNodesVisibility = (nodes?: Node[], visibility?: boolean) => {
        nodes?.forEach((node) => {
            setNodesVisibility(node.children, visibility);
            node.visible = visibility ?? false;
        });
    };

    return dictAttributes && options !== undefined && selectedOptions !== undefined ? (
        <Dropdown
            show={true}
            toggleRef={toggleRef}
            strategy="absolute"
            onClose={p.onCloseModal}
            style={{
                width: 'auto',
                minWidth: p.widthDropdown,
            }}
        >
            {/*<TrapFocus open>*/}
            <SelectMenu
                ref={selectDropDownRef}
                options={options}
                selected={selectedOptions}
                //multiselect={multiselect}
                menuVariantSize="m"
                isAsync={false}
                preloader={false}
                onOptionClick={(item) => {
                    p.onSubmitModal([item.payload]);
                    p.onCloseModal();
                }}
                multiselect={false}
                selectId={0}
                query={highlightedStr}
                onClose={p.onCloseModal}
                // onScroll={onScroll}
            />
            {/*</TrapFocus>*/}
        </Dropdown>
    ) : (
        <></>
    );
};

export default DictpickerSelect;
