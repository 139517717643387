import $api from '@/http/index';
import { IFileInfo } from '@models/response/IFileInfo';
import { IDataGridDescriptionResponse } from '@models/response/IGridDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IGridResponse } from '@models/response/IGridResponse';
import { getLoadOptionsQuery } from '@utils/dataGridUtils';
import { IDataGridService } from './IDataGridService';
import { AxiosResponse } from 'axios';
import { IDocumentResult } from '@/models/document/IDocumentResult';

export class DocumentLinksService extends IDataGridService {
    documentId: string;

    constructor(docId: string) {
        super();
        this.documentId = docId;
    }

    fetchDetailData(data: any, detailData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        throw new Error('Method not implemented.');
    }
    async fetchScheme(data: any): Promise<AxiosResponse<IDataGridDescriptionResponse, any>> {
        if (!this.documentId) {
            console.error('Попытка вызвать DocumentLinksService.fetchScheme linkScheme с пустым id документа');
            throw new Error('Отсутствует documentId при получении схемы грида линков');
        }

        return $api.get<IDataGridDescriptionResponse>(`/document/linkScheme/${this.documentId}/${data}`);
    }
    async fetchData(data: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        return $api.post<IGridResponse>(`/document/linkData/${this.documentId ?? '-1'}/${data}`, loadOptions);
    }

    async fetchCount(tableKey: any): Promise<AxiosResponse<number, any>> {
        return $api.get<number>(`/document/linkCount/${this.documentId}/${tableKey}`);
    }

    async putData(data: any): Promise<AxiosResponse<IDocumentResult, any>> {
        return $api.put<IDocumentResult>(`/document/linksUpdate`, data);
    }

    async exportData(gridKey: string, options: any, columns: IGridExportSettings[]): Promise<AxiosResponse<IFileInfo>> {
        return $api.post<IFileInfo>(
            `/document/exportLinkedDocGrid?docId=${this.documentId}&gridKey=${gridKey}&${getLoadOptionsQuery(options)}`,
            columns,
        );
    }
}
