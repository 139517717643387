import React from 'react';

const Login = React.lazy(() => import('@pages/login/Login'));
const Dashboard = React.lazy(() => import('@pages/dashboard/Dashboard'));
const Documents = React.lazy(() => import('@pages/documents/Documents'));
const Documentation = React.lazy(() => import('@pages/documentation/Documentation'));
const Dictionaries = React.lazy(() => import('@pages/dictionaries/Dictionaries'));
const Reports = React.lazy(() => import('@pages/reports/Reports'));
const Document = React.lazy(() => import('@pages/document/Document'));
const Tasks = React.lazy(() => import('@pages/tasks/Tasks'));
const Search = React.lazy(() => import('@pages/search/Search'));
const Notification = React.lazy(() => import('@pages/notification/Notification'));
const Roles = React.lazy(() => import('@pages/roles/Roles'));
const NotificationSettings = React.lazy(() => import('@pages/notificationSettings/NotificationSettings'));
const RedirectsAndReplacements = React.lazy(() => import('@pages/RedirectsAndReplacements/RedirectsAndReplacements'));
const AdministrationsAddressBook = React.lazy(
    () => import('@pages/administrations/AdministrationsAddressBook/AdministrationsAddressBook'),
);
const Incident = React.lazy(() => import('@pages/administrations/Incident/Incident'));
const NotificationFiltering = React.lazy(
    () => import('@pages/administrations/NotificationFiltering/NotificationFiltering'),
);

export interface IRoute {
    path: string;
    component: React.ComponentType;
}

export enum RouteNames {
    DOCUMENTATION = '/documentation',
    LOGIN = '/login',
    DASHBOARD = '/',
    DOCUMENTS = '/documents',
    DICTIONARIES = '/dictionaries',
    REPORTS = '/reports',
    DOCUMENT = '/document/:id',
    DOCUMENT_CREATE = '/document/create/:type',
    DOCUMENT_CREATE_ON_BASE = '/document/createOnBase/:baseDocId/:actId',
    DOCUMENT_CREATE_ON_BASE_TEMPLATE = '/document/createOnBase/:baseDocId/:actId/:templateId',
    DOCUMENT_CREATE_BY_ACTION = '/document/createByAction/:actId',
    DOCUMENT_CREATE_BY_ACTION_TEMPLATE = '/document/createByAction/:actId/:templateId',
    TASKS = '/tasks',
    SEARCH = '/search',
    NOTIFICATION = '/notification',
    ROLES = '/roles',
    REDIRECTS_AND_REPLACEMENTS = '/redirects-and-replacements',
    NOTIFICATIONSETTINGS = '/notification-settings',
    ADMINISTRATIONS_ADDRESSBOOK = '/administrations/addressBook',
    ADMINISTRATIONS_INCIDENT = '/administrations/incident',
    ADMINISTRATIONS_NOTIFICATION_FILTERING = '/administrations/notificationFiltering',
}

export const publicRoutes: IRoute[] = [{ path: RouteNames.LOGIN, component: Login }];

export const privateRoutes: IRoute[] = [
    { path: RouteNames.DOCUMENTATION, component: Documentation },
    { path: RouteNames.DASHBOARD, component: Dashboard },
    { path: RouteNames.DOCUMENTS, component: Documents },
    { path: RouteNames.DICTIONARIES, component: Dictionaries },
    { path: RouteNames.REPORTS, component: Reports },
    { path: RouteNames.DOCUMENT, component: Document },
    { path: RouteNames.DOCUMENT_CREATE, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_ON_BASE, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_ON_BASE_TEMPLATE, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_BY_ACTION, component: Document },
    { path: RouteNames.DOCUMENT_CREATE_BY_ACTION_TEMPLATE, component: Document },
    { path: RouteNames.TASKS, component: Tasks },
    { path: RouteNames.SEARCH, component: Search },
    { path: RouteNames.NOTIFICATION, component: Notification },
    { path: RouteNames.ROLES, component: Roles },
    { path: RouteNames.REDIRECTS_AND_REPLACEMENTS, component: RedirectsAndReplacements },
    { path: RouteNames.NOTIFICATIONSETTINGS, component: NotificationSettings },
    { path: RouteNames.ADMINISTRATIONS_ADDRESSBOOK, component: AdministrationsAddressBook },
    { path: RouteNames.ADMINISTRATIONS_INCIDENT, component: Incident },
    { path: RouteNames.ADMINISTRATIONS_NOTIFICATION_FILTERING, component: NotificationFiltering },
];
