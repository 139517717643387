import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IAuthResponce } from '@models/response/IAuthResponce';

export class AuthService {
    static async login(username: string, password: string): Promise<AxiosResponse<IAuthResponce>> {
        return $api.post<IAuthResponce>(`/Account/SignIn`, { username: username, password: password });
    }

    static async loginByHeader(): Promise<AxiosResponse<IAuthResponce>> {
        return $api.post<IAuthResponce>(`/Account/SignInByHeader`);
    }

    static async refreshToken(refreshToken?: string | null): Promise<AxiosResponse<IAuthResponce>> {
        return $api.post<IAuthResponce>(`/Account/RefreshToken`, { refreshToken: refreshToken });
    }

    static async logout(): Promise<void> {
        return $api.post(`/Account/SignOut`);
    }

    static async checkOrganizationExists(inn?: string, kpp?: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/OrganizationExists`, { inn: inn, kpp: kpp });
    }

    static async checkForeignOrganizationExists(tin?: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/ForeignOrganizationExists`, { tin: tin });
    }
}
