import { InputNumberControl } from '@/components/controls';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface IOwnProps {
    formMethods: UseFormReturn<ISmartBusinessParameters>;
}

export const SmartBusinessOrderNumberField: FC<IOwnProps> = ({ formMethods }) => {
    return (
        <InputNumberControl
            label="Номер заявки на покупку"
            required={true}
            name="orderNumber"
            groupBy={0}
            separator={''}
            formState={formMethods.formState}
            control={formMethods.control}
            rules={{ required: 'Обязательное поле' }}
        />
    );
};
