import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { privateRoutes, publicRoutes, RouteNames } from '../router';
import Preloader from '@atoms/Preloader';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import GhostScreen from '@molecules/GhostScreen';

const AppRouter = () => {
    const isAuth = useTypedSelector((state) => state.auth.isAuth);
    const isLoading = useTypedSelector((state) => state.auth.isLoading);
    const returnUrl = useTypedSelector((state) => state.auth.returnUrl);
    const navigate = useNavigate();
    const { resetReturnUrl } = useActions();

    useEffect(() => {
        // возврат на returnUrl
        if (returnUrl) {
            navigate(returnUrl);
            resetReturnUrl();
        }
    }, [returnUrl]);

    return isAuth && !isLoading ? (
        <Suspense fallback={<Preloader size="xl" />}>
            <Routes>
                {privateRoutes.map((route) => (
                    <Route path={route.path} element={<route.component />} key={route.path} />
                ))}
                <Route path={RouteNames.LOGIN} element={<Navigate to={RouteNames.DASHBOARD} replace />} />
                <Route path="*" element={<GhostScreen />} />
            </Routes>
        </Suspense>
    ) : !isLoading ? (
        <Suspense fallback={<Preloader size="xl" />}>
            <Routes>
                {publicRoutes.map((route) => (
                    <Route path={route.path} element={<route.component />} key={route.path} />
                ))}
                <Route path="*" element={<Navigate to={RouteNames.LOGIN} replace />} />
            </Routes>
        </Suspense>
    ) : (
        <Preloader size="xl" />
    );
};

export default AppRouter;
