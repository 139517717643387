import React, { useEffect, useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import TableForm from './ModalForms/TableForm';
import { IField, IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';

export interface RowEditButtonProps {
    table: IDocumentTable;
    rowData: any;
    getParentFields: () => IField[];
    onSubmit: (data: IField[]) => void;
    docId?: string;
    displayFormula?: string;
}

const RowEditButton: React.FC<RowEditButtonProps> = ({
    table,
    rowData,
    getParentFields,
    onSubmit,
    docId,
    displayFormula,
}: RowEditButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [count, setCount] = useState(0);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(true);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        setIsMounted(true);

        const calcVisibliRules = async () => {
            if (displayFormula) {
                let formulaMgr = new FormulaManager(displayFormula);
                let formulaData: Record<string, IFieldElem> = {} as Record<string, IFieldElem>;
                for (let key in rowData) {
                    formulaData[key] = {
                        index: 0,
                        name: '',
                        value: rowData[key],
                    };
                }
                formulaMgr.Init(formulaData);
                let vis = await formulaMgr.EvalFormulaValues(true, false);
                setIsVisible(vis);
            }
        };
        calcVisibliRules();

        return () => {
            // componentwillunmount in functional component.
            setIsMounted(false);
        };
    }, []);

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(!showModal);
    };

    const onSubmitHandler = (data: IField[]) => {
        onSubmit(data);
        setShowModal(!showModal);
    };

    return !isVisible ? (
        <></>
    ) : (
        <>
            <a
                href="#"
                className="dx-link dx-link-delete dx-icon-edit dx-link-icon"
                onClick={onClick}
                title="Редактировать"
                aria-label="Редактировать"
            />

            {showModal && (
                <TableForm
                    table={table}
                    docId={docId}
                    title="Редактирование таблицы"
                    isEdit={true}
                    rowData={rowData}
                    onClose={() => {
                        setShowModal(!showModal);
                    }}
                    getParentFields={getParentFields}
                    onSubmit={onSubmitHandler}
                ></TableForm>
            )}
        </>
    );
};

export default RowEditButton;
