import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITreeOption } from '@/types';

export interface IFoldersState {
    tasks: {
        items: ITreeOption[];
        isLoading: boolean;
    };
    documents: {
        items: ITreeOption[];
        isLoading: boolean;
    };
    dictionaries: {
        items: ITreeOption[];
        isLoading: boolean;
    };
    reports: {
        items: ITreeOption[];
        isLoading: boolean;
    };
}

const initialState: IFoldersState = {
    tasks: {
        items: [] as ITreeOption[],
        isLoading: false,
    },
    documents: {
        items: [] as ITreeOption[],
        isLoading: false,
    },
    dictionaries: {
        items: [] as ITreeOption[],
        isLoading: false,
    },
    reports: {
        items: [] as ITreeOption[],
        isLoading: false,
    },
};

export const FoldersSlice = createSlice({
    name: 'folders',
    initialState,
    reducers: {
        setTasksIsLoading: (state, action: PayloadAction<boolean>) => {
            state.tasks.isLoading = action.payload;
        },
        successTasks: (state, action: PayloadAction<ITreeOption[]>) => {
            state.tasks.items = action.payload;
        },
        setDocumentsIsLoading: (state, action: PayloadAction<boolean>) => {
            state.documents.isLoading = action.payload;
        },
        successDocuments: (state, action: PayloadAction<ITreeOption[]>) => {
            state.documents.items = action.payload;
        },
        setDictionariesIsLoading: (state, action: PayloadAction<boolean>) => {
            state.dictionaries.isLoading = action.payload;
        },
        successDictionaries: (state, action: PayloadAction<ITreeOption[]>) => {
            state.dictionaries.items = action.payload;
        },
        setReportsIsLoading: (state, action: PayloadAction<boolean>) => {
            state.reports.isLoading = action.payload;
        },
        successReports: (state, action: PayloadAction<ITreeOption[]>) => {
            state.reports.items = action.payload;
        },
    },
});
export const {
    setTasksIsLoading,
    successTasks,
    setDocumentsIsLoading,
    successDocuments,
    setDictionariesIsLoading,
    successDictionaries,
    setReportsIsLoading,
    successReports,
} = FoldersSlice.actions;
export default FoldersSlice.reducer;
