import React, { FC, useState } from 'react';
import { AllClose } from '@/indexIcon';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import Modal from '../../Modal';
import SearchModeControl from './ModeControls/SearchModeControl';
import { IExternalSearchDict, IFormValues } from '@models/Forms/IForms';
import { DictionariesService } from '@services/DictionariesService';
import { sendErrorMsg } from '@molecules/Errors';
import { IErrorsButton } from '@molecules/Errors/Errors';

export interface IDictExternalSearchModalProp {
    settings: IExternalSearchDict;
    onCloseModal: () => void;
    onSubmitModal: (data: string) => void;
    getFormValuesAsync?: (formdataParams: IFormValues) => Promise<Record<string, any>>;
}

const DictExternalSearchModal: FC<IDictExternalSearchModalProp> = (p: IDictExternalSearchModalProp) => {
    const [selectedRowItem, setSelectedRowItem] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const ModalFooter = ({ onClose, onSubmit }: { onClose: () => void; onSubmit: (data: any) => void }) => (
        <div className={'dictModal_footer'}>
            <div className={'left'}>
                <Button
                    disabled={selectedRowItem == undefined || loading}
                    onClick={() => {
                        if (selectedRowItem) {
                            setLoading(true);
                            DictionariesService.saveExternalSearch(p.settings.submitApiUrl, selectedRowItem)
                                .then((response) => {
                                    if (response.data.message) {
                                        if (p.settings.errorMessages && p.settings.errorMessages.errorMessage) {
                                            let conf = p.settings.errorMessages.errorMessage.find(
                                                (x) => x.errorValue === response.data.message,
                                            );
                                            if (conf) {
                                                let buttons: IErrorsButton[] = [];
                                                conf?.buttons?.forEach((x) => {
                                                    let url = x.act.replace('{payload}', response.data.payLoadId);
                                                    buttons.push({
                                                        title: x.title,
                                                        onClick: () => {
                                                            window.open(`.${url}`, '_blank');
                                                        },
                                                    });
                                                });
                                                sendErrorMsg({
                                                    message: [conf.message],
                                                    closeBtnTitle: conf.closeBtnTitle,
                                                    buttons: buttons,
                                                    closeBtnClick: () => {
                                                        onSubmit(response.data.payLoadKey);
                                                        onClose();
                                                    },
                                                });
                                            } else {
                                                sendErrorMsg({
                                                    message: [response.data.message],
                                                    closeBtnClick: () => {
                                                        onSubmit(response.data.payLoadKey);
                                                        onClose();
                                                    },
                                                });
                                            }
                                        } else {
                                            sendErrorMsg({
                                                message: [response.data.message],
                                                closeBtnClick: () => {
                                                    onSubmit(response.data.payLoadKey);
                                                    onClose();
                                                },
                                            });
                                        }
                                    } else {
                                        onSubmit(response.data.payLoadKey);
                                        onClose();
                                    }
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        } else {
                            onClose();
                        }
                    }}
                    size="s"
                >
                    Выбрать
                </Button>
                <Button onClick={onClose} buttonType="light" size="s">
                    Отменить
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            className={'dictExternalSearchModal dx-dropdowneditor-overlay'}
            size="content"
            footer={<ModalFooter onClose={p.onCloseModal} onSubmit={p.onSubmitModal} />}
            header={
                <div className={classnames('box')}>
                    <div>
                        <div>{p.settings.buttonName}</div>
                    </div>
                    <div className={classnames('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<AllClose size="xs" />}
                        />
                    </div>
                </div>
            }
        >
            <div className="abool-container">
                <div className="abool-grid">
                    <SearchModeControl
                        {...p.settings}
                        getFormValuesAsync={p.getFormValuesAsync}
                        onSelectedRowItems={(data) => {
                            setSelectedRowItem(data);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DictExternalSearchModal;
