import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';

import './DeleteDocumentExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { useNavigate } from 'react-router-dom';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { ModalSize } from '@atoms/Modal/Modal';

export class DeleteDocumentExecutor extends DocBaseActionExecutor {
    private _modalProps?: IDeleteDocumentExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <DeleteDocumentExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IDeleteDocumentExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const DeleteDocumentExecutorModal: React.FC<IDeleteDocumentExecutorProps> = (props: IDeleteDocumentExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const navigate = useNavigate();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            let service = new DocumentExecutionService(props.docId);
            return service
                .deleteDocument(props.actionKey)
                .then(() => {
                    navigate('/');
                })
                .catch((error) => setErrorText(error));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} />;
};
