import { AxiosResponse } from 'axios';
import { ISelectedFiles } from '@molecules/AttachFilesBlock/AttachFilesBlock';
import $api from '@/http/index';
import { AttachActionTypes } from '@models/actions/AttachActionTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IAttachesCreateModel, IAttachesProperty } from '@models/attaches/IAttachesCreateModel';
import { IAttachViewSignModel, IAttachWithContentBase64 } from '@models/attaches/IAttachViewSignModel';
import { IDocAttachesVersions } from '@models/attaches/IDocAttachesVersions';
import { CreateAttachVersionExecutor } from '@utils/actions/attachExecutors/CreateAttachVersion/CreateAttachVersionExecutor';
import { DeleteExecutor } from '@utils/actions/attachExecutors/DeleteExecutor';
import { DownloadExecutor } from '@utils/actions/attachExecutors/DownloadExecutor';
import { EditExecutor } from '@utils/actions/attachExecutors/EditExecutor';
import { ViewSignExecutor } from '@utils/actions/attachExecutors/ViewSignExecutor';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { FilesService } from '@services/FilesService';
import { BaseActionsService } from './BaseActionsService';
import { IDocBasedService } from './IDocBasedService';
import { IAttachListItemModel } from '@/models/attaches/IAttachListItemModel';

export class AttachActionsService extends BaseActionsService implements IDocBasedService {
    documentId: string;
    flowKey: string;

    constructor(docId: string, flowKey: string) {
        super();
        this.documentId = docId;
        this.flowKey = flowKey;
    }

    public allExecutors: { [actType: string]: () => IActionExecutor } = {
        [AttachActionTypes[AttachActionTypes.Download]]: () => new DownloadExecutor(),
        [AttachActionTypes[AttachActionTypes.Delete]]: () => new DeleteExecutor(),
        [AttachActionTypes[AttachActionTypes.CreateVersion]]: () => new CreateAttachVersionExecutor(),
        [AttachActionTypes[AttachActionTypes.Edit]]: () => new EditExecutor(),
        [AttachActionTypes[AttachActionTypes.ViewSign]]: () => new ViewSignExecutor(),
    };

    //Получает перечень доступных действий для документа и аттача
    async fetchActions(fileId: string): Promise<AxiosResponse<IBaseAction[]>> {
        return $api.get<IBaseAction[]>(`attachment/getAttacheActions/${this.documentId}/${fileId}`);
    }

    //Получает перечень доступных групповых действий для аттачей документа
    async fetchGroupActions(fileIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        return $api.get<IBaseAction[]>(`attachment/getAttacheGroupActions/${this.documentId}/${fileIds}`);
    }

    //Удаляет аттач
    async deleteAttach(attachId: string): Promise<AxiosResponse> {
        return $api.delete(`attachment/${this.documentId}/${attachId}`);
    }

    //загрузка аттачей из модального окна
    async createAttaches(files: ISelectedFiles): Promise<AxiosResponse<IAttachesCreateModel>> {
        const formData = new FormData();
        files.attachProperties.map((prop) => formData.append('attachProps', JSON.stringify(prop)));
        files.files.map((f) => formData.append('attachFiles', f));

        return $api.post(`attachment/createAttaches/${this.documentId}`, formData);
    }

    //Создает версию аттача
    async createAttachVersion(file?: File, attachId?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        if (file) formData.append('attachVersion', file);
        return $api.post(`attachment/createVersion/${this.documentId}/${attachId}`, formData);
    }

    //Получает свойства/метаданные аттача
    async getAttachProperties(
        properties: string[],
        attachId?: string,
    ): Promise<AxiosResponse<{ [index: string]: IAttachesProperty }>> {
        let propsParameters = properties.map((p) => `props=${p}`).join('&');
        return $api.get<{ [index: string]: IAttachesProperty }>(
            `attachment/properties?docId=${this.documentId}&attachId=${attachId}&${propsParameters}`,
        );
    }

    //Редактирует аттач
    async editAttach(attachProperties: any, attachId?: string): Promise<AxiosResponse> {
        return $api.post(`attachment/edit/${this.documentId}/${attachId}`, attachProperties);
    }

    //получает модель таблицы для прикрепления аттачей
    async getAttachInfo(actionKey: string, activityId?: string): Promise<AxiosResponse<IAttachesCreateModel>> {
        return $api.get<IAttachesCreateModel>(
            `attachment/getAttachCreationModel/${this.documentId}/${this.flowKey}/${actionKey}/${activityId ?? ''}`,
        );
    }

    //Возвращает список аттачей для документа (по ид активити)
    public async getDocAttachKeysByActKey(
        actId: string,
        actionKey?: string,
    ): Promise<AxiosResponse<IDocAttachesVersions[]>> {
        return $api.get<IDocAttachesVersions[]>(`/attachment/getDocAttachList/${actId}/${actionKey}`);
    }

    //Возвращает содержимое аттача в base64
    public async getAttachContentInBase64(docId: number, attachId: string): Promise<string> {
        let response = await $api.get<string>(`/attachment/base64Content/${docId}/${attachId}`);
        return response.data;
    }

    //Возвращает все подписи для аттача
    public getAttachSigns(attachId: number): Promise<AxiosResponse<IAttachViewSignModel>> {
        return $api.get<IAttachViewSignModel>(`/attachment/getSigns/${this.documentId}/${attachId}`);
    }

    //Скачать подпись
    public downloadAttachSigns(attachId: number, signId: string): void {
        const filesService = new FilesService();
        filesService.downloadFile(`attachment/downloadSign/${this.documentId}/${attachId}/${signId}`);
    }

    //скачать подпись и содержимое файла в base64 (для проверки)
    public getAttachSignsWithContentBase64(
        docId: string,
        attachId: number,
        signId: string,
    ): Promise<AxiosResponse<IAttachWithContentBase64>> {
        return $api.get<IAttachWithContentBase64>(
            `/attachment/getSignWithContent/${this.documentId}/${attachId}/${signId}`,
        );
    }

    public getDocsAttaches(
        docIds: number[],
        category?: string,
        docDisplayRequisite?: string,
        filters?: string,
    ): Promise<AxiosResponse<IAttachListItemModel[]>> {
        return $api.get<IAttachListItemModel[]>(
            `/attachment/getDocsAttaches?${docIds.map((x) => `&docIds=${x}`).join('')}&category=${
                category ?? ''
            }&docDisplayRequisite=${docDisplayRequisite ?? ''}&filters=${filters ?? ''}`,
        );
    }
}
