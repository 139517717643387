import React from 'react';
import Tooltip from '../Tooltip';
import { StatusInfoOutline } from '@/indexIcon';

export interface IToolTipTitleProps {
    title?: string;
}

const ToolTipTitle: React.FC<IToolTipTitleProps> = ({ title }: IToolTipTitleProps) => {
    return title ? (
        <div className="field-wrapper-title">
            <Tooltip openDelay={100} background="black" position="bottom">
                <StatusInfoOutline size="xxxs" />
                {title}
            </Tooltip>
        </div>
    ) : (
        <></>
    );
};

export default ToolTipTitle;
