import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';

import './DownloadAllExecutor.scss';
import { FilesService } from '@services/FilesService';

export class DownloadAllExecutor extends DocBaseActionExecutor {
    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        const filesService = new FilesService();
        filesService.downloadFile(`documentExecution/downloadAll/${objId}/${action.key}`);
        completeHandler && completeHandler(false);
    };
}
