import React, { ReactNode } from 'react';

import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import Dictpicker from '@atoms/Dictpicker';
import { IDictpickerProps } from '@atoms/Dictpicker/Dictpicker';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';

export type IDictControlProps = IDictpickerProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        /** Функция измекнения значения */
        onSetValues: (value?: IDictionaryData[]) => void;
        onDictValueChanged?: (value?: any) => void;
    };

const DictpickerControl = ({
    name,
    rules,
    diplayValue,
    onSetValues,
    onDictValueChanged,
    setValues,
    control,
    shouldUnregister,
    formState,
    label,
    tooltip,
    required,
    formGroupProps,
    ...props
}: IDictControlProps) => {
    const Controller = useHookFormController();
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            render={({ field: { ref, onChange, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <Dictpicker
                        {...fieldProps}
                        {...props}
                        defaultValue={value}
                        diplayValue={diplayValue}
                        onChange={(e) => {
                            if (e && e.length > 0) {
                                if (props.source?.table) {
                                    let rows: any[] = [];
                                    e.forEach((item, index) => {
                                        let row: any = {};
                                        row[props.source?.key!] = item.code;
                                        props.source?.table.columns.forEach((column) => {
                                            let _value: any;
                                            if (column.attr === '{currentRowNumber}') {
                                                _value = (index + 1).toString();
                                            } else {
                                                _value = item.fields.find((_) => _.name == column.attr)?.value;
                                            }
                                            row[column.key] = _value;
                                        });
                                        rows.push(row);
                                    });
                                    onChange(rows);
                                    onSetValues(e);
                                    if (onDictValueChanged) {
                                        onDictValueChanged(rows.map((row) => row[props.source?.key!]).join('|'));
                                    }
                                } else {
                                    let result = [] as string[];
                                    e.forEach((item) => {
                                        let elem = item.code;
                                        result.push(elem.toString());
                                    });
                                    onChange(result.join('|'));
                                    onSetValues(e);
                                    if (onDictValueChanged) {
                                        onDictValueChanged(result.join('|'));
                                    }
                                }
                            } else {
                                onChange(null);
                                onSetValues(undefined);
                                if (onDictValueChanged) {
                                    onDictValueChanged(null);
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default DictpickerControl;
