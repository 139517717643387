import React, { ChangeEvent, FC, HTMLProps, ReactNode, useCallback, useEffect, useState } from 'react';
import './Timepicker.scss';
import InputMask from '@mona-health/react-input-mask';
import Input from '@atoms/Input';
import Button from '@atoms/Button';
import TimeElement, { getTime } from './TimeElement';
import Menu from '../Menu';
import { classnames } from '@utils/classnames';
import { AllClose, StatusPending } from '@/indexIcon';

export interface ITimepickerProps extends Omit<HTMLProps<HTMLInputElement>, 'ref'> {
    /** Css класс */
    className?: string;
    /** Заблокирован выбор или нет
     * @default false
     */
    disabled?: boolean;
    /** Переводит инпут в невалидный статус
     * @default false
     */
    readOnly?: boolean;
    /** Переводит инпут в невалидный статус
     * @default false
     */
    invalid?: boolean;
    /** Начальное значение
     * @example 12:00
     */
    initialValue?: string;
    /** Функция при изменении значения */
    onChangeValue?: (value: string, id: string) => void;
    /** Минимальное значение
     * @default 00:00
     */
    minTime?: string;
    /** Максимальное значение
     * @default 24:00
     */
    maxTime?: string;
    /**
     *  Безрамочный укороченый вариант
     * @default false
     */
    isMinified?: boolean;
    /**
     *  Формат значения без секунд
     * @default true
     */
    isNoSecFormat?: boolean;
    /**
     *  Отображение иконки в конце строки
     * @default true
     *  */
    showTailIcon?: boolean;

    children?: ReactNode | ReactNode[];
}

// FIXME: Form elements must have labels
// FIXME: Elements must have sufficient color contrast
const Timepicker: FC<ITimepickerProps> = ({
    className,
    initialValue = '',
    disabled = false,
    readOnly = false,
    invalid = false,
    onChangeValue,
    minTime = '00:00',
    maxTime = '24:00',
    isMinified = false,
    isNoSecFormat = true,
    showTailIcon = true,
    children = null,
    ...props
}: ITimepickerProps) => {
    const [time, setTime] = useState(initialValue);

    useEffect(() => {
        // onChangeValue && onChangeValue(initialValue, props.id || "");
        setTime(initialValue);
    }, [initialValue]);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value;
            setTime(val);

            if (val && !~val.indexOf('_')) {
                onChangeValue && onChangeValue(val, props.id || '');
            }
        },
        [onChangeValue, props.id],
    );

    const updateTime = useCallback(
        (newTime: string) => {
            setTime(newTime);
            onChangeValue && onChangeValue(newTime, props.id || '');
        },
        [onChangeValue, props.id],
    );

    const onClearValue = useCallback(() => setTime(''), []);

    const content = (
        /*<TrapFocus open>*/
        <div tabIndex={-1} className="rf-timepicker__time-elmnt-wrapper">
            <TimeElement
                updateTime={updateTime}
                value={time}
                min={minTime}
                max={maxTime}
                isNoSecFormat={isNoSecFormat}
            />
        </div>
        /*</TrapFocus>*/
    );
    const emptyValue = !time || time === '' || time === '__:__' || time === '__:__:__';

    const getMask = useCallback(() => {
        const [hours] = getTime(time);
        const startsWithTwo = hours?.startsWith('2');

        let format = [/[0-2]/, startsWithTwo ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/];

        if (!isNoSecFormat) {
            format.push(':', /[0-5]/, /[0-9]/);
        }

        return format;
    }, [time]);

    return (
        <div
            className={classnames(
                'rf-timepicker__wrapper',
                disabled && 'rf-timepicker--disabled',
                emptyValue && 'rf-timepicker--empty',
                isMinified && 'rf-timepicker-minified',
                readOnly && 'rf-timepicker--readonly',
            )}
        >
            <Menu position="bottom" disabled={readOnly} content={content}>
                {!children && (
                    <InputMask
                        mask={getMask()}
                        value={time}
                        disabled={disabled}
                        alwaysShowMask={true}
                        autoComplete="off"
                        readOnly={readOnly}
                        onChange={onChange}
                    >
                        <Input
                            isBorder={!isMinified}
                            data-testid="rf-timepicker__input"
                            disabled={disabled}
                            readOnly={readOnly}
                            autoComplete="off"
                            invalid={invalid}
                            className={className}
                            {...props}
                        />
                    </InputMask>
                )}

                {children ||
                    (showTailIcon && (
                        <div className="rf-timepicker__menu">
                            <Button
                                buttonType="text"
                                className="rf-timepicker__btn"
                                disabled={disabled}
                                aria-label={emptyValue ? 'Выбрать время' : 'Сбросить'}
                            >
                                {emptyValue ? (
                                    <StatusPending className="rf-timepicker__icon rf-timepicker__icon-time" />
                                ) : (
                                    <AllClose
                                        className="rf-timepicker__icon rf-timepicker__icon-close"
                                        onClick={onClearValue}
                                    />
                                )}
                            </Button>
                        </div>
                    ))}
            </Menu>
        </div>
    );
};

export default Timepicker;
