import { FoldersService } from '@services/FoldersService';
import { FoldersSlice } from '@/store/reducers/FoldersReducer';
import { AppDispatch } from '@/store/index';

export const FoldersActionCreators = {
    fetchTasks: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(FoldersSlice.actions.setTasksIsLoading(true));
            const response = await FoldersService.tasks();
            dispatch(FoldersSlice.actions.successTasks(response.data));
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(FoldersSlice.actions.setTasksIsLoading(false));
        }
    },

    fetchDocuments: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(FoldersSlice.actions.setDocumentsIsLoading(true));
            const response = await FoldersService.documents();
            dispatch(FoldersSlice.actions.successDocuments(response.data));
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(FoldersSlice.actions.setDocumentsIsLoading(false));
        }
    },

    fetchDictionaries: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(FoldersSlice.actions.setDictionariesIsLoading(true));
            const response = await FoldersService.dictionaries();
            dispatch(FoldersSlice.actions.successDictionaries(response.data));
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(FoldersSlice.actions.setDictionariesIsLoading(false));
        }
    },

    fetchReports: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(FoldersSlice.actions.setReportsIsLoading(true));
            const response = await FoldersService.reports();
            dispatch(FoldersSlice.actions.successReports(response.data));
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(FoldersSlice.actions.setReportsIsLoading(false));
        }
    },
};
