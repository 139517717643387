import {
    Column,
    ColumnChooser,
    FilterRow,
    Item,
    Pager,
    Paging,
    RemoteOperations,
    Scrolling,
    Selection,
    Toolbar,
    TreeList,
} from 'devextreme-react/ui/tree-list';
import CustomStore from 'devextreme/data/custom_store';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IDataGridDescriptionResponse } from '@models/response/IGridDescriptionResponse';
import DevExpressTreeList, { IDevExpressTreeListOptions } from './DevExpressTreeList';
import { IDataTreeService } from '@services/IDataTreeService';
import Button from '@atoms/Button';
import { AllDownload } from '@/indexIcon';
import { Template } from 'devextreme-react/core/template';
import '../styles/iproc-scheme.css';
import './DevExpressTreeListDynamic.scss';
import { IGridStateSettings } from '@models/response/IGridStateSettings';
import StateModal from '@organisms/grids/StateModal';
import { IDataGridStateService } from '@services/IDataGridStateService';
import Tooltip from '@atoms/Tooltip';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IFilesService } from '@services/IFilesService';
import {
    applyGridState,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    getLoadOptionsQuery,
    onCellHoverChanged,
} from '@utils/dataGridUtils';
import { IActionService } from '@services/actions/IActionService';
import { IBaseAction } from '@models/actions/IBaseAction';
import ActionButton from '@atoms/DevExpress/Buttons/ActionButton/ActionButton';
import { simulateMouseClick } from '@utils/helpers';
import GroupActionButton, { IGroupActionButtonRefActions } from '@atoms/DevExpress/DataGrid/GroupActionButton';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { Sorting } from 'devextreme-react/ui/data-grid';
import GridSettingsButton, {
    IMenuSettingsRefActions,
} from '@atoms/DevExpress/Buttons/GridSettingsButton/GridSettingsButton';
import { useLocalStorage } from 'usehooks-ts';
import { GridWordWrapMode, IGridSettings } from '@models/dataGrid/IGridSettings';

export interface IDevExpressTreeListDynamicOptions extends IDevExpressTreeListOptions {
    service: IDataTreeService;
    onRowClick?: (e: any) => void;
    onRowPrepared?: (e: any) => void;
    dataKey: string;
    gridKey: string;
    stateService: IDataGridStateService;
    filesService: IFilesService;
    actionService?: IActionService<IBaseAction>;
    externalLink?: string;
}

export type IDxTreeListDynamicHandle = {
    refresh: () => void;
};

const DevExpressTreeListDynamic = forwardRef<IDxTreeListDynamicHandle, IDevExpressTreeListDynamicOptions>(
    (
        {
            service,
            onRowClick,
            onRowPrepared,
            dataKey,
            gridKey,
            stateService,
            filesService,
            actionService,
            externalLink,
        }: IDevExpressTreeListDynamicOptions,
        ref: React.Ref<IDxTreeListDynamicHandle>,
    ) => {
        const [gridDescription, setGridDescription] = useState<IDataGridDescriptionResponse>();
        const [showStateModal, setShowStateModal] = useState<boolean>(false);
        const [visibleColumnsCount, setVisibleColumnsCount] = useState<number>();
        const [isInit, setIsinit] = useState<boolean>(false);
        const [stateData, setStateData] = useState<IGridStateSettings[]>();
        const activated = React.useRef(false);
        const controlRef = useRef<IGroupActionButtonRefActions>(null);
        const [originState, setOriginState] = useState<IGridStateSettings>();
        const treeRef = useRef<TreeList>(null);

        useEffect(() => {
            activated.current = true;
            return () => {
                activated.current = false;
            };
        }, []);

        useImperativeHandle(ref, () => ({
            refresh() {
                treeRef.current?.instance.refresh();
            },
        }));

        //
        useEffect(() => {
            setVisibleColumnsCount(undefined);

            service.fetchScheme(dataKey).then((res) => {
                setGridDescription(res.data);
                setIsinit(true);
            });
        }, [dataKey]);

        const store = useMemo(() => {
            // зануляем стейт грида
            treeRef.current?.instance.state({});

            return new CustomStore({
                key: gridDescription?.dataSource.store.key,
                load(loadOptions: any) {
                    const parentIdsParam = loadOptions.parentIds === undefined ? null : loadOptions.parentIds;
                    // TODO Проверить правила хуков, реализовать отправку только после получения и обработки схемы
                    const columns = treeRef.current?.instance.getVisibleColumns();
                    if (columns?.length === 0) return [];

                    let params = '?';
                    params += getLoadOptionsQuery(loadOptions);

                    // TODO Переместить в тело запроса
                    let columnsIndexes = '';
                    columns?.forEach((column) => {
                        if ((column.visibleIndex ?? -1) >= 0) {
                            columnsIndexes += column.dataField?.toString() + '||';
                        }
                    });

                    params += `&fields=${columnsIndexes.slice(0, -2)}`;
                    return service.fetchData(dataKey, parentIdsParam[0], params).then((res) => {
                        return res.data;
                    });
                },
            });
        }, [gridDescription, dataKey]);

        // -------------------------------------------------------------------------------------------------------------------
        // Пользовательские настройки
        // -------------------------------------------------------------------------------------------------------------------
        const userSettingsRef = useRef<IMenuSettingsRefActions>(null);
        const needReopenMenu = useRef<boolean>(false);

        const [userSettings, setUserSettings] = useLocalStorage<IGridSettings>(`tree.settings.${gridKey}`, {
            wordWrapMode: GridWordWrapMode.Wrap,
        });

        useEffect(() => {
            treeRef.current?.instance.repaint();

            if (needReopenMenu.current) {
                // Задержка для открытия меню после завершения рендера
                setTimeout(() => {
                    userSettingsRef.current?.openMenu();
                    needReopenMenu.current = false;
                }, 0);
            }
        }, [userSettings.wordWrapMode]);

        // -------------------------------------------------------------------------------------------------------------------

        // Список состояний
        useEffect(() => {
            if (!gridDescription) return;

            let st: IGridStateSettings = {
                default: true,
                name: 'default',
                selected: false,
                state: {
                    columns: [],
                    allowedPageSizes: [10, 20, 30],
                    filterPanel: {
                        filterEnabled: false,
                    },
                    filterValue: '',
                    pageIndex: 0,
                    pageSize: 10,
                    searchText: '',
                },
                filters: undefined,
                sort: undefined,
            };

            gridDescription?.columns?.forEach((column, index) => {
                if (column.field !== 'emptyColumn' && !column.defaultHide) {
                    st.state.columns.push({
                        dataField: column.field,
                        dataType: column.dataType,
                        name: column.header,
                        visible: !column.defaultHide,
                        visibleIndex: index,
                        width: column.width,
                    });
                }
            });

            if (st.state.columns.length > 0) {
                setOriginState(st);
            } else {
                setOriginState(undefined);
            }

            stateService.fetchState(dataKey).then((response) => {
                if (activated.current) {
                    if (response.data.length > 0) {
                        setStateData(response.data);
                    } else {
                        setStateData(undefined);
                    }
                }
            });
        }, [gridDescription]);

        // Применение состояния
        useEffect(() => {
            if (stateData) {
                let state = stateData?.filter((x) => x?.selected)?.at(0);

                if (!state) {
                    state = stateData?.filter((x) => x?.default)?.at(0);
                }

                applyGridState(state, gridDescription, treeRef);
            }
        }, [stateData]);

        const onClickRefresh = () => {
            applyGridState(originState, gridDescription, treeRef);
            treeRef.current?.instance.refresh();
        };

        const onClickStateChooser = () => {
            setShowStateModal(true);
        };

        const onClickExport = () => {
            let columns: IGridExportSettings[] = [];

            treeRef.current?.instance.getVisibleColumns()?.forEach((column) => {
                if ((column?.visibleIndex ?? -1) >= 0) {
                    columns.push({
                        dataField: column.dataField,
                        dataType: column.dataType,
                        name: column.caption,
                        visible: column.visible,
                        visibleIndex: column.visibleIndex,
                        width: column.width,
                    });
                }
            });

            const options = treeRef.current?.instance.getDataSource().loadOptions();

            treeRef.current?.instance.beginCustomLoading('Экспортировать данные');
            service.exportData(dataKey, options, columns).then((response) => {
                filesService.downloadFileByGuid(response.data.fileGuid, response.data.fileName, () => {
                    treeRef.current?.instance.endCustomLoading();
                });
            });
        };

        const onClickColumnChooser = () => {
            treeRef.current?.instance.showColumnChooser();
        };

        const onModifyData = () => {
            treeRef.current?.instance.refresh();
        };

        const onEditorPreparing = (e: any) => {
            if (e.parentType === 'filterRow') {
                e.editorOptions.onEnterKey = function () {
                    // применение фильтра по нажатию Enter
                    simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
                };
            }
        };

        const onContentReady = (e: any) => {
            // обновляем грид при выборе столбцов
            let currentColumnsCount = e.component.getVisibleColumns().length;
            if (!visibleColumnsCount) {
                setVisibleColumnsCount(currentColumnsCount);
                return;
            }
            if (currentColumnsCount != visibleColumnsCount) {
                e.component.refresh();
                setVisibleColumnsCount(currentColumnsCount);
            }
        };

        const onSelectionChanged = (e: any) => {
            let keys = e.selectedRowKeys;
            let data = e.selectedRowsData;
            if (keys.length > 0) {
                controlRef.current?.setObjData(keys.join(','), data);
            } else {
                controlRef.current?.setObjData('', data);
            }
        };

        return isInit ? (
            <>
                <StateModal
                    stateKey={dataKey}
                    show={showStateModal}
                    stateSettings={stateData}
                    onCloseClick={() => setShowStateModal(false)}
                    onRefreshGrid={onClickRefresh}
                    onSelectState={(stateData: IGridStateSettings[] | undefined) => setStateData(stateData)}
                    componentRef={treeRef}
                    stateService={stateService}
                />

                <DevExpressTreeList
                    showBorders={true}
                    parentIdExpr="parentId"
                    hasItemsExpr="expandable"
                    keyExpr="key"
                    rootValue={null}
                    onRowPrepared={onRowPrepared}
                    dataSource={store}
                    hoverStateEnabled={true}
                    columnHidingEnabled={false}
                    columnMinWidth={30}
                    showColumnHeaders={gridDescription?.options.showColumnHeaders}
                    columnAutoWidth={gridDescription?.options.columnAutoWidth}
                    allowColumnReordering={gridDescription?.options.allowColumnReordering}
                    allowColumnResizing={gridDescription?.options.allowColumnResizing}
                    columnResizingMode="widget"
                    noDataText={gridDescription?.options.noDataText}
                    rowAlternationEnabled={gridDescription?.options.rowAlternationEnabled}
                    onRowClick={!gridDescription?.options.isMaster ? onRowClick : undefined}
                    ref={treeRef}
                    className={gridDescription?.options.cssClasses.join(' ')}
                    onEditorPreparing={onEditorPreparing}
                    onContentReady={onContentReady}
                    onSelectionChanged={onSelectionChanged}
                    onCellHoverChanged={onCellHoverChanged}
                    onCellPrepared={(e) => {
                        // записываем ключ элемента в кастомный data-key атрибут элемента строки
                        if (e.rowType === 'data' && !e.cellElement.parentElement?.getAttribute('data-key')) {
                            e.cellElement.parentElement?.setAttribute('data-key', e.key);
                        }
                    }}
                >
                    <FilterRow visible={true} />
                    <RemoteOperations filtering={true} />
                    {gridDescription?.columns?.map((schemeColumn, i) => {
                        return (
                            <Column
                                key={`col_${i}`}
                                width={schemeColumn.width ? schemeColumn.width : undefined}
                                minWidth={schemeColumn.width.toString().endsWith('vw') ? 5 : schemeColumn.minWidth}
                                allowFiltering={schemeColumn.allowFiltering}
                                caption={schemeColumn.header}
                                dataField={schemeColumn.field}
                                dataType={getColumnDataTypeByFieldDataType(schemeColumn.dataType)}
                                alignment={schemeColumn.alignment}
                                showInColumnChooser={schemeColumn.showInColumnChooser}
                                visible={!schemeColumn.defaultHide}
                                sortIndex={schemeColumn.sortIndex}
                                sortOrder={schemeColumn.sortOrder}
                                //allowGrouping={schemeColumn.allowGrouping}
                                //colIndexId={ schemeColumn.colIndexId}
                                //allowFiltering={schemeColumn.dataField !== "actionColumn"}
                                allowReordering={
                                    !(schemeColumn.field === 'actionColumn' || schemeColumn.field === 'emptyColumn')
                                }
                                allowSorting={
                                    !(schemeColumn.field === 'actionColumn' || schemeColumn.field === 'emptyColumn')
                                }
                                filterOperations={getColumnFilterOperationsByColumnDataType(
                                    getColumnDataTypeByFieldDataType(schemeColumn.dataType),
                                )}
                                encodeHtml={true}
                                cellTemplate={
                                    schemeColumn.field === 'actionColumn'
                                        ? 'dxGridRowMenuCellTemplateAjax'
                                        : 'dxGridFieldCellTemplate'
                                }
                            />
                        );
                    })}

                    <Template
                        name="dxGridFieldCellTemplate"
                        render={
                            //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                            function (data: any) {
                                const { value } = data;

                                let col = gridDescription?.columns?.find((x) => x.field === data.column.dataField);
                                let link = '';
                                if (externalLink) {
                                    link = `${externalLink}`;
                                    link = link.replace('{key}', data.data.key);
                                    link = link.replace('{documentKey}', data.data.documentKey);
                                }

                                let className;
                                switch (userSettings.wordWrapMode) {
                                    case GridWordWrapMode.NoWrap:
                                        className = 'no-wrap';
                                        break;
                                    case GridWordWrapMode.Trim:
                                        className = 'trim-long-text';
                                        break;
                                    case GridWordWrapMode.Wrap:
                                        className = 'wrap';
                                        break;
                                }

                                return externalLink ? (
                                    <a href={`${link}`}>
                                        <DisplayField className={className} type={col?.dataType!} value={value} />
                                    </a>
                                ) : (
                                    <DisplayField className={className} type={col?.dataType!} value={value} />
                                );
                            }
                        }
                    />

                    <Template
                        name="dxGridRowMenuCellTemplateAjax"
                        render={
                            //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                            function (data: any) {
                                var key = data['key'];
                                return (
                                    <ActionButton
                                        objId={key}
                                        objData={data.data}
                                        service={actionService}
                                        onModifyData={onModifyData}
                                    />
                                );
                            }
                        }
                    />

                    <Selection
                        allowSelectAll={gridDescription?.selection.allowSelectAll}
                        mode={gridDescription?.selection.mode}
                        recursive={gridDescription?.selection.recursive}
                    />

                    <ColumnChooser
                        enabled={gridDescription?.options.allowColumnChooser}
                        mode={'select'}
                        allowSearch={true}
                        height={600}
                    />

                    <FilterRow
                        visible={gridDescription?.filterRow.visible}
                        applyFilter={gridDescription?.filterRow.applyFilter}
                    />

                    <Pager
                        showInfo={gridDescription?.pager.showInfo}
                        showPageSizeSelector={gridDescription?.pager.showPageSizeSelector}
                        visible={true}
                        showNavigationButtons={true}
                        displayMode={'full'}
                        allowedPageSizes={[5, 10, 25]}
                    />

                    <Paging
                        enabled={gridDescription?.paging.enabled}
                        defaultPageSize={gridDescription?.paging.pageSize}
                    />

                    <Scrolling mode={gridDescription?.scrolling.mode} />

                    <Sorting mode="multiple" />

                    <Toolbar>
                        <Item location="before">
                            {/*кнопка групповых действий*/}
                            <GroupActionButton
                                gridRef={treeRef}
                                controlRef={controlRef}
                                service={actionService}
                                onModifyData={() => treeRef.current?.instance.refresh()}
                            />
                        </Item>
                        <Item name="groupPanel" location="before" visible={true} />
                        <Item name="applyFilterButton" location="after" visible={true} />

                        <Item location="after" visible={gridDescription?.options.ods_format}>
                            <Tooltip openDelay={100} background="black" position="top">
                                <Button
                                    buttonType="text"
                                    textColor="neutral"
                                    size="xxs"
                                    aria-label="Экспортировать данные в формате ODS"
                                    onClick={onClickExport}
                                    startAdornment={<AllDownload size="xs" />}
                                />
                                Экспортировать данные в формате ODS
                            </Tooltip>
                        </Item>
                        <Item location="after" visible={gridDescription?.options.excel_format}>
                            <Tooltip openDelay={100} background="black" position="top">
                                <Button
                                    buttonType="text"
                                    textColor="neutral"
                                    size="xxs"
                                    aria-label="Экспортировать данные в формате .xls"
                                    onClick={onClickExport}
                                    startAdornment={<AllDownload size="xs" />}
                                />
                                Экспортировать данные в формате .xls
                            </Tooltip>
                        </Item>
                        <Item location="after">
                            <GridSettingsButton
                                value={userSettings}
                                onChange={(value) => {
                                    setUserSettings(value);
                                    needReopenMenu.current = true;
                                }}
                                onClickStateChooser={onClickStateChooser}
                                onClickRefresh={onClickRefresh}
                                onClickColumnChooser={onClickColumnChooser}
                                menuSettingsRef={userSettingsRef}
                            />
                        </Item>
                    </Toolbar>
                </DevExpressTreeList>
            </>
        ) : (
            <></>
        );
    },
);

export default DevExpressTreeListDynamic;
