import { Circle, CircleInfo } from '@/assets/legacyIcons';
import Button from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox';
import Menu from '@/components/atoms/Menu';
import Preloader from '@/components/atoms/Preloader';
import Tag from '@/components/atoms/Tag';
import Textarea from '@/components/atoms/Textarea';
import { FormProviderControl, HookFormProvider, InputNumberControl } from '@/components/controls';
import { CreditCardOutlined, SafetyOutlined, WalletOutlined } from '@/indexIcon';
import { ISmartBusinessExcludeParticipants } from '@/models/smartBusiness/ISmartBusinessExcludeParticipants';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { ISmartBusinessSearchParams } from '@/models/smartBusiness/ISmartBusinessSearchParams';
import { ISmartBusinessSearchResult } from '@/models/smartBusiness/ISmartBusinessSearchResult';
import { ISmartBusinessSearchResultCount } from '@/models/smartBusiness/ISmartBusinessSearchResultCount';
import { ISmartBusinessWinner } from '@/models/smartBusiness/ISmartBusinessWinner';
import { SmartBusinessService } from '@/services/SmartBusinessService';
import { IListElement } from '@/types';
import { AxiosResponse } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SmartBusinessFinancialProfileBar from '../SmartBusinessFinancialProfileBar/SmartBusinessFinancialProfileBar';
import { SmartBusinessPagination } from './components/SmartBusinessPagination';
import './SmartBusinessWinners.scss';

interface ISmartBusinessWinnersProps {
    formParams: ISmartBusinessParameters;
    token: string;
    onSearch: (data: ISmartBusinessSearchParams) => void;
}

export const SmartBusinessWinners: FC<ISmartBusinessWinnersProps> = ({ formParams, token, onSearch }) => {
    const PAGE_SIZE = 10;
    const [searchResult, setSearchResult] = useState<ISmartBusinessSearchResult>();
    const [counts, setCounts] = useState<ISmartBusinessSearchResultCount>();
    const [excludeParticipants, setExcludeParticipants] = useState<ISmartBusinessExcludeParticipants[]>([]);
    const [excludeWinners, setExcludeWinners] = useState<ISmartBusinessWinner[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExcludeTab, setIsExcludeTab] = useState<boolean>(false);
    const [filters, setFilters] = useState<string[]>([]);
    const [error, setError] = useState<string>();
    const currentForm = React.useRef<ISmartBusinessParameters>(formParams);
    const currentSearchParam = React.useRef<ISmartBusinessSearchParams>();
    const methods = useForm<ISmartBusinessParameters>({
        mode: 'onBlur', // "onChange"
        defaultValues: formParams,
    });

    const fetchCounts = (hashId: string) => {
        setCounts(undefined);
        SmartBusinessService.getCounts(hashId, token).then((result) => {
            if (result.data.data) {
                setCounts(result.data.data);
            }
        });
    };

    const fetchWinners = (form: ISmartBusinessParameters, currentPage: number, filters?: string[], hashId?: string) => {
        setIsLoading(true);
        SmartBusinessService.searchWinners(
            form,
            currentPage,
            currentForm.current.orderNumber!,
            token,
            PAGE_SIZE,
            filters,
            hashId,
        )
            .then((result) => {
                setSearchResult(result.data.data);
                if (result.data.data.filters.exclude_participants) {
                    setExcludeParticipants(result.data.data.filters.exclude_participants);
                }

                fetchCounts(result.data.data.hashId);
                let param = {
                    filter: form,
                    currentPage: currentPage,
                    orderId: currentForm.current.orderNumber!,
                    token: token,
                    pageSize: PAGE_SIZE,
                    hashId: result.data.data.hashId,
                    calculationId: result.data.data.calculationId,
                    filters: filters,
                };
                currentSearchParam.current = param;
                onSearch(param);
            })
            .catch((error) => {
                setSearchResult(undefined);
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    useEffect(() => {
        methods.reset(formParams);
        fetchWinners(formParams, 1);
    }, [formParams]);

    const reCalculate = (
        e: any,
        localExcludeParticipants: ISmartBusinessExcludeParticipants[] | undefined = undefined,
    ) => {
        let exParticipants = localExcludeParticipants ? localExcludeParticipants : excludeParticipants;
        let data = { ...e, exclude_participants: exParticipants };
        currentForm.current = data;
        setIsExcludeTab(false);
        fetchWinners(data, 1, currentSearchParam.current?.filters, currentSearchParam.current?.hashId);
    };

    const downloadReport = () => {
        SmartBusinessService.getReport(token, searchResult?.calculationId!).then((result) => {
            download(result, 'Отчет-подтверждени.xls');
        });
    };

    const downloadProtocol = () => {
        SmartBusinessService.getProtocol(
            currentSearchParam.current?.filter!,
            currentSearchParam.current?.currentPage!,
            currentSearchParam.current?.orderId!,
            currentSearchParam.current?.token!,
            currentSearchParam.current?.pageSize!,
            currentSearchParam.current?.filters,
            currentSearchParam.current?.hashId,
        ).then((result) => {
            download(result, 'Протокол.xls');
        });
    };

    const download = (file: AxiosResponse<Blob, any>, name: string) => {
        const url = window.URL.createObjectURL(
            new Blob([file.data], { type: file.headers['Content-Type']?.toString() ?? '' }),
        );
        let filename = name;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    };

    const downloadItems: IListElement[] = [
        {
            value: '1',
            label: 'Скачать отчет-подтверждение',
            handler: downloadReport,
        },
        {
            value: '2',
            label: 'Скачать протокол',
            handler: downloadProtocol,
        },
    ];

    const renderWinnersForm = () => (
        <FormProviderControl
            formMethods={methods}
            onSubmit={(e) => {
                reCalculate(e);
            }}
        >
            <HookFormProvider controller={Controller}>
                <div className="flex flex-col mar-top-10 ">
                    <div className="grid grid-cols-3 gap-5">
                        <InputNumberControl
                            label="Коэффициент опыта"
                            name="experience_weight"
                            formState={methods.formState}
                            control={methods.control}
                            onValueChange={(value: any) => {
                                let val = +value;
                                if (val > 0) {
                                    methods.setValue('price_weight', 100 - val);
                                }
                            }}
                            max={100}
                            min={0}
                            floatPoints={2}
                            groupBy={0}
                            separator={''}
                        />
                        <InputNumberControl
                            label="Опыт выполнения контрактов (min.)"
                            name="min_experience"
                            formState={methods.formState}
                            control={methods.control}
                            min={0}
                            groupBy={0}
                            separator={''}
                        />
                        <InputNumberControl
                            label="Опыт выполнения контрактов (max.)"
                            name="max_experience"
                            formState={methods.formState}
                            control={methods.control}
                            min={0}
                            groupBy={0}
                            separator={''}
                        />
                        <InputNumberControl
                            label="Коэффициент цены"
                            name="price_weight"
                            formState={methods.formState}
                            control={methods.control}
                            min={0}
                            floatPoints={2}
                            groupBy={0}
                            separator={''}
                            max={100}
                            onValueChange={(value: any) => {
                                let val = +value;
                                if (val > 0) {
                                    methods.setValue('experience_weight', 100 - val);
                                }
                            }}
                        />
                        <InputNumberControl
                            label="Начальная цена"
                            name="start_price"
                            formState={methods.formState}
                            control={methods.control}
                            groupBy={0}
                            floatPoints={2}
                            separator={''}
                            min={0}
                        />
                        <div />
                    </div>
                    <div className="grid grid-cols-3 gap-5  pt-0 tablet:pt-0">
                        <Button buttonType="light" size="s" disabled={isLoading} type="submit">
                            Рассчитать
                        </Button>
                        <Menu list={downloadItems} position="bottom-end" disabled={searchResult === undefined}>
                            <Button buttonType="light" size="s" className="w100" disabled={searchResult === undefined}>
                                Скачать документы
                            </Button>
                        </Menu>
                    </div>
                </div>
            </HookFormProvider>
        </FormProviderControl>
    );

    const calcForecast = (estimationsPrice: number | null): string => {
        if (estimationsPrice !== null && currentForm.current.start_price) {
            const forecast: number = estimationsPrice * currentForm.current.start_price;

            return Math.round(forecast).toLocaleString();
        } else {
            return '0';
        }
    };

    const financialStabilityIndexColor = (financialStabilityIndex: number) => {
        if (financialStabilityIndex <= 9) {
            return 'darkred';
        }
        if (financialStabilityIndex >= 10 && financialStabilityIndex <= 29) {
            return '#ff0000b5';
        }
        if (financialStabilityIndex >= 30 && financialStabilityIndex <= 69) {
            return '#f8a504';
        }
        if (financialStabilityIndex >= 70) {
            return '#2E8C2E99';
        }
    };

    const trustworthinessIndexColor = (trustworthinessIndex: number) => {
        if (trustworthinessIndex <= 49) {
            return '#ff0000b5';
        }
        if (trustworthinessIndex >= 50 && trustworthinessIndex <= 74) {
            return '#f8a504';
        }
        if (trustworthinessIndex >= 75) {
            return '#2E8C2E99';
        }
    };

    // проверка на наличие поля, при этом учитываем, что может прийти 0
    const checkAvailabilityPriceField = (fieldName: number | null): boolean => {
        return fieldName !== undefined && typeof fieldName === 'number';
    };

    const renderWinner = (winner: ISmartBusinessWinner, index: number) => (
        <div key={`winner-${index}`} className="grid gap-0 grid-cols-9 pt-1">
            <span
                className={`col-span-3 flex flex-col items-start text-n1 uppercase a-color border-none p-3 ${
                    winner?.inn ? 'cursor-pointer' : 'cursor-default'
                }`}
                // href={
                //     winner?.inn && winner?.ogrn
                //         ? `/new/platform/smart-business/company/${winner?.inn}?categoryId=${formParams.category_id}&ogrn=${winner?.ogrn}`
                //         : ''
                // }
                // target="_blank"
            >
                <div className="mb-3 mt-4 ml-2">
                    {winner?.msp && (
                        <span className="pr-2">
                            <div className="div-circle">
                                <Circle className="grey-circle" />
                            </div>
                            <Tag variant="default">МСП</Tag>
                        </span>
                    )}
                    {winner?.bad_supplier && (
                        <span className="pr-2">
                            <div className="div-circle">
                                <CircleInfo className="red-circle" />
                            </div>
                            <Tag variant="red">РНП</Tag>
                        </span>
                    )}
                    {winner?.liquidation && (
                        <span className="pr-2">
                            <div className="div-circle">
                                <Circle className="red-circle" />
                            </div>
                            <Tag variant="red">Ликвидация</Tag>
                        </span>
                    )}
                    {winner?.has_seldon_error && (
                        <span className="pr-2 whitespace-no-wrap inline-block mt-2">
                            <Tag variant="yellow">Требуется уточнить данные</Tag>
                        </span>
                    )}
                </div>
                <div className={`ml-2 ${winner?.inn && 'text-blue-frosty-sky'}`}>{winner?.company_name}</div>
                {winner?.inn && (
                    <div className={`ml-2 mt-1 ${winner?.inn && 'text-blue-frosty-sky'}`}>ИНН: {winner?.inn}</div>
                )}
                {checkAvailabilityPriceField(winner?.estimations.price_mean) && (
                    <p className={`ml-2 mt-4 `}>
                        Прогноз цены по среднему значению -
                        <strong>{` ${calcForecast(winner?.estimations.price_mean)}`}</strong>
                    </p>
                )}
                {checkAvailabilityPriceField(winner?.estimations.price_last) && (
                    <p className={`ml-2 mt-1 `}>
                        Прогноз цены по последнему значению -
                        <strong>{` ${calcForecast(winner?.estimations.price_last)}`}</strong>
                    </p>
                )}
            </span>

            <div className="col-span-1 flex flex-col justify-center items-center text-n1 bg-white p-3 ant-col-lg-offset-1">
                <SmartBusinessFinancialProfileBar
                    title={`Финансовая устойчивость ${
                        winner?.financial_stability_index ? `${winner?.financial_stability_index}%` : ''
                    }`}
                    format={<WalletOutlined size="xxxxs" />}
                    percent={Number(winner?.financial_stability_index)}
                    strokeColor={
                        winner?.financial_stability_index != null
                            ? financialStabilityIndexColor(winner?.financial_stability_index)
                            : '#2E8C2E99'
                    }
                />
                <SmartBusinessFinancialProfileBar
                    title={`Благонадежность ${
                        winner?.trustworthiness_index ? `${winner?.trustworthiness_index}%` : ''
                    }`}
                    format={<SafetyOutlined size="xxxxs" className="" />}
                    percent={Number(winner?.trustworthiness_index)}
                    strokeColor={
                        winner?.trustworthiness_index != null
                            ? trustworthinessIndexColor(winner?.trustworthiness_index)
                            : '#2E8C2E99'
                    }
                />
                <SmartBusinessFinancialProfileBar
                    title={`Кредитоспособность ${
                        winner?.creditworthiness_index ? `${winner?.creditworthiness_index} класс` : ''
                    }`}
                    format={<CreditCardOutlined size="xxxxs" className="" />}
                    percent={Number(winner?.creditworthiness_index)}
                    strokeColor={
                        winner?.creditworthiness_index != null && winner?.creditworthiness_index > 1
                            ? '#f8a504'
                            : '#2E8C2E99'
                    }
                />
            </div>
            <div className="col-span-1 flex justify-center items-center text-n1 bg-white p-3 ant-col-lg-offset-1">
                {winner?.estimations?.price}
            </div>
            <div className="col-span-1 flex justify-center items-center text-n1 bg-white p-3">
                {winner?.estimations?.experience}
            </div>
            <div className="col-span-1 flex justify-center items-center text-n1 bg-white p-3">
                {winner?.estimations?.final_estimation}
            </div>

            <div className="col-span-2 flex flex-col justify-center items-center text-n1 bg-white p-3 ant-col-lg-offset-1">
                <div className="absolute">
                    <Checkbox
                        className="checknox-padding"
                        onChange={(e) => {
                            if (e.currentTarget.checked) {
                                if (excludeParticipants.findIndex((x) => x.inn === winner.inn) === -1) {
                                    setExcludeParticipants([
                                        ...excludeParticipants,
                                        {
                                            inn: winner.inn,
                                            company_name: winner.company_name,
                                            comment: '',
                                        } as ISmartBusinessExcludeParticipants,
                                    ]);
                                    setExcludeWinners([...excludeWinners, winner]);
                                }
                            } else {
                                let exParticipants = excludeParticipants.filter((x) => {
                                    return x.inn !== winner.inn;
                                });
                                setExcludeParticipants(exParticipants);
                                setExcludeWinners(
                                    excludeWinners.filter((x) => {
                                        return x.inn !== winner.inn;
                                    }),
                                );
                                let data = methods.getValues();
                                reCalculate(data, exParticipants);
                            }
                        }}
                        defaultChecked={excludeParticipants.findIndex((x) => x.inn === winner.inn) > -1}
                        checked={excludeParticipants.findIndex((x) => x.inn === winner.inn) > -1}
                    ></Checkbox>
                </div>
                <div className="w-full">
                    {excludeParticipants.findIndex((x) => x.inn === winner.inn) > -1 && (
                        <Textarea
                            placeholder="Введите причину исключения"
                            defaultValue={
                                excludeParticipants[excludeParticipants.findIndex((x) => x.inn === winner.inn)].comment
                            }
                            onChange={(e) => {
                                let index = excludeParticipants.findIndex((x) => {
                                    return x.inn === winner.inn;
                                });
                                excludeParticipants[index].comment = e.currentTarget.value;
                                setExcludeParticipants(excludeParticipants);
                            }}
                            onBlur={() => {
                                let data = methods.getValues();
                                reCalculate(data);
                            }}
                        ></Textarea>
                    )}
                </div>
            </div>
        </div>
    );

    const renderWinners = () => (
        <>
            <div className="grid grid-cols-9 gap-0 pt-1">
                <div className="col-span-4 invisible" />
                <div className="col-span-1 flex justify-center items-center text-n1 bg-white pt-2 pb-2 ant-col-lg-offset-1">
                    <span>Оценка цены</span>
                </div>
                <div className="col-span-1 flex justify-center items-center text-n1 bg-white pt-2 pb-2">
                    <span>Оценка опыта</span>
                </div>
                <div className="col-span-1 flex flex-col justify-center items-center text-n1 bg-white pt-2 pb-2">
                    <span>Итоговая оценка</span>
                </div>
                <div className="col-span-2 flex justify-center items-center text-n1 bg-white pt-2 pb-2 ant-col-lg-offset-1">
                    <span>Исключить</span>
                </div>
            </div>
            {isExcludeTab ? excludeWinners?.map(renderWinner) : <></>}
            {searchResult && !isExcludeTab && searchResult.winners?.map(renderWinner)}
            {/* {excludedOnlyFilter ? excludedCompanies?.map(renderWinner) : winnersState?.map(renderWinner)} */}
            {searchResult && !isExcludeTab && (
                <div>
                    <SmartBusinessPagination
                        current={searchResult.page}
                        total={searchResult.pageCount}
                        onChange={(numPAge: number) => {
                            fetchWinners(
                                currentForm.current,
                                numPAge,
                                currentSearchParam.current?.filters,
                                currentSearchParam.current?.hashId,
                            );
                        }}
                    />
                </div>
            )}
        </>
    );

    const renderFetchWinnersResult = () => {
        if (isLoading) {
            return <Preloader size="xl" />;
        }

        if (searchResult === undefined || searchResult.winners === undefined || searchResult.winners === null) {
            return (
                <div className="bg-white flex justify-center p-5 tablet:p-8">
                    <span>Не был получен ответ. Пожалуйста, ограничьте критерии запроса.</span>
                </div>
            );
        }

        if (searchResult && searchResult.winners) {
            return renderWinners();
        }

        return (
            <div className="bg-white flex justify-center p-5 tablet:p-8">
                <span>Данных по заданным параметрам не найдено</span>
            </div>
        );
    };

    const clickFilter = (type: string) => {
        let result: string[];
        if (type === 'all') {
            result = [];
        } else {
            if (filters.includes('msp')) {
                result = filters.filter((item) => item !== type);
            } else {
                result = [...filters, type];
            }
        }
        setFilters(result);
        fetchWinners(currentForm.current, 1, result, searchResult?.hashId);
    };

    const renderWinnersFilters = () => (
        <div className="filters-conteiner">
            <Button
                size="s"
                buttonType={filters.length === 0 ? 'primary' : 'white'}
                onClick={() => {
                    clickFilter('all');
                }}
            >
                <>
                    {/* {`Все `} {counts === undefined ? <Preloader size="m" /> : counts.all} */}
                    {`Все `} {counts !== undefined ? counts.all : <></>}
                </>
            </Button>
            <Button
                size="s"
                buttonType={filters.includes('msp') ? 'primary' : 'white'}
                disabled={counts?.msp === '-'}
                onClick={() => {
                    clickFilter('msp');
                }}
            >
                <>
                    {/* {`МСП `} {counts === undefined ? <Preloader size="m" /> : counts.msp} */}
                    {`МСП `} {counts !== undefined ? counts.msp : <></>}
                </>
            </Button>
            <Button
                size="s"
                buttonType={filters.includes('noBankrupt') ? 'primary' : 'white'}
                disabled={counts?.noBankrupt === '-'}
                onClick={() => {
                    clickFilter('noBankrupt');
                }}
            >
                <>
                    {/* {`БЕЗ БАНКРОТСТВА/ЛИКВИДАЦИИ `} {counts === undefined ? <Preloader size="m" /> : counts.noBankrupt} */}
                    {`БЕЗ БАНКРОТСТВА/ЛИКВИДАЦИИ `} {counts !== undefined ? counts.noBankrupt : <></>}
                </>
            </Button>
            <Button
                size="s"
                buttonType={filters.includes('noRnp') ? 'primary' : 'white'}
                disabled={counts?.noRnp === '-'}
                onClick={() => {
                    clickFilter('noRnp');
                }}
            >
                <>
                    {/* {`НЕТ В РНП `} {counts === undefined ? <Preloader size="m" /> : counts.noRnp} */}
                    {`НЕТ В РНП `} {counts !== undefined ? counts.noRnp : <></>}
                </>
            </Button>
            <Button
                size="s"
                buttonType={filters.includes('innOnly') ? 'primary' : 'white'}
                disabled={counts?.innOnly === '-'}
                onClick={() => {
                    clickFilter('innOnly');
                }}
            >
                <>
                    {/* {`С ИНН `} {counts === undefined ? <Preloader size="m" /> : counts.innOnly} */}
                    {`С ИНН `} {counts !== undefined ? counts.innOnly : <></>}
                </>
            </Button>

            <Button
                size="s"
                buttonType={isExcludeTab ? 'primary' : 'white'}
                disabled={excludeWinners.length === 0}
                onClick={() => {
                    setIsExcludeTab(true);
                }}
            >
                <>
                    {`ИСКЛЮЧЕННЫЕ `} {searchResult?.filters.exclude_participants?.length!}
                </>
            </Button>
        </div>
    );

    return (
        <>
            {renderWinnersForm()}
            {renderWinnersFilters()}
            {renderFetchWinnersResult()}
            {/* {excludedParticipants?.length === 0 && excludedOnlyFilter ? (
                <div className="bg-white flex justify-center p-5 tablet:p-8">
                    <span> В списке нет исключенных компаний.</span>
                </div>
            ) : ( */}

            {/* )} */}
        </>
    );
};

export default SmartBusinessWinners;
