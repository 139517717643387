import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';

export class AddressBookService {
    /*
    static async fetchScheme(types: any, query: any, selected: any): Promise<AxiosResponse<IAddressBookScheme, any>> {
        let q = query ? query : 'null';
        let s = selected ? selected : 'null';
        return $api.get<IAddressBookScheme>(`/addressbook/scheme/${types}/${q}/${s}`);
    }
    
    static async fetchData(
        types: any,
        query: any,
        selected: any,
        loadOptions: any
    ): Promise<AxiosResponse<IGridResponse, any>> {
        let q = query ? query : 'null';
        let s = selected ? selected : 'null';
        return $api.get<IGridResponse>(`/addressbook/data/${types}/${q}/${s}${loadOptions}`);
    }
    */
    static async fetchTreeData(
        cache: string,
        node: string,
        items: number[],
        loadOptions: any,
    ): Promise<AxiosResponse<IAddressBookData, any>> {
        return $api.get<IAddressBookData>(
            `/addressbook/tree/data/get${loadOptions}&cache=${cache}&node=${node ?? ''}&items=${items?.length > 0 ? items.join(',') : ''}`,
        );
    }

    static async fetchListData(
        cache: string,
        items: number[],
        loadOptions: any,
    ): Promise<AxiosResponse<IAddressBookData, any>> {
        return $api.get<IAddressBookData>(
            `/addressbook/list/data/get${loadOptions}&cache=${cache}&items=${items?.length > 0 ? items.join(',') : ''}`,
        );
    }
    static async fetchExternalSearch(
        searchApiUrl: string,
        searchText: string,
        loadOptions: any,
    ): Promise<AxiosResponse<IAddressBookData, any>> {
        return $api.get<IAddressBookData>(`${searchApiUrl}${searchText}/get${loadOptions}`);
    }

    static async saveExternalSearch(saveApiUrl: string, data: IAddressBookData): Promise<AxiosResponse<any>> {
        return $api.post<any>(`${saveApiUrl}`, data);
    }

    static async fetchListSelectedKeys(cache: string, items: number[]): Promise<AxiosResponse<number[], any>> {
        return $api.get<number[]>(
            `/addressbook/list/selectedKeys/get?cache=${cache}&items=${items?.length > 0 ? items.join(',') : ''}`,
        );
    }
}
