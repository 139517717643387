import './ActionButton.scss';
import React, { useState } from 'react';
import { AllMenuHorizontal } from '@/indexIcon';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionService } from '@services/actions/IActionService';
import { IDocBasedService } from '@services/actions/IDocBasedService';
import { IListElement } from '@/types';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import Preloader from '@atoms/Preloader';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertActions } from '@utils/actions/helper';

export interface IActionButtonProps {
    objId: string;
    objData?: any;
    service?: IActionService<IBaseAction>;
    onActionClick?: () => void;
    onModifyData?: () => void;
}

const ActionButton: React.FC<IActionButtonProps> = (props: IActionButtonProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onActionClick = (act: IBaseAction) => {
        let executor = props.service?.getActionExecutor(act);
        if (executor) {
            executor.navigate = navigate;
            executor.location = location;
        }

        let docsBasedService = props.service as unknown as IDocBasedService;
        let parentId: string | undefined = undefined;
        if (docsBasedService) {
            parentId = docsBasedService.documentId;
        }

        executor?.run(
            props.objId,
            parentId,
            act,
            props.objData,
            (isSucceed) => {
                setActionExecutor(undefined);

                if (isSucceed && props.onModifyData) {
                    props.onModifyData();
                }
            },
            () => {
                props.onActionClick && props.onActionClick();
                setActionExecutor(undefined);
                setActionExecutor(executor);
            },
        );
        setActionExecutor(executor);
    };

    const [loadingIndicators, setLoadingIndicators] = useState<{ [id: string]: boolean }>({});
    const [actItems, setActItems] = useState<IListElement[] | undefined>(
        convertActions(props.objData?.actions, onActionClick),
    );
    const [actionExecutor, setActionExecutor] = useState<IActionExecutor>();

    return (
        <>
            {!props?.objData?.hideActions && (
                <Menu list={actItems} position="bottom-end">
                    <div className="actionButton">
                        {loadingIndicators[props.objId] ? (
                            <Preloader size="s" />
                        ) : (
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxxs"
                                aria-label="Действия"
                                startAdornment={<AllMenuHorizontal size="xxs" />}
                                onClick={(e) => {
                                    if (!actItems && !loadingIndicators[props.objId]) {
                                        let newLoadingIndicators = { ...loadingIndicators };
                                        newLoadingIndicators[props.objId] = true;
                                        setLoadingIndicators(newLoadingIndicators);

                                        props.service
                                            ?.fetchActions(props.objId)
                                            .then((res) => {
                                                let acts = convertActions(res.data, onActionClick);
                                                setActItems(acts);
                                            })
                                            .finally(() => {
                                                let newLoadingIndicators = { ...loadingIndicators };
                                                newLoadingIndicators[props.objId] = false;
                                                setLoadingIndicators(newLoadingIndicators);
                                            });
                                    }
                                }}
                            />
                        )}
                    </div>
                </Menu>
            )}

            {actionExecutor?.visualElement && actionExecutor?.visualElement()}
        </>
    );
};

export default ActionButton;
