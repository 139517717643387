import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DataGrid, { Column, Editing, Grouping, Lookup, Selection } from 'devextreme-react/ui/data-grid';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import {
    ICopyAndEditAttachesAddResponse,
    ICopyAndEditAttachesData,
    ICopyAndEditAttachesItem,
    ICopyAndEditAttachesScheme,
} from '@/models/attaches/ICopyAndEditAttaches';
import dxDataGrid, { EditingStartEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { EventInfo } from 'devextreme/events';
import { onCellHoverChanged } from '@/utils/dataGridUtils';

export interface ICopyAndEditAttachFilesBlockProps {
    id?: string;
    scheme: ICopyAndEditAttachesScheme;
    data: ICopyAndEditAttachesData;
    onFilesSelected: (files: ICopyAndEditAttachesAddResponse[]) => void;
}

export const CopyAndEditAttachFilesBlock = forwardRef((props: ICopyAndEditAttachFilesBlockProps, ref) => {
    const [files, setFiles] = useState<ICopyAndEditAttachesItem[]>(props.data.data);
    const [selected, setSelected] = useState<string[]>();
    const [init, setInit] = useState<boolean>(false);

    const onSelectionChanged = (e: SelectionChangedEvent<any, string>) => {
        let keys = e.selectedRowKeys;
        let data = e.selectedRowsData;

        // Нельзя снимать выделение с ReadOnly строк
        let readOnlyKeys = e.currentDeselectedRowKeys.filter(
            (key) => props.data.data.findIndex((data) => data.key == key && data.isReadOnly) != -1,
        );
        keys = keys.concat(readOnlyKeys);

        // Снятие выделение у всех одинаковых файлов
        let deselectedKeys = e.currentDeselectedRowKeys.filter((key) => !readOnlyKeys.includes(key));
        let deselectedData = files.filter((data) => deselectedKeys.includes(data.key));
        keys = keys.filter((key) => {
            let existAttachKey = files.find((file) => file.key == key)?.existAttachKey;
            return (
                deselectedData.findIndex((data) => data.isExistAttach && data.existAttachKey == existAttachKey) == -1
            );
        });

        setSelected(keys);

        updateSelectedFiles(keys);
    };

    const updateSelectedFiles = (keys: string[]) => {
        var selectedData = files.filter((file) => keys.findIndex((key) => file.key == key) != -1);

        var responseData = selectedData.map((data) => {
            return {
                key: data.key,
                fields: Object.assign({}, data.fields), //data.fields
            } as ICopyAndEditAttachesAddResponse;
        });

        props.onFilesSelected(responseData);
    };

    const onContentReady = (e: any) => {
        // Инициализируем стартовая
        if (!init) {
            var items = props.data.data.filter((data) => data.isExpanded);
            items.forEach((item) => {
                var groupIndex0ColumnKey = props.scheme.properties.find((item) => item.groupIndex == 0)?.key;
                var groupIndex1ColumnKey = props.scheme.properties.find((item) => item.groupIndex == 1)?.key;

                var key0 = groupIndex0ColumnKey?.split('.')[1];
                var key1 = groupIndex1ColumnKey?.split('.')[1];

                e.component.expandRow([item?.fields[key0!]]);
                e.component.expandRow([item?.fields[key0!], item?.fields[key1!]]);
            });

            setInit(true);
        }
    };

    useEffect(() => {
        let keys = files
            .filter((item: ICopyAndEditAttachesItem) => item.isSelected)
            .map((item: ICopyAndEditAttachesItem) => item.key);

        setSelected(keys);
    }, [files]);

    const gridRef = useRef<DataGrid>(null);

    function onRowUpdated(e: EventInfo<dxDataGrid<any, any>>): void {
        updateSelectedFiles(selected ?? []);
    }

    function onEditingStart(e: EditingStartEvent<any, any>): void {
        if (e.column?.dataField == 'fields.AttachmentPublication') {
            // Признак "Публикация" всегда доступен для редактирования
            e.cancel = false;
        } else {
            // isReadOnly - признок только для чтения с бэка (правило на основе категории и свойств аттача)
            // isAutoCreate - признак автоматического формирования документации
            let isAutoCreate = e.data.fields.AttachmentAutoCreate;
            let cancel = e.data.isReadOnly || isAutoCreate;
            e.cancel = cancel;
        }
    }

    const calcDictCell = (dictName: string, cellData: any) => {
        var dictData = props.scheme.dictionaries[dictName];
        return dictData.find((item) => item.code.toLowerCase() == cellData.toLowerCase())?.code ?? cellData;
    };

    return (
        <div data-testid={props.id ? `attach-files-block-${props.id}` : undefined}>
            <DevExpressDataGrid
                ref={gridRef}
                keyExpr="key"
                dataSource={files}
                remoteOperations={true}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                columnMinWidth={30}
                showColumnHeaders={true}
                columnAutoWidth={true}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Нет доступных файлов'}
                rowAlternationEnabled={true}
                onCellHoverChanged={onCellHoverChanged}
                onSelectionChanged={onSelectionChanged}
                onRowUpdated={onRowUpdated}
                onContentReady={onContentReady}
                onEditingStart={onEditingStart}
                showBorders={false}
                selectedRowKeys={selected}
                wordWrapEnabled={true}
            >
                <Grouping autoExpandAll={false} allowCollapsing={true} />

                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={false}
                    confirmDelete={false}
                />

                <Selection
                    mode="multiple"
                    allowSelectAll={false}
                    selectAllMode={'pages'}
                    showCheckBoxesMode={'always'}
                />

                {props?.scheme?.properties?.map((col, i) => {
                    return (
                        <Column
                            key={`col_${i}`}
                            allowFiltering={true}
                            caption={col.displayName}
                            dataField={col.key}
                            dataType={col.valueType}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            allowEditing={!col.isReadOnly}
                            groupIndex={col.groupIndex}
                            encodeHtml={true}
                        >
                            {props?.scheme?.dictionaries &&
                                col.dictName &&
                                props?.scheme?.dictionaries[col.dictName] && (
                                    <Lookup
                                        dataSource={props.scheme.dictionaries[col.dictName]}
                                        displayExpr="code"
                                        valueExpr="code"
                                        calculateCellValue={(cellData: any) => calcDictCell(col.dictName!, cellData)}
                                    />
                                )}
                        </Column>
                    );
                })}
            </DevExpressDataGrid>
        </div>
    );
});
