import React, { ReactNode, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './Abookpicker.scss';
import { Manager, Reference } from 'react-popper';
import { AllMenuHorizontal } from '@/indexIcon';
import Input from '../Input';
import { classnames } from '@utils/classnames';
import AbookpickerModal from './AbookpickerModal/AbookpickerModal';
import { IAbookSource, IAbookTab, IExternalSearchAbook, IGridModalAttributes, ISetValues } from '@models/Forms/IForms';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';

export interface IAbookpickerRefActions {
    reset: () => void;
    unSelect: (item: IAddressBookData) => void;
}

export interface IAbookpickerProps<T extends HTMLElement = HTMLDivElement> {
    /** Имя поля */
    name?: string;
    /** */
    isMultiple: boolean;
    /** сортировка (asc/desc) */
    sort?: string;
    /** */
    title: string;
    /** зависимые поля*/
    setValues?: ISetValues;
    /** колонки для грида */
    gridAttribute?: IGridModalAttributes;
    /** Текст Placeholder */
    placeholder?: string;
    /** Значение по умолчанию */
    defaultValue?: any;
    /** отображаемое значение */
    diplayValue: string;
    /** Скрыть поле */
    disabled?: boolean;
    /** Только для просмотра */
    readOnly?: boolean;
    /** Функция измекнения значения */
    onChange?: (value: IAddressBookData[]) => void;
    /** Кастомная кнопка */
    children?: ReactNode | ReactNode[];
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /**
     * Добавляет инпуту белый фон
     * @default true
     */
    filled?: boolean;
    /** Сыылка на контейнер портала */
    containerRef?: RefObject<T>;
    /** Реф для пробрасывания действий по кастомным кнопкам */
    controlRef?: RefObject<IAbookpickerRefActions | null>;
    /**
     *  Добавлять фокус при выборе дат
     * @default false
     *  */
    isFocusBorder?: boolean;
    formValues: string;

    /**
     * Метод получения formValues
     * */
    getFormValues?: () => string;

    /**
     * Вкладки адресной книги
     * */
    tabsSettings?: IAbookTab[];

    /**
     * Дополнительная настройка источника данных (выбор данных из реквизитов-таблиц и т.д.)
     * */
    source?: IAbookSource;

    externalSearch?: IExternalSearchAbook;
    showChips: boolean;

    insideGrid?: boolean;
}

// FIXME: Добавить управление с клавиатуры
const Abookpicker: React.FC<IAbookpickerProps> = ({
    name = 'Abookpicker',
    placeholder = 'Выберите значение',
    defaultValue,
    diplayValue,
    gridAttribute,
    invalid = false,
    filled = true,
    disabled = false,
    readOnly = false,
    insideGrid = false,
    onChange,
    children,
    title,
    isMultiple,
    sort,
    containerRef,
    controlRef,
    formValues,
    isFocusBorder = false,
    tabsSettings,
    source,
    externalSearch,
    showChips,
    getFormValues = undefined,
}: IAbookpickerProps) => {
    useImperativeHandle(controlRef, () => ({
        reset: () => {},
        unSelect: (item: IAddressBookData) => {},
    }));

    // -------------------------------------------------------------------------------------------------------------------

    const AbookpickerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const [showAbookionary, toggleAbookionary] = useState<boolean>(!readOnly && insideGrid);

    const onClose = () => toggleAbookionary(false);

    // -------------------------------------------------------------------------------------------------------------------

    const [inputValue, setInputValue] = useState<number[]>();

    useEffect(() => {
        if (defaultValue === null || defaultValue === undefined || defaultValue === '') {
            setInputValue(undefined);
            return;
        }

        if (source?.table) {
            let keys = (defaultValue as any[])?.map((row: any) => parseInt(row[source.key]));
            if (keys) {
                setInputValue(keys);
            }
        } else {
            if (defaultValue.toString().indexOf('|') > 0) {
                let arr = [] as number[];
                defaultValue
                    .toString()
                    .split('|')
                    .forEach((item: string) => {
                        arr.push(Number(item));
                    });

                setInputValue(arr);
            } else {
                let arr = [] as number[];
                arr.push(Number(defaultValue));
                setInputValue(arr);
            }
        }
    }, [defaultValue]);

    return (
        <Manager>
            <div
                className={classnames(
                    'rf-abookpicker',
                    isFocusBorder && 'rf-abookpicker__focus-border',
                    disabled && 'rf-abookpicker--disabled',
                )}
                ref={AbookpickerRef}
            >
                <Reference>
                    {(referenceProps) => (
                        <div
                            {...referenceProps}
                            className={classnames('rf-abookpicker__input-wrapper', {
                                'rf-abookpicker__input-wrapper--disabled': disabled,
                                'rf-abookpicker__input-wrapper--readonly': readOnly,
                            })}
                            onClick={!readOnly ? (e: any) => toggleAbookionary((prev) => !prev) : () => {}}
                        >
                            {children || (
                                <Input
                                    name={name}
                                    value={diplayValue}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                    readOnly={true}
                                    withoutClasses={true}
                                    invalid={invalid}
                                    filled={filled}
                                    endAdornment={
                                        !readOnly && !disabled ? (
                                            <div className={'rf-abookpicker__calendar-chevron'}>
                                                <AllMenuHorizontal />
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            )}
                        </div>
                    )}
                </Reference>
                {showAbookionary && (
                    <AbookpickerModal
                        selected={inputValue!}
                        isMultiple={isMultiple}
                        sort={sort}
                        title={title!}
                        externalSearch={externalSearch}
                        formValues={formValues}
                        getFormValues={getFormValues}
                        gridAttribute={gridAttribute}
                        onSubmitModal={onChange!}
                        onCloseModal={() => toggleAbookionary((prev) => !prev)}
                        tabsSettings={tabsSettings!}
                        showChips={showChips}
                    />
                )}
            </div>
        </Manager>
    );
};

export default Abookpicker;
