import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';

import './../actions.scss';
import { ActivityActionsService } from '@services/actions/ActivityActionsService';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';

export class RollbackPerformerExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IRollbackPerformerProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RollbackPerformerModal {...this._modalProps} /> : <></>;
    };
}

interface IRollbackPerformerProps {
    activityId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const RollbackPerformerModal: React.FC<IRollbackPerformerProps> = (props: IRollbackPerformerProps) => {
    const [errorText, setErrorText] = useState<string>();

    const messageBoxProp: IMessageDialogProps = {
        header: `${props.title}`,
        message: ``,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            let service = new ActivityActionsService();
            return service
                .saveRollbackPerformer(props.actionKey, props.activityId)
                .then(() => {
                    props.completeHandler && props.completeHandler(true);
                })
                .catch((error) => setErrorText(error));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} />;
};
