import React from 'react';
import { IIconProps } from '@/types';
import { iconSize } from '@utils/helpers';
/* eslint-disable max-len */

export default ({ size = 'xs', ...props }: IIconProps) => {
    return (
        <svg
            {...props}
            width={iconSize[size]}
            height={iconSize[size]}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m14.375 8c-2.4162 0-4.375 1.9587-4.375 4.375v15.25c0 2.4162 1.9588 4.375 4.375 4.375h11.25c2.4162 0 4.375-1.9588 4.375-4.375v-15.25c0-2.4162-1.9588-4.375-4.375-4.375zm11.356 3.5392c0.35838 0.38558 0.35838 1.0107 0 1.3963l-3.6339 3.9097c-0.50174 0.53981-1.3152 0.5398-1.8169 0l-2.0116-2.1643c-0.35839-0.38558-0.35839-1.0107 0-1.3963 0.35837-0.38558 0.93942-0.38558 1.2978 0l1.6223 1.7454 3.2445-3.4908c0.35838-0.38558 0.9395-0.38558 1.2979 0zm-11.731 9.4608c0-0.55225 0.44771-1 1-1h10c0.55225 0 1 0.44775 1 1s-0.44775 1-1 1h-10c-0.55229 0-1-0.44775-1-1zm1 5c-0.55229 0-1 0.44775-1 1s0.44771 1 1 1h10c0.55225 0 1-0.44775 1-1s-0.44775-1-1-1z"
                fill="currentColor"
            />
        </svg>
    );
};
