import React, { FC, useState } from 'react';
import { RouteNames } from '@/router';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import './Navbar.scss';
import logo from '@images/logo.svg';
import Search from '@atoms/Search';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import { ArrowsDown, Doc, Notification, WebsiteCall } from '@/indexIcon';
import { IListElement } from '@/types';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IHistorySearchItem } from '@models/search/IHistorySearchItem';
import SupportModal from '@atoms/SupportModal/SupportModal';
import UserPanel from '../UserPanel/UserPanel';
import List from '@atoms/Menu/List';
import VersionPanel from '../VersionPanel/VersionPanel';

const Navbar: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const user = useTypedSelector((state) => state.auth.user);
    const { logout, searchDocumentsGlobal } = useActions();

    const setMailDeputyItem: IListElement = {
        value: '103',
        label: 'Замещение сотрудников и уведомления',
        handler: () => {
            navigate(RouteNames.REDIRECTS_AND_REPLACEMENTS);
        },
    };
    const viewAccessGroupsItem: IListElement = {
        value: '104',
        label: 'Роли',
        handler: () => {
            navigate(RouteNames.ROLES);
        },
    };
    const selfEditNotificationFilteringItem: IListElement = {
        value: '105',
        label: 'Настройка уведомлений',
        handler: () => {
            navigate(RouteNames.NOTIFICATIONSETTINGS);
        },
    };

    const exitItem: IListElement = {
        value: '106',
        label: 'Выход',
        separated: true,
        handler: () => {
            logout();
        },
        style: { color: 'red' },
        separatedBottom: true,
    };

    const _listMenu: IListElement[] = [];

    _listMenu.push(setMailDeputyItem);

    _listMenu.push(viewAccessGroupsItem);

    _listMenu.push(selfEditNotificationFilteringItem);

    if (user.canView.signOut) {
        _listMenu.push(exitItem);
    }

    const onKeyUp = (e: any) => {
        if (e.keyCode == 13) {
            let searchString = e.target.value;
            doSearchByString(searchString);
        }
    };

    const doSearchByString = (searchString: any) => {
        if (location.pathname !== RouteNames.SEARCH) {
            navigate(`${RouteNames.SEARCH}?text=` + searchString);
        } else {
            setSearchParams({ text: searchString });
            searchDocumentsGlobal({ text: searchString, requireTotalCount: true });
        }
    };

    const onHistorySearchSelected = (historyItem: IHistorySearchItem) => {
        doSearchByString(historyItem.text);
    };

    const onSupportToggle = () => {
        setShowSupportModal(!showSupportModal);
    };

    const getUserMenu = (list: IListElement[]) => {
        return (
            <>
                {' '}
                <UserPanel />
                <List list={list} />
                <VersionPanel />
            </>
        );
    };

    return (
        <div className="rf-navbar">
            <Link to="/">
                {' '}
                <div className="logo-button">
                    <img src={logo} />
                </div>
            </Link>
            <div className="controls">
                <div className="search">
                    <Search width={350} onKeyUp={onKeyUp} onHistorySearchSelected={onHistorySearchSelected} />
                </div>
                <div className="items">
                    {/*{user.canView.adminPanel && (
                        <Button
                            buttonType="round"
                            textColor="neutral"
                            title="Администрирование"
                            size="m"
                            startAdornment={<Cog size="m" />}
                        ></Button>
                    )}*/}
                    {user.canView.documentation && (
                        <Button
                            buttonType="round"
                            textColor="neutral"
                            title="Документация"
                            size="m"
                            startAdornment={<Doc size="m" />}
                            onClick={() => {
                                navigate(RouteNames.DOCUMENTATION);
                            }}
                        ></Button>
                    )}
                    {user.canView.support && (
                        <Button
                            buttonType="round"
                            title="Техническая поддержка"
                            textColor="neutral"
                            size="m"
                            startAdornment={<WebsiteCall size="m" />}
                            onClick={onSupportToggle}
                        ></Button>
                    )}
                    <Button
                        buttonType="round"
                        title="Уведомления"
                        textColor="neutral"
                        size="m"
                        startAdornment={<Notification size="m" />}
                        onClick={() => {
                            navigate(RouteNames.NOTIFICATION);
                        }}
                    ></Button>
                </div>
                {showSupportModal && <SupportModal onCloseModal={onSupportToggle} />}
                <div className="user">
                    <div>
                        <Menu content={getUserMenu(_listMenu)} position="top-start">
                            {/*<Avatar fullName= {user.fullName} size='m' photo={user.photo} />*/}
                            <div className="user-menu">
                                <Button buttonType={'text'} textColor="neutral" size="m" endAdornment={<ArrowsDown />}>
                                    {user.fullName}
                                </Button>
                            </div>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
