import {
    IAddRow,
    IChange,
    IChanges,
    IClearTables,
    IDeleteRow,
    IDocumentTable,
    IFormValues,
    ISetValue,
    IUpdateRow,
} from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from './FormulaManager';
import { UseFormReturn } from 'react-hook-form';
import { isIsoDateNoTimeString, isIsoDateString } from './documentUtils';
import { sendNotification } from '@molecules/Notifications';
import { naiveRound } from './helpers';
import { DictionariesService } from '@/services/DictionariesService';
import { IErrorMessage } from '@/components/molecules/Errors/Errors';
import Moment from 'moment';

export const clearTables = (
    clearTables: IClearTables[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    clearTables?.forEach((item) => {
        if (item.table) {
            let table = fields[item.table];
            if (item.column) {
                let tableRows = formMethods.getValues(table.name as any) as any[];
                for (let index = 0; index < tableRows.length; index++) {
                    const element = tableRows[index];
                    if (item.subTable) {
                        let subTableRows = element[item.subTable];
                        for (let index = 0; index < subTableRows.length; index++) {
                            subTableRows[index][item.column] = null;
                        }
                        element[item.subTable] = subTableRows;
                    } else {
                        element[item.column] = null;
                    }
                    tableRows[index] = element;
                }
                formMethods.setValue(table.name as any, tableRows as any, { shouldDirty: true });
            } else {
                if (item.subTable) {
                    let tableRows = formMethods.getValues(table.name as any) as any[];
                    for (let index = 0; index < tableRows.length; index++) {
                        const element = tableRows[index];
                        element[item.subTable] = [] as any;
                        tableRows[index] = element;
                    }
                    formMethods.setValue(table.name as any, tableRows as any, {
                        shouldDirty: true,
                    });
                } else {
                    formMethods.setValue(table.name as any, [] as any, { shouldDirty: true });
                }
            }
        }
    });
};

const getFormValues = (
    formdataParams: IFormValues,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    const map = {} as Record<string, any>;
    if (formdataParams && formdataParams != null) {
        for (const ele of formdataParams?.formValue!) {
            let field = fields[ele.attr];
            let fname = ('fields.[' + field.index + '].value') as any;
            let val = formMethods.getValues(fname);
            if (ele.column) {
                if (ele.function === '{join}') {
                    map[ele.key] = (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                } else {
                    map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                }
            } else {
                map[ele.key] = val;
            }
        }
    }
    return JSON.stringify(map);
};

export const addRow = async (
    addRows: IAddRow[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
    rowData?: any,
) => {
    if (addRows) {
        for (let index = 0; index < addRows.length; index++) {
            const item = addRows[index];
            if (item.table) {
                let table = fields[item.table];

                let rowcount = 1;
                if (item.countRows) {
                    let count = new FormulaManager(item.countRows);
                    count.Init(fields, formMethods);
                    rowcount = await count.EvalFormulaValues(true, false, undefined);
                }
                let tableRows = formMethods.getValues(table.name as any) as any[];
                let exRows: any[] = [];
                if (tableRows == undefined || tableRows == null) tableRows = [];
                if (item.externalDataSource != null) {
                    let params =
                        '?formValues=' +
                        getFormValues(item.externalDataSource.formDataSource.formValues, formMethods, fields);

                    await DictionariesService.getGridFormdataItems(
                        '-1',
                        item.externalDataSource.formDataSource.dictName,
                        params,
                    ).then((response) => {
                        rowcount = response.data.totalCount;
                        exRows = response.data.data;
                    });
                }

                for (let indexRow = 0; indexRow < rowcount; indexRow++) {
                    const obj: Record<string, any> = {};
                    if (exRows.length > 0) {
                        let exRowData: Record<string, any> = {};
                        exRows[indexRow].fields.forEach((item: any) => {
                            exRowData['|' + item.name] = item.value;
                        });
                        rowData = exRowData;
                    }
                    for (let index = 0; index < item.setValues.sets.length; index++) {
                        const element = item.setValues.sets[index];
                        let objCopy = { ...element };
                        let formula = element.attr.replace(/\{index\}/g, indexRow.toString());

                        objCopy.attr = replaceCurrentRow(objCopy.attr, formula, rowData);
                        obj[element.key] = await GetValueForSetValue(objCopy, undefined, fields, rowData, formMethods);
                    }

                    tableRows.push(obj);
                }
                formMethods.setValue(table.name as any, tableRows as any, { shouldDirty: true });
            }
        }
    }
};

const replaceCurrentRow = (rule: string, formula: string, row?: any) => {
    const regex = /{\[currentRow\](?<column>.*?)\}/gm;

    let m;
    while ((m = regex.exec(rule)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        let match = m[0];
        let columnCurrentRow = m.groups?.column;
        if (columnCurrentRow) {
            if (row[columnCurrentRow]) {
                formula = formula.replace(match, row[columnCurrentRow]);
            } else {
                formula = formula.replace(match, '0');
            }
        }
    }
    return formula;
};

const proccesingUpdateRow = async (
    tableRows: any[],
    item: IUpdateRow,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    let newArr: any[] = [];

    for (let index = 0; index < tableRows.length; index++) {
        const obj = tableRows[index];
        let objCopy = { ...obj };
        newArr.push(objCopy);
    }

    for (let indexRow = 0; indexRow < newArr.length; indexRow++) {
        let row = newArr[indexRow];

        let checkResult = true;
        if (item.filter) {
            let filterMng = new FormulaManager(item.filter);
            filterMng.Init();
            let coll = filterMng.GetFields();
            let values: any[] = [];
            coll.forEach((field) => {
                let val: any = undefined;
                if (row && row[field]) {
                    val = row[field];
                    values.push(val);
                }
            });
            checkResult = await filterMng.EvalFormulaValues(false, false, values);
        }
        if (checkResult) {
            for (let index = 0; index < item.setValues.sets.length; index++) {
                const set = item.setValues.sets[index];
                let objCopy = { ...set };
                let formula = set.attr.replace(/\{index\}/g, indexRow.toString());

                objCopy.attr = replaceCurrentRow(set.attr, formula, row);
                newArr[indexRow][set.key] = await GetValueForSetValue(objCopy, undefined, fields, row, formMethods);
            }
        }
    }
    return newArr;
};
const proccesingDeleteRow = async (
    tableRows: any[],
    item: IDeleteRow,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    let newArr: any[] = [];

    for (let index = 0; index < tableRows.length; index++) {
        const obj = tableRows[index];
        let objCopy = { ...obj };

        let checkResult = false;
        if (item.filter) {
            let filter = item.filter;
            filter = replaceCurrentRow(filter, item.filter, objCopy);
            let filterMng = new FormulaManager(filter);
            filterMng.Init();
            let coll = filterMng.GetFields();
            let values: any[] | undefined = undefined;
            if (formMethods && objCopy && coll.length > 0) {
                values = GetRowFormulaValues(coll, undefined, fields, objCopy, formMethods);
            }
            checkResult = await filterMng.EvalFormulaValues(true, true, values);
        }
        if (!checkResult) {
            newArr.push(objCopy);
        }
    }

    return newArr;
};
export const updateRow = async (
    updateRows: IUpdateRow[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    if (updateRows) {
        for (let index = 0; index < updateRows.length; index++) {
            const item = updateRows[index];
            if (item.table) {
                let table = fields[item.table];
                let tableRows = formMethods.getValues(table.name as any) as any[];

                if (tableRows != undefined && tableRows != null && tableRows.length > 0) {
                    let result: any;
                    if (item.subTable) {
                        let parent: any[] = [];

                        for (let index = 0; index < tableRows.length; index++) {
                            const obj = tableRows[index];
                            let objCopy = { ...obj };
                            parent.push(objCopy);
                        }
                        for (let indexRow = 0; indexRow < parent.length; indexRow++) {
                            let row = parent[indexRow];
                            let subTableRows = row[item.subTable];
                            if (subTableRows != undefined) {
                                parent[indexRow][item.subTable] = await proccesingUpdateRow(
                                    subTableRows,
                                    item,
                                    formMethods,
                                    fields,
                                );
                            }
                        }
                        result = parent;
                    } else {
                        result = await proccesingUpdateRow(tableRows, item, formMethods, fields);
                    }

                    formMethods.setValue(table.name as any, result, { shouldDirty: true });
                }
            }
        }
    }
};
export const deleteRow = async (
    deleteRows: IDeleteRow[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    if (deleteRows) {
        for (let index = 0; index < deleteRows.length; index++) {
            const item = deleteRows[index];
            if (item.table) {
                let table = fields[item.table];
                let tableRows = formMethods.getValues(table.name as any) as any[];

                if (tableRows != undefined && tableRows != null && tableRows.length > 0) {
                    let result: any;
                    if (item.subTable) {
                        let parent: any[] = [];

                        for (let index = 0; index < tableRows.length; index++) {
                            const obj = tableRows[index];
                            let objCopy = { ...obj };
                            parent.push(objCopy);
                        }
                        for (let indexRow = 0; indexRow < parent.length; indexRow++) {
                            let row = parent[indexRow];
                            let subTableRows = row[item.subTable];
                            parent[indexRow][item.subTable] = await proccesingDeleteRow(
                                subTableRows,
                                item,
                                formMethods,
                                fields,
                            );
                        }
                        result = parent;
                    } else {
                        result = await proccesingDeleteRow(tableRows, item, formMethods, fields);
                    }

                    formMethods.setValue(table.name as any, result, { shouldDirty: true });
                }
            }
        }
    }
};

export const GetValueForSetValue = async (
    item: ISetValue,
    tableName: any,
    data: Record<string, IFieldElem>,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
) => {
    let val: any = undefined;
    let formulaMng = new FormulaManager(item.attr);
    formulaMng.Init(data, formMethods);
    let coll = formulaMng.GetFields();
    let values: any[] | undefined = undefined;
    if (formMethods && rowData && coll.length > 0 && !coll.every((element) => element === undefined)) {
        values = GetRowFormulaValues(coll, tableName, data, rowData, formMethods);
    }
    switch (item.type) {
        case 'text':
            val = await formulaMng.ReplaceFormulaValues(true, true, values, true);
            break;
        case 'calc': {
            try {
                let valCalc = await formulaMng.EvalFormulaValues(true, true, values);
                if (isNaN(valCalc) && typeof valCalc !== 'string') {
                    val = 0;
                } else {
                    if (typeof valCalc == 'string' && valCalc.indexOf(',')) {
                        valCalc = valCalc.replace(',', '.');
                    }
                    val = +valCalc;
                    if (item.floatPoints) {
                        val = naiveRound(val, item.floatPoints);
                    }
                }
            } catch (error) {
                console.log(error);
                val = 0;
            }
            break;
        }
        case 'date': {
            try {
                val = await formulaMng.EvalFormulaValues(true, true, values);
                try {
                    let parseVal = new Date(val);
                    if (!isNaN(parseVal.getTime()) && parseVal.getTime() > 0) {
                        val = parseVal;
                    } else {
                        throw new Error('parse Date');
                    }
                } catch (error) {
                    let parts = val.split(' ');
                    if (parts.length > 1) {
                        val = Moment(val, 'DD.MM.YYYY hh.mm.ss').toDate();
                    } else {
                        if (Moment(val, 'DD.MM.YYYY').isValid()) {
                            val = Moment(val, 'DD.MM.YYYY').toDate();
                        } else {
                            if (Moment(val).isValid()) {
                                val = Moment(val).toDate();
                            } else {
                                val = null;
                            }
                        }
                    }
                }
            } catch (error) {
                val = null;
            }
            break;
        }
        default:
            val = item.attr;
            break;
    }
    return val;
};

export const GetRowFormulaValues = (
    valuesFields: string[],
    tableName: any,
    data: Record<string, IFieldElem>,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
) => {
    let values: any[] = [];
    if (formMethods) {
        valuesFields.forEach((field) => {
            let val: any = undefined;
            if (field.includes('|Document')) {
                val = formMethods.getValues(data[field].name);
                if (field == tableName) {
                    if (val == undefined) {
                        val = [];
                    }
                    let arr = val as any[];
                    let indexRow = arr.findIndex((f) => {
                        return f['|NUM'] == rowData['|NUM'];
                    });
                    if (indexRow == -1) {
                        arr.push(rowData);
                    } else {
                        arr[indexRow] = rowData;
                    }
                    val = arr;
                }
            } else {
                val = rowData[field];
            }
            values?.push(val);
        });
    }
    return values;
};

export const checkCondition = async (
    change: IChange,
    value: any,
    fieldName: string,
    isEdit: boolean,
    isNew: boolean,
    data: Record<string, IFieldElem>,
    formMethods?: UseFormReturn<any>,
    rowData?: any,
) => {
    let val = value;
    if (val instanceof Date) {
        val = val.getTime().toString();
    } else {
        if (isIsoDateString(val) || isIsoDateNoTimeString(val)) {
            let valDate = new Date(val); // Moment(value).toDate();
            val = valDate.getTime().toString();
        }
    }

    let rule = change.condition.replace(/\{value\}/g, val);
    rule = replaceCurrentRow(change.condition, rule, rowData);
    let condition = new FormulaManager(rule);
    condition.Init(data, formMethods);
    let valuesFields = condition.GetFields();

    let values: any[] | undefined = undefined;
    if (formMethods && rowData && valuesFields.length > 0) {
        values = GetRowFormulaValues(valuesFields, fieldName, data, rowData, formMethods);
    }
    if (!(await condition.EvalFormulaValues(isEdit, isNew, values))) {
        return false;
    } else {
        return true;
    }
};

export const executeChanges = async (
    change: IChange,
    data: Record<string, IFieldElem>,
    onSave: (item: ISetValue, table?: IDocumentTable, rowData?: any) => void,
    setError: (errors?: IErrorMessage) => void,
    table?: IDocumentTable,
    formMethods?: UseFormReturn<any>,
    rowData?: any,
) => {
    if (change?.setValues?.sets) {
        for (let index = 0; index < change?.setValues?.sets.length; index++) {
            const item = change?.setValues?.sets[index];
            await onSave(item, table, rowData);
        }
    }
    clearTables(change?.setValues?.clearTables!, formMethods!, data);
    await deleteRow(change?.setValues?.deleteRows!, formMethods!, data);
    await addRow(change?.setValues?.addRows!, formMethods!, data, rowData);
    await updateRow(change?.setValues?.updateRows!, formMethods!, data);
    if (change?.showErrors) {
        let messages: string[] = [];
        let type: string = 'error';
        change?.showErrors.messages.forEach((msg) => {
            messages.push(msg.value);
            if (msg.type) type = msg.type;
        });
        setError({ message: messages, type: type });
    }
    if (change?.showNotifications) {
        for (let index = 0; index < change?.showNotifications.notifications.length; index++) {
            const msg = change?.showNotifications.notifications[index];
            let txt = await GetTextForNotification(msg.text, table?.key, data, rowData, formMethods);
            if (txt) {
                sendNotification(
                    {
                        title: msg.title,
                        message: txt,
                        variant: msg.type,
                    },
                    msg.disabledAutoHide ? 100000 : undefined,
                );
            }
        }
    }
};

export const GetTextForNotification = async (
    text: string,
    tableName: any,
    data: Record<string, IFieldElem>,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
) => {
    if (text.indexOf('{') > -1 || text.indexOf('(') > -1) {
        let formulaMng = new FormulaManager(text);
        formulaMng.Init(data, formMethods);
        let coll = formulaMng.GetFields();
        let values: any[] | undefined = undefined;
        if (formMethods && rowData && coll.length > 0 && !coll.every((element) => element === undefined)) {
            values = GetRowFormulaValues(coll, tableName, data, rowData, formMethods);
        }

        let result = await formulaMng.ReplaceFormulaValues(true, true, values, true);
        return result;
    } else {
        return text;
    }
};

export const sortChanges = (changes: IChanges) => {
    let newArr: IChange[] = [];
    changes.change.forEach((obj: any) => {
        const objCopy = { ...obj };
        newArr.push(objCopy);
    });

    let arr = newArr.sort((a, b) => {
        if (a.index > b.index || a.condition == null) {
            return 1;
        } else {
            return -1;
        }
    });

    return arr;
};

export const ChangesFieldValue = async (
    changes: IChanges,
    value: any,
    fieldName: string,
    data: Record<string, IFieldElem>,
    isEdit: boolean,
    isNew: boolean,
    onSave: (item: ISetValue, table?: IDocumentTable, rowData?: any, value?: any) => void,
    setError: (errors?: IErrorMessage) => void,
    table?: IDocumentTable,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
) => {
    if (changes && changes.change.length > 0) {
        let arr = sortChanges(changes);
        for (let index = 0; index < arr.length; index++) {
            const change = arr[index];
            if (change.condition) {
                if (await checkCondition(change, value, fieldName, isEdit, isNew, data, formMethods, rowData)) {
                    await executeChanges(change, data, onSave, setError, table, formMethods, rowData);
                    if (change.changes && change.changes.change.length > 0) {
                        await ChangesFieldValue(
                            change.changes,
                            value,
                            fieldName,
                            data,
                            isEdit,
                            isNew,
                            onSave,
                            setError,
                            table,
                            rowData,
                            formMethods,
                        );
                    }
                } else {
                    if (change.elseChanges && change.elseChanges.changes.change.length > 0) {
                        await ChangesFieldValue(
                            change.elseChanges.changes,
                            value,
                            fieldName,
                            data,
                            isEdit,
                            isNew,
                            onSave,
                            setError,
                            table,
                            rowData,
                            formMethods,
                        );
                    }
                }
            } else {
                await executeChanges(change, data, onSave, setError, table, formMethods, rowData);
                if (change.changes && change.changes.change.length > 0) {
                    await ChangesFieldValue(
                        change.changes,
                        value,
                        fieldName,
                        data,
                        isEdit,
                        isNew,
                        onSave,
                        setError,
                        table,
                        rowData,
                        formMethods,
                    );
                }
            }
        }
    }
};
