import { InputControl, InputNumberControl, SelectControl } from '@/components/controls';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { ISmartBusinessParametersAttribute } from '@/models/smartBusiness/ISmartBusinessParametersAttribute';
import { SmartBusinessAttributeType } from '@/models/smartBusiness/SmartBusinessAttributeType';
import { IOption } from '@/types';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface IOwnProps {
    formMethods: UseFormReturn<ISmartBusinessParameters>;
    attributes: ISmartBusinessParametersAttribute[];
}

const suggestedValuesToOption = (suggestedValue: string): IOption => ({
    value: suggestedValue,
    label: suggestedValue,
});

export const SmartBusinessTendersAttributesField: FC<IOwnProps> = ({ formMethods, attributes }) => {
    return (
        <>
            {attributes.map((item, index) => {
                switch (item.type) {
                    case SmartBusinessAttributeType.float:
                        return (
                            <InputNumberControl
                                key={index}
                                label={item.name}
                                groupBy={0}
                                separator={''}
                                floatPoints={2}
                                name={`attributes.[0].[${index}].value`}
                                formState={formMethods.formState}
                                control={formMethods.control}
                            />
                        );
                        break;
                    case SmartBusinessAttributeType.int:
                        return (
                            <InputNumberControl
                                key={index}
                                label={item.name}
                                groupBy={0}
                                separator={''}
                                floatPoints={0}
                                name={`attributes.[0].[${index}].value`}
                                formState={formMethods.formState}
                                control={formMethods.control}
                            />
                        );
                        break;
                    case SmartBusinessAttributeType.enum:
                        return (
                            <SelectControl
                                key={index}
                                label={item.name}
                                setValue={(options) => {
                                    return options[0].value;
                                }}
                                getValue={(options: IOption[], value: any) => {
                                    return options.filter((x) => x.value === value);
                                }}
                                multiselect={false}
                                name={`attributes.[0].[${index}].value`}
                                options={item.suggestedValues?.map(suggestedValuesToOption)}
                                formState={formMethods.formState}
                                control={formMethods.control}
                            />
                        );
                        break;
                    default:
                        return (
                            <InputControl
                                key={index}
                                label={item.name}
                                name={`attributes.[0].[${index}].value`}
                                formState={formMethods.formState}
                                control={formMethods.control}
                            />
                        );
                        break;
                }
            })}
        </>
    );
};
