import { UseFormReturn } from 'react-hook-form';
import {
    IDictFilter,
    IDictFilters,
    IDocumentTable,
    IFormValues,
    ISetValue,
    ITableColumn,
    ITableColumnAbook,
    ITableColumnAutoComplete,
    ITableColumnCalc,
    ITableColumnDict,
} from '@models/Forms/IForms';
import { getFieldKeysByChanges, parseDisplayFormatCell } from '@utils/documentUtils';
import './EditTable.scss';
import { IField, IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import Dictpicker from '@atoms/Dictpicker';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import Abookpicker from '@atoms/Abookpicker';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import Checkbox from '@atoms/Checkbox';
import Textarea from '@atoms/Textarea';
import InputNumber from '@atoms/InputNumber/InputNumber';
import Datepicker from '@atoms/Datepicker';
import Input from '@atoms/Input';
import DisplayField from '@atoms/DisplayField/DisplayField';
import TableControl from '@controls/TableControl';
import { ChangesFieldValue, GetRowFormulaValues, GetValueForSetValue } from '@utils/ChangesManager';
import Select from '@atoms/Select';
import { IOption } from '@/types';
import { DictExternalDataSource } from '@utils/DictExternalDataSource';
import { ValueType } from '@/types/ValueType';
import { getOptions } from '@molecules/formbuilder/controls/AutoComplete/AutoCompleteHelper';
import AutoCompletePicker from '@atoms/AutoCompletePicker/AutoCompletePicker';
import {
    isTableColumn,
    isTableColumnAbook,
    isTableColumnAutoComplete,
    isTableColumnCalc,
    isTableColumnDict,
} from '@utils/tableHelper';
import { naiveRound } from '@/utils/helpers';
import Moment from 'moment';
import { IErrorMessage } from '@/components/molecules/Errors/Errors';
import InputMoney from '@/components/atoms/InputMoney';
import { ConfigService } from '@/configuration/services/configService';

export interface IEditTableProps<TFieldValues extends object = object> {
    table?: IDocumentTable;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: IErrorMessage) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    docId?: string;
}

const EditTable = <TFieldValues extends object = object>({
    table,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IEditTableProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const dataField = fields[table?.key!];
    let removeRowButtonRules = table?.removeRowButtonRules;
    const removeRowVisibilityMng = new FormulaManager(removeRowButtonRules!);
    let approvalListModeRules = table?.approvalListMode;
    const approvalListModeMng = new FormulaManager(approvalListModeRules!);
    let addRowButtonRules = table?.addRowButtonRules;
    const addRowVisibilityMng = new FormulaManager(addRowButtonRules!);
    removeRowVisibilityMng.Init(fields);
    addRowVisibilityMng.Init(fields);
    approvalListModeMng.Init(fields);

    const config = ConfigService.get();
    const moneyCulture: string = config.application.moneyCulture ?? 'ru-Ru';

    const [approvalListMode, setApprovalListMode] = useState<boolean>(false);
    const InitFormulas = async () => {
        if (approvalListModeRules) {
            let appMode = await approvalListModeMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setApprovalListMode(appMode);
            }
        }
        if (isNew && table?.invokeChangesOnNew) {
            await onTableChange(dataField.value, table);
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    useEffect(() => {
        if (isEdit && table?.invokeChangesOnEdit) {
            onTableChange(dataField.value, table);
        }
    }, [isEdit]);

    const onSaveField = async (item: ISetValue, table?: IDocumentTable, rowData?: any, value?: any) => {
        let val = value ? value : await GetValueForSetValue(item, table?.key, fields, rowData, formMethods);
        if (item.key.includes('|Document')) {
            let field = fields[item.key];
            formMethods.setValue(field.name as any, val, { shouldDirty: true });
        } else {
            const column = table?.tableColumn.find((col) => {
                return col.key == item.key;
            });
            if (
                column &&
                (column.valueType == ValueType.Date ||
                    column.valueType == ValueType.DateTime ||
                    column.valueType == ValueType.NoSecDateTime)
            ) {
                rowData[item.key] = new Date(val);
            } else {
                rowData[item.key] = val;
            }
        }
    };

    const getValue = (name: string, rowData?: any, rowParent?: any) => {
        if (name.includes('|Document')) {
            let field = fields[name];
            let fname = ('fields.[' + field.index + '].value') as any;
            let val = formMethods.getValues(fname);
            return val as any;
        } else if (name.includes('|Parent') && rowParent) {
            let fname = name.replace('|Parent', '');
            type ObjectKey = keyof typeof rowParent;
            const attrNAme = fname as ObjectKey;
            return rowParent[attrNAme] as any;
        } else {
            if (rowData) {
                type ObjectKey = keyof typeof rowData;
                const attrNAme = name as ObjectKey;
                return rowData[attrNAme] as any;
            }
        }
    };

    const evalTableFormulaValue = async (condition: string, rowData?: any, rowParent?: any) => {
        let conditionMng = new FormulaManager(condition);
        conditionMng.Init(fields!, formMethods, rowParent);
        let coll = conditionMng.GetFields();
        // var common = fields.filter((x) => keysNew.indexOf(x) !== -1);
        // if (common.length > 0) {
        let values: any[] | undefined = [];
        coll.forEach((f) => {
            if (f.includes('|Document')) {
                let field = fields[f];
                let fname = ('fields.[' + field.index + '].value') as any;
                let val = formMethods.getValues(fname);
                values?.push(val as any);
            } else if (f.includes('|Parent') && rowParent) {
                let fname = f.replace('|Parent', '');
                type ObjectKey = keyof typeof rowParent;
                const attrNAme = fname as ObjectKey;
                values?.push(rowParent[attrNAme] as any);
            } else {
                if (rowData) {
                    type ObjectKey = keyof typeof rowData;
                    const attrNAme = f as ObjectKey;
                    values?.push(rowData[attrNAme] as any);
                }
            }
        });
        if (values.every((element) => element === undefined)) {
            values = undefined;
        }

        return await conditionMng.EvalFormulaValues(isEdit, isNew, values);
    };

    const getFormValuesAsync = async (formValues: IFormValues, row: any) => {
        let formdataParams = formValues;
        const map = {} as Record<string, any>;
        if (formdataParams && formdataParams != null) {
            for (const ele of formdataParams?.formValue!) {
                if (ele.attr?.includes('|Document')) {
                    if (ele.formula) {
                        let manager = new FormulaManager(ele.formula);
                        manager.Init(fields, formMethods);
                        map[ele.key] = await manager.ReplaceFormulaValues(isEdit, isNew);
                    } else {
                        let field = fields[ele.attr];
                        let fname = ('fields.[' + field.index + '].value') as any;
                        let val = formMethods.getValues(fname);
                        if (ele.column) {
                            if (ele.function === '{join}') {
                                map[ele.key] =
                                    (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                            } else {
                                map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                            }
                        } else {
                            map[ele.key] = val;
                        }
                    }
                } else {
                    if (ele.formula) {
                        let manager = new FormulaManager(ele.formula);
                        manager.Init(fields, formMethods);
                        let _fields = manager.GetFields();
                        let values = _fields.map((field) => {
                            return getValue(field, row);
                        });
                        map[ele.key] = await manager.ReplaceFormulaValues(isEdit, isNew, values);
                    } else {
                        map[ele.key] = getValue(ele.attr, row);
                    }
                }
            }
        }
        return map;
    };

    const getFormValues = (formValues: IFormValues, row: any) => {
        let formdataParams = formValues;

        const map = {} as Record<string, any>;
        if (formdataParams && formdataParams != null) {
            for (const ele of formdataParams?.formValue!) {
                if (ele.attr.includes('|Document')) {
                    let field = fields[ele.attr];
                    let fname = ('fields.[' + field.index + '].value') as any;
                    let val = formMethods.getValues(fname);
                    if (ele.column) {
                        if (ele.function === '{join}') {
                            map[ele.key] = (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                        } else {
                            map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                        }
                    } else {
                        map[ele.key] = val;
                    }
                } else {
                    type ObjectKey = keyof typeof row;
                    const attrNAme = ele.attr as ObjectKey;
                    map[ele.key] = row[attrNAme] as any;
                }
            }
        }
        return JSON.stringify(map);
    };

    const getWatchesByFormula = (formula?: string, rowParent?: any) => {
        if (formula) {
            let vis = new FormulaManager(formula);
            vis.Init(fields, formMethods, rowParent);
            return vis.GetWatchFields();
        }

        return [];
    };

    const getColumnWatches = (table?: IDocumentTable, rowParent?: any) => {
        let result: string[] = [];
        let allColumns: any[] = [];
        allColumns = allColumns
            .concat(table?.tableColumn)
            .concat(table?.tableColumnAbook)
            .concat(table?.tableColumnCalc)
            .concat(table?.tableColumnDict);
        allColumns.forEach((col) => {
            if (col.visibilityRules != undefined && col.visibilityRules != null) {
                let vis = new FormulaManager(col.visibilityRules);
                vis.Init(fields, formMethods, rowParent);
                result = result.concat(vis.GetWatchFields());
            }
            if (col.required != undefined && col.required != null) {
                let vis = new FormulaManager(col.required);
                vis.Init(fields, formMethods, rowParent);
                result = result.concat(vis.GetWatchFields());
            }
            if (col.readonly != undefined && col.readonly != null) {
                let vis = new FormulaManager(col.readonly);
                vis.Init(fields, formMethods, rowParent);
                result = result.concat(vis.GetWatchFields());
            }
        });
        return result;
    };

    const getFiltersAsync = async (dictFilters: IDictFilters, row: any) => {
        let filters = dictFilters;
        let result = {} as IDictFilter;
        const map = {} as Record<string, any>;
        if (filters && filters != null) {
            for (const ele of filters.filter) {
                let conditionMng = new FormulaManager(ele.condition);
                conditionMng.Init(fields, formMethods);
                let coll = conditionMng.GetFields();
                let values: any[] = [];
                coll.forEach((f) => {
                    if (f.includes('|Document')) {
                        let field = fields[f];
                        let val = formMethods.getValues(field.name as any);
                        values.push(val);
                    } else {
                        type ObjectKey = keyof typeof row;
                        const attrNAme = f as ObjectKey;
                        values.push(row[attrNAme] as any);
                    }
                });
                if (await conditionMng.EvalFormulaValues(isEdit, isNew, values)) {
                    result.condition = ele.condition;
                    if (ele.filter) {
                        let filterMng = new FormulaManager(ele.filter);
                        filterMng.Init(fields, formMethods);
                        result.filter = (await filterMng.ReplaceFormulaValues(isEdit, isNew))!;
                    }
                    if (ele.script) {
                        let scriptMng = new FormulaManager(ele.script);
                        scriptMng.Init(fields, formMethods);
                        result.script = (await scriptMng.ReplaceFormulaValues(isEdit, isNew))!;
                    }
                }
            }
        }
        return result;
    };

    const getValues = (values: IDictionaryData[], attr: string) => {
        let result = [] as string[];
        values.forEach((item) => {
            if (attr == 'code') {
                result.push(item.code);
            } else {
                let elem = item.fields.find((obj) => {
                    return obj.name === attr;
                });
                result.push(elem?.value?.toString()!);
            }
        });
        return result.join('|');
    };

    const getValuesAbook = (values: IAddressBookData[], attr: string) => {
        let result = [] as string[];
        values.forEach((item) => {
            type ObjectKey = keyof typeof item;
            const attrNAme = attr as ObjectKey;
            result.push(item[attrNAme]?.toString());
        });
        return result.join('|');
    };

    const getExternalDataSource = (dict: ITableColumnDict) => {
        if (dict?.externalDataSource) {
            const dictExternalDataSource = new DictExternalDataSource(dict?.externalDataSource);
            return dictExternalDataSource.GetData(formMethods, fields);
        }
        return [];
    };

    const getParentFields = () => {
        let fields: IField[] = [];
        let values = formMethods.getValues() as any;
        let coll = values.fields as IField[];
        if (coll != undefined) {
            for (let index = 0; index < coll.length; index++) {
                const field_src = coll[index];
                if (field_src.name != table?.key) {
                    let objCopy = { ...field_src };
                    fields.push(objCopy);
                }
            }
        }
        return fields;
    };

    const renderColumnGrid = async (column: ITableColumn, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;

        const options: IOption[] = [];
        let countItems = 100;
        let startYear = 1950;

        if (column?.min && Number(column?.min) > 1950) {
            startYear = Number(column.min);
        }
        if (column?.max && column.max) {
            countItems = Number(column.max) - startYear;
        }

        if (column?.valueType == ValueType.Year) {
            for (let index = 0; index <= countItems; index++) {
                let year = startYear + index;
                options.push({
                    label: year.toString(),
                    value: year.toString(),
                } as IOption);
            }
        }

        switch (column?.valueType) {
            case ValueType.Boolean:
                return (
                    <Checkbox
                        readOnly={readOnly}
                        disabled={readOnly}
                        name={column.key}
                        defaultChecked={p.value && (p.value === 1 || p.value === '1') ? true : false}
                        onChange={(e) => {
                            let val = e.currentTarget.checked ? 1 : 0;
                            p.setValue(val);
                            p.component.saveEditData();
                        }}
                    />
                );
            case ValueType.Year:
                return (
                    <Select
                        disabled={readOnly}
                        readOnly={true}
                        options={options}
                        onlySelect={true}
                        values={
                            p.value
                                ? [
                                      {
                                          value: p.value.toString(),
                                          label: p.value.toString(),
                                      } as IOption,
                                  ]
                                : undefined
                        }
                        onChange={(option: IOption[]) => {
                            if (option.length > 0) {
                                let val = option[0].value;
                                if (p.value === undefined || p.value === null || p.value.toString() != val.toString()) {
                                    p.setValue(+val);
                                }
                            }
                        }}
                    />
                );
            case ValueType.YesNo:
                return (
                    <Select
                        disabled={readOnly}
                        readOnly={true}
                        options={[
                            {
                                value: '1',
                                label: 'Да',
                            },
                            {
                                value: '0',
                                label: 'Нет',
                            },
                        ]}
                        onlySelect={true}
                        values={[
                            {
                                value: p.value?.toString() ?? '',
                                label: p.value?.toString() == '1' ? 'Да' : p.value?.toString() == '0' ? 'Нет' : '',
                            } as IOption,
                        ]}
                        onChange={(option: IOption[]) => {
                            if (option.length > 0) {
                                let val = option[0].value == '1' ? 1 : 0;
                                if (p.value === undefined || p.value === null || p.value.toString() != val.toString()) {
                                    p.setValue(val);
                                }
                            }
                        }}
                    />
                );
            case ValueType.LongText:
                return (
                    <Textarea
                        autoResize={true}
                        readOnly={readOnly}
                        name={column.key}
                        initialRowCount={1}
                        value={p.value}
                        onValueChange={(e) => {
                            p.setValue(e);
                        }}
                    />
                );
            case ValueType.Double:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            if (value === undefined || value === '') {
                                p.setValue(null);
                            } else {
                                let val = +value.replace(/\s/g, '');
                                if (p.value != val) {
                                    p.setValue(val);
                                }
                            }
                        }}
                    />
                );
            case ValueType.Money:
                return (
                    <InputMoney
                        readOnly={readOnly}
                        name={column.key}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        locale={moneyCulture}
                        onChangeValue={(value) => {
                            if (p.value != value) {
                                p.setValue(value);
                            }
                        }}
                    />
                );
            case ValueType.Integer:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            if (value === undefined || value === '') {
                                p.setValue(null);
                            } else {
                                let val = +value;
                                if (p.value != val) {
                                    p.setValue(val);
                                }
                            }
                        }}
                    />
                );
            case ValueType.NoSecDateTime:
            case ValueType.Date:
            case ValueType.DateTime:
                return (
                    <Datepicker
                        readOnly={readOnly}
                        name={column.key}
                        defaultValue={p.value}
                        onChange={(e) => {
                            const val = e.date.value ?? null;
                            p.setValue(val);
                        }}
                    />
                );

            case ValueType.Url:
                return (
                    <Input
                        pattern={column.inputRegExp}
                        readOnly={readOnly}
                        name={column.key}
                        defaultValue={p.value}
                        onChange={(e) => {
                            p.setValue(e.currentTarget.value);
                        }}
                    />
                );

            case ValueType.Text:
                return (
                    <Input
                        maxLength={255}
                        pattern={column.inputRegExp}
                        readOnly={readOnly}
                        name={column.key}
                        defaultValue={p.value}
                        onChange={(e) => {
                            p.setValue(e.currentTarget.value);
                        }}
                    />
                );

            default:
                return <div></div>;
        }
    };

    const renderColumnDictGrid = async (column: ITableColumnDict, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        return (
            <Dictpicker
                readOnly={readOnly}
                docId={docId}
                isSelectMode={column.isSelectMode}
                dictionaryName={column.dictName}
                modalTitle={column.modalTitle}
                isFormData={column.isFormData}
                isMultiple={column.isMultiple}
                selectableLevels={column.selectableLevels}
                visibleLevels={column.visibleLevels}
                predicatesCache={column.predicatesCache}
                gridAttribute={column.gridAttribute}
                externalSearch={column.externalSearch}
                loadMode={column.loadMode}
                formValues={column.formValues}
                getExternalDataSource={async (loadOptions: any) => {
                    return getExternalDataSource(column);
                }}
                getFormValuesAsync={async (formValues: IFormValues) => {
                    return await getFormValuesAsync(formValues, p.data);
                }}
                getFiltersAsync={async () => {
                    return await getFiltersAsync(column.filters, p.data);
                }}
                defaultValue={p.value}
                diplayValue={parseDisplayFormatCell(column.displayFormat, p.data)}
                insideGrid={true}
                source={column.source}
                onChange={(e) => {
                    if (e && e.length > 0) {
                        column.setValues?.sets?.forEach((el) => {
                            let val: any = getValues(e, el.attr);

                            if (el.type) {
                                switch (el.type) {
                                    case 'double':
                                    case 'integer': {
                                        try {
                                            if (typeof val == 'string' && val.indexOf(',')) {
                                                val = val.replace(',', '.');
                                            }
                                            val = +val;
                                            if (isNaN(val)) {
                                                val = 0;
                                            }
                                        } catch (error) {
                                            val = 0;
                                        }
                                        break;
                                    }
                                    case 'date': {
                                        try {
                                            let parseVal = new Date(val);
                                            if (!isNaN(parseVal.getTime()) && parseVal.getTime() > 0) {
                                                val = parseVal;
                                            } else {
                                                throw new Error('parse Date');
                                            }
                                        } catch (error) {
                                            let parts = val.split(' ');
                                            if (parts.length > 1) {
                                                val = Moment(val, 'DD.MM.YYYY hh.mm.ss').toDate();
                                            } else {
                                                if (Moment(val, 'DD.MM.YYYY').isValid()) {
                                                    val = Moment(val, 'DD.MM.YYYY').toDate();
                                                } else {
                                                    if (Moment(val).isValid()) {
                                                        val = Moment(val).toDate();
                                                    } else {
                                                        val = null;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    }
                                    default:
                                        val = val;
                                        break;
                                }
                            }
                            p.data[el.key] = val;
                        });
                        p.setValue(getValues(e, 'code'));
                    } else {
                        column.setValues?.sets?.forEach((el) => {
                            let val: any = '';
                            p.data[el.key] = val;
                        });
                        p.setValue('');
                    }

                    p.component.saveEditData();
                    p.component.cancelEditData();
                }}
            />
        );
    };

    const renderColumnAutoCompleteGrid = async (column: ITableColumnAutoComplete, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        return (
            <AutoCompletePicker
                disabled={readOnly}
                defInputValue={p.value}
                debounceTime={500}
                findOptions={async (text: string) => {
                    return await getOptions(column?.dataSource!, text);
                }}
                onInputValueChanged={(e) => {
                    p.setValue(e);
                }}
            />
        );
    };

    const renderColumnCalcGrid = async (column: ITableColumnCalc, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        const typeValue = column.valueType !== undefined ? column.valueType : ValueType.Integer;
        switch (typeValue) {
            case ValueType.Double:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            if (value === undefined || value === '') {
                                p.setValue(null);
                            } else {
                                let val = +value.replace(/\s/g, '');
                                if (p.value != val) {
                                    p.setValue(val);
                                }
                            }
                        }}
                    />
                );
            case ValueType.Money:
                return (
                    <InputMoney
                        readOnly={readOnly}
                        name={column.key}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        onChangeValue={(value) => {
                            if (value === undefined) {
                                p.setValue(null);
                            } else {
                                if (p.value != value) {
                                    p.setValue(value);
                                }
                            }
                        }}
                    />
                );
            default:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            let val = +value;
                            if (p.value != val) {
                                p.setValue(val);
                            }
                        }}
                    />
                );
        }
    };
    const renderColumnAbookGrid = async (column: ITableColumnAbook, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        return (
            <Abookpicker
                readOnly={readOnly}
                isMultiple={column.isMultiple}
                tabsSettings={column.tabs}
                externalSearch={column.externalSearch}
                formValues={getFormValues(column.formValues, p.data)}
                title={column.name}
                defaultValue={p.value}
                showChips={column.showChips}
                diplayValue={parseDisplayFormatCell(column.displayFormat, p.data)}
                insideGrid={true}
                onChange={(e) => {
                    if (e && e.length > 0) {
                        column.setValues.sets.forEach((el) => {
                            p.data[el.key] = getValuesAbook(e, el.attr);
                        });
                        p.setValue(getValuesAbook(e, 'key'));
                    } else {
                        column.setValues.sets.forEach((el) => {
                            p.data[el.key] = '';
                        });
                        p.setValue('');
                    }
                    p.component.saveEditData();
                    p.component.cancelEditData();
                }}
            />
        );
    };

    const cellRenderSwitcherField = async (p: any, column: any, rowParent?: any) => {
        let content = cellRenderSwitcher(p, column);
        let readOnly = column.readonly
            ? column.readonly === 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        let required = column.required
            ? column.required === 'true'
                ? true
                : await evalTableFormulaValue(column.required, p.data, rowParent)
            : false;

        let className = column.valueType == ValueType.LongText ? ' w100 longtext-cell' : '';
        className = column.valueType == ValueType.Text ? ' w100 text-cell' : className;
        className = readOnly ? className + ' readonly-cell' : className;
        let cellClassName =
            required && (p.value === undefined || p.value === null || p.value === '')
                ? 'table-cell invalid-cell'
                : 'table-cell';
        if (column.styles) {
            for (let index = 0; index < column.styles.style.length; index++) {
                const x = column.styles.style[index];
                let result = await evalTableFormulaValue(x.condition, p.data, rowParent);
                if (result) {
                    cellClassName += ' ' + x.cellStyle;
                }
            }
        }
        return (
            <div className={cellClassName}>
                <div className={className}>{content}</div>
            </div>
        );
    };

    const cellRenderSwitcher = (p: any, column: any) => {
        if (isTableColumnDict(column) || isTableColumnAbook(column)) {
            return <div>{parseDisplayFormatCell(column.displayFormat, p.data)}</div>;
        } else if (isTableColumnCalc(column)) {
            const typeValue = column.valueType !== undefined ? column.valueType : ValueType.Integer;
            return <DisplayField type={typeValue} value={p.value} />;
        } else if (isTableColumnAutoComplete(column)) {
            return <DisplayField type={ValueType.Text} value={p.value} />;
        } else if (isTableColumn(column)) {
            return <DisplayField type={column?.valueType} value={p.value} />;
        }
    };

    const editCellRenderSwitcher = async (p: any, column: any, rowParent?: any) => {
        if (isTableColumnCalc(column)) {
            return await renderColumnCalcGrid(column, p, rowParent);
        } else if (isTableColumnAbook(column)) {
            return await renderColumnAbookGrid(column, p, rowParent);
        } else if (isTableColumnDict(column)) {
            return await renderColumnDictGrid(column, p, rowParent);
        } else if (isTableColumnAutoComplete(column)) {
            return await renderColumnAutoCompleteGrid(column, p, rowParent);
        } else if (isTableColumn(column)) {
            return await renderColumnGrid(column, p, rowParent);
        }
    };

    const calculateRow = async (row: any, column: any, table: IDocumentTable) => {
        if (table.tableColumnCalc) {
            for (let index = 0; index < table.tableColumnCalc.length; index++) {
                let keysChanged: string[] = [];
                const item = table.tableColumnCalc[index];
                if (column) {
                    keysChanged.push(column.key);
                    if (column.changes) {
                        keysChanged = keysChanged.concat(getFieldKeysByChanges(column.changes));
                    }
                }

                if (
                    column == undefined ||
                    (column.key != item.key &&
                        keysChanged.some((x) => {
                            return item.formula.indexOf(x) > -1;
                        }))
                ) {
                    const formulaMng = new FormulaManager(item.formula!);
                    formulaMng.Init(fields);
                    let coll = formulaMng.GetFields();
                    let values: any[] | undefined = undefined;
                    if (formMethods && row && coll.length > 0) {
                        values = GetRowFormulaValues(coll, table.key, fields, row, formMethods);
                    }
                    let val = await formulaMng.EvalFormulaValues(isEdit, isNew, values);
                    let valForm = isNaN(val) ? (item.clearIfThrow ? null : 0) : +val;
                    if (item.floatPoints && valForm !== null) {
                        valForm = naiveRound(valForm, item.floatPoints);
                    }
                    row[item.key] = valForm;
                }
            }
        }

        return row;
    };

    const onChangeCellValue = async (row: any, column: any, table: IDocumentTable) => {
        if (column && column.key && table && table.key) {
            let val = row[column.key];
            await ChangesFieldValue(
                column.changes,
                val,
                table.key,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                table,
                row,
                formMethods,
            );
        }
    };
    const onInitNewRow = async (data: any, table: IDocumentTable) => {
        const sets = table.newRowData?.setValues?.sets;
        if (table.newRowData && sets) {
            const promises = [];
            for (let index = 0; index < sets.length; index++) {
                const item = sets[index];
                promises.push(onSaveField(item, table, data));
            }
            await Promise.all(promises);
        }
    };

    const onSetFormDataNewRow = async (item: any, table: IDocumentTable, data: IDictionaryData) => {
        if (table.addFormDataRows && table.addFormDataRows?.setValues?.sets) {
            for (let index = 0; index < table.addFormDataRows?.setValues?.sets.length; index++) {
                const set = table.addFormDataRows?.setValues?.sets[index];
                let elem = data.fields.find((obj) => {
                    return obj.name === set.attr;
                });
                if (elem && elem?.value && elem?.value !== '') {
                    await onSaveField(set, table, item, elem?.value);
                }
            }
        }
    };

    const onTableChange = async (value: any, table: IDocumentTable) => {
        await ChangesFieldValue(
            table.changes!,
            value,
            table.key,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            table,
            undefined,
            formMethods,
        );
        for (let index = 0; index < table.tables.length; index++) {
            const subTable = table.tables[index];
            await ChangesFieldValue(
                subTable.changes!,
                value,
                subTable.key,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                subTable,
                undefined,
                formMethods,
            );
        }
    };

    const setParentField = (field: IField) => {
        formMethods.setValue(field.name as any, field.value as any, { shouldDirty: true });
    };

    return table && table.key && table.tableColumn && dataField ? (
        <TableControl
            name={dataField.name}
            formMethods={formMethods}
            table={table}
            docId={docId}
            getParentFields={getParentFields}
            setParentField={setParentField}
            allowUpdating={!table?.updateRowsDisabled}
            onTableChange={onTableChange}
            calculateRow={calculateRow}
            onChangeCellValue={onChangeCellValue}
            cellRenderSwitcher={cellRenderSwitcherField}
            editCellRenderSwitcher={editCellRenderSwitcher}
            evalTableFormulaValue={evalTableFormulaValue}
            onInitNewRow={onInitNewRow}
            getColumnWatches={getColumnWatches}
            getWatchesByFormula={getWatchesByFormula}
            onSaved={async (e: any) => {
                formMethods.setValue(dataField.name as any, e as any, { shouldDirty: true });
                await onTableChange(e, table);
            }}
            getFormValuesAsync={async (formValues: IFormValues) => {
                return await getFormValuesAsync(table?.addFormDataRows.formValues, null);
            }}
            getFiltersAsync={async () => {
                return await getFiltersAsync(table?.addFormDataRows.filters, null);
            }}
            onSetFormDataNewRow={onSetFormDataNewRow}
        />
    ) : (
        <></>
    );
};

export default EditTable;
