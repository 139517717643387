import { FormProviderControl, HookFormProvider } from '@/components/controls';
import { ISmartBusinessDirectories } from '@/models/smartBusiness/ISmartBusinessDirectories';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SmartBusinessParametersStepper from './components/SmartBusinessParametersStepper';

import './SmartBusinessParametersForm.scss';

interface ISmartBusinessParametersFormProps {
    directories: ISmartBusinessDirectories;
    onSubmit: (data: ISmartBusinessParameters | undefined) => void;
    token: string;
}

const SmartBusinessParametersForm: FC<ISmartBusinessParametersFormProps> = ({ onSubmit, directories, token }) => {
    const methods = useForm<ISmartBusinessParameters>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        methods.reset({
            attributes: [],
            category_id: undefined,
            region_id: undefined,
            experience_weight: 0,
            exclude_participants: [],
            start_month: 1,
            start_year: 2020,
            max_experience: 0,
            min_experience: 0,
            price_weight: 0,
            start_price: 0,
        } as ISmartBusinessParameters);
    }, []);

    return (
        <FormProviderControl
            formMethods={methods}
            onSubmit={(e) => {
                console.log(e);
                onSubmit(e);
            }}
        >
            <HookFormProvider controller={Controller}>
                <SmartBusinessParametersStepper
                    methods={methods}
                    onReset={() => {
                        onSubmit(undefined);
                    }}
                    directories={directories}
                    token={token}
                />
            </HookFormProvider>
        </FormProviderControl>
    );
};

export default SmartBusinessParametersForm;
