import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { AllClose } from '@/indexIcon';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';

import './DictpickerModal.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter, IExternalSearchDict } from '@models/Forms/IForms';

import { IDictSettings } from '../IDictSettings';

import ListModeControl from './ModeControls/ListModeControl';
import TreeModeControl from './ModeControls/TreeModeControl';
import DictExternalSearchModal from './DictExternalSearchModal';
import { IDictpickerRefActions } from '../Dictpicker';

export interface ISelectFromDictDialogProp extends IDictSettings {
    onCloseModal: () => void;
    onSubmitModal: (data: IDictionaryData[]) => void;
    externalSearch?: IExternalSearchDict;
}

const DictpickerModal: FC<ISelectFromDictDialogProp> = (p: ISelectFromDictDialogProp) => {
    const [dictAttributes, setDictAttributes] = useState<string[]>();
    const [selectedKeys, setSelectedKeys] = useState<string[] | undefined>(p.selected);
    const [maxStructLevel, setMaxStructLevel] = useState<number>(0);
    const [filterResponse, setFilterResponse] = useState<IDictFilter>();
    const [selectedRowItems, setSelectedRowItems] = useState<IDictionaryData[]>([]);
    const [showExternalSearch, setShowExternalSearch] = useState<boolean>(false);
    const controlRef = useRef<IDictpickerRefActions>(null);
    const onSelectedRowItems = useCallback((value: IDictionaryData[]) => {
        setSelectedRowItems(value);
    }, []);
    const onSelectedKeys = useCallback((value: string[]) => {
        setSelectedKeys(value);
    }, []);

    const getFilterAsync = async () => {
        p.getFiltersAsync ? setFilterResponse(await p.getFiltersAsync()) : setFilterResponse({} as IDictFilter);
    };

    useEffect(() => {
        if (!dictAttributes) {
            getFilterAsync().then(() => {
                if (p.isFormData) {
                    if (p.dictName) {
                        DictionariesService.fetchFormdataDictionaryGridScheme(p.dictName).then((response) => {
                            setDictAttributes(response.data.attributes);
                        });
                    } else {
                        let coll = p.gridAttribute?.attrs.map((item) => {
                            return item.key;
                        });
                        setDictAttributes(coll);
                    }
                } else {
                    DictionariesService.fetchDictionaryGridScheme(p.dictName).then((response) => {
                        let attrs = [...response.data.attributes];
                        if (p.joinedDictionaries) {
                            p.joinedDictionaries.forEach((x) => {
                                x.outColumns.forEach((col) => attrs.push(col.name));
                            });
                        }
                        setDictAttributes(attrs);
                        setMaxStructLevel(response.data.maxStructLevel);
                    });
                }
            });
        }
    }, [p.dictName]);

    const toggleShowExternalSearch = () => {
        setShowExternalSearch(!showExternalSearch);
    };

    const ModalFooter = ({
        onClose,
        onSubmit,
    }: {
        onClose: () => void;
        onSubmit: (data: IDictionaryData[]) => void;
    }) => (
        <div className={'dictModal_footer'}>
            {p.externalSearch && (
                <div>
                    <Button onClick={toggleShowExternalSearch} buttonType="light" size="s">
                        {p.externalSearch.buttonName}
                    </Button>
                </div>
            )}
            <div className={'left'}>
                <Button
                    onClick={() => {
                        if (selectedKeys && selectedKeys.length == selectedRowItems.length) {
                            onSubmit(selectedRowItems);
                        }
                        onClose();
                    }}
                    size="s"
                    //  disabled={selectedKeys && selectedKeys.length == 0}
                >
                    Выбрать
                </Button>
                <Button onClick={onClose} buttonType="light" size="s">
                    Отменить
                </Button>
            </div>
        </div>
    );

    const CheckHierarchical = useCallback(() => {
        return maxStructLevel > 0;
    }, [maxStructLevel]);

    return dictAttributes && filterResponse && maxStructLevel !== undefined ? (
        <Modal
            className={'dictModal dx-dropdowneditor-overlay'}
            size="content"
            footer={<ModalFooter onClose={p.onCloseModal} onSubmit={p.onSubmitModal} />}
            header={
                <div className={classnames('box')}>
                    <div>
                        <div>{p.modalTitle ? p.modalTitle : p.dictName}</div>
                    </div>
                    <div className={classnames('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<AllClose size="xs" />}
                        />
                    </div>
                </div>
            }
        >
            {p.isFormData || !CheckHierarchical() ? (
                <ListModeControl
                    onSelectedRowItems={onSelectedRowItems}
                    onSelectedKeys={onSelectedKeys}
                    dictAttributes={dictAttributes}
                    filterResponse={filterResponse!}
                    controlRef={controlRef}
                    {...p}
                />
            ) : (
                <TreeModeControl
                    onSelectedRowItems={onSelectedRowItems}
                    onSelectedKeys={onSelectedKeys}
                    maxStructLevel={maxStructLevel}
                    dictAttributes={dictAttributes}
                    filterResponse={filterResponse!}
                    controlRef={controlRef}
                    {...p}
                />
            )}
            {showExternalSearch && (
                <DictExternalSearchModal
                    settings={p.externalSearch!}
                    getFormValuesAsync={p.getFormValuesAsync}
                    onCloseModal={() => {
                        toggleShowExternalSearch();
                    }}
                    onSubmitModal={(data: string) => {
                        controlRef.current?.setSelected([data]);
                    }}
                />
            )}
        </Modal>
    ) : (
        <></>
    );
};

export default DictpickerModal;
