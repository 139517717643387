// @ts-ignore
import cadesplugin from 'crypto-pro-cadesplugin';
import { ISignVerificationResult, ISignWithAlgorithm } from '@models/attaches/IAttachViewSignModel';
import { ICertificate } from '@models/certificates/cert';

export class CadespluginService {
    private _providerSupport: { FriendlyName: string; Value: string; algorithm: any }[] = [];

    private readonly CADESCOM_PKCS7_TYPE = 0xffff;

    private readonly _skipValidationForAlgs = ['гост', 'gost'];

    async initPlugin() {
        let certsApi = await cadesplugin();
        let plugin = (window as any).cadesplugin;

        this._providerSupport = [
            {
                FriendlyName: 'sha256RSA',
                Value: '1.2.840.113549.1.1.11',
                algorithm: plugin.CADESCOM_HASH_ALGORITHM_SHA_256,
            },
            { FriendlyName: 'RSA', Value: '1.2.840.113549.1.1.1', algorithm: plugin.CADESCOM_HASH_ALGORITHM_SHA1 },
            {
                FriendlyName: 'sha512RSA',
                Value: '1.2.840.113549.1.1.13',
                algorithm: plugin.CADESCOM_HASH_ALGORITHM_SHA_512,
            },
            {
                FriendlyName: 'ГОСТ Р 34.10-2012 256 бит',
                Value: '1.2.643.7.1.1.1.1',
                algorithm: plugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
            },
            {
                FriendlyName: 'ГОСТ Р 34.10-2012 512 бит',
                Value: '1.2.643.7.1.1.1.2',
                algorithm: plugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
            },
            {
                FriendlyName: 'ГОСТ Р 34.10-2001',
                Value: '1.2.643.2.2.19',
                algorithm: plugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411,
            },
        ];

        return { api: certsApi, plugin: plugin };
    }

    getCnPart(info: string, partName: string = 'cn'): string {
        let cn = info
            .split(',')
            .map((x) => x.trim())
            .map((x) => {
                let parts = x.split('=');
                if (parts.length == 2) {
                    if (parts[0].toLocaleLowerCase() == partName.toLocaleLowerCase()) return parts[1];
                }
            })
            .find((x) => x);
        return cn as string;
    }

    // Получает список сертификатов по подстроке с алгоритмом (передаем RSA или ГОСТ)
    async getCertListByAlgSubstring(algToFilter: string[], gostCNFilter: string[]): Promise<ICertificate[]> {
        const { api: certsApi, plugin } = await this.initPlugin();

        let oStore = await plugin.CreateObjectAsync('CAdESCOM.Store');
        await oStore.Open();

        const certificates = await oStore.Certificates;
        const count = await certificates.Count;

        const certsList: any[] = [];

        for (let i = 1; i < count + 1; i++) {
            let certApi = await certificates.Item(i);
            certsList.push({
                certApi: certApi,
                issuerInfo: await certApi.IssuerName,
                //privateKey: await certApi.PrivateKey,
                serialNumber: await certApi.SerialNumber,
                subjectInfo: await certApi.SubjectName,
                thumbprint: await certApi.Thumbprint,
                validPeriod: {
                    from: await certApi.ValidFromDate,
                    to: await certApi.ValidToDate,
                },
            });
        }

        let resultCerts: ICertificate[] = [];

        console.log(`Получение сертификатов по алгоритму: ${algToFilter?.join()}`);

        await Promise.all(
            (certsList as any[]).map(async (cert) => {
                let subj = cert.subjectInfo;
                let issuer = cert.issuerInfo;
                console.log(`Сертификат: ${subj} ${issuer}`);

                let pubKey = await cert.certApi.PublicKey();
                let alg = await pubKey.Algorithm;
                let algName = (await alg.FriendlyName) as string;

                console.log(`Алгоритм: ${algName}`);

                if (algName && algToFilter.some((alg) => algName.toLowerCase().includes(alg.toLowerCase()))) {
                    let subjectName = this.getCnPart(subj);
                    if (!subjectName) {
                        console.log(`Не удалось получить subjectName. subj: ${subj}`);
                        return; //если пустое имя subjectName - не добавляем в список
                    }
                    let issuerName = this.getCnPart(issuer);
                    if (
                        gostCNFilter &&
                        gostCNFilter.length > 0 &&
                        !gostCNFilter.some((cn) => issuerName.toLowerCase().includes(cn.toLowerCase()))
                    ) {
                        console.log(`сертификат не подходит по фильтру gostCN`);
                        return; //если пустое имя subjectName - не добавляем в список
                    }

                    let hasPrivateKey = await cert.certApi.HasPrivateKey();
                    if (!hasPrivateKey) {
                        console.log(`Не имеет закрытого ключа: ${subj}`);
                        return;
                    }

                    const skipValidation = this._skipValidationForAlgs.some((alg) =>
                        algName.toLowerCase().includes(alg.toLowerCase()),
                    );
                    if (!skipValidation) {
                        let validFromDt = new Date(await cert.certApi.ValidFromDate);
                        let validToDt = new Date(await cert.certApi.ValidToDate);
                        let nowDt = new Date();
                        if (nowDt < validFromDt || nowDt > validToDt) {
                            console.log(
                                `Не пройдена кастомная проверка по дате. subj: ${subj}. (${validFromDt} - ${validToDt})`,
                            );
                            return;
                        }

                        let dtStartCheck = new Date().getTime();
                        let isValidF = await cert.certApi.IsValid();
                        let isValid = await isValidF.Result;

                        let dtEndCheck = new Date().getTime();

                        if (!isValid) {
                            console.log(
                                `Не прошла проверка isValid. subj: ${subj}. Время проверки ${
                                    (dtEndCheck - dtStartCheck) / 1000
                                }`,
                            );
                            return;
                        }
                        console.log(
                            `Сертификат успешно прошёл проверку subj: ${subj}. Время проверки ${
                                (dtEndCheck - dtStartCheck) / 1000
                            }`,
                        );
                    } else {
                        console.log(`Пропускаем проверку isValid. subj: ${subj}`);
                    }

                    let certToAdd: ICertificate = {
                        rawSubject: subj,
                        thumbprint: cert.thumbprint,
                        subjectName: subjectName,
                        issuerName: this.getCnPart(cert.issuerInfo),
                        validFrom: new Date(cert.validPeriod.from),
                        validTo: new Date(cert.validPeriod.to),

                        serialNumber: cert.serialNumber,
                        issuedToEmail: this.getCnPart(subj, 'e') ?? '',
                    };
                    resultCerts.push(certToAdd);
                } else {
                    console.log(`Не подошёл алгоритм: ${algName}; subj: ${subj}`);
                }
            }),
        );

        return resultCerts;
    }

    async signData(dataToSignBase64: string, certThumbPrint: string): Promise<ISignWithAlgorithm> {
        const { api: certsApi, plugin } = await this.initPlugin();

        const cert = await certsApi.currentCadesCert(certThumbPrint);
        let certPublicKey = await cert.PublicKey();
        let providerKey = await cert.PrivateKey;
        let providerName = await providerKey.ProviderName;
        let certAlgorithm = await certPublicKey.Algorithm;
        let algorithmValue = await certAlgorithm.Value;

        let prov = this._providerSupport.find((p) => {
            return p.Value === algorithmValue;
        });
        let algNumber;
        if (!prov) {
            console.log('Крипто провайдер указанный в сертификате не поддерживается. ' + providerName);
            algNumber = this._providerSupport[0].algorithm;
        } else {
            algNumber = prov.algorithm;
        }

        // let certsApi = await this._module.default();
        // const sign = await certsApi.signBase64(certThumbPrint, dataToSignBase64);
        // return sign as string;
        let oHashedData = await plugin.CreateObjectAsync('CAdESCOM.HashedData');
        // Алгоритм хэширования нужно указать до того, как будут переданы данные
        await oHashedData.propset_Algorithm(algNumber);
        // Указываем кодировку данных
        // Кодировка должна быть указана до того, как будут переданы сами данные
        await oHashedData.propset_DataEncoding(plugin.CADESCOM_BASE64_TO_BINARY);

        // Указываем кодировку данных
        // Кодировка должна быть указана до того, как будут переданы сами данные
        let chunkSize = 3 * 1024 * 1024; // 3MB
        if (dataToSignBase64.length > chunkSize * 5) {
            let chunksCount = Math.ceil(dataToSignBase64.length / chunkSize);
            for (let i = 0; i < chunksCount; i++) {
                const chunk = this.getChunk(dataToSignBase64, chunkSize, i);
                oHashedData.Hash(chunk);
            }
        } else {
            oHashedData.Hash(dataToSignBase64);
        }

        let oSigner = await plugin.CreateObjectAsync('CAdESCOM.CPSigner');
        await oSigner.propset_Certificate(cert);

        let oSignedData = await plugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
        await oSignedData.propset_ContentEncoding(plugin.CADESCOM_BASE64_TO_BINARY);
        let sSignedMessage = await oSignedData.SignHash(oHashedData, oSigner, 1);
        return {
            algorithm: algorithmValue,
            sign: sSignedMessage,
        };
    }

    async verifyDataWithoutHash(dataToVerify: string, sign: string): Promise<ISignVerificationResult | undefined> {
        const { api: certsApi, plugin } = await this.initPlugin();
        try {
            let oSignedData = await plugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
            await oSignedData.propset_ContentEncoding(plugin.CADESCOM_BASE64_TO_BINARY);
            await oSignedData.propset_Content(dataToVerify);
            await oSignedData.VerifyCades(sign, this.CADESCOM_PKCS7_TYPE, true);
            return await this.GetVerifyResult(oSignedData);
        } catch (e) {
            let result: ISignVerificationResult = {
                suceeded: false,
                errorText: plugin?.getLastError(e),
            };
            return result;
        }
    }

    async verifyData(
        dataToVerify: string,
        sign: string,
        algorithmValue: string,
    ): Promise<ISignVerificationResult | undefined> {
        const { api: certsApi, plugin } = await this.initPlugin();

        try {
            let oSignedData = await plugin.CreateObjectAsync('CAdESCOM.CadesSignedData');

            // Создаем объект CAdESCOM.HashedData
            let oHashedData = await plugin.CreateObjectAsync('CAdESCOM.HashedData');

            // Алгоритм хэширования нужно указать до того, как будут переданы данные
            let alg = this._providerSupport.find((x) => x.Value == algorithmValue);
            if (!alg) throw new Error(`Не найден алгоритм хэша для значения ${algorithmValue}`);
            await oHashedData.propset_Algorithm(alg.algorithm); //TODO тут заранее брать алгоритм!!!!!!!!!!!

            // Указываем кодировку данных
            // Кодировка должна быть указана до того, как будут переданы сами данные
            await oHashedData.propset_DataEncoding(plugin.CADESCOM_BASE64_TO_BINARY);

            // Указываем кодировку данных
            // Кодировка должна быть указана до того, как будут переданы сами данные
            let chunkSize = 3 * 1024 * 1024; // 3MB
            if (dataToVerify.length > chunkSize * 5) {
                let chunksCount = Math.ceil(dataToVerify.length / chunkSize);
                for (let i = 0; i < chunksCount; i++) {
                    const chunk = this.getChunk(dataToVerify, chunkSize, i);
                    oHashedData.Hash(chunk);
                }
            } else {
                oHashedData.Hash(dataToVerify);
            }

            await oSignedData.VerifyHash(oHashedData, sign, plugin.CADESCOM_CADES_BES);

            return await this.GetVerifyResult(oSignedData);
        } catch (e) {
            let err = plugin?.getLastError(e);
            console.error(err);
            let result: ISignVerificationResult = {
                suceeded: false,
                errorText: err,
            };
            return result;
        }
    }

    private async GetVerifyResult(oSignedData: any): Promise<ISignVerificationResult | undefined> {
        let signers = await oSignedData.Signers;
        if (signers) {
            let firstSigner = await signers.Item(1);
            let certif = await firstSigner.Certificate;
            let certOwner = await certif.SubjectName;
            let serialNumber = await certif.SerialNumber;
            let validFromDate = await certif.ValidFromDate;
            let validToDate = await certif.ValidToDate;

            let result: ISignVerificationResult = {
                certOwner: certOwner,
                serialNumber: serialNumber,
                validFrom: validFromDate,
                validTo: validToDate,
                suceeded: true,
            };

            return result;
        }
    }

    private getChunk(str: string, len: number, chunkNum: number): string {
        const size = Math.ceil(str.length / len);
        return str.substring(chunkNum * size, len);
    }
}
