import { UseFormReturn } from 'react-hook-form';
import { IDocumentLinksTable } from '@models/Forms/IForms';
import './LinksTable.scss';
import { IFieldElem } from '@models/IFormData';
import React, { useEffect } from 'react';
import { TableTab } from '@models/document/IDocumentScheme';
import DocumentLinksTable from '@organisms/document/DocumentTabBuilder/tabs/DocumentTabLinks/DocumentLinksTable';

export interface ILinksTableProps<TFieldValues extends object = object> {
    table: IDocumentLinksTable;
    tables: TableTab[];
    formMethods: UseFormReturn<TFieldValues>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    docId?: string;
}

const LinksTable = <TFieldValues extends object = object>({
    table,
    tables,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: ILinksTableProps<TFieldValues>) => {
    const activated = React.useRef(false);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    const linkTable = tables.find((t) => t.key === table.key);

    return table && table.key && docId && linkTable && !isEdit && !isNew ? (
        <DocumentLinksTable id={table.id} linkTable={linkTable} docId={docId} />
    ) : (
        <></>
    );
};

export default LinksTable;
