import { ValueType } from '@/types/ValueType';
import { IGridStateSettings, IGridStateSettingsColumn } from '@models/response/IGridStateSettings';
import { MutableRefObject } from 'react';
import { IColumnProps } from 'devextreme-react/ui/tree-list';
import { IDataGridDescriptionResponse } from '@models/response/IGridDescriptionResponse';
import dxDataGrid, { Column } from 'devextreme/ui/data_grid';

export const isNullOrEmpty = (object: any, key: string) => {
    return object[key] !== undefined && object[key] !== null && object[key] !== '';
};

export const getLoadOptionsParameters = (options: any) => {
    let params: string[] = [];
    [
        'skip',
        'take',
        'requireTotalCount',
        'requireGroupCount',
        'sort',
        'filter',
        'totalSummary',
        'group',
        'groupSummary',
    ].forEach((i) => {
        if (i in options && options[i] !== undefined && options[i] !== null && options[i] !== '') {
            params.push(`${i}=${JSON.stringify(options[i]).replaceAll('+', '%2b')}`);
        }
    });
    return params;
};

export const getLoadOptionsQuery = (options: any, ampersandPrefix: boolean = false) => {
    let params: string[] = getLoadOptionsParameters(options);

    //if (!isNullOrEmpty(options, 'userData')) {
    //    Object.keys(options['userData']).forEach(i => {
    //        if (!isNullOrEmpty(options['userData'], i)) {
    //            params.push(`${i}=${JSON.stringify(options['userData'][i])}`);
    //        }
    //    });
    //}
    let query = '';

    if (params.length > 0) query = (ampersandPrefix ? '&' : '') + params.join('&');

    return query;
};

export const getColumnFilterOperationsByColumnDataType = (dataType: string) => {
    switch (dataType) {
        case 'date':
        case 'datetime':
            return ['=', '<>', '<', '>', 'between'];
        case 'number':
            return ['=', '<>', '<', '>', '<=', '>=', 'between'];
        case 'boolean':
            return ['='];
        default:
            return ['contains', '=', '<>'];
    }
};

export const getColumnDataTypeByFieldDataType = (dataType: ValueType) => {
    switch (dataType) {
        case ValueType.Date:
            return 'date';
        case ValueType.DateTime:
        case ValueType.NoSecDateTime:
            return 'datetime';
        case ValueType.Money:
        case ValueType.Double:
        case ValueType.Integer:
            return 'number';
        case ValueType.Boolean:
            return 'boolean';
        default:
            return 'string';
    }
};

export const onCellHoverChanged = (e: any) => {
    // для masterDetail ячеек тултип не показываем
    if (e.row && e.row.rowType === 'detail') return;

    e.cellElement.setAttribute('title', e.cellElement.innerText);
};

export const applyGridState = (
    settings: IGridStateSettings | undefined,
    gridDesc: IDataGridDescriptionResponse | undefined,
    objRef: MutableRefObject<any>,
) => {
    if (settings) {
        gridDesc?.columns.forEach((column, index) => {
            let options = objRef.current?.instance.columnOption(index) as IColumnProps;

            const stateColumn = settings?.state.columns.filter(
                (x) => x.dataField?.toUpperCase() === options.dataField.toUpperCase(),
            )[0];

            if (stateColumn !== undefined) {
                options.visible = stateColumn.visible;
                options.visibleIndex = stateColumn.visibleIndex;
                options.width = stateColumn.width;
            } else {
                options.visible = false;
            }

            objRef.current?.instance.columnOption(index, options);
        });
    }
};

export const applyStateSettings = (grid?: dxDataGrid, settings?: IGridStateSettings) => {
    if (grid && settings) {
        grid.clearFilter();
        grid.clearSorting();
        let userColumns = new Map<string, IGridStateSettingsColumn>();
        settings.state.columns.forEach((column) => {
            userColumns.set(column.dataField?.toLowerCase()!, column);
        });
        for (let i = 0; i < grid.columnCount()!; ++i) {
            let gridOptions = grid.columnOption(i) as IColumnProps;
            if (gridOptions) {
                let userOptions = userColumns.get(gridOptions.dataField?.toLowerCase());
                if (userOptions) {
                    gridOptions.visible = userOptions.visible;
                    gridOptions.visibleIndex = userOptions.visibleIndex;
                    gridOptions.width = userOptions.width;
                    gridOptions.sortIndex = userOptions.sortIndex;
                    gridOptions.sortOrder = userOptions.sortOrder;
                } else {
                    gridOptions.visible = false;
                }
                grid.columnOption(i, gridOptions);
            }
        }
        if (settings.filters) {
            grid.option('filterValue', settings.filters);
        }
    }
};

export const getStateSettings = (settings?: IGridStateSettings[]) => {
    if (settings) {
        let state = settings.find((x) => x?.selected);
        if (!state) {
            state = settings.find((x) => x?.default);
        }
        return state;
    }
    return undefined;
};

export const addSeconds = (date: Date, seconds: number): Date => {
    let _date = new Date(date);
    _date.setTime(_date.getTime() + seconds * 1000);
    return _date;
};

export const parseDateWithoutTz = (date: string): Date => {
    let _date = new Date(date);
    return addSeconds(_date, -_date.getTimezoneOffset() * 60);
};

export const getIsoDate = (date: Date): string => {
    return date.toISOString().substring(0, 10);
};

export function calculateFilterExpression(this: any, filterValue: any, selectedFilterOperation: any) {
    if (typeof filterValue == 'string') {
        filterValue = filterValue.trim();
    }

    if (this.dataType === 'boolean') {
        return [this.dataField, filterValue ? '=' : '<>', 1];
    }

    if (this.dataType === 'date') {
        if (Array.isArray(filterValue)) {
            if (selectedFilterOperation === 'between') {
                if (filterValue.length === 2 && filterValue[0] && filterValue[1]) {
                    let fr = parseDateWithoutTz(filterValue[0]);
                    let to = parseDateWithoutTz(filterValue[1]);
                    return [[this.dataField, '>=', getIsoDate(fr)], 'and', [this.dataField, '<=', getIsoDate(to)]];
                }
            }
        } else {
            if (selectedFilterOperation === '=') {
                let fr = parseDateWithoutTz(filterValue);
                let to = addSeconds(fr, 24 * 60 * 60);
                return [[this.dataField, '>=', getIsoDate(fr)], 'and', [this.dataField, '<', getIsoDate(to)]];
            } else if (selectedFilterOperation === '<>') {
                let fr = parseDateWithoutTz(filterValue);
                let to = addSeconds(fr, 24 * 60 * 60);
                return [[this.dataField, '<', getIsoDate(fr)], 'or', [this.dataField, '>=', getIsoDate(to)]];
            } else {
                let date = parseDateWithoutTz(filterValue);
                return [this.dataField, selectedFilterOperation, getIsoDate(date)];
            }
        }
    }

    // Invokes the default filtering behavior
    return this.defaultCalculateFilterExpression.apply(this, arguments);
}

export const buildGridColumns = (description?: IDataGridDescriptionResponse) => {
    let columns: Column<any, string>[] = [];
    if (description) {
        columns = description.columns.map(
            (schemeColumn, index) =>
                ({
                    //key: `col_${index}`,
                    /* Настройка width ломает настройку грида columnResizingMode="widget" */
                    width: schemeColumn.width ? schemeColumn.width : undefined,
                    minWidth: schemeColumn.width.toString().endsWith('vw') ? 5 : schemeColumn.minWidth,
                    allowFiltering: schemeColumn.allowFiltering,
                    caption: schemeColumn.header,
                    dataField: schemeColumn.field,
                    dataType: getColumnDataTypeByFieldDataType(schemeColumn.dataType),
                    // alignment: schemeColumn.alignment,
                    showInColumnChooser: schemeColumn.showInColumnChooser,
                    visible: !schemeColumn.defaultHide,
                    allowGrouping: schemeColumn.allowGrouping,
                    //colIndexId: schemeColumn.colIndexId,
                    //allowFiltering: schemeColumn.dataField !== "actionColumn",
                    allowReordering: !(schemeColumn.field === 'actionColumn' || schemeColumn.field === 'emptyColumn'),
                    allowSorting: !(schemeColumn.field === 'actionColumn' || schemeColumn.field === 'emptyColumn'),
                    // filterOperations: getColumnFilterOperationsByColumnDataType(
                    //     getColumnDataTypeByFieldDataType(schemeColumn.dataType)
                    // ),
                    calculateFilterExpression: calculateFilterExpression,
                    encodeHtml: true,
                    cellTemplate: schemeColumn.dxGridCellTemplate
                        ? schemeColumn.dxGridCellTemplate
                        : schemeColumn.field === 'actionColumn'
                          ? 'dxGridRowMenuCellTemplateAjax'
                          : 'dxGridFieldCellTemplate',
                }) as Column<any, string>,
        );
    }
    return columns;
};

export const getOriginalState = (description?: IDataGridDescriptionResponse) => {
    if (description) {
        let st: IGridStateSettings = {
            default: true,
            name: 'default',
            selected: false,
            state: {
                columns: [],
                allowedPageSizes: [10, 20, 30],
                filterPanel: {
                    filterEnabled: false,
                },
                filterValue: '',
                pageIndex: 0,
                pageSize: 10,
                searchText: '',
            },
            filters: undefined,
            sort: undefined,
        };

        description?.columns?.forEach((column, index) => {
            if (column.field !== 'emptyColumn' && !column.defaultHide) {
                st.state.columns.push({
                    dataField: column.field,
                    dataType: column.dataType,
                    name: column.header,
                    visible: !column.defaultHide,
                    visibleIndex: index,
                    width: column.width,
                });
            }
        });

        return st.state.columns.length > 0 ? st : undefined;
    }
    return undefined;
};

export const updateModeOn = (grid: dxDataGrid | undefined, isUpdating: MutableRefObject<boolean>) => {
    if (grid) {
        if (!isUpdating.current) {
            isUpdating.current = true;
            grid?.beginUpdate();
        }
    }
};

export const updateModeOff = (grid: dxDataGrid | undefined, isUpdating: MutableRefObject<boolean>) => {
    if (grid) {
        if (isUpdating.current) {
            isUpdating.current = false;
            grid?.endUpdate();
        }
    }
};
