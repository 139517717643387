import {
    ITableColumn,
    ITableColumnAbook,
    ITableColumnAutoComplete,
    ITableColumnCalc,
    ITableColumnDict,
} from '@models/Forms/IForms';

export const isTableColumn = (anyObject: any): anyObject is ITableColumn => {
    return (
        (anyObject as ITableColumn) !== null &&
        (anyObject as ITableColumnDict).dictName === undefined &&
        (anyObject as ITableColumnCalc).formula === undefined &&
        (anyObject as ITableColumnAutoComplete).dataSource == null &&
        (anyObject as ITableColumnAbook).tabs === undefined
    );
};
export const isTableColumnDict = (anyObject: any): anyObject is ITableColumnDict => {
    return (anyObject as ITableColumnDict).dictName !== undefined;
};
export const isTableColumnCalc = (anyObject: any): anyObject is ITableColumnCalc => {
    return (anyObject as ITableColumnCalc).formula !== undefined;
};
export const isTableColumnAutoComplete = (anyObject: any): anyObject is ITableColumnAutoComplete => {
    return (anyObject as ITableColumnAutoComplete).dataSource != null;
};
export const isTableColumnAbook = (anyObject: any): anyObject is ITableColumnAbook => {
    return (anyObject as ITableColumnAbook).tabs !== undefined;
};
