import { SelectControl } from '@/components/controls';
import { ISmartBusinessCategoryType } from '@/models/smartBusiness/ISmartBusinessCategoryType';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { IOption } from '@/types';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

const categoryToOption = (category: ISmartBusinessCategoryType): IOption => ({
    value: category.id,
    label: category.name,
});

// const optionToCategory = ({ value, label }: any): ISmartBusinessCategoryType => ({
//   id: value,
//   name: label,
// });

interface IOwnProps {
    formMethods: UseFormReturn<ISmartBusinessParameters>;
    categories: ISmartBusinessCategoryType[];
}

export const SmartBusinessCategoryField: FC<IOwnProps> = ({ formMethods, categories }) => {
    return (
        <SelectControl
            label="Категория"
            multiselect={false}
            setValue={(options) => {
                return options[0].value;
            }}
            getValue={(options: IOption[], value: any) => {
                return options.filter((x) => x.value === value);
            }}
            required={true}
            name="category_id"
            options={categories?.map(categoryToOption)}
            formState={formMethods.formState}
            control={formMethods.control}
            rules={{ required: 'Обязательное поле' }}
        />
    );
};
