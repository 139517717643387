import { AppDispatch } from '@/store/index';
import { UserAvatarSlice } from '@/store/reducers/UserAvatarReducer';
import { UserService } from '@services/UserService';

export const UserAvatarActionCreators = {
    getUserAvatar: () => async (dispatch: AppDispatch) => {
        try {
            const response = await UserService.getCurrentUserAvatar();
            if (response) {
                dispatch(UserAvatarSlice.actions.setAvatar(response.data));
            }
        } catch (e) {
            console.log(e);
        }
    },
};
