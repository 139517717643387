import React from 'react';

interface IProps {
    data: any;
    column: any;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
}

interface IState {
    comp: React.ReactNode;
}

class ViewCellRenderSwitcher extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { comp: undefined };
    }
    _isMounted = false;

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        const component = await this.props.cellRenderSwitcher(this.props.data, this.props.column);
        if (this._isMounted) {
            this.setState({ comp: component });
        }
    }

    render() {
        if (this.state.comp) {
            return this.state.comp;
        } else {
            return <></>;
        }
    }
}

export default ViewCellRenderSwitcher;
