import React, { useCallback, useState } from 'react';
import './GhostScreen.scss';
import Button from '@atoms/Button';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '@/router';
import { useTypedSelector } from '@hooks/useTypedSelector';

const GhostScreen: React.FC = () => {
    const isAuth = useTypedSelector((state) => state.auth.isAuth);
    const navigate = useNavigate();

    const [eyesTranslate, setEyesTranslate] = useState({
        xAxis: 0,
        yAxis: 0,
    });

    const handleMouseMove = useCallback((ev: React.MouseEvent) => {
        //verticalAxis
        const mouseY = ev.pageY;
        let yAxis = -1 * ((window.innerHeight / 2 - mouseY) / window.innerHeight) * 300;
        //horizontalAxis
        const mouseX = 0.6 * (ev.pageX / window.innerWidth) + 0.2;
        let xAxis = mouseX * 100 - 100;
        setEyesTranslate({ xAxis: xAxis, yAxis: yAxis });
    }, []);

    return (
        <div className="ghost__wrapper" onMouseMove={handleMouseMove}>
            <div className="ghost__image">
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>

                <div className="ghost__container">
                    <div
                        className="ghost__eyes"
                        style={{
                            transform: 'translate(' + eyesTranslate.xAxis + '%,' + eyesTranslate.yAxis + '%)',
                        }}
                    >
                        <div className="ghost__eye-left"></div>
                        <div className="ghost__eye-right"></div>
                    </div>
                    <div className="ghost__bottom">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className="ghost__shadow"></div>
            </div>
            <div className="ghost__text ghost__text-404">404</div>
            <div className="ghost__text ghost__text-message">Страница не найдена</div>
            <div className="ghost__button">
                <Button
                    type="button"
                    className="ghost_back-button"
                    fullWidth
                    onClick={() => {
                        if (isAuth) {
                            navigate(RouteNames.DASHBOARD);
                        } else {
                            navigate(RouteNames.LOGIN);
                        }
                    }}
                >
                    На главную страницу
                </Button>
            </div>
        </div>
    );
};

export default GhostScreen;
