import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ISmartBusinessDirectories } from '@/models/smartBusiness/ISmartBusinessDirectories';
import { ISmartBusinessParametersAttributes } from '@/models/smartBusiness/ISmartBusinessParametersAttributes';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { ISmartBusinessSearchResultData } from '@/models/smartBusiness/ISmartBusinessSearchResultData';
import { ISmartBusinessSearchResultCountData } from '@/models/smartBusiness/ISmartBusinessSearchResultCountData';

export class SmartBusinessService {
    static async getDirectories(): Promise<AxiosResponse<ISmartBusinessDirectories>> {
        return $api.get<ISmartBusinessDirectories>(`/smartbusiness/directories/`);
    }

    static async getAttributes(
        categoryId: string,
        orderId: number,
        token: string,
    ): Promise<AxiosResponse<ISmartBusinessParametersAttributes>> {
        return $api.get<ISmartBusinessParametersAttributes>(
            `/smartbusiness/attributes/${categoryId}/${orderId}/${token}`,
        );
    }

    static async getCounts(hashId: string, token: string): Promise<AxiosResponse<ISmartBusinessSearchResultCountData>> {
        return $api.get<ISmartBusinessSearchResultCountData>(`/smartbusiness/counts/${hashId}/${token}`, {
            retry: 3,
            retryDelay: 3000,
        } as any);
    }

    static async searchWinners(
        filter: ISmartBusinessParameters,
        currentPage: number,
        orderId: number,
        token: string,
        pageSize: number,
        filters?: string[],
        hashId?: string,
    ): Promise<AxiosResponse<ISmartBusinessSearchResultData>> {
        return $api.post<ISmartBusinessSearchResultData>(`/smartbusiness/winners/${orderId}/${token}`, {
            ...filter,
            region_id: filter.region_id?.flat(1),
            page: currentPage,
            pageSize: pageSize,
            groupFilter: filters,
            hashId: hashId,
        });
    }

    static async getProtocol(
        filter: ISmartBusinessParameters,
        currentPage: number,
        orderId: number,
        token: string,
        pageSize: number,
        filters?: string[],
        hashId?: string,
    ): Promise<AxiosResponse<Blob>> {
        return $api.post<Blob>(
            `/smartbusiness/getProtocol/${orderId}/${token}`,
            {
                ...filter,
                region_id: filter.region_id?.flat(1),
                page: currentPage,
                pageSize: pageSize,
                groupFilter: filters,
                hashId: hashId,
                report_in: 'xls',
            },
            { responseType: 'blob' },
        );
    }

    static async getReport(token: string, calculationId: string): Promise<AxiosResponse<Blob>> {
        return $api.get<Blob>(`/smartbusiness/getReport/${token}/${calculationId}`, { responseType: 'blob' });
    }
}
