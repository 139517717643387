import { UseFormReturn } from 'react-hook-form';
import { IDocumentTable } from '@models/Forms/IForms';
import './Table.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import EditTable from './controls/EditTable';
import ViewTable from './controls/ViewTable';
import { IErrorMessage } from '@/components/molecules/Errors/Errors';

export interface ITableProps<TFieldValues extends object = object> {
    table: IDocumentTable;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: IErrorMessage) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId?: string;
}

const Table = <TFieldValues extends object = object>({
    table,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    docId,
    ...props
}: ITableProps<TFieldValues>) => {
    const activated = React.useRef(false);
    let rules = table.visibilityRules;
    const dataField = fields[table.key];
    if (dataField) {
        includedFields.push(dataField.index);
    }
    const visibilityMng = new FormulaManager(rules!);
    visibilityMng.Init(fields);
    const [visibility, setVisibility] = useState<boolean>(false);

    const InitFormulas = async () => {
        let vis = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(vis);
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    return table && table.key && table.tableColumn && visibility && dataField ? (
        isEdit || isNew ? (
            <EditTable
                table={table}
                formMethods={formMethods}
                setError={setError}
                isEdit={isEdit}
                isNew={isNew}
                fields={fields}
                docId={docId}
            />
        ) : (
            <ViewTable table={table} formMethods={formMethods} fields={fields} docId={docId} />
        )
    ) : (
        <></>
    );
};

export default Table;
