import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { IFileData } from '@/types';
import { v4 as uuidv4 } from 'uuid';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/ui/data-grid';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import InputFile from '@atoms/InputFile';
import { getAcceptExtensionString } from '@utils/helpers';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { MAX_FILE_SIZE } from '@utils/constants';

export interface IAttachFilesBlockProps {
    id?: string;
    model: IAttachesCreateModel;
    onFilesSelected: (files: ISelectedFiles) => void;
}

export interface ISelectedFiles {
    attachProperties: any[];
    files: File[];
}

export const AttachFilesBlock = forwardRef((props: IAttachFilesBlockProps, ref) => {
    const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);
    const [binaryFiles, setBinaryFiles] = useState<{ [fileId: string]: File }>({});

    const getUpdatedFiles = (): ISelectedFiles => {
        let binaryFilesToUpload = uploadingFiles.map((fileData) => {
            let f = binaryFiles[fileData._fileId];
            //delete fileDataClone._fileId;
            return f;
        });

        let filesDataToUpload = uploadingFiles.map((x) => {
            let clone = { ...x };
            delete clone._fileId;
            return clone;
        });

        return {
            attachProperties: filesDataToUpload,
            files: binaryFilesToUpload,
        };
    };

    useEffect(() => {
        let f: ISelectedFiles = getUpdatedFiles();
        if (props.onFilesSelected) props.onFilesSelected(f);
    }, [uploadingFiles, binaryFiles]);

    useImperativeHandle(ref, () => ({
        beforeSubmit: (): ISelectedFiles => {
            gridRef.current?.instance.saveEditData();
            return getUpdatedFiles();
        },
    }));

    const setFile = (files: IFileData[]) => {
        if (files && props?.model?.properties && props?.model?.categories) {
            let newBinaryFiles = { ...binaryFiles };
            for (var i = 0; i < files.length; i++) {
                let f = files[i].file;
                let fileGuid = uuidv4();

                //записываем в словарь
                if (newBinaryFiles && f) {
                    newBinaryFiles[fileGuid] = f;
                }

                let fileObj: any = {
                    _fileId: fileGuid,
                    _actType: 'add',
                    _filename: f.name,
                    _category_: props?.model?.categories[0]?.name,
                };
                for (var j = 0; j < props?.model?.properties?.length; j++) {
                    let col = props?.model?.properties[j];
                    let keyName = col.key;

                    switch (keyName) {
                        case 'AttachmentFileName':
                            fileObj[keyName] = f?.name;
                            break;
                        default:
                            let foundDefaultValue = props.model?.defaultValues?.find((x) => x.attr == keyName);
                            if (foundDefaultValue) {
                                //значение по умолчанию
                                fileObj[keyName] = foundDefaultValue.val;
                            } else {
                                //если поле словарное - берем первое значение из соответствующего словаря
                                if (
                                    props.model.dictionaries &&
                                    col.dictName &&
                                    props.model.dictionaries[col.dictName]
                                ) {
                                    if (props.model.dictionaries[col.dictName].length > 0)
                                        fileObj[keyName] = props.model.dictionaries[col.dictName][0].code;
                                } else {
                                    fileObj[keyName] = undefined;
                                }
                            }
                    }
                }

                if (props.model?.defaultValues) {
                    let customProps = props.model?.defaultValues.filter((x) => x.attr.startsWith('_custom_property_'));
                    customProps.forEach((customProp) => {
                        let customVal = customProp.val;

                        switch (customProp.val) {
                            case '@guid()':
                                customVal = uuidv4();
                                break;
                        }

                        fileObj[customProp.attr] = customVal;
                    });
                }

                setUploadingFiles((uploadingFiles) => [...uploadingFiles, fileObj]);
            }

            setBinaryFiles(newBinaryFiles);
        }

        gridRef.current?.instance.refresh();
    };

    const gridRef = useRef<DataGrid>(null);
    return (
        <div data-testid={props.id ? `attach-files-block-${props.id}` : undefined}>
            <div className="AttachFiles__AllowedExtensionsGroup">
                <p className="AttachFiles__AllowedExtensions">
                    <span>Список допустимых форматов: </span>
                    {props?.model?.categories && props?.model?.categories?.length > 0 ? (
                        props?.model?.categories?.length > 1 ? (
                            <ul>
                                {props?.model?.categories.map((cat) => (
                                    <li key={cat?.name}>
                                        <span>{cat?.name}:</span>
                                        <span>
                                            {cat?.allowedExtensions ? cat?.allowedExtensions : 'ограничений нет'}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <span>
                                {props?.model?.categories[0].allowedExtensions
                                    ? props?.model?.categories[0].allowedExtensions
                                    : 'ограничений нет'}
                            </span>
                        )
                    ) : (
                        <span>ограничений нет</span>
                    )}
                </p>
            </div>

            <InputFile
                className="AttachFiles__SelectFilesInput"
                setFile={setFile}
                placeholder="Выбрать файл..."
                maxSize={MAX_FILE_SIZE}
                multiple={true}
                showChips={false}
                clearFilesAfterSet={true}
                accept={
                    props?.model?.categories && props?.model?.categories?.length > 0
                        ? props?.model?.categories.find(
                              (cat) => cat?.allowedExtensions == null || !cat?.allowedExtensions?.trim(),
                          )
                            ? '*' // если среди категорий есть категория без ограничений
                            : getAcceptExtensionString(
                                  props?.model?.categories
                                      .map((cat) => cat?.allowedExtensions)
                                      .filter((ext) => ext)
                                      .join(','), // конкатенируем допустимые категории
                              )
                        : '*'
                }
            />

            <DevExpressDataGrid
                dataSource={uploadingFiles}
                remoteOperations={true}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                columnMinWidth={30}
                showColumnHeaders={true}
                columnAutoWidth={true}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Файлы не выбраны'}
                rowAlternationEnabled={true}
                ref={gridRef}
                onRowRemoved={(e) => {
                    let removedFileId = e.key._fileId;
                    if (removedFileId && binaryFiles[removedFileId]) {
                        let newBinaryFiles = { ...binaryFiles };
                        delete newBinaryFiles[removedFileId];
                        setBinaryFiles(newBinaryFiles);
                    }
                    setUploadingFiles([...uploadingFiles]);
                }}
                onCellHoverChanged={onCellHoverChanged}
            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={true}
                    confirmDelete={false}
                />

                {props?.model?.properties?.map((col, i) => {
                    return (
                        <Column
                            key={`col_${i}`}
                            allowFiltering={true}
                            caption={col.displayName}
                            dataField={col.key}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={true}
                        >
                            {props?.model?.dictionaries && col.dictName && props?.model?.dictionaries[col.dictName] && (
                                <Lookup
                                    dataSource={props.model.dictionaries[col.dictName]}
                                    displayExpr="code"
                                    valueExpr="code"
                                />
                            )}
                        </Column>
                    );
                })}

                {/*Отображаем столбец с аттачами только когда категорий больше чем одна*/}
                {(props?.model?.categories?.length ?? 0) > 1 && (
                    <Column
                        key={'col_category'}
                        allowFiltering={true}
                        caption={'Категория'}
                        dataField={'_category_'}
                        dataType={'string'}
                        visible={true}
                        allowSorting={false}
                        filterOperations={['contains']}
                        showEditorAlways={true}
                    >
                        <Lookup dataSource={props?.model?.categories} displayExpr="name" valueExpr="name" />
                    </Column>
                )}
            </DevExpressDataGrid>
        </div>
    );
});
