import { SelectControl } from '@/components/controls';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { ISmartBusinessRegionHolderType } from '@/models/smartBusiness/ISmartBusinessRegionHolderType';
import { IOption } from '@/types';
import { deepEqual } from '@/utils/helpers';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface IOwnProps {
    regions: ISmartBusinessRegionHolderType[];
    formMethods: UseFormReturn<ISmartBusinessParameters>;
}

const ALL_REGIONS_SELECTOR = 'all-regions';

export const SmartBusinessRegionField: FC<IOwnProps> = ({ regions, formMethods }) => {
    const options: IOption[] = [
        {
            label: 'Все регионы',
            value: regions.map((x) => {
                return x.id;
            }),
        },
    ];
    options.push({
        label: 'Федеральные округа',
        value: 'regions',
        disabled: true,
    } as IOption);

    const districts: IOption[] = regions
        .map((region) => region.federalDistrictName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((label) => ({
            value: '',
            label,
            cssClass: 'rf-select_subElement',
        }));

    districts.forEach((item) => {
        const districtRegions = regions
            .filter((region) => region.federalDistrictName === item.label)
            .map((region) => {
                return region.id;
            });
        item.value = districtRegions;
        options.push(item);
    });

    districts.forEach((district, index) => {
        const districtRegions = regions
            .filter((region) => region.federalDistrictName === district.label)
            .map(
                (region) =>
                    ({
                        value: [region.id],
                        label: region.name,
                        cssClass: 'rf-select_subElement',
                    }) as IOption,
            );
        options.push({
            label: district.label === 'Без округа' ? district.label : `${district.label} федеральный округ`,
            value: `sub-regions-${index}`,

            disabled: true,
        } as IOption);
        districtRegions.forEach((item) => {
            options.push(item);
        });
    });

    console.log(options);

    return (
        <SelectControl
            label="Регион"
            multiselect={true}
            setValue={(options) => {
                return options.map((x) => x.value);
            }}
            getValue={(options: IOption[], value: any) => {
                return value.map((x: any) => {
                    return options.filter((y) => deepEqual(y.value, x))[0];
                });
            }}
            required={true}
            name="region_id"
            options={options}
            formState={formMethods.formState}
            control={formMethods.control}
            rules={{ required: 'Обязательное поле' }}
        />
    );
};
