import React from 'react';
import { useLocation } from 'react-router-dom';
import { ITreeOption } from '@/types';
import TreeItem from './TreeItem';

export interface ITreeProps {
    /** Уникальный ID дерева */
    id: string;
    /** Список */
    list: ITreeOption[];
    /** Глубина вложенности */
    depth?: number;
    /** Состояние открыт/закрыт */
    open?: boolean;
    /** Родительский элемент */
    parent?: ITreeOption;
    /** Обновление структуры */
    onChange?: (o: ITreeOption) => void;
    /** Изменение состояния чекбокса. */
    onCheck?: (option: ITreeOption) => void;
    /** Клик по лейблу */
    onClick?: (o: ITreeOption) => void;
    /** Активная запись */
    activeItem?: ITreeOption | undefined;
}

const Folder: React.FC<ITreeProps> = ({
    id,
    list,
    onChange,
    onCheck,
    onClick,
    depth = 0,
    open = true,
    activeItem,
}: ITreeProps) => {
    // ---------------------------------------------------------------------------------------------------------------------------------------
    const location = useLocation();
    /** Базовый размер отступа слева */
    const PADDING_LEFT_BASE = 35;
    const style = { paddingLeft: depth === 0 ? 0 : PADDING_LEFT_BASE };

    // ---------------------------------------------------------------------------------------------------------------------------------------

    const listJSX = list.map((item: ITreeOption, i: number) => {
        return (
            <TreeItem
                id={id}
                key={item.value + '_' + depth + '_' + i}
                item={item}
                onChange={onChange}
                onCheck={onCheck}
                onClick={onClick}
                depth={depth + 1}
                open={open}
                activeItem={item.url == location.pathname + location.search ? item : undefined}
                last={i === list.length - 1}
            />
        );
    });

    // ---------------------------------------------------------------------------------------------------------------------------------------

    return (
        <div className="rf-tree" style={style}>
            {listJSX}
        </div>
    );
};

export default Folder;
