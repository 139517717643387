import React, { useCallback, useEffect, useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import { FieldValues, useForm } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import { IForms } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import Preloader from '@atoms/Preloader';
import './DocumentsBatchEditingExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { DocumentService } from '@services/DocumentService';
import ModalFormBuilder, { IModalFormBuilderProps } from '@molecules/ModalFormBuilder/ModalFormBuilder';
import { convertListToMap, getEmptyFormDataByScheme, getFormDataByScheme } from '@utils/documentUtils';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { ISelectedFiles } from '@molecules/AttachFilesBlock/AttachFilesBlock';
import { ModalSize } from '@atoms/Modal/Modal';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';
import { RemindersService } from '@services/RemindersService';
import { IMailRemindersOptions } from '@molecules/AddMailReminderBlock/AddMailReminderBlock';
import { ValidatorManager } from '@/utils/validatorManager';
import { sendErrorMsg } from '@/components/molecules/Errors';
import { FormulaManager } from '@/utils/FormulaManager';

export class DocumentsBatchEditingExecutor extends DocBaseActionExecutor {
    private _modalProps?: IDocumentsBatchEditingExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            attachFiles: action.options?.attachFiles == true,
            attachRequired: action.options?.attachRequired == true,
            addMailReminders: action.options?.addMailReminders == true,
            virtualFields: action.options?.virtualFields as string[],
            showCurrentValues: action.options?.showCurrentValues ?? true,
            postActions: action.options?.postActions as any[],
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <DocumentsBatchEditingExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IDocumentsBatchEditingExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    showCurrentValues: boolean;
    attachFiles: boolean;
    attachRequired: boolean;
    addMailReminders: boolean;
    virtualFields?: string[];
    postActions?: any[];
    completeHandler?: (isSucceed: boolean) => void;
}

const DocumentsBatchEditingExecutorModal: React.FC<IDocumentsBatchEditingExecutorProps> = (
    props: IDocumentsBatchEditingExecutorProps,
) => {
    const [loading, setLoading] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [showOkButton, setShowOkButton] = useState<boolean>(true);
    const [form, setForm] = useState<IForms>();
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [mailRemindersBlockModel, setMailRemindersBlockModel] = useState<IMailRemindersBlockModel>();

    const methods = useForm<IDocumentData>({
        mode: 'onBlur', // "onChange"
    });
    const service = new DocumentExecutionService(props.docId);

    useEffect(() => {
        //получение модели для аттачей
        if (props.attachFiles) {
            //let actvotyPartId = props.actId;
            let attachService = new AttachActionsService(props.docId, '-1');
            attachService.getAttachInfo(props.actionKey).then((res) => {
                setAttachesCreateModel(res.data);
            });
        }

        //получение модели для блока напоминаний
        if (props.addMailReminders) {
            RemindersService.getMailRemindersBlockModel(props.docId, props.actionKey).then((res) => {
                setMailRemindersBlockModel(res.data);
            });
        }

        // Получение схемы
        service
            .getFormBuilderScheme(props.actionKey)
            .then((dto) => {
                let form = dto.data;
                setForm(form);

                // Заполнить пустые данные на основе схемы
                if (!props.showCurrentValues) {
                    let formData = getEmptyFormDataByScheme(form?.view?.rows?.row!);
                    let fields = convertListToMap(formData.fields);
                    setFieldsData(fields);
                    methods.reset(formData);
                }
            })
            .catch((error) => setErrorText(error));

        // Получение данных
        if (props.showCurrentValues) {
            DocumentService.getDataByAction(props.actionKey, props.docId, undefined, false, props.showCurrentValues)
                .then((result) => {
                    if (result.data) {
                        let f = result.data.fields;

                        //доп фаршируем данные виртуальными свойствами
                        if (props.virtualFields) {
                            props.virtualFields.forEach((x) => {
                                f.push({
                                    name: x,
                                    value: undefined,
                                });
                            });
                        }

                        let fields = convertListToMap(f);
                        setFieldsData(fields);
                        methods.reset(result.data);
                    }
                })
                .catch((error) => setErrorText(error));
        }
    }, []);

    /**
     * Обрабатывает подстановки
     * @param expression
     * @returns
     */
    const processSubstitutionsAsync = async (expression: string): Promise<string | undefined> => {
        let mng = new FormulaManager(expression);
        mng.Init();
        let fields = mng.GetFields();
        if (fields.length > 0) {
            let response = await DocumentService.getFormData(props.docId, mng.GetFields());
            return mng.ReplaceFormulaValues(
                false,
                false,
                response.data.fields.map((field) => field.value),
                true,
            );
        } else {
            return Promise.resolve(expression);
        }
    };

    /**
     * Обрабатывает post-action действия
     * @param actions
     */
    const processPostActions = (actions?: any[]) => {
        if (actions) {
            actions.forEach(async (action) => {
                switch (action.type) {
                    case 'openUrlInNewTab':
                        let url = await processSubstitutionsAsync(action.url);
                        window.open(url, '_blank', 'noopener,noreferrer');
                        break;

                    default:
                        console.log('Неизвестный тип post-action действия');
                        break;
                }
            });
        }
    };

    const onSubmit = useCallback(
        async (data: FieldValues, files?: ISelectedFiles, mailReminderOptions?: IMailRemindersOptions) => {
            // console.log(data);
            setLoading(true);

            // проверка на обязательность наличия аттачей
            if (
                props.attachFiles &&
                props.attachRequired &&
                (files?.files === undefined || files?.files.length === 0)
            ) {
                setWarningText('Необходимо приложить файл');
                setLoading(false);
                return;
            }
            let errors: string[] = [];

            if (form?.view?.validators && form?.view?.validators != null && fieldsData) {
                let validatorManager = new ValidatorManager(fieldsData, methods);
                await validatorManager.validateAllAsync(form?.view.validators?.validator, errors);

                if (errors.length > 0) {
                    sendErrorMsg({
                        message: errors,
                    });
                    setLoading(false);
                    return;
                }
            }
            let val = getFormDataByScheme(form?.view?.rows?.row!, data);

            // Сохранение данных
            service
                .saveDocumentsBatchEditingData(props.docId, props.actionKey, val, files)
                .then((result) => {
                    if (result.data.warnings && result.data.warnings.length > 0) {
                        setWarningText(result.data.warnings.join(', '));
                        setShowOkButton(false);
                    } else {
                        processPostActions(props.postActions);
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setLoading(false);
                });

            if (mailReminderOptions && mailRemindersBlockModel) {
                let remIds =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderUserIdsField)?.value ?? '';
                let remTargetDate = new Date(
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTargetDateField)
                        ?.value as string,
                );
                let remText =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTextField)?.value ?? '';

                let dayOfWeek =
                    mailReminderOptions.periodical !== undefined &&
                    mailReminderOptions.periodical.dayOfWeek !== undefined
                        ? mailReminderOptions.periodical.dayOfWeek[0]?.value
                        : '';

                //Создание напоминаний
                RemindersService.createBlockMailReminders(
                    props.docId,
                    props.actionKey,
                    remText?.toString(),
                    remTargetDate.toISOString(),
                    remIds?.toString(),
                    mailReminderOptions.daysBefore,
                    mailReminderOptions.periodical.weekly === 1 ? 'true' : 'false',
                    mailReminderOptions.periodical.monthly === 1 ? 'true' : 'false',
                    dayOfWeek,
                );
            }
        },
        [form, fieldsData, methods],
    );

    const messageBoxProp: IModalFormBuilderProps = {
        header: props.title,
        showOkButton: showOkButton,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        isBusy: loading,
        onSubmit: onSubmit,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        warningText: warningText,
        formMethods: methods,
        fields: fieldsData!,
        rows: form?.view,
        createAttachModel: attachesCreateModel,
        mailRemindersBlockModel: mailRemindersBlockModel,
        showContent: showOkButton,
        docId: props.docId,
    };

    return fieldsData ? <ModalFormBuilder {...messageBoxProp} /> : <Preloader size="l" />;
};
