import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { AllClose } from '@/indexIcon';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import Preloader from '@atoms/Preloader';

import './SmartBusinessExecutor.scss';
import Hint from '@atoms/Hint';
import { ModalSize } from '@atoms/Modal/Modal';
import SmartBusinessParametersForm from '@/components/molecules/SmartBusiness/SmartBusinessParametersForm/SmartBusinessParametersForm';
import SmartBusinessWinners from '@/components/molecules/SmartBusiness/SmartBusinessWinners/SmartBusinessWinners';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { SmartBusinessService } from '@/services/SmartBusinessService';
import { ISmartBusinessDirectories } from '@/models/smartBusiness/ISmartBusinessDirectories';
import { ISmartBusinessResult } from '@/models/smartBusiness/ISmartBusinessResult';
import { DocumentExecutionService } from '@/services/actions/DocumentExecutionService';
import { ISmartBusinessSearchParams } from '@/models/smartBusiness/ISmartBusinessSearchParams';

export class SmartBusinessExecutor extends DocBaseActionExecutor {
    private _modalProps?: ISmartBusinessProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            action: action,
            userType: action.options?.userType ?? 'secretary',
            participantVote: action.options?.participantVote,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <SmartBusinessExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ISmartBusinessProps {
    docId: string;
    action: IBaseAction;
    userType: 'participant' | 'secretary';
    participantVote?: 'yes' | 'no' | 'selectable';
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const SmartBusinessExecutorModal: React.FC<ISmartBusinessProps> = (props: ISmartBusinessProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const [paramsForm, setParamsForm] = useState<ISmartBusinessParameters>();
    const [directories, setDirectories] = useState<ISmartBusinessDirectories>();
    const [searchParam, setSearchParam] = useState<ISmartBusinessSearchParams>();

    const activated = React.useRef(false);

    useEffect(() => {
        if (directories === undefined) {
            SmartBusinessService.getDirectories()
                .then((result) => {
                    setDirectories(result.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, []);

    const onSubmit = async () => {
        if (searchParam !== undefined) {
            try {
                setLoading(true);
                let execServ = new DocumentExecutionService(props.docId);
                let protocol: File;
                let report: File;

                SmartBusinessService.getReport(searchParam.token, searchParam.calculationId).then(async (result) => {
                    report = new File([result.data], 'Отчет-подтверждени.xls');
                    SmartBusinessService.getProtocol(
                        searchParam.filter,
                        searchParam.currentPage,
                        searchParam.orderId,
                        searchParam.token,
                        searchParam.pageSize,
                        searchParam.filters,
                        searchParam.hashId,
                    )
                        .then(async (result) => {
                            protocol = new File([result.data], 'Протокол.xls');
                            await execServ.saveAnalysisSmartBusiness(
                                props.action.key,
                                {
                                    experience_weight: searchParam.filter.experience_weight,
                                    max_experience: searchParam.filter.max_experience,
                                    min_experience: searchParam.filter.min_experience,
                                    price_weight: searchParam.filter.price_weight,
                                    start_price: searchParam.filter.start_price,
                                    hashId: searchParam.hashId,
                                } as ISmartBusinessResult,
                                protocol,
                                report,
                            );

                            if (props.completeHandler) props.completeHandler(true);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>{props.action.displayName}</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<AllClose size="xs" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading && directories ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth="100%" />}
                    <SmartBusinessParametersForm
                        onSubmit={(data) => {
                            setParamsForm(data);
                        }}
                        directories={directories!}
                        token={directories?.token!}
                    />
                    {paramsForm && (
                        <SmartBusinessWinners
                            formParams={paramsForm}
                            onSearch={(data) => {
                                setSearchParam(data);
                            }}
                            token={directories?.token!}
                        />
                    )}
                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                disabled={searchParam === undefined}
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={async () => {
                                    await onSubmit();
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
