import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import InputMoney from '@/components/atoms/InputMoney';
import { IInputMoneyProps } from '@/components/atoms/InputMoney/InputMoney';

export type IInputMoneyControlProps = IInputMoneyProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
    };

const InputMoneyControl = ({
    name,
    rules,
    defaultValue,
    control,
    formState,
    shouldUnregister,
    label,
    tooltip,
    required,
    formGroupProps,
    ...props
}: IInputMoneyControlProps) => {
    const Controller = useHookFormController();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <InputMoney
                        {...fieldProps}
                        {...props}
                        defaultValue={value}
                        onChangeValue={(val) => {
                            if (
                                value === undefined ||
                                value === null ||
                                value === 0 ||
                                value === '0' ||
                                value.toString() != val?.toString()
                            ) {
                                onChange(val);
                                if (props.onValueChange) {
                                    props.onValueChange(val);
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default InputMoneyControl;
