export enum AttachActionTypes {
    /// <summary>
    /// ���������
    /// </summary>
    Download,
    /// <summary>
    /// ������� ������
    /// </summary>
    CreateVersion,
    /// <summary>
    /// �������
    /// </summary>
    Delete,
    /// <summary>
    /// ���������
    /// </summary>
    Edit,
    /// <summary>
    /// ��������� ����
    /// </summary>
    Sign,
    /// <summary>
    /// ����������� ��
    /// </summary>
    ViewSign,
}
