import React from 'react';
import { IIconProps } from '@/types';
import { iconSize } from '@utils/helpers';
/* eslint-disable max-len */

export default ({ size = 'xs', ...props }: IIconProps) => {
    return (
        <svg
            {...props}
            width={iconSize[size]}
            height={iconSize[size]}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 11.5858V4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4V11.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L12.7071 14.7071C12.5261 14.8881 12.2761 15 12 15C11.7239 15 11.4739 14.8881 11.2929 14.7071L7.29289 10.7071C6.90237 10.3166 6.90237 9.68342 7.29289 9.29289C7.68342 8.90237 8.31658 8.90237 8.70711 9.29289L11 11.5858Z"
                fill="currentColor"
            />
            <path
                d="M4 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20C3 19.4477 3.44772 19 4 19Z"
                fill="currentColor"
            />
        </svg>
    );
};
