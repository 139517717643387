import React, { forwardRef, InputHTMLAttributes, useEffect, useRef } from 'react';
import 'focus-visible';
import './Checkbox.scss';
import { AllReduce, AllSuccess } from '@/indexIcon';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    /** Лейбл */
    label?: React.ReactNode;
    /** Значение */
    value?: string;
    /**
     * Отображение иконки
     * @default true
     *  */
    icon?: boolean;
    /**
     * Вертикальное выравнивание
     * @default 'flex-start'
     *  */
    align?: 'flex-start' | 'center' | 'flex-end';
    /**
     * Если дочерние чекбоксы чекнуты, флаг равен true
     * @default false
     *  */
    halfChecked?: boolean;
    /**
     * Положение чекбокса
     *  @default 'left'
     * */
    position?: 'left' | 'right';
    /**
     * Круглый чекбокс
     * @default false
     */
    round?: boolean;
    /**
     *  100% ширины
     * @default false
     *  */
    fullWidth?: boolean;
    /**
     * Лейбл - текст
     * @default '''
     *  */
    titleAtt?: string;
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
}

const useCombinedRefs = (
    ...refs: Array<React.Ref<HTMLLabelElement> | React.MutableRefObject<null>>
): React.MutableRefObject<HTMLInputElement | null> => {
    const targetRef = useRef(null);

    useEffect(() => {
        refs.forEach((ref: React.Ref<HTMLLabelElement> | React.MutableRefObject<null>) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            }
            //else {
            //    ref.current = targetRef.current;
            //}
        });
    }, [refs]);

    return targetRef;
};

const Checkbox = forwardRef<HTMLLabelElement | null, ICheckboxProps>(
    (
        {
            label,
            value,
            invalid,
            icon = true,
            align = 'flex-start',
            halfChecked = false,
            position = 'left',
            round = false,
            fullWidth = false,
            titleAtt = '',
            ...props
        }: ICheckboxProps,
        ref,
    ) => {
        const defaultRef = useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);

        const roundClass = round ? 'rf-checkbox__check--round' : '';
        const fullWidthClass = fullWidth ? 'rf-checkbox__check--fullWidth' : '';

        const disabledClass = props.disabled ? 'disabled' : '';
        const alignClass: Record<string, string> = {
            'flex-start': 'rf-checkbox--flex-start',
            center: 'rf-checkbox--center',
            'flex-end': 'rf-checkbox--flex-end',
        };
        const showIconClass = icon ? '' : 'rf-checkbox__label--no-icon';
        const positionClass = position === 'left' ? 'rf-checkbox--left' : 'rf-checkbox--right';

        useEffect(() => {
            if (combinedRef?.current) {
                combinedRef.current.indeterminate = (value === undefined && halfChecked) ?? false;
            }
        }, [combinedRef, halfChecked]);

        useEffect(() => {
            if (combinedRef?.current) {
                if (props.defaultChecked) {
                    combinedRef.current.checked = true;
                } else {
                    combinedRef.current.checked = false;
                }
            }
        }, [props.defaultChecked]);

        return (
            <label
                ref={ref}
                className={`rf-checkbox ${props.className || ''} ${disabledClass} ${
                    alignClass[align]
                } ${positionClass} ${fullWidthClass}`}
            >
                <input
                    {...props}
                    type="checkbox"
                    className={`rf-checkbox__input ${
                        halfChecked && (value === undefined || value === null) ? 'rf-checkbox__input--half-checked' : ''
                    }`}
                    value={value === undefined || value === null ? undefined : value}
                    readOnly={props.readOnly}
                    ref={combinedRef}
                />

                {!!icon && (
                    <span className={`rf-checkbox__check ${roundClass}`}>
                        <span className="rf-checkbox__mark">
                            {halfChecked && (value === undefined || value === null) ? <AllReduce /> : <AllSuccess />}
                        </span>
                    </span>
                )}

                {label &&
                    (typeof label === 'string' ? (
                        <div
                            title={titleAtt}
                            dangerouslySetInnerHTML={{ __html: label }}
                            className={`rf-checkbox__label ${showIconClass}`}
                            tabIndex={-1}
                        />
                    ) : (
                        <div title={titleAtt} className={`rf-checkbox__label ${showIconClass}`} tabIndex={-1}>
                            {label}
                        </div>
                    ))}
            </label>
        );
    },
);

export default Checkbox;
