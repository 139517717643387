import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { AllClose } from '@/indexIcon';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import Preloader from '@atoms/Preloader';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import { IRows } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { IDocumentData } from '@models/document/IDocumentData';
import { FormProviderControl, HookFormProvider } from '@controls/index';
import './ModalFormBuilder.scss';
import Hint from '@atoms/Hint';
import { ModalSize } from '@atoms/Modal/Modal';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { AttachFilesBlock, ISelectedFiles } from '../AttachFilesBlock/AttachFilesBlock';
import { v4 as uuidv4 } from 'uuid';
import { AddMailReminderBlock, IMailRemindersOptions } from '../AddMailReminderBlock/AddMailReminderBlock';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';
import { checkFilesSize, formatBytes } from '@utils/helpers';
import { MAX_FILE_SIZE } from '@utils/constants';
import { sendErrorMsg } from '../Errors';
import { IErrorMessage } from '../Errors/Errors';

export interface IModalFormBuilderProps {
    header: string;
    message?: string;
    size?: ModalSize;
    showOkButton: boolean;
    okButtonText: string;
    showCancelButton: boolean;
    cancelButtonText: string;
    onSubmit: (data: FieldValues, files?: ISelectedFiles, mailReminderOptions?: IMailRemindersOptions) => void;
    cancelClick: () => void;
    isBusy?: boolean;
    errorText?: string;
    successText?: string;
    warningText?: string;
    rows?: IRows;
    formMethods: UseFormReturn<IDocumentData>;
    fields: Record<string, IFieldElem>;
    children?: ReactNode;
    createAttachModel?: IAttachesCreateModel;
    mailRemindersBlockModel?: IMailRemindersBlockModel;
    showContent?: boolean;
    docId?: string;
}

const ModalFormBuilder: FC<IModalFormBuilderProps> = ({
    size = 'm',
    showContent = true,
    ...props
}: IModalFormBuilderProps) => {
    const [selectedFiles, setSelectedFiles] = useState<ISelectedFiles>();
    const [filesSizeExceeded, setFilesSizeExceeded] = useState<boolean>(false);
    const childFilesBlock = useRef();
    const childAddMailRemindersBlock = useRef();
    const [errorText, setErrorText] = useState<string>();

    let fieldsName: number[] = [];
    let idForm = uuidv4();

    const onSubmit = (form: any) => {
        var fields = props.formMethods.control._formValues.fields as FieldValues;
        let filesToSend = selectedFiles;
        if (childFilesBlock) {
            let f = (childFilesBlock?.current as any)?.beforeSubmit() as ISelectedFiles;
            if (f) filesToSend = f;
        }

        let mrOptions = props.formMethods.control._formValues.mailReminders;

        // Проверка ограничений по аттачам
        if (props.createAttachModel?.restrictions) {
            setErrorText(undefined);
            if (!checkFilesForRestrictions(filesToSend)) return;
        }

        props.onSubmit(fields, filesToSend, mrOptions);
    };

    useEffect(() => {
        if (selectedFiles) {
            if (
                !checkFilesSize(
                    Object.values<File>(selectedFiles.files),
                    MAX_FILE_SIZE ? MAX_FILE_SIZE * 1024 : undefined,
                )
            ) {
                setFilesSizeExceeded(true);
            } else {
                setFilesSizeExceeded(false);
            }
        }
    }, [selectedFiles]);

    const checkFilesForRestrictions = (filesToSend: ISelectedFiles | undefined): boolean => {
        // Далее в WebApi соответствие Attach и Property происходит по name
        let uploadingFiles = filesToSend?.attachProperties;
        let binaryFiles: Record<string, File> = {};
        filesToSend?.files.forEach((el, index) => (binaryFiles[el.name] = el));

        let extErrors: string[] = [];
        if (uploadingFiles) {
            uploadingFiles.forEach((f) => {
                let fCat = f._category_;
                let fExt = f.AttachmentFileName?.split('.')?.pop()?.toLowerCase();
                let foundCat = props.createAttachModel?.categories.find((x) => x.name == fCat);
                if (fExt && foundCat && foundCat.allowedExtensions) {
                    if (!foundCat.allowedExtensions.split(',').some((x) => x.toLowerCase() == fExt)) {
                        extErrors.push(
                            `Файл '${f.AttachmentFileName}' не может находиться в категории '${foundCat.name}'`,
                        );
                    }
                }
                if (props.createAttachModel?.restrictions) {
                    props.createAttachModel?.restrictions.forEach((r) => {
                        if (f[r.ifAttachAttrName] == r.ifAttachAttrValue) {
                            //если условие, применять ли проверку - сработало, начинаем саму проверку
                            //Если не совпадает или заданный атрибут или перечень расширений - ругаемся
                            if (
                                (r.checkAttrName && f[r.checkAttrName] != r.checkAttrValue) ||
                                (r.checkExtensions &&
                                    !r.checkExtensions.split(',').some((x) => x.toLowerCase() == fExt)) ||
                                (r.checkFileSizeInBytes && r.checkFileSizeInBytes < binaryFiles[f._filename].size)
                            ) {
                                extErrors.push(`Файл '${f.AttachmentFileName}' ${r.checkMessage}`);
                            }
                        }
                    });
                }
            });
        }

        if (extErrors.length > 0) {
            setErrorText(extErrors.join('; '));
            return false;
        } else {
            return true;
        }
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={size}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>
                            <span>{props.header}</span>
                        </div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.cancelClick) props?.cancelClick();
                                }}
                                startAdornment={<AllClose size="xs" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {props.isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {props.errorText && (
                        <Hint icon="info" title={`Ошибка: ${props.errorText}`} variant="red" maxWidth="100%" />
                    )}
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth="100%" />}
                    {filesSizeExceeded && (
                        <Hint
                            icon="info"
                            title={`Ошибка: Превышен максимальный размер загружаемых файлов (${formatBytes(
                                MAX_FILE_SIZE! * 1024,
                            )}).`}
                            variant="red"
                            maxWidth="100%"
                        />
                    )}
                    {props.warningText && (
                        <Hint icon="info" title={`Внимание: ${props.warningText}`} variant="yellow" maxWidth="100%" />
                    )}
                    {props.successText && (
                        <Hint icon="info" title={`${props.successText}`} variant="green" maxWidth="100%" />
                    )}
                    {props.message && <div className="modal-dlg-text">{props.message}</div>}

                    <FormProviderControl
                        formMethods={props.formMethods}
                        className="base-form-provider doc-form"
                        onSubmit={onSubmit}
                        id={idForm}
                    >
                        <HookFormProvider controller={Controller}>
                            {showContent && (
                                <>
                                    {props.children}
                                    <div className=" pw-container-row pw-content">
                                        <FormBuilder
                                            fields={props.fields}
                                            setError={(errors?: IErrorMessage) => {
                                                if (errors) {
                                                    sendErrorMsg(errors);
                                                }
                                            }}
                                            rows={props.rows}
                                            isEdit={false}
                                            isNew={true}
                                            formMethods={props.formMethods}
                                            includedFields={fieldsName}
                                            docId={props.docId ? props.docId : ''}
                                            uniqueKey={props.docId ? props.docId : ''}
                                        />
                                    </div>

                                    {props.createAttachModel && (
                                        <AttachFilesBlock
                                            ref={childFilesBlock}
                                            model={props.createAttachModel}
                                            onFilesSelected={setSelectedFiles}
                                        />
                                    )}

                                    {props.mailRemindersBlockModel && (
                                        <AddMailReminderBlock
                                            ref={childAddMailRemindersBlock}
                                            model={props.mailRemindersBlockModel}
                                            formMethods={props.formMethods}
                                        />
                                    )}
                                </>
                            )}
                            <div className="modal-dlg-buttons">
                                <div className={'left'}>
                                    {props.showOkButton && (
                                        <Button
                                            size="s"
                                            aria-label={props.okButtonText}
                                            type="submit"
                                            form={idForm}
                                            disabled={filesSizeExceeded}
                                        >
                                            {props.okButtonText}
                                        </Button>
                                    )}

                                    {props.showCancelButton && (
                                        <Button
                                            buttonType="light"
                                            size="s"
                                            aria-label={props.cancelButtonText}
                                            onClick={() => {
                                                if (props?.cancelClick) props?.cancelClick();
                                            }}
                                        >
                                            {props.cancelButtonText}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </HookFormProvider>
                    </FormProviderControl>
                </div>
            )}
        </Modal>
    );
};

export default ModalFormBuilder;
