import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './TimeElement.scss';
import { arrayTimeHours, arrayTimeMinutes, arrayTimeSeconds } from './timeData';
import { MenuContext } from '../../Menu/Menu';
import { classnames } from '@utils/classnames';
import { ArrowsChevronLeft } from '@/indexIcon';

export interface ITimepickerProps {
    /** Выбрать часы/время */
    updateTime: (newTime: string) => void;
    /** Минимальное значение 00:00 */
    min: string;
    /** Максимальное значение 00:00 */
    max: string;
    /** Значение */
    value?: string;
    /** Формат значения без секунд */
    isNoSecFormat?: boolean;
}

export const getTime = (time?: string | null) => {
    //  console.log(time);
    return time?.split(':') || [];
};

const TimeElement: FC<ITimepickerProps> = ({ updateTime, min, max, value, isNoSecFormat }: ITimepickerProps) => {
    const [time, setTime] = useState<string | null>(null);
    const [translate, setTranslate] = useState<number>(0);

    useEffect(() => {
        if (value) {
            const [hours, minutes] = getTime(value);
            setTime(minutes ? hours + ':' + minutes : hours);
        }
    }, [value]);

    useEffect(() => {
        if (translate == 1) {
            const [hours] = getTime(value);
            setTime(hours);
        }
    }, [translate]);

    /** Переключение часов, минут и секунд */
    useEffect(() => {
        const [hours, minutes, seconds] = getTime(value);

        if (hours) {
            const hasHours = hours ? !hours.includes('_') : false;
            const hasMinutes = minutes ? !minutes.includes('_') : false;
            const hasSeconds = seconds ? !seconds.includes('_') : false;

            setTranslate(!hasHours ? 0 : !hasMinutes ? 1 : !hasSeconds && !isNoSecFormat ? 2 : 0);
        }
    }, [value]);

    const clickHandler = (event: React.MouseEvent) => {
        const [hours] = getTime((event.currentTarget as HTMLElement).innerText);
        updateTime(hours);
        setTime(hours);
    };

    const applyTimeHandler = (event: React.MouseEvent, onClose: () => void) => {
        const [hours, minutes, seconds] = getTime((event.currentTarget as HTMLElement).innerText);

        updateTime((event.currentTarget as HTMLElement).innerText);

        if (seconds || isNoSecFormat) {
            onClose();
        } else {
            setTime(hours + ':' + minutes);
        }
    };

    // -------------------------------------------------------------------------------------------------------------------

    const [minH] = useMemo(() => min.split(':'), [min]);
    const [maxH] = useMemo(() => max.split(':'), [max]);

    const isActiveHours = useCallback(
        (current: string | null) => {
            const [hours] = getTime(current);
            const [activeHours] = getTime(time);

            return hours === activeHours;
        },
        [time],
    );

    /** Сравнение времени */
    const isValidTime = useCallback(
        (time: string, flag: 'h' | 'm' | 's'): boolean => {
            const [h, m, s] = getTime(time);

            if (flag === 'h') {
                return h >= minH && h <= maxH;
            }

            const cur = h + m + s;
            const minStr = min.replace(':', '');
            const maxStr = max.replace(':', '');

            return cur >= minStr && cur <= maxStr;
        },
        [minH, maxH, min, max],
    );

    // -------------------------------------------------------------------------------------------------------------------

    const getTimeHours = useCallback(() => {
        const [hours] = getTime(time);
        const secondHour = hours ? hours[1] : '_';

        /**
         * Если нету часов или часы ну указаны или оба часа указано то выводим все часы
         */
        if (!hours || hours === '__' || (!hours.startsWith('_') && secondHour !== '_')) {
            return arrayTimeHours;
        }

        /** Фильтруем часы по началу первого часа */
        return arrayTimeHours.reduce((acc: string[][], curr: string[]) => {
            if (curr.some((time) => +time[0] >= +hours[0])) {
                return [...acc, curr];
            }

            return acc;
        }, []);
    }, [arrayTimeHours, time]);

    /** Ячейки с часами */
    const itemsHour = getTimeHours().map((itemRow: string[], id) => (
        <div key={id} className="rf-time-element__item-row">
            {itemRow.map((item) => (
                <button
                    type="button"
                    key={item}
                    disabled={!isValidTime(item, 'h')}
                    className={classnames('rf-time-element', isActiveHours(item) && 'rf-time-element--active')}
                    onClick={clickHandler}
                >
                    {item}
                </button>
            ))}
        </div>
    ));

    const { onClose } = useContext(MenuContext);

    /** Ячейки с минутами */
    const itemsMinutes = arrayTimeMinutes.map((itemRow: string[], id) => (
        <div key={id} className="rf-time-element__item-row">
            {itemRow.map((item, idx) => {
                const t = (time || '00') + item;

                return (
                    <button
                        autoFocus={id === 0 && idx === 0}
                        type="button"
                        key={item}
                        disabled={!isValidTime(t, 'm')}
                        className="rf-time-element"
                        onClick={(e: React.MouseEvent) => applyTimeHandler(e, onClose)}
                    >
                        {t}
                    </button>
                );
            })}
        </div>
    ));

    /** Ячейки с секундами */
    const itemsSeconds = arrayTimeSeconds.map((itemRow: string[], id) => (
        <div key={id} className="rf-time-element__item-row">
            {itemRow.map((item, idx) => {
                const t = (time || '00:00') + item;

                return (
                    <button
                        autoFocus={id === 0 && idx === 0}
                        type="button"
                        key={item}
                        disabled={!isValidTime(t, 's')}
                        className="rf-time-element rf-time-element_sec"
                        onClick={(e: React.MouseEvent) => applyTimeHandler(e, onClose)}
                    >
                        {t}
                    </button>
                );
            })}
        </div>
    ));

    return (
        <div className="rf-time-element__wrapper">
            <div
                data-testid="rf-time-element"
                // className={`rf-time-element__translate ${translate ? 'element__translate--active' : ''}`}
            >
                <div className="rf-time-element__flex">
                    {translate == 0 && (
                        <div className="rf-time-element__column">
                            <div className="rf-time-element__header-container">
                                <div className="rf-time-element__name">Выберите час</div>
                            </div>
                            {itemsHour}
                        </div>
                    )}

                    {translate == 1 && (
                        <div className={'rf-time-element__column'}>
                            <div className="rf-time-element__header-container">
                                <button
                                    className="rf-time-element__name rf-time-element__btn"
                                    onClick={() => setTranslate(0)}
                                >
                                    <ArrowsChevronLeft /> <span>Выберите минуты</span>
                                </button>
                            </div>
                            {itemsMinutes}
                        </div>
                    )}

                    {translate == 2 && (
                        <div className={'rf-time-element__column'}>
                            <div className="rf-time-element__header-container">
                                <button
                                    className="rf-time-element__name rf-time-element__btn"
                                    onClick={() => {
                                        setTranslate(1);
                                    }}
                                >
                                    <ArrowsChevronLeft /> <span>Выберите секунды</span>
                                </button>
                            </div>
                            {itemsSeconds}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TimeElement;
