import React, { useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import TableForm from './ModalForms/TableForm';
import SignCheckContainer from './ModalForms/SignCheckContainer';
import { IField } from '@models/IFormData';

export interface RowDetailsButtonProps {
    table: IDocumentTable;
    rowData: any;
    getParentFields: () => IField[];
    docId?: string;
}

const RowDetailsButton: React.FC<RowDetailsButtonProps> = ({
    table,
    rowData,
    getParentFields,
    docId,
}: RowDetailsButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(!showModal);
    };

    return (
        <>
            <a
                href="#"
                className="dx-link dx-link-delete dx-icon-doc dx-link-icon"
                onClick={onClick}
                title="Просмотреть"
                aria-label="Просмотреть"
            />
            {showModal && (
                <TableForm
                    table={table}
                    title="Просмотр таблицы"
                    isEdit={false}
                    rowData={rowData}
                    onClose={() => {
                        setShowModal(!showModal);
                    }}
                    getParentFields={getParentFields}
                    docId={docId}
                >
                    {table.signCheck && <SignCheckContainer signCheck={table.signCheck} rowData={rowData} />}
                </TableForm>
            )}
        </>
    );
};

export default RowDetailsButton;
