import React, { useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { AllClose } from '@/indexIcon';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';

import './../actions.scss';
import { ActFinishType, ActivityActionsService } from '@services/actions/ActivityActionsService';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { checkFilesSize, formatBytes, getActivityIdFromCompositeId } from '@utils/helpers';
import Textarea from '@atoms/Textarea';
import { ModalSize } from '@atoms/Modal/Modal';
import { AttachFilesBlock, ISelectedFiles } from '@molecules/AttachFilesBlock/AttachFilesBlock';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { IActivityActionModel } from '@models/document/activityExecutors/IActivityActionModel';
import { MAX_FILE_SIZE } from '@utils/constants';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';

export abstract class BaseApproveExecutor extends ActivityBaseActionExecutor {
    private _model?: IActionDialogProps;

    protected approveModel?: IApproveActionModel;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        if (this.approveModel) {
            this._model = {
                actionKey: action.key,
                displayName: action.displayName,
                approveModel: this.approveModel,

                okButtonText: action.options?.okButtonText ?? 'ОК',
                cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
                modalSize: modalSize ?? action.options?.modalSize ?? 'xxl',
                docFlow: act?.flowName,
                docId: act.docId,
                objId: objId,
                activityId: getActivityIdFromCompositeId(objId),
                actName: act?.actName ?? rowData?.fields?.ActivityName,
                attachFiles: action.options?.attachFiles ?? false,
                attachRequired: action.options?.attachRequired ?? false,
                completeHandler: completeHandler,
            };
        }
    };

    visualElementInner = () => {
        return this._model ? <ApproveModal {...this._model} /> : <></>;
    };
}

interface IApproveActionModel {
    commentIsRequired: boolean;
    actType: ActFinishType;
}

interface IActionDialogProps {
    actionKey: string;
    displayName: string;
    approveModel: IApproveActionModel;
    objId: string;
    activityId: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    docFlow?: string;
    docId: string;
    actName: string;
    attachFiles: boolean;
    attachRequired: boolean;
    completeHandler?: (isSucceed: boolean) => void;
}

const ApproveModal: React.FC<IActionDialogProps> = (props: IActionDialogProps) => {
    const [selectedFiles, setSelectedFiles] = useState<ISelectedFiles>();
    const [filesSizeExceeded, setFilesSizeExceeded] = useState<boolean>(false);
    const childFilesBlock = useRef();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [actionModel, setActionModel] = useState<IActivityActionModel>();

    const [comment, setComment] = useState<string>();

    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [loadingText, setLoadingText] = useState<string>();

    useEffect(() => {
        setLoadingText('Загрузка настроек...');
        setLoading(true);
        let activServ = new ActivityActionsService();
        activServ.getActivityActionModel(props.actionKey, props.activityId).then((res) => {
            setActionModel(res.data);
            setComment(res.data.prefilledComment);
            setLoading(false);
        });

        //получение модели для аттачей
        if (props.attachFiles) {
            let attachServ = new AttachActionsService(props.docId, '-1');
            attachServ.getAttachInfo(props.actionKey, props.activityId).then((res) => {
                setAttachesCreateModel(res.data);
            });
        }
    }, []);

    useEffect(() => {
        if (selectedFiles) {
            if (
                !checkFilesSize(
                    Object.values<File>(selectedFiles.files),
                    MAX_FILE_SIZE ? MAX_FILE_SIZE * 1024 : undefined,
                )
            ) {
                setFilesSizeExceeded(true);
            } else {
                setFilesSizeExceeded(false);
            }
        }
    }, [selectedFiles]);

    useEffect(() => {
        if (filesSizeExceeded)
            setErrorText(`Превышен максимальный размер загружаемых файлов (${formatBytes(MAX_FILE_SIZE! * 1024)}).`);
        else setErrorText(undefined);
    }, [filesSizeExceeded]);

    const onSubmit = async () => {
        setLoading(true);

        let attachServ = new AttachActionsService(props.docId, '-1');

        try {
            if (props.objId) {
                let filesToSend = selectedFiles;

                if (childFilesBlock) {
                    let f = (childFilesBlock?.current as any)?.beforeSubmit() as ISelectedFiles;
                    if (f) filesToSend = f;
                }

                // проверка на обязательность наличия аттачей
                if (
                    props.attachFiles &&
                    props.attachRequired &&
                    (filesToSend?.files === undefined || filesToSend?.files.length === 0)
                ) {
                    setWarningText('Необходимо приложить файл');
                    setLoading(false);
                    return;
                }

                if (filesToSend) {
                    await attachServ.createAttaches(filesToSend);
                }

                let actId = getActivityIdFromCompositeId(props.objId);

                setLoadingText(`Отправка на сервер...`);

                let activServ = new ActivityActionsService();
                await activServ.activityAction(props.actionKey, actId, props.approveModel.actType, comment);

                setLoading(false);
                if (props.completeHandler) props.completeHandler(true);
                return;
            }
        } catch (e: any) {
            setErrorText(e);
            console.error(e);
        }

        setLoading(false);
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>
                            <span>{props.displayName}</span>
                        </div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                startAdornment={<AllClose size="xs" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading ? (
                <div>
                    <Preloader size="m" />
                    {loadingText}
                </div>
            ) : (
                <>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {warningText && (
                        <Hint icon="info" title={`Внимание: ${warningText}`} variant="yellow" maxWidth="100%" />
                    )}

                    <div>
                        <Textarea
                            className={classnames(
                                props.approveModel.commentIsRequired &&
                                    (comment == undefined || comment.length == 0) &&
                                    'invalid',
                            )}
                            initialRowCount={8}
                            placeholder={'Комментарий' + (props.approveModel.commentIsRequired ? ' (обязательно)' : '')}
                            defaultValue={actionModel?.prefilledComment}
                            required={props.approveModel.commentIsRequired}
                            onChange={(e: any) => {
                                setComment(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        {attachesCreateModel && (
                            <AttachFilesBlock
                                ref={childFilesBlock}
                                model={attachesCreateModel}
                                onFilesSelected={setSelectedFiles}
                            />
                        )}
                    </div>
                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                disabled={
                                    (props.approveModel.commentIsRequired &&
                                        (comment == undefined || comment?.length == 0)) ||
                                    filesSizeExceeded
                                }
                                onClick={async () => {
                                    await onSubmit();
                                }}
                                size="s"
                                aria-label={props.okButtonText}
                            >
                                {props.okButtonText}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};
