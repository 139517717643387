import Button from '@/components/atoms/Button';
import { ISmartBusinessDirectories } from '@/models/smartBusiness/ISmartBusinessDirectories';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { ISmartBusinessParametersAttribute } from '@/models/smartBusiness/ISmartBusinessParametersAttribute';
import { ISmartBusinessParametersAttributeValue } from '@/models/smartBusiness/ISmartBusinessParametersAttributeValue';
import { SmartBusinessService } from '@/services/SmartBusinessService';
import { FC, useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { SmartBusinessCategoryField } from './SmartBusinessCategoryField';
import { SmartBusinessOrderNumberField } from './SmartBusinessOrderNumberField';
import { SmartBusinessRegionField } from './SmartBusinessRegionField';
import { SmartBusinessTendersAttributesField } from './SmartBusinessTendersAttributesField';
import { SmartBusinessTendersMonthField } from './SmartBusinessTendersMonthField';
import { SmartBusinessTendersYearField } from './SmartBusinessTendersYearField';

interface ISmartBusinessParametersStepperProps {
    methods: UseFormReturn<ISmartBusinessParameters>;
    directories: ISmartBusinessDirectories;
    token: string;
    onReset: () => void;
}

const SmartBusinessParametersStepper: FC<ISmartBusinessParametersStepperProps> = ({
    methods,
    directories,
    token,
    onReset,
}) => {
    const [formStep, setFormStep] = useState<number>(0);
    const [firstSubmit, setFirstSubmit] = useState<boolean>(false);
    const [attributes, setAttributes] = useState<ISmartBusinessParametersAttribute[]>();

    const watch = useWatch({
        name: ['category_id', 'orderNumber'],
    });

    const attrToattrValue = (category: ISmartBusinessParametersAttribute): ISmartBusinessParametersAttributeValue => ({
        value: category.defaultValue !== undefined && category.defaultValue !== null ? category.defaultValue : '',
        id: category.id,
    });

    useEffect(() => {
        if (watch.every((x) => x !== undefined)) {
            let prom = SmartBusinessService.getAttributes(watch[0], watch[1], token);
            prom.then((result) => {
                let attrs = result.data;
                setAttributes(attrs.attributes[0]);
                let val = attrs.attributes[0].map(attrToattrValue);
                methods.setValue('attributes', [val]);
            });
        }
    }, [watch]);

    const resetStep = useCallback(() => {
        setFormStep(0);
        setFirstSubmit(false);
        methods.reset({
            attributes: [],
        } as ISmartBusinessParameters);
        onReset();
    }, []);

    const renderBackButton = () => (
        <Button
            buttonType="ghost"
            onClick={() => {
                setFormStep(formStep - 1);
            }}
            size={'s'}
        >
            Назад
        </Button>
    );

    const renderStep1Form = () => (
        <>
            <SmartBusinessCategoryField formMethods={methods} categories={directories?.categories!} />
            <SmartBusinessOrderNumberField formMethods={methods} />
            <SmartBusinessRegionField formMethods={methods} regions={directories?.regions!} />
        </>
    );

    const renderAttributesForm = () => {
        if (attributes) {
            return <SmartBusinessTendersAttributesField formMethods={methods} attributes={attributes} />;
        }
    };
    const renderStep2Form = () => (
        <>
            <SmartBusinessCategoryField formMethods={methods} categories={directories?.categories!} />
            <SmartBusinessOrderNumberField formMethods={methods} />
            <SmartBusinessRegionField formMethods={methods} regions={directories?.regions!} />
            <SmartBusinessTendersYearField formMethods={methods} />
            <SmartBusinessTendersMonthField formMethods={methods} />
        </>
    );

    const renderStep3Form = () => (
        <>
            <SmartBusinessCategoryField formMethods={methods} categories={directories?.categories!} />
            <SmartBusinessOrderNumberField formMethods={methods} />
            <SmartBusinessRegionField formMethods={methods} regions={directories?.regions!} />
            <SmartBusinessTendersYearField formMethods={methods} />
            <SmartBusinessTendersMonthField formMethods={methods} />
            {renderAttributesForm()}
        </>
    );

    return directories ? (
        <div className="w-full flex flex-col">
            <div className="grid grid-cols-2 gap-5">
                {formStep === 0 && renderStep1Form()}
                {formStep === 1 && renderStep2Form()}
                {formStep === 2 && renderStep3Form()}
            </div>
            {formStep !== 2 && (
                <div className="grid grid-cols-3 gap-5 mt-5 tablet:mt-12">
                    <div className="buttonInLine">
                        {' '}
                        {formStep !== 0 ? renderBackButton() : <div />}
                        <div />{' '}
                        <Button
                            buttonType="light"
                            onClick={() => {
                                setFormStep(formStep + 1);
                            }}
                            size={'s'}
                        >
                            Далее
                        </Button>{' '}
                    </div>
                    <div />
                    <div></div>
                </div>
            )}
            {formStep === 2 && (
                <div className="grid grid-cols-3 gap-5 mt-5 tablet:mt-12">
                    <div className="buttonInLine">
                        <Button buttonType="ghost" onClick={resetStep} size={'s'}>
                            Сначала
                        </Button>
                        <div />{' '}
                        <Button
                            buttonType="light"
                            type="submit"
                            size={'s'}
                            onClick={() => {
                                setFirstSubmit(true);
                            }}
                        >
                            {firstSubmit ? 'Обновить' : 'Далее'}
                        </Button>
                    </div>
                    <div /> <div></div>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};

export default SmartBusinessParametersStepper;
