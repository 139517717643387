import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IDocumentData } from '@models/document/IDocumentData';
import { IDocumentChange } from '@models/document/IDocumentHistory';
import { IDocumentResult } from '@models/document/IDocumentResult';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import { IDocumentStatusBar } from '@models/document/IDocumentStatusBar';
import { IForms } from '@models/Forms/IForms';
import { IFormData } from '@models/IFormData';

export class DocumentService {
    static async getData(docId: string, openDocumentGuid: string | undefined): Promise<AxiosResponse<IDocumentData>> {
        // return axios.get<ITreeOption[]>('./tasksMenu.json')
        return $api.get<IDocumentData>(`/document/data/${docId}`, {
            params: {
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    static async getFormData(docId: string, fields: string[]): Promise<AxiosResponse<IFormData>> {
        return $api.get<IFormData>(`/document/formData/${docId}?fields=${fields.join(',')}`);
    }
    static async getDataByAction(
        actId: string,
        docId?: string,
        templateId?: string,
        isFromActivity?: boolean,
        showCurrentValues?: boolean,
    ): Promise<AxiosResponse<IDocumentData>> {
        return $api.get<IDocumentData>(`/document/dataByAction/${actId}/${docId ?? ''}`, {
            params: { templateId: templateId, showCurrentValues: showCurrentValues, isFromActivity: isFromActivity },
        });
    }
    static async getHistory(docId: string, isHistoryDiffMode: boolean): Promise<AxiosResponse<IDocumentChange[]>> {
        return $api.get<IDocumentChange[]>(`/document/history/${docId}/${isHistoryDiffMode}`);
    }

    static async getStatusBar(
        docId: string,
        openDocumentGuid: string | undefined,
    ): Promise<AxiosResponse<IDocumentStatusBar>> {
        return $api.get<IDocumentStatusBar>(`/document/statusBar/${docId}`, {
            params: {
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    static async getScheme(
        docId: string,
        openDocumentGuid: string | undefined,
    ): Promise<AxiosResponse<IDocumentScheme>> {
        return $api.get<IDocumentScheme>(`/document/scheme/${docId}`, {
            params: {
                openDocumentGuid: openDocumentGuid,
            },
        });
    }
    static async getTableScheme(table: string): Promise<AxiosResponse<IForms>> {
        return $api.get<IForms>(`/document/tableScheme/${table}`);
    }
    static async getSchemeByAction(actId: string): Promise<AxiosResponse<IDocumentScheme>> {
        return $api.get<IDocumentScheme>(`/document/schemeByAction/${actId}`);
    }

    static async saveData(doc: IDocumentData, templateId?: string): Promise<AxiosResponse<IDocumentResult>> {
        const formData = new FormData();
        formData.append('doc', JSON.stringify(doc));
        formData.append('templateId', templateId ?? '');

        return $api.post(`/document/save`, formData);
    }

    static async checkIsHistoryDiffMode(): Promise<AxiosResponse<boolean>> {
        return $api.get<boolean>(`/document/checkIsHistoryDiffMode`);
    }
}
