import React, { ReactNode } from 'react';

import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import Datepicker from '@atoms/Datepicker';
import { IDatepickerProps } from '@atoms/Datepicker/Datepicker';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import { getValueDates } from '@utils/documentUtils';

export type IInputControlProps = IDatepickerProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        onChangeVal?: (value: Date) => void;
        isValueAsString?: boolean;
    };

const DatepickerControl = ({
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    formState,
    label,
    tooltip,
    required,
    formGroupProps,
    className,
    onChangeVal,
    isValueAsString,
    ...props
}: IInputControlProps) => {
    const Controller = useHookFormController();
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <Datepicker
                        {...fieldProps}
                        {...props}
                        defaultValue={getValueDates(value)}
                        //onChange={(value) => onChange(value.timestamp.value)}
                        onChange={(value) => {
                            if (props.readOnly) return;

                            let utc = value.date.utc ?? null;
                            if (isValueAsString) {
                                let val = utc?.toISOString().split('T')[0];
                                onChange(val);
                            } else {
                                onChange(utc);
                            }

                            if (onChangeVal && utc) onChangeVal(utc);
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default DatepickerControl;
