import React, { useState } from 'react';
import Moment from 'moment';
import Checkbox from '../Checkbox';
import { getValueBoolean } from '@utils/documentUtils';
import './DisplayField.scss';
import { ValueType } from '@/types/ValueType';
import { moneyFieldCopyHandler } from '@utils/helpers';
import { AllCopy } from '@/indexIcon';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { classnames } from '@utils/classnames';
import { ConfigService } from '@/configuration/services/configService';

export interface IDisplayFieldProps {
    type: ValueType;
    value: any;
    lable?: string;
    floatPoints?: number;
    trueText?: string;
    falseText?: string;
    className?: string;
    showCopyButton?: boolean;
}

const DisplayField: React.FC<IDisplayFieldProps> = ({
    type,
    value,
    lable,
    floatPoints,
    trueText,
    falseText,
    className,
    showCopyButton,
}: IDisplayFieldProps) => {
    const config = ConfigService.get();
    const moneyCulture: string = config.application.moneyCulture ?? 'ru-Ru';
    const [titleText, setTitleText] = useState<string>('Копировать');

    const onCopy = (event: React.MouseEvent) => {
        let text = getCopyValue(type, value);
        event.stopPropagation();
        navigator.clipboard.writeText(text);
        setTitleText('Скопировано');
        setTimeout(() => {
            setTitleText('Копировать');
        }, 3000);
    };

    const getCopyValue = (type: ValueType, fieldVal: any) => {
        switch (type.toLowerCase()) {
            case ValueType.NoSecDateTime: {
                let val = fieldVal as Date;
                if (val) {
                    return Moment(val).format('DD.MM.YYYY HH:mm');
                }
                return '';
            }
            case ValueType.Date: {
                if (fieldVal === undefined || fieldVal === null) return '';

                let val = fieldVal.toString().match(/^(\d{1,2}).(\d{1,2}).(\d{4})$/)
                    ? Moment(fieldVal, 'DD.MM.YYYY').toDate()
                    : (fieldVal as Date);

                if (val) {
                    return Moment(val).format('DD.MM.YYYY');
                }
                return '';
            }
            case ValueType.Boolean:
            case ValueType.YesNo: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    const value = fieldVal.toString();
                    if (value === '1') {
                        return trueText ?? 'Да';
                    }
                    if (value === '0') {
                        return falseText ?? 'Нет';
                    }
                }
                return '';
            }
            case ValueType.Money: {
                let displayValue = '';
                if (fieldVal !== undefined && fieldVal !== null) {
                    if (moneyCulture) {
                        displayValue = (+fieldVal)?.toLocaleString(moneyCulture, {
                            minimumFractionDigits: floatPoints ? floatPoints : 2,
                            maximumFractionDigits: floatPoints ? floatPoints : 2,
                        });
                    } else {
                        displayValue = (+fieldVal)
                            ?.toLocaleString('ru-Ru', {
                                minimumFractionDigits: floatPoints ? floatPoints : 2,
                                maximumFractionDigits: floatPoints ? floatPoints : 2,
                            })
                            .replace(',', '.');
                    }
                }
                const textWithoutSpaces = displayValue.replace(/\s/g, '');

                return textWithoutSpaces;
            }
            case ValueType.Double:
            case ValueType.Integer:
            case ValueType.LongText:
            case ValueType.Year:
            case ValueType.AddressBook:
            case ValueType.Text:
            case ValueType.FileSize:
            case ValueType.Url:
            case ValueType.Flow: {
                let val = fieldVal?.toString();
                if (val !== undefined && val !== null) {
                    return val;
                }
                return '';
            }
            case ValueType.DateTime: {
                let val = fieldVal as Date;
                if (val) {
                    return Moment(val).format('DD.MM.YYYY HH:mm:ss');
                }
                return '';
            }

            default:
                return fieldVal?.toString();
        }
    };

    const fieldDisplayValue = (type: ValueType, fieldVal: any, lable?: string) => {
        switch (type.toLowerCase()) {
            case ValueType.NoSecDateTime: {
                let val = fieldVal as Date;
                if (val) {
                    return Moment(val).format('DD.MM.YYYY HH:mm');
                }
                return '';
            }
            case ValueType.Date: {
                if (fieldVal === undefined || fieldVal === null) return '';

                let val = fieldVal.toString().match(/^(\d{1,2}).(\d{1,2}).(\d{4})$/)
                    ? Moment(fieldVal, 'DD.MM.YYYY').toDate()
                    : (fieldVal as Date);

                if (val) {
                    return Moment(val).format('DD.MM.YYYY');
                }
                return '';
            }
            case ValueType.YesNo: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    const value = fieldVal.toString();
                    if (value === '1') {
                        return trueText ?? 'Да';
                    }
                    if (value === '0') {
                        return falseText ?? 'Нет';
                    }
                }
                return '';
            }
            case ValueType.Money: {
                let displayValue = '';
                if (fieldVal !== undefined && fieldVal !== null) {
                    if (moneyCulture) {
                        displayValue = (+fieldVal)?.toLocaleString(moneyCulture, {
                            minimumFractionDigits: floatPoints ? floatPoints : 2,
                            maximumFractionDigits: floatPoints ? floatPoints : 2,
                        });
                    } else {
                        displayValue = (+fieldVal)
                            ?.toLocaleString('ru-Ru', {
                                minimumFractionDigits: floatPoints ? floatPoints : 2,
                                maximumFractionDigits: floatPoints ? floatPoints : 2,
                            })
                            .replace(',', '.');
                    }
                }
                return (
                    <div className="money-content-text" onCopy={moneyFieldCopyHandler}>
                        {displayValue}
                    </div>
                );
            }
            case ValueType.Double:
            case ValueType.Integer:
            case ValueType.LongText:
            case ValueType.Year:
            case ValueType.AddressBook:
            case ValueType.Text:
            case ValueType.FileSize: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    return (
                        <div
                            className={classnames('content-text', className)}
                            dangerouslySetInnerHTML={{ __html: fieldVal?.toString() }}
                        />
                    );
                }
                return '';
            }
            case ValueType.Base64Text: {
                if (fieldVal !== undefined && fieldVal !== null) {
                    return (
                        <div
                            className={classnames('content-text', className)}
                            dangerouslySetInnerHTML={{ __html: decodeURIComponent(escape(atob(fieldVal?.toString()))) }}
                        ></div>
                    );
                }
                return '';
            }
            case ValueType.Flow: {
                let val = fieldVal?.toString();
                if (val !== undefined && val !== null) {
                    return val;
                }
                return '';
            }
            case ValueType.Url: {
                let val = fieldVal?.toString();
                if (val !== undefined && val !== null) {
                    return <a href={val}>{val}</a>;
                }
                return '';
            }
            case ValueType.DateTime: {
                let val = fieldVal as Date;
                if (val) {
                    return Moment(val).format('DD.MM.YYYY HH:mm:ss');
                }
                return '';
            }
            case ValueType.Boolean:
                if (fieldVal) {
                    return (
                        <div>
                            <Checkbox disabled={true} defaultChecked={getValueBoolean(fieldVal!)} label={lable} />
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <Checkbox disabled={true} defaultChecked={false} label={lable} />
                        </div>
                    );
                }

            default:
                return (
                    <div className={className}>
                        {type} --- {fieldVal?.toString()}
                    </div>
                );
        }
    };
    return type ? (
        <div className="fieldBox">
            {fieldDisplayValue(type, value, lable)}{' '}
            {showCopyButton && value && (
                <div className="copyStyle">
                    <Tooltip>
                        <Button
                            size="xxxxs"
                            buttonType={'icon'}
                            textColor="neutral"
                            onClick={onCopy}
                            startAdornment={<AllCopy size="xxxxs" />}
                        />
                        {titleText}
                    </Tooltip>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};

export default DisplayField;
