import { classnames } from '@/utils/classnames';
import { FC, ReactNode } from 'react';
import './SmartBusinessPagination.scss';

interface IOwnProps {
    current: number;
    total: number;
    onChange: (currentPage: number) => void;
}

export const SmartBusinessPagination: FC<IOwnProps> = ({ current, total, onChange }) => {
    const pageBlock = (numPage: number) => {
        return (
            <div
                className={classnames('smpager-page', current === numPage && 'smpager-selection')}
                onClick={() => {
                    onChange(numPage);
                }}
            >
                {numPage}
            </div>
        );
    };

    const centerBlock = () => {
        let pages: ReactNode[] = [];
        let pagesCount = total < 5 ? total : current + 5;
        let start = total < 5 ? 1 : current;
        for (let index = start; index <= pagesCount; index++) {
            if (current < 5) {
                pages.push(pageBlock(index));
            } else if (total - 5 < current) {
                pages.push(pageBlock(index));
            } else {
                pages.push(pageBlock(index - 2));
            }
        }
        return pages;
    };
    const leftBlock = () => {
        return (
            <>
                <div
                    className={classnames('smpager-prev-button', current === 1 && 'smpager-disabled')}
                    onClick={
                        current === 1
                            ? () => {}
                            : () => {
                                  onChange(current - 1);
                              }
                    }
                ></div>
                {current > 5 && (
                    <>
                        {' '}
                        {pageBlock(1)} <div className="smpager-separator">. . .</div>
                    </>
                )}
            </>
        );
    };
    const rightBlock = () => {
        return (
            <>
                {total - 5 > 0 && total - 5 < current && (
                    <>
                        {' '}
                        <div className="smpager-separator">. . .</div>
                        {pageBlock(total)}{' '}
                    </>
                )}
                <div
                    className={classnames('smpager-next-button', current === total && 'smpager-disabled')}
                    onClick={
                        current === total
                            ? () => {}
                            : () => {
                                  onChange(current + 1);
                              }
                    }
                ></div>
            </>
        );
    };

    return (
        <div className="smpager-page-container">
            <div className="smpager-page-indexes">
                {leftBlock()}
                {centerBlock()}
                {rightBlock()}
            </div>
        </div>
    );
};
