import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    Grouping,
    GroupPanel,
    Item,
    MasterDetail,
    Pager,
    Paging,
    RemoteOperations,
    Scrolling,
    Selection,
    Sorting,
    Toolbar,
} from 'devextreme-react/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IDataGridColumnDescription, IDataGridDescriptionResponse } from '@models/response/IGridDescriptionResponse';
import DevExpressDataGrid, { IDevExpressDataGridOptions } from './DevExpressDataGrid';
import { IDataGridService } from '@services/IDataGridService';
import Button from '@atoms/Button';
import { AllDownload, AllMenuHorizontal } from '@/indexIcon';
import { Template } from 'devextreme-react/core/template';
import '../styles/iproc-scheme.css';
import './DevExpressDataGridDynamic.scss';
import { IGridStateSettings } from '@models/response/IGridStateSettings';
import StateModal from '@organisms/grids/StateModal';
import { IDataGridStateService } from '@services/IDataGridStateService';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IFilesService } from '@services/IFilesService';
import {
    applyStateSettings,
    calculateFilterExpression,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    getOriginalState,
    getStateSettings,
    onCellHoverChanged,
    updateModeOff,
    updateModeOn,
} from '@utils/dataGridUtils';
import Preloader from '@atoms/Preloader';
import ActionButton from '@atoms/DevExpress/Buttons/ActionButton/ActionButton';
import { IActionService } from '@services/actions/IActionService';
import { IBaseAction } from '@models/actions/IBaseAction';
import { simulateMouseClick } from '@utils/helpers';
import GroupActionButton, { IGroupActionButtonRefActions } from './GroupActionButton';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { Link } from 'react-router-dom';
import Tooltip from '@atoms/Tooltip';
import dxDataGrid, { OptionChangedEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import DataSource from 'devextreme/data/data_source';
import GridSettingsButton, {
    IMenuSettingsRefActions,
} from '@atoms/DevExpress/Buttons/GridSettingsButton/GridSettingsButton';
import { GridWordWrapMode, IGridSettings } from '@models/dataGrid/IGridSettings';
import { useLocalStorage } from 'usehooks-ts';

export interface IDevExpressDataGridDynamicOptions extends IDevExpressDataGridOptions {
    service: IDataGridService;
    onRowClick?: (e: any) => void;
    onRowPrepared?: (e: any) => void;
    onContextMenuPreparing?: (e: any) => void;
    dataKey: string;
    gridKey: string;
    stateService: IDataGridStateService;
    filesService: IFilesService;
    actionService?: IActionService<IBaseAction>;
    toolbarItems?: JSX.Element[];
    customTemplates?: JSX.Element[];
    linkPrefix?: string;
    isExternalDescription?: boolean;
    externalDescription?: IDataGridDescriptionResponse;
}

export type IDxDataGridDynamicHandle = {
    refresh: () => void;
};

const DevExpressDataGridDynamic = forwardRef<IDxDataGridDynamicHandle, IDevExpressDataGridDynamicOptions>(
    (
        {
            service,
            onRowClick,
            onRowPrepared,
            onContextMenuPreparing,
            dataKey,
            gridKey,
            stateService,
            filesService,
            actionService,
            toolbarItems = [],
            customTemplates,
            linkPrefix,
            isExternalDescription = false,
            externalDescription = undefined,
        }: IDevExpressDataGridDynamicOptions,
        ref: React.Ref<IDxDataGridDynamicHandle>,
    ) => {
        const [loadedGridDescription, setLoadedGridDescription] = useState<IDataGridDescriptionResponse>();
        const [currentGridDescription, setCurrentGridDescription] = useState<IDataGridDescriptionResponse>();
        const [showStateModal, setShowStateModal] = useState<boolean>(false);
        const [isInit, setIsInit] = useState<boolean>(false);
        const [stateData, setStateData] = useState<IGridStateSettings[]>();
        const activated = React.useRef(false);
        const controlRef = useRef<IGroupActionButtonRefActions>(null);
        const [loadingIndicators, setLoadingIndicators] = useState<{
            [id: string]: boolean;
        }>({});
        const [localLoadOptions, setLocalLoadOptions] = useState<any>();
        const gridRef = useRef<DataGrid>(null);
        const isGridUpdating = useRef<boolean>(false);

        const grid = () => {
            return gridRef.current?.instance;
        };

        useImperativeHandle(ref, () => ({
            refresh() {
                grid()?.refresh();
            },
        }));

        const buildDataSource = (
            keyGetter: () => string,
            gridGetter: () => dxDataGrid | undefined,
            editing: () => boolean,
        ) => {
            return new DataSource<any, string>({
                key: keyGetter(),
                ...(editing() && {
                    update: (key: any, values: any) => {
                        return service.putData({ key: key, values: values, runHook: false }).then((res) => {
                            return res.data;
                        });
                    },
                }),
                load(options: any) {
                    setLocalLoadOptions(options);

                    let columnsIndexes: string[] = [];
                    gridGetter()
                        ?.getVisibleColumns()
                        ?.forEach((column) => {
                            if (
                                column?.visibleIndex == null ||
                                column.dataField == 'actionColumn' ||
                                column.dataField == 'emptyColumn' ||
                                column.type == 'selection'
                            ) {
                                return;
                            }

                            columnsIndexes.push(((column as any).index + 1).toString());
                        });

                    if (columnsIndexes.length === 0) return [];

                    let loadOptions = {
                        options: options,
                        fields: columnsIndexes.join(','),
                    };

                    return service.fetchData(dataKey, loadOptions).then((res) => {
                        return res.data;
                    });
                },
                requireTotalCount: true,
            });
        };

        const resetState = () => {
            grid()?.state(null);
        };

        const resetDataSource = () => {
            grid()?.option('dataSource', undefined);
        };

        const createDataSource = useCallback(() => {
            if (loadedGridDescription) {
                grid()?.option(
                    'dataSource',
                    buildDataSource(
                        () => loadedGridDescription.dataSource.store.key,
                        () => grid(),
                        () => loadedGridDescription.columns.some((x) => x.allowEdit === true),
                    ),
                );
            }
        }, [loadedGridDescription]);

        useEffect(() => {
            activated.current = true;
            return () => {
                activated.current = false;
            };
        }, []);

        /**
         * Сброс по изменению темплейта
         */
        useEffect(() => {
            if (dataKey) {
                //resetDataSource();
                setLoadedGridDescription(undefined);
                setStateData(undefined);
            }
        }, [dataKey]);

        // -------------------------------------------------------------------------------------------------------------------
        // Пользовательские настройки
        // -------------------------------------------------------------------------------------------------------------------
        const userSettingsRef = useRef<IMenuSettingsRefActions>(null);
        const needReopenMenu = useRef<boolean>(false);

        const [userSettings, setUserSettings] = useLocalStorage<IGridSettings>(`grid.settings.${gridKey}`, {
            wordWrapMode: GridWordWrapMode.Wrap,
        });

        useEffect(() => {
            grid()?.repaint();

            if (needReopenMenu.current) {
                // Задержка для открытия меню после завершения рендера
                setTimeout(() => {
                    userSettingsRef.current?.openMenu();
                    needReopenMenu.current = false;
                }, 0);
            }
        }, [userSettings.wordWrapMode]);

        // -------------------------------------------------------------------------------------------------------------------

        /**
         * Получение состояний
         */
        useEffect(() => {
            if (dataKey) {
                stateService.fetchState(dataKey).then((response) => {
                    if (activated.current) {
                        setStateData(response.data.length > 0 ? response.data : []);
                    }
                });
            }
        }, [dataKey]);

        /**
         * Получение схемы грида (внешний обработчик)
         */
        useEffect(() => {
            if (isExternalDescription) {
                if (externalDescription) {
                    setLoadedGridDescription(externalDescription);
                }
            }
        }, [externalDescription]);

        /**
         * Получение схемы грида (внутренний обработчик)
         */
        useEffect(() => {
            if (!isExternalDescription) {
                if (dataKey) {
                    service.fetchScheme(dataKey).then((res) => {
                        if (activated.current) {
                            setLoadedGridDescription(res.data);
                        }
                    });
                }
            }
        }, [dataKey]);

        useEffect(() => {
            if (loadedGridDescription) {
                setCurrentGridDescription(loadedGridDescription);
            }
        }, [loadedGridDescription]);

        /**
         * TODO Fix W1005
         */
        useEffect(() => {
            if (loadedGridDescription && currentGridDescription) {
                updateModeOn(grid(), isGridUpdating);
                resetState();
                createDataSource();
                //updateModeOff(grid(), isGridUpdating);
            }
        }, [loadedGridDescription, currentGridDescription]);

        /**
         * Обновление пользовательского состояния при загрузке и при выборе из модального окна
         */
        useEffect(() => {
            if (loadedGridDescription && currentGridDescription && stateData) {
                updateModeOn(grid(), isGridUpdating);
                applyStateSettings(grid(), getStateSettings(stateData));
                updateModeOff(grid(), isGridUpdating);
            }
        }, [loadedGridDescription, currentGridDescription, stateData]);

        const detailStore = (scheme: IDataGridDescriptionResponse, mainParam: any, detailParam: any) => {
            return new CustomStore({
                key: scheme?.dataSource.store.key,
                load(options: any) {
                    const columns = gridRef.current?.instance.getVisibleColumns();
                    if (columns?.length === 0) return [];

                    let columnsIndexes: string[] = [];
                    columns?.forEach((column) => {
                        if (
                            column?.visibleIndex == null ||
                            column.dataField == 'actionColumn' ||
                            column.dataField == 'emptyColumn'
                        ) {
                            return;
                        }

                        columnsIndexes.push((column.visibleIndex + 1).toString());
                    });

                    let loadOptions = {
                        options: options,
                        fields: columnsIndexes.join(','),
                    };

                    if (detailParam) {
                        return service.fetchDetailData(mainParam, detailParam, loadOptions).then((res) => {
                            return res.data;
                        });
                    }

                    return service.fetchData(mainParam, loadOptions).then((res) => {
                        return res.data;
                    });
                },
            });
        };

        const onClickRefresh = () => {
            updateModeOn(grid(), isGridUpdating);
            applyStateSettings(grid(), getOriginalState(loadedGridDescription));
            updateModeOff(grid(), isGridUpdating);
        };

        const onClickStateChooser = () => {
            setShowStateModal(true);
        };

        const onClickColumnChooser = () => {
            grid()?.showColumnChooser();
        };

        const onClickExport = () => {
            let columns: IGridExportSettings[] = [];

            gridRef.current?.instance.getVisibleColumns()?.forEach((column) => {
                if (
                    column?.visibleIndex == null ||
                    column.dataField == 'actionColumn' ||
                    column.dataField == 'emptyColumn' ||
                    column.type == 'selection'
                )
                    return;

                if ((column?.visibleIndex ?? -1) >= 0) {
                    columns.push({
                        dataField: column.dataField,
                        dataType: column.dataType,
                        name: column.caption,
                        visible: column.visible,
                        visibleIndex: column.visibleIndex,
                        width: column.width,
                    });
                }
            });

            const options = localLoadOptions
                ? localLoadOptions
                : gridRef.current?.instance.getDataSource().loadOptions();
            gridRef.current?.instance.beginCustomLoading('Экспортировать данные');
            service.exportData(dataKey, options, columns).then((response) => {
                filesService.downloadFileByGuid(response.data.fileGuid, response.data.fileName, () => {
                    gridRef.current?.instance.endCustomLoading();
                });
            });
        };

        const onEditorPreparing = (e: any) => {
            if (e.parentType === 'filterRow') {
                e.editorOptions.onEnterKey = function () {
                    // применение фильтра по нажатию Enter
                    simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
                };
            }
        };

        const onSelectionChanged = (e: SelectionChangedEvent<any, string>) => {
            let keys = e.selectedRowKeys;
            let data = e.selectedRowsData;
            if (keys.length > 0) {
                controlRef.current?.setObjData(keys.join(','), data);
            } else {
                controlRef.current?.setObjData('', data);
            }
        };

        const onOptionChanged = (e: OptionChangedEvent<any, string>) => {
            /**
             * Обновление данных грида при добавлении столбцов
             */
            if (e.name === 'columns') {
                if (e.fullName.endsWith('.visible') && e.value === true) {
                    e.component.refresh();
                }
            }
        };

        const onDetailsOptionChanged = (e: OptionChangedEvent<any, string>) => {
            /**
             * Обработка по логике основгого грида
             */
            onOptionChanged(e);
        };

        const onDetailsCellClick = (e: any) => {
            if (e.column.name == 'actionColumn') {
                e.event?.stopImmediatePropagation();
            }
        };

        const refreshGrid = () => {
            grid()?.refresh();
        };

        const onGroupActionComplete = () => {
            refreshGrid();
            grid()?.deselectAll();
        };

        const getGridColumnFromDescription = (description: IDataGridColumnDescription, key: string): JSX.Element => {
            return (
                <Column
                    key={key}
                    /* Настройка width ломает настройку грида columnResizingMode="widget" */
                    width={description.width ? description.width : undefined}
                    minWidth={description.width.toString().endsWith('vw') ? 5 : description.minWidth}
                    allowFiltering={description.allowFiltering}
                    caption={description.header}
                    dataField={description.field}
                    dataType={getColumnDataTypeByFieldDataType(description.dataType)}
                    alignment={description.alignment}
                    showInColumnChooser={description.showInColumnChooser}
                    visible={!description.defaultHide}
                    allowGrouping={description.allowGrouping}
                    allowEditing={description.allowEdit}
                    sortIndex={description.sortIndex}
                    sortOrder={description.sortOrder}
                    //colIndexId={ description.colIndexId}
                    //allowFiltering={description.dataField !== "actionColumn"}
                    allowReordering={!(description.field === 'actionColumn' || description.field === 'emptyColumn')}
                    allowSorting={!(description.field === 'actionColumn' || description.field === 'emptyColumn')}
                    filterOperations={getColumnFilterOperationsByColumnDataType(
                        getColumnDataTypeByFieldDataType(description.dataType),
                    )}
                    calculateFilterExpression={calculateFilterExpression}
                    encodeHtml={true}
                    cellTemplate={
                        description.dxGridCellTemplate
                            ? description.dxGridCellTemplate
                            : description.field === 'actionColumn'
                              ? 'dxGridRowMenuCellTemplateAjax'
                              : 'dxGridFieldCellTemplate'
                    }
                />
            );
        };

        const mainGridColumns = useMemo(() => {
            if (currentGridDescription && currentGridDescription.columns) {
                return currentGridDescription.columns.map((description, i) =>
                    getGridColumnFromDescription(description, `col_${i}`),
                );
            } else {
                return [] as JSX.Element[];
            }
        }, [currentGridDescription]);

        const DetailSection = (param: any) => {
            let scheme = loadedGridDescription?.details[0];
            let fParam = param.key;
            return (
                <DevExpressDataGrid
                    onRowPrepared={onRowPrepared}
                    dataSource={detailStore(scheme!, dataKey, fParam)}
                    remoteOperations={true}
                    hoverStateEnabled={true}
                    columnHidingEnabled={false}
                    columnMinWidth={30}
                    showColumnHeaders={scheme?.options.showColumnHeaders}
                    columnAutoWidth={scheme?.options.columnAutoWidth}
                    allowColumnReordering={scheme?.options.allowColumnReordering}
                    allowColumnResizing={scheme?.options.allowColumnResizing}
                    columnResizingMode="widget"
                    noDataText={scheme?.options.noDataText}
                    rowAlternationEnabled={scheme?.options.rowAlternationEnabled}
                    onCellClick={onDetailsCellClick}
                    onRowClick={useCallback((e: any) => {
                        if (!scheme?.options.isMaster) {
                            onRowClick?.(e);
                        }
                    }, [])}
                    onContextMenuPreparing={onContextMenuPreparing}
                    className={scheme?.options.cssClasses.join(' ')}
                    onCellHoverChanged={onCellHoverChanged}
                    onOptionChanged={onDetailsOptionChanged}
                >
                    {scheme?.options.isMaster && scheme?.details && (
                        <MasterDetail enabled={true} render={DetailSection} />
                    )}

                    {scheme?.columns?.map((schemeColumn, i) => {
                        return (
                            <Column
                                key={`col_${fParam}_${i}`}
                                /* Настройка width ломает настройку грида columnResizingMode="widget" */
                                width={schemeColumn.width ? schemeColumn.width : undefined}
                                minWidth={
                                    schemeColumn.minWidth && schemeColumn.minWidth > 0
                                        ? schemeColumn.width.toString().endsWith('vw')
                                            ? 5
                                            : schemeColumn.minWidth
                                        : undefined
                                }
                                allowFiltering={schemeColumn.allowFiltering}
                                caption={schemeColumn.header}
                                dataField={schemeColumn.field}
                                dataType={getColumnDataTypeByFieldDataType(schemeColumn.dataType)}
                                alignment={schemeColumn.alignment}
                                showInColumnChooser={schemeColumn.showInColumnChooser}
                                visible={!schemeColumn.defaultHide}
                                allowGrouping={schemeColumn.allowGrouping}
                                sortIndex={schemeColumn.sortIndex}
                                sortOrder={schemeColumn.sortOrder}
                                //colIndexId={ schemeColumn.colIndexId}
                                //allowFiltering={schemeColumn.dataField !== "actionColumn"}
                                allowSorting={
                                    schemeColumn.field !== 'actionColumn' &&
                                    schemeColumn.field !== 'emptyColumn' /*schemeColumn.allowSorting*/
                                }
                                filterOperations={getColumnFilterOperationsByColumnDataType(
                                    getColumnDataTypeByFieldDataType(schemeColumn.dataType),
                                )}
                                encodeHtml={true}
                                cellTemplate={
                                    schemeColumn.dxGridCellTemplate
                                        ? schemeColumn.dxGridCellTemplate
                                        : schemeColumn.field === 'actionColumn'
                                          ? 'dxGridRowMenuCellTemplateAjax'
                                          : 'dxGridFieldCellTemplate'
                                }
                            />
                        );
                    })}

                    <Template
                        name="dxGridFieldCellTemplate"
                        render={
                            //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                            function (data: any) {
                                const { value } = data;

                                let key = '';
                                if (data.rowType === 'data') {
                                    if (data.data.documentKey) {
                                        key = data.data.documentKey;
                                    } else if (data.data.key) {
                                        key = data.data.key;
                                    }
                                }

                                let col = scheme?.columns?.find((x) => x.field === data.column.dataField);
                                let ids = key.split('/');
                                let linkedDocId = ids.length > 1 ? ids[1] : ids[0];
                                return linkPrefix ? (
                                    <Link to={`${linkPrefix}/${linkedDocId}`}>
                                        {' '}
                                        <DisplayField type={col?.dataType!} value={value} />
                                    </Link>
                                ) : (
                                    <DisplayField type={col?.dataType!} value={value} />
                                );
                            }
                        }
                    />
                    {customTemplates}

                    <Template
                        name="dxGridRowMenuCellTemplateAjax"
                        render={
                            //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                            function (data: any) {
                                let dataGrid = data['component'] as dxDataGrid;
                                let key = data['key'];
                                let loading: boolean = false;

                                /*return <DevExpressButton icon="more" onClick={onRowMenuClick}></DevExpressButton>*/
                                return loading ? (
                                    <Preloader size="s" />
                                ) : (
                                    <Button
                                        id={key}
                                        buttonType="text"
                                        textColor="neutral"
                                        size="xxxs"
                                        aria-label="Действия"
                                        onClick={() => {
                                            loading = true;
                                        }}
                                        startAdornment={<AllMenuHorizontal size="xxs" />}
                                    />
                                );
                            }
                        }
                    />

                    <Selection
                        allowSelectAll={scheme?.selection.allowSelectAll}
                        mode={scheme?.selection.mode}
                        selectAllMode={scheme?.selection.selectAllMode}
                        showCheckBoxesMode={scheme?.selection.showCheckBoxesMode}
                    />
                    <ColumnChooser
                        enabled={scheme?.options.allowColumnChooser}
                        mode={'select'}
                        allowSearch={true}
                        height={600}
                    />

                    <FilterRow
                        visible={true} //visible={gridDescription?.options?.allowFiltersRow}
                        //visible={gridDescription?.filterRow.visible}
                        applyFilter={'onClick'} //{gridDescription?.filterRow.applyFilter}
                    />

                    <GroupPanel
                        visible={scheme?.groupPanel.visible}
                        allowColumnDragging={scheme?.groupPanel.allowColumnDragging}
                    />

                    <Grouping autoExpandAll={scheme?.groupPanel.visible} />

                    <Pager
                        showInfo={scheme?.pager.showInfo}
                        showPageSizeSelector={scheme?.pager.showPageSizeSelector}
                        visible={scheme?.paging.enabled}
                        showNavigationButtons={true}
                        displayMode={'full'}
                        allowedPageSizes={[5, 10, 25]}
                    />

                    <Paging enabled={scheme?.paging.enabled} defaultPageSize={scheme?.paging.pageSize} />

                    <Sorting mode="multiple" />
                </DevExpressDataGrid>
            );
        };

        const onCellClick = (e: any) => {
            if (e.column && e.column.name == 'actionColumn') {
                e.event?.stopImmediatePropagation();
            }
        };

        const onMainRowClick = useCallback(
            (e: any) => {
                if (!currentGridDescription?.options.isMaster) {
                    onRowClick?.(e);
                }
            },
            [currentGridDescription],
        );

        const allowedPageSizes = [5, 10, 25];

        const gridControl = (
            <DevExpressDataGrid
                filterSyncEnabled={true}
                onRowPrepared={onRowPrepared}
                //remoteOperations={true}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                columnMinWidth={30}
                showColumnHeaders={currentGridDescription?.options.showColumnHeaders}
                columnAutoWidth={currentGridDescription?.options.columnAutoWidth}
                allowColumnReordering={currentGridDescription?.options.allowColumnReordering}
                allowColumnResizing={currentGridDescription?.options.allowColumnResizing}
                columnResizingMode="widget"
                noDataText={currentGridDescription?.options.noDataText}
                rowAlternationEnabled={currentGridDescription?.options.rowAlternationEnabled}
                onCellClick={onCellClick}
                onRowClick={onMainRowClick}
                onContextMenuPreparing={onContextMenuPreparing}
                ref={gridRef}
                className={currentGridDescription?.options.cssClasses.join(' ')}
                onEditorPreparing={onEditorPreparing}
                onSelectionChanged={onSelectionChanged}
                onCellHoverChanged={onCellHoverChanged}
                onOptionChanged={onOptionChanged}
            >
                {mainGridColumns}

                {currentGridDescription?.options.isMaster && currentGridDescription?.details && (
                    <MasterDetail enabled={true} render={DetailSection} />
                )}
                {customTemplates}
                <Template
                    key="template_dxGridFieldCellTemplate"
                    name="dxGridFieldCellTemplate"
                    //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                    render={useCallback(
                        (data: any) => {
                            const { value, text } = data;

                            let key = '';
                            if (data.rowType === 'data') {
                                if (data.data.documentKey) {
                                    key = data.data.documentKey;
                                } else if (data.data.key) {
                                    key = data.data.key;
                                }
                            }

                            let col = currentGridDescription?.columns?.find((x) => x.field === data.column.dataField);
                            let ids = key.split('/');
                            let linkedDocId = ids.length > 1 ? ids[1] : ids[0];

                            let className;
                            switch (userSettings.wordWrapMode) {
                                case GridWordWrapMode.NoWrap:
                                    className = 'no-wrap';
                                    break;
                                case GridWordWrapMode.Trim:
                                    className = 'trim-long-text';
                                    break;
                                case GridWordWrapMode.Wrap:
                                    className = 'wrap';
                                    break;
                            }

                            return linkPrefix ? (
                                <Link to={`${linkPrefix}/${linkedDocId}`}>
                                    {' '}
                                    <DisplayField className={className} type={col?.dataType!} value={value} />
                                </Link>
                            ) : (
                                <DisplayField className={className} type={col?.dataType!} value={value} />
                            );
                        },
                        [currentGridDescription, userSettings],
                    )}
                />

                <Template
                    key="template_dxGridRowMenuCellTemplateAjax"
                    name="dxGridRowMenuCellTemplateAjax"
                    render={useCallback((data: any) => {
                        let dataGrid = data['component'] as dxDataGrid;
                        let key = data['key'];

                        /*return <DevExpressButton icon="more" onClick={onRowMenuClick}></DevExpressButton>*/
                        return (
                            <ActionButton
                                objId={key}
                                service={actionService}
                                objData={data.data}
                                onModifyData={refreshGrid}
                            />
                        );
                    }, [])}
                />

                <Selection
                    allowSelectAll={currentGridDescription?.selection.allowSelectAll}
                    mode={currentGridDescription?.selection.mode}
                    selectAllMode={currentGridDescription?.selection.selectAllMode}
                    showCheckBoxesMode={currentGridDescription?.selection.showCheckBoxesMode}
                />

                <ColumnChooser
                    enabled={currentGridDescription?.options.allowColumnChooser}
                    mode={'select'}
                    allowSearch={true}
                    height={600}
                />

                <FilterRow
                    visible={true} //visible={gridDescription?.options?.allowFiltersRow}
                    //visible={gridDescription?.filterRow.visible}
                    applyFilter={'onClick'} //{gridDescription?.filterRow.applyFilter}
                />

                <GroupPanel
                    visible={currentGridDescription?.groupPanel.visible}
                    allowColumnDragging={currentGridDescription?.groupPanel.allowColumnDragging}
                />

                <Grouping autoExpandAll={false} />
                <RemoteOperations
                    groupPaging={true}
                    filtering={true}
                    grouping={true}
                    paging={true}
                    sorting={true}
                    summary={true}
                />

                <Pager
                    showInfo={true}
                    showPageSizeSelector={currentGridDescription?.pager.showPageSizeSelector}
                    visible={true}
                    showNavigationButtons={true}
                    displayMode={'full'}
                    allowedPageSizes={allowedPageSizes}
                />

                <Paging
                    enabled={currentGridDescription?.paging.enabled}
                    defaultPageSize={currentGridDescription?.paging.pageSize}
                />

                <Scrolling mode={currentGridDescription?.scrolling.mode} />

                <Export enabled={true} allowExportSelectedData={true} />

                <Sorting mode="multiple" />

                <Toolbar>
                    <Item key="toolbar_group_actions_button" location="before">
                        {/*кнопка групповых действий*/}
                        <GroupActionButton
                            gridRef={gridRef}
                            controlRef={controlRef}
                            service={actionService}
                            onModifyData={onGroupActionComplete}
                        />
                    </Item>

                    <Item
                        key="toolbar_group_panel"
                        name="groupPanel"
                        location="before"
                        visible={currentGridDescription?.groupPanel.visible}
                    />

                    {toolbarItems}

                    <Item key="toolbar_apply_filter_button" name="applyFilterButton" location="after" visible={true} />

                    <Item
                        key="toolbar_export_ods_button"
                        location="after"
                        visible={currentGridDescription?.options.ods_format}
                    >
                        <Tooltip openDelay={100} background="black" position="top">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxs"
                                aria-label="Экспортировать данные в формате ODS"
                                onClick={onClickExport}
                                startAdornment={<AllDownload size="xs" />}
                            />
                            Экспортировать данные в формате ODS
                        </Tooltip>
                    </Item>

                    <Item
                        key="toolbar_export_xls_button"
                        location="after"
                        visible={currentGridDescription?.options.excel_format}
                    >
                        <Tooltip openDelay={100} background="black" position="top">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxs"
                                aria-label="Экспортировать данные в формате .xls"
                                onClick={onClickExport}
                                startAdornment={<AllDownload size="xs" />}
                            />
                            Экспортировать данные в формате .xls
                        </Tooltip>
                    </Item>
                    <Item location="after">
                        <GridSettingsButton
                            value={userSettings}
                            onChange={(value) => {
                                setUserSettings(value);
                                needReopenMenu.current = true;
                            }}
                            onClickStateChooser={onClickStateChooser}
                            onClickRefresh={onClickRefresh}
                            onClickColumnChooser={onClickColumnChooser}
                            menuSettingsRef={userSettingsRef}
                        />
                    </Item>
                    <Item key="saveButton" name="saveButton" location="after" />
                </Toolbar>
                {currentGridDescription?.columns.some((x) => x.allowEdit === true) && (
                    <Editing mode="batch" newRowPosition="last" refreshMode="full" allowUpdating={true} />
                )}
            </DevExpressDataGrid>
        );

        return (
            <>
                <StateModal
                    stateKey={dataKey}
                    show={showStateModal}
                    stateSettings={stateData}
                    onRefreshGrid={onClickRefresh}
                    onCloseClick={() => setShowStateModal(false)}
                    onSelectState={(stateData: IGridStateSettings[] | undefined) => {
                        setStateData(stateData);
                        setShowStateModal(false);
                    }}
                    componentRef={gridRef}
                    stateService={stateService}
                />
                {gridControl}
            </>
        );
    },
);

export default DevExpressDataGridDynamic;
