import React, { ReactNode, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './Dictpicker.scss';
import { Manager, Reference } from 'react-popper';
import { AllMenuHorizontal, ArrowsChevronDown } from '@/indexIcon';
import Input from '../Input';
import { classnames } from '@utils/classnames';
import DictpickerModal from './DictpickerModal/DictpickerModal';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import {
    IDictFilter,
    IDictSource,
    IExternalSearchDict,
    IFormValues,
    IGridModalAttributes,
    IJoinedDictionary,
    ISetValues,
} from '@models/Forms/IForms';
import DictpickerSelect from './DictpickerSelect/DictpickerSelect';

export interface IDictpickerRefActions {
    reset: () => void;
    setSelected: (item: string[]) => void;
}

export interface IDictpickerProps<T extends HTMLElement = HTMLDivElement> {
    /** Имя поля */
    name?: string;
    /** Имя справочника */
    dictionaryName: string;
    /** Псевдо-справочник */
    isFormData: boolean;
    isMultiple: boolean;
    isSelectMode: boolean;
    getExternalDataSource?: (loadOptions: any) => Promise<any[]>;
    getFormValuesAsync?: (formdataParams: IFormValues) => Promise<Record<string, any>>;
    getFiltersAsync?: () => Promise<IDictFilter>;
    /** зависимые поля*/
    setValues?: ISetValues;
    formValues?: IFormValues;
    /** колонки для грида */
    gridAttribute?: IGridModalAttributes;
    /** Уровень загрузки справочника */
    startLevel?: number;
    /** Текст Placeholder */
    placeholder?: string;
    /** Значение по умолчанию */
    defaultValue?: any;
    /** отображаемое значение */
    diplayValue: string;
    /** Скрыть поле */
    disabled?: boolean;
    /** Только для просмотра */
    readOnly?: boolean;
    /** Функция измекнения значения даты */
    onChange?: (value: IDictionaryData[]) => void;
    /** Кастомная кнопка */
    children?: ReactNode | ReactNode[];
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /**
     * Добавляет инпуту белый фон
     * @default true
     */
    filled?: boolean;
    /** Сыылка на контейнер портала */
    containerRef?: RefObject<T>;
    /** Реф для пробрасывания действий по кастомным кнопкам */
    controlRef?: RefObject<IDictpickerRefActions | null>;
    /**
     *  Добавлять фокус при выборе дат
     * @default false
     *  */
    isFocusBorder?: boolean;
    insideGrid: boolean;
    /** Доступные для выбора уровни */
    selectableLevels?: string;
    /** Отображаемые уровни */
    visibleLevels?: string;
    /** Ключ кэша предикатов */
    predicatesCache?: string;
    externalSearch?: IExternalSearchDict;

    /**
     * Режим загрузки справочника
     * */
    loadMode?: string;

    docId?: string;

    modalTitle?: string;

    joinedDictionaries?: IJoinedDictionary[];

    /**
     * Дополнительная настройка источника данных (выбор данных из реквизитов-таблиц и т.д.)
     */
    source?: IDictSource;
}

// FIXME: Добавить управление с клавиатуры
const Dictpicker: React.FC<IDictpickerProps> = ({
    name = 'dictpicker',
    placeholder = 'Выберите значение',
    defaultValue,
    diplayValue,
    gridAttribute,
    dictionaryName,
    invalid = false,
    filled = true,
    disabled = false,
    readOnly = false,
    formValues: formValues,
    onChange,
    children,
    isFormData,
    isMultiple,
    getFormValuesAsync: getFormValuesAsync,
    getFiltersAsync: getFiltersAsync,
    containerRef,
    controlRef,
    getExternalDataSource,
    isFocusBorder = false,
    insideGrid = false,
    selectableLevels = '',
    visibleLevels = '',
    predicatesCache = '',
    loadMode = '',
    isSelectMode,
    docId,
    externalSearch,
    modalTitle,
    joinedDictionaries,
    source,
}: IDictpickerProps) => {
    useImperativeHandle(controlRef, () => ({
        reset: () => {},
        setSelected: (items) => {},
    }));

    // -------------------------------------------------------------------------------------------------------------------

    const dictpickerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const [showDictionary, toggleDictionary] = useState<boolean>(!readOnly && insideGrid);

    const onClose = () => toggleDictionary(false);

    // -------------------------------------------------------------------------------------------------------------------

    const [inputValue, setInputValue] = useState<string[]>();

    useEffect(() => {
        if (defaultValue === null || defaultValue === undefined || defaultValue === '') {
            setInputValue(undefined);
        } else {
            if (source?.table) {
                let keys = (defaultValue as any[])?.map((row: any) => row[source.key]);
                if (keys) {
                    setInputValue(keys);
                }
            } else {
                if (defaultValue.toString().indexOf('|') > 0) {
                    setInputValue(defaultValue.toString().split('|'));
                } else {
                    let arr = [] as string[];
                    arr.push(defaultValue);
                    setInputValue(arr);
                }
            }
        }
    }, [defaultValue]);

    // const onDatepickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   let result = e.target.value;
    //   setInputValue(result);
    // };

    // const setValue = (value: string) => {
    //   setInputValue(value);
    // };

    // useEffect(() => {
    //   onChange && onChange([inputValue]);
    //   //fireOnChange();
    // }, [inputValue]);

    // const fireOnChange = () => {
    //   //console.log('FIRE ON CHANGE!!!');
    //   setTimeout(() => {
    //     if (inputRef.current) {
    //       const input = inputRef.current.querySelector("input");

    //       if (input) {
    //         let event;

    //         if (typeof Event === "function") {
    //           event = new Event("change");
    //         } else {
    //           event = document.createEvent("Event");
    //           event.initEvent("change", true, true);
    //         }

    //         input.dispatchEvent(event);
    //       }
    //     }
    //   }, 100);
    // };

    const onSelectedDictItem = (selectedVal: IDictionaryData | undefined) => {
        // setIsDictModalOpened(false);
        // if (selectedVal) {
        //   if (p.field.dictSettings.selectAttribute) {
        //     let fld = selectedVal?.fields.find(
        //       (x) => x.name == p.field.dictSettings.selectAttribute
        //     );
        //     p.form.setValue(p.fieldFormName, fld?.value);
        //   } else {
        //     p.form.setValue(p.fieldFormName, selectedVal?.code);
        //   }
        // }
    };

    const toggleDict = (e: any) => {
        toggleDictionary((prev) => !prev);
    };

    return (
        <Manager>
            <div
                className={classnames(
                    !insideGrid && 'rf-dictpicker',
                    insideGrid && 'rf-dictpicker-ingrid',
                    isFocusBorder && 'rf-dictpicker__focus-border',
                    disabled && 'rf-dictpicker--disabled',
                )}
                ref={dictpickerRef}
            >
                <Reference>
                    {(referenceProps) => (
                        <div
                            {...referenceProps}
                            className={classnames('rf-dictpicker__input-wrapper', {
                                'rf-dictpicker__input-wrapper--disabled': disabled,
                                'rf-dictpicker__input-wrapper--readonly': readOnly,
                            })}
                            onClick={!readOnly ? (e: any) => toggleDict(e) : () => {}}
                        >
                            {children || (
                                <Input
                                    name={name}
                                    value={diplayValue}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                    readOnly={true}
                                    withoutClasses={!readOnly}
                                    invalid={invalid}
                                    filled={filled}
                                    //nInputClick={!readOnly ? (e: any) => toggleDict(e) : () => {}}
                                    endAdornment={
                                        !readOnly || !disabled ? (
                                            <div className={'rf-dictpicker__calendar-chevron'}>
                                                {isSelectMode ? <ArrowsChevronDown /> : <AllMenuHorizontal />}
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            )}
                        </div>
                    )}
                </Reference>
                {showDictionary &&
                    (isSelectMode ? (
                        <DictpickerSelect
                            widthDropdown={dictpickerRef.current?.getBoundingClientRect().width}
                            selected={inputValue}
                            docId={docId}
                            dictName={dictionaryName}
                            isFormData={isFormData}
                            getExternalDataSource={getExternalDataSource}
                            isMultiple={isMultiple}
                            selectableLevels={selectableLevels}
                            visibleLevels={visibleLevels}
                            predicatesCache={predicatesCache}
                            loadMode={loadMode}
                            formValues={formValues}
                            getFormValuesAsync={getFormValuesAsync}
                            getFiltersAsync={getFiltersAsync}
                            gridAttribute={gridAttribute}
                            onSubmitModal={onChange!}
                            onCloseModal={() => toggleDictionary((prev) => !prev)}
                        />
                    ) : (
                        <DictpickerModal
                            selected={inputValue}
                            docId={docId}
                            dictName={dictionaryName}
                            modalTitle={modalTitle}
                            isFormData={isFormData}
                            getExternalDataSource={getExternalDataSource}
                            isMultiple={isMultiple}
                            selectableLevels={selectableLevels}
                            visibleLevels={visibleLevels}
                            predicatesCache={predicatesCache}
                            externalSearch={externalSearch}
                            loadMode={loadMode}
                            formValues={formValues}
                            getFormValuesAsync={getFormValuesAsync}
                            getFiltersAsync={getFiltersAsync}
                            gridAttribute={gridAttribute}
                            onSubmitModal={onChange!}
                            onCloseModal={() => toggleDictionary((prev) => !prev)}
                            joinedDictionaries={joinedDictionaries}
                        />
                    ))}
            </div>
        </Manager>
    );
};

export default Dictpicker;
