import React, { useEffect, useMemo, useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import { ISendToEtpModel } from '@models/document/actionsExecution/ISendToEtpModel';
import './SendToEtpExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import InputControl from '@controls/InputControl/InputControl';
import { ModalSize } from '@atoms/Modal/Modal';
import { FormulaManager } from '@/utils/FormulaManager';
import { IField, IFieldElem } from '@/models/IFormData';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { AutoTaskService } from '@/services/actions/AutoTaskService';
import { IDocumentData } from '@/models/document/IDocumentData';

import { API_URL } from '@/http';
import * as signalR from '@microsoft/signalr';

const hubMethodName = 'eetpSendCompleted';

export class SendToEtpExecutor extends DocBaseActionExecutor {
    private _modalProps?: ISendToEtpExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            message: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            mchdDataChecks: action.options?.mchdDataChecks,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <SendToEtpExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ISendToEtpExecutorProps {
    docId: string;
    actionKey: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    mchdDataChecks: IMchdDataCheck;
    completeHandler?: (isSucceed: boolean) => void;
}

const SendToEtpExecutorModal: React.FC<ISendToEtpExecutorProps> = (props: ISendToEtpExecutorProps) => {
    const currentDocData = useTypedSelector((state) => state.document.data);
    const [loading, setLoading] = useState<boolean>();
    const [success, setSuccess] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [successText, setSuccessText] = useState<string>();
    const [etpOrgCodeRequired, setEtpOrgCodeRequired] = useState<boolean>();
    const [authRequired, setAuthRequired] = useState<boolean>();
    const formState = useForm<ISendToEtpFormData>({
        mode: 'onBlur', // "onChange"
    });

    const service = new DocumentExecutionService(props.docId);

    const hubConn = useMemo(() => {
        let eetpSedningGuidAtStart: string = '';
        let connection = new signalR.HubConnectionBuilder().withUrl(`${API_URL}/autotaskHub`).build();
        let setTimeoutCounter = 0;

        const signalrIdHandler = (eetpSendingGuid: string, success: boolean, message: string) => {
            if (!hubConn.eetpSedningGuidAtStart && setTimeoutCounter < 100) {
                setTimeoutCounter++;
                setTimeout(() => signalrIdHandler(eetpSendingGuid, success, message), 1000);
                return;
            }

            if ((eetpSendingGuid as string) == hubConn.eetpSedningGuidAtStart) {
                connection?.stop();
                hubConn.eetpSedningGuidAtStart = '';
                setTimeoutCounter = 0;

                if (success && message) {
                    setSuccessText(message);
                    setSuccess(true);
                } else {
                    setErrorText(message);
                }

                setLoading(false);
            }
        };

        connection.on(hubMethodName, signalrIdHandler);

        return { connection, eetpSedningGuidAtStart };
    }, []);

    useEffect(() => {
        // Модель данных
        setLoading(true);
        service
            .getSendToEtpModel(props.actionKey)
            // .then((dto) => {
            //     hubConn.connection.start();
            //     hubConn.eetpSedningGuidAtStart = dto.data;//асинхронное выполнение, просто записываем ид выполняющейся задачи
            // })
            // .catch((error) =>
            // {
            //     setErrorText(error);
            //     setLoading(false);
            // })
            // .finally(() => {
            // });
            .then((result) => {
                if (result.data) {
                    setEtpOrgCodeRequired(result.data.etpOrgCodeRequired);
                    setAuthRequired(result.data.authRequired);
                    formState.reset({
                        login: result.data.login,
                        password: result.data.password,
                        etpOrgCode: result.data.etpOrgCode,
                    } as ISendToEtpFormData);
                }
            })
            .catch((error) => setErrorText(error))
            .finally(() => setLoading(false));
    }, []);

    const onSubmit = async (form: ISendToEtpFormData) => {
        // Отправить данные
        setLoading(true);
        setErrorText('');
        setSuccessText('');

        //Проверка МЧД по аналогии со СНИЛС
        if (props.mchdDataChecks) {
            let mchdDataChecks = props.mchdDataChecks;
            for (let i = 0; i < mchdDataChecks.conditionChecks.length; i++) {
                let check = mchdDataChecks.conditionChecks[i];

                //определить по усливию, нужна ли проверка
                //let anyCoditionIsOk = check.conditions.forEach(async x=>
                let checkIsOk: boolean = false;
                for (let x of check.conditions) {
                    if (
                        !x.formula ||
                        (await checkConditionFormula(x.formula)) //или формула не задачи или она "выполняется"
                    )
                        checkIsOk = true;
                }
                if (!checkIsOk) continue;

                if (check.servCheck.runAutoTaskNameToCheck) {
                    let error: { flag: boolean; msg?: string } = {
                        flag: false,
                        msg: '',
                    };
                    let docRequisites = getFieldsByNames(currentDocData, check.servCheck.docRequisite);
                    if (docRequisites.length > 0) {
                        let serv = new AutoTaskService();
                        let parameters = docRequisites.map((item, index) => {
                            return item.value as string;
                        });

                        try {
                            let res = await serv.runAutoImport({
                                name: check.servCheck.runAutoTaskNameToCheck,
                                docKey: currentDocData?.id,
                                parameters: parameters,
                            });

                            if (!res.data.isSucceded) {
                                error.flag = true;
                                error.msg = res.data.message;
                            }
                        } catch (e) {
                            error.flag = true;
                            error.msg = e as string;
                        }

                        if (error.flag) {
                            setErrorText(error.msg);
                            setLoading(false);
                            return;
                        }
                    }
                }
            }
        }

        let sendToEtpData: ISendToEtpModel = {
            login: form.login,
            password: form.password,
            etpOrgCode: form.etpOrgCode,
        };

        await hubConn.connection.start();

        return service
            .sendToEtp(props.actionKey, sendToEtpData)
            .then((dto) => {
                hubConn.eetpSedningGuidAtStart = dto.data;
                // setSuccessText(dto.data);
                // setSuccess(true);
                //props.completeHandler && props.completeHandler(true);
            })
            .catch((error) => {
                hubConn.connection?.stop();
                setErrorText(error);
                setLoading(false);
            });
        //.finally(() => setLoading(false));
    };

    const checkConditionFormula = async (formula: string): Promise<boolean> => {
        let formulaMgr = new FormulaManager(formula);
        let formulaData: Record<string, IFieldElem> = {} as Record<string, IFieldElem>;
        currentDocData?.fields.forEach(
            (x) =>
                (formulaData[x.name] = {
                    index: 0,
                    name: '',
                    value: x.value,
                }),
        );
        formulaMgr.Init(formulaData);
        let result = await formulaMgr.EvalFormulaValues(true, false);
        return result;
    };

    const getFieldByName = (data: IDocumentData | undefined, value: string): IField | undefined => {
        return data?.fields.find((_) => _.name === value);
    };

    const getFieldsByNames = (data: IDocumentData | undefined, values: string | string[]): IField[] => {
        let fields: IField[] = [];

        if (Array.isArray(values)) {
            values.forEach((item) => {
                let field = getFieldByName(data, item);
                if (field) fields.push(field);
            });
        } else {
            let field = getFieldByName(data, values);
            if (field) fields.push(field);
        }

        return fields;
    };

    const modalContainerProps: IModalContainerProps = {
        header: props.message,
        size: props.modalSize,
        showOkButton: !success,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        cancelClick: () => {
            hubConn.connection.stop();
            props.completeHandler && props.completeHandler(success);
        },
        errorText: errorText,
        successText: successText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
        isBusy: loading,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            {!success && etpOrgCodeRequired && (
                <InputControl
                    label="Код организации"
                    name="etpOrgCode"
                    required={etpOrgCodeRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {!success && authRequired && (
                <InputControl
                    label="Логин"
                    name="login"
                    autoComplete="off"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {!success && authRequired && (
                <InputControl
                    label="Пароль"
                    name="password"
                    autoComplete="off"
                    type="password"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}
        </ModalContainer>
    );
};

interface ISendToEtpFormData {
    login: string;
    password: string;
    etpOrgCode: string;
}

interface IMchdDataCheck {
    conditionChecks: IMchdConditionCheck[];
}

interface IMchdConditionCheck {
    conditions: ICheckCondition[];
    servCheck: IMchdServCheck;
}

interface IMchdServCheck {
    docRequisite: string | string[];
    systemVal: string;
    resultOkVal: string;
    resultFailVal: string;
    runAutoTaskNameToCheck: string;
}

interface ICheckCondition {
    flowName: string;
    activityName: string;
    formula: string;
}
