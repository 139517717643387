import React from 'react';
import { IIconProps } from '@/types';
import { iconSize } from '@utils/helpers';
/* eslint-disable max-len */

export default ({ size = 'xs', ...props }: IIconProps) => {
    return (
        <svg
            {...props}
            width={iconSize[size]}
            height={iconSize[size]}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m14.375 8c-2.4162 0-4.375 1.9587-4.375 4.375v15.25c0 2.4162 1.9588 4.375 4.375 4.375h11.25c2.4162 0 4.375-1.9588 4.375-4.375v-15.25c0-2.4162-1.9588-4.375-4.375-4.375zm1.0968 18.083c-0.26632-0.2835-0.39949-0.622-0.39949-1.0154 0-0.3935 0.13316-0.729 0.39949-1.0068 0.26632-0.2835 0.64918-0.42525 1.1485-0.42525 0.49936 0 0.87942 0.14175 1.1402 0.42525 0.26632 0.27775 0.39949 0.61325 0.39949 1.0068 0 0.39338-0.13316 0.73188-0.39949 1.0154-0.26078 0.27762-0.64084 0.4165-1.1402 0.4165-0.49381 0-0.87666-0.13888-1.1485-0.4165zm0.04162-8.8692h-2.5134v-0.32978c0-1.0761 0.35232-1.9092 1.057-2.4993 0.7102-0.59012 1.6423-0.88519 2.7964-0.88519 1.1763 0 2.1223 0.30085 2.838 0.90254 0.71575 0.60169 1.0736 1.4406 1.0736 2.5167 0 0.80419-0.1942 1.4782-0.58259 2.022-0.38285 0.53805-0.81285 0.99221-1.29 1.3625-0.44942 0.34712-0.73518 0.70588-0.85724 1.0761-0.11651 0.37025-0.17478 0.729-0.17478 1.0761v0.11275h-2.5634v-0.13012c0-0.68275 0.09432-1.2641 0.28298-1.7444 0.18865-0.486 0.56871-0.97774 1.1402-1.4753 0.47162-0.41078 0.82672-0.7579 1.0653-1.0414 0.24412-0.28928 0.36619-0.6798 0.36619-1.1716 0-0.52648-0.10541-0.91411-0.31626-1.1629-0.20529-0.25456-0.54096-0.38184-1.007-0.38184-0.43832 0-0.76846 0.12728-0.9904 0.38184-0.21639 0.24878-0.32458 0.62194-0.32458 1.1195zm7.7983 8.8605c-0.26075-0.2835-0.39112-0.62188-0.39112-1.0154 0-0.39912 0.13038-0.73762 0.39112-1.0154 0.26638-0.28338 0.64638-0.42512 1.1402-0.42512 0.49938 0 0.88225 0.14175 1.1485 0.42512 0.26638 0.27775 0.3995 0.61625 0.3995 1.0154 0 0.3935-0.13312 0.73188-0.3995 1.0154-0.26075 0.2835-0.64088 0.42525-1.1402 0.42525-0.49925 0-0.88212-0.14175-1.1485-0.42525zm2.2555-3.506h-2.2221l-0.17488-9.0688h2.5801z"
                fill="currentColor"
            />
        </svg>
    );
};
