import React from 'react';
import { IIconProps } from '@/types';
import { iconSize } from '@utils/helpers';
/* eslint-disable max-len */

export default ({ size = 'xs', ...props }: IIconProps) => {
    return (
        <svg
            {...props}
            width={iconSize[size]}
            height={iconSize[size]}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 16C21 17.6569 19.6569 19 18 19C16.3431 19 15 17.6569 15 16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16Z"
                fill="currentColor"
            />
            <path
                d="M25 25C25 26.6569 23.6569 28 22 28C20.3431 28 19 26.6569 19 25C19 23.3431 20.3431 22 22 22C23.6569 22 25 23.3431 25 25Z"
                fill="currentColor"
            />
            <path
                d="M26.7711 26.5C26.9198 26.0265 27 25.5226 27 25C27 24.4774 26.9198 23.9735 26.7711 23.5H29.5C30.3284 23.5 31 24.1716 31 25C31 25.8284 30.3284 26.5 29.5 26.5H26.7711Z"
                fill="currentColor"
            />
            <path
                d="M17.2289 26.5H10.5C9.67157 26.5 9 25.8284 9 25C9 24.1716 9.67157 23.5 10.5 23.5H17.2289C17.0802 23.9735 17 24.4774 17 25C17 25.5226 17.0802 26.0265 17.2289 26.5Z"
                fill="currentColor"
            />
            <path
                d="M19.4013 26.5C19.1461 26.0587 19 25.5464 19 25C19 24.4536 19.1461 23.9413 19.4013 23.5H24.5987C24.8539 23.9413 25 24.4536 25 25C25 25.5464 24.8539 26.0587 24.5987 26.5H19.4013Z"
                fill="currentColor"
            />
            <path
                d="M22.7711 17.5C22.9198 17.0265 23 16.5226 23 16C23 15.4774 22.9198 14.9735 22.7711 14.5H29.5C30.3284 14.5 31 15.1716 31 16C31 16.8284 30.3284 17.5 29.5 17.5H22.7711Z"
                fill="currentColor"
            />
            <path
                d="M13.2289 17.5H10.5C9.67157 17.5 9 16.8284 9 16C9 15.1716 9.67157 14.5 10.5 14.5H13.2289C13.0802 14.9735 13 15.4774 13 16C13 16.5226 13.0802 17.0265 13.2289 17.5Z"
                fill="currentColor"
            />
            <path
                d="M15.4013 17.5C15.1461 17.0587 15 16.5464 15 16C15 15.4536 15.1461 14.9413 15.4013 14.5H20.5987C20.8539 14.9413 21 15.4536 21 16C21 16.5464 20.8539 17.0587 20.5987 17.5H15.4013Z"
                fill="currentColor"
            />
            <path
                d="M26.7711 26.5C26.9198 26.0265 27 25.5226 27 25C27 24.4774 26.9198 23.9735 26.7711 23.5H29.5C30.3284 23.5 31 24.1716 31 25C31 25.8284 30.3284 26.5 29.5 26.5H26.7711Z"
                fill="currentColor"
            />
            <path
                d="M17.2289 26.5H10.5C9.67157 26.5 9 25.8284 9 25C9 24.1716 9.67157 23.5 10.5 23.5H17.2289C17.0802 23.9735 17 24.4774 17 25C17 25.5226 17.0802 26.0265 17.2289 26.5Z"
                fill="currentColor"
            />
            <path
                d="M19.4013 26.5C19.1461 26.0587 19 25.5464 19 25C19 24.4536 19.1461 23.9413 19.4013 23.5H24.5987C24.8539 23.9413 25 24.4536 25 25C25 25.5464 24.8539 26.0587 24.5987 26.5H19.4013Z"
                fill="currentColor"
            />
            <path
                d="M22.7711 17.5C22.9198 17.0265 23 16.5226 23 16C23 15.4774 22.9198 14.9735 22.7711 14.5H29.5C30.3284 14.5 31 15.1716 31 16C31 16.8284 30.3284 17.5 29.5 17.5H22.7711Z"
                fill="currentColor"
            />
            <path
                d="M13.2289 17.5H10.5C9.67157 17.5 9 16.8284 9 16C9 15.1716 9.67157 14.5 10.5 14.5H13.2289C13.0802 14.9735 13 15.4774 13 16C13 16.5226 13.0802 17.0265 13.2289 17.5Z"
                fill="currentColor"
            />
            <path
                d="M15.4013 17.5C15.1461 17.0587 15 16.5464 15 16C15 15.4536 15.1461 14.9413 15.4013 14.5H20.5987C20.8539 14.9413 21 15.4536 21 16C21 16.5464 20.8539 17.0587 20.5987 17.5H15.4013Z"
                fill="currentColor"
            />
        </svg>
    );
};
