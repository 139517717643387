import React, { useMemo, useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';

import './RunAutoTaskExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { IRunTaskModel } from '@models/document/actionsExecution/IRunTaskModel';
import { ModalSize } from '@atoms/Modal/Modal';

import { API_URL } from '@/http';
import * as signalR from '@microsoft/signalr';

export class RunAutoTaskExecutor extends DocBaseActionExecutor {
    private _modalProps?: IRunAutoTaskExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            frontAsync: action.options?.frontAsync,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RunAutoTaskExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IRunAutoTaskExecutorProps {
    docId: string;
    actionKey: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    frontAsync: boolean; //Асинхронно ли на фронте (через signalr дожидаться ответа)
    completeHandler?: (isSucceed: boolean) => void;
}

const hubMethodName = 'autotaskCompleted';

const RunAutoTaskExecutorModal: React.FC<IRunAutoTaskExecutorProps> = (props: IRunAutoTaskExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [successText, setSuccessText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const hubConn = useMemo(() => {
        let autotaskGuidAtStart: string = '';
        let connection = new signalR.HubConnectionBuilder().withUrl(`${API_URL}/autotaskHub`).build();
        let setTimeoutCounter = 0;

        const signalrIdHandler = (autotaskGuid: string, success: boolean, message: string) => {
            if (!hubConn.autotaskGuidAtStart && setTimeoutCounter < 100) {
                setTimeoutCounter++;
                setTimeout(() => signalrIdHandler(autotaskGuid, success, message), 1000);
                return;
            }

            if ((autotaskGuid as string) == hubConn.autotaskGuidAtStart) {
                connection.stop();
                hubConn.autotaskGuidAtStart = '';
                setTimeoutCounter = 0;

                setLoading(false);
                if (!success) {
                    setErrorText(message);
                } else {
                    //hubConn?.connection?.off(hubMethodName);
                    props.completeHandler && props.completeHandler(true);
                }
            }
        };

        connection.on(hubMethodName, signalrIdHandler);

        return { connection, autotaskGuidAtStart };
    }, []);

    const messageBoxProp: IMessageDialogProps = {
        header: 'Запуск задачи',
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        size: props.modalSize,
        okClick: async () => {
            setErrorText('');
            setSuccessText('');
            setLoading(true);

            let service = new DocumentExecutionService(props.docId);
            let runAutoTaskData: IRunTaskModel = {
                actionKey: props.actionKey,
                taskContext: '',
                parameters: [],
                frontAsync: props.frontAsync,
            };

            await hubConn.connection.start();
            return service
                .runAutoTask(runAutoTaskData)
                .then((dto) => {
                    if (props.frontAsync) {
                        hubConn.autotaskGuidAtStart = dto.data; //асинхронное выполнение, просто записываем ид выполняющейся задачи
                    } else {
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => {
                    hubConn.connection?.stop();
                    setErrorText(error);
                })
                .finally(() => {
                    if (!props.frontAsync) {
                        setLoading(false); //вырубаем крутилку только если синхронная история у нас
                        //hubConn?.connection?.off(hubMethodName);
                    }
                });
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);

            //hubConn?.connection?.off(hubMethodName);
            //hubConn.connection.stop();
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} successText={successText} />;
};
