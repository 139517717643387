import { SelectControl } from '@/components/controls';
import { ISmartBusinessParameters } from '@/models/smartBusiness/ISmartBusinessParameters';
import { IOption } from '@/types';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

const options = [
    { value: 1, label: 'Январь' },
    { value: 2, label: 'Февраль' },
    { value: 3, label: 'Март' },
    { value: 4, label: 'Апрель' },
    { value: 5, label: 'Май' },
    { value: 6, label: 'Июнь' },
    { value: 7, label: 'Июль' },
    { value: 8, label: 'Август' },
    { value: 9, label: 'Сентябрь' },
    { value: 10, label: 'Октябрь' },
    { value: 11, label: 'Ноябрь' },
    { value: 12, label: 'Декабрь' },
] as IOption[];

interface IOwnProps {
    formMethods: UseFormReturn<ISmartBusinessParameters>;
}

export const SmartBusinessTendersMonthField: FC<IOwnProps> = ({ formMethods }) => {
    return (
        <SelectControl
            label="Минимальный месяц тендера"
            multiselect={false}
            required={true}
            name="start_month"
            options={options}
            formState={formMethods.formState}
            control={formMethods.control}
            setValue={(options) => {
                return options[0].value;
            }}
            getValue={(options: IOption[], value: any) => {
                return options.filter((x) => x.value === value);
            }}
            rules={{ required: 'Обязательное поле' }}
        />
    );
};
