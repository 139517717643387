import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';

export class OpenDocumentExecutor extends DocBaseActionExecutor {
    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        let serv = new DocumentExecutionService(objId);
        serv.getOpenDocumentModel(action.key).then((res) => {
            if (action.options.openInNewTab) {
                window.open(`./document/${res.data.docId}`, '_blank');
            } else {
                if (this.navigate) {
                    this.navigate(`/document/${res.data.docId}`);
                } else {
                    window.location.href = `./document/${res.data.docId}`;
                }
            }
            completeHandler && completeHandler(false);
        });
    };
}
