import DataGrid, { Column, FilterRow, Paging, Scrolling, Selection, Sorting } from 'devextreme-react/ui/data-grid';
import React, { FC, RefObject, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import './ListModeControl.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import { ColumnFixing } from 'devextreme-react/ui/tree-list';
import { simulateMouseClick } from '@utils/helpers';
import { IDictSettings } from '@atoms/Dictpicker/IDictSettings';
import DataSource from 'devextreme/data/data_source';
import { getLoadOptionsQuery, onCellHoverChanged } from '@utils/dataGridUtils';
import { IDictpickerRefActions } from '@atoms/Dictpicker/Dictpicker';
import { stringToDate } from '@/utils/helpersDatePicker';

export interface IListModeControlProp extends IDictSettings {
    onSelectedRowItems: (value: IDictionaryData[]) => void;
    onSelectedKeys: (value: string[]) => void;
    dictAttributes: string[];
    filterResponse: IDictFilter;
    controlRef: RefObject<IDictpickerRefActions>;
}

const ListModeControl: FC<IListModeControlProp> = (p: IListModeControlProp) => {
    const [filterRemoteQuery, setFilterRemoteQuery] = useState<any[]>([]);
    //const [selectedItems, setSelectedItems] = useState<string[]>(p.selected);
    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            gridRef?.current?.instance.refresh();
        },
        setSelected: (items: string[]) => {
            gridRef?.current?.instance.selectRows(items, false);
            gridRef?.current?.instance.refresh();
        },
    }));

    const filterPredicate = (currentItem: IDictionaryData, filter: any) => {
        var value = filter.columnName
            ? currentItem.fields.find((x) => x.name === filter.columnName)?.value
            : currentItem.code;

        if (filter.exlude && filter.exlude?.codes?.indexOf(value) !== -1) return false;

        if (filter.codes?.indexOf(value) !== -1) return filter.isShow;

        return !filter.isShow;
    };

    /**
     * TODO Построение фильтрующего условия для отправки на сервер
     * */
    useEffect(() => {
        if (p.filterResponse && p.filterResponse.filter && p.filterResponse.filter != '') {
            var filter = JSON.parse(p.filterResponse.filter.replace(/\'/g, '"'));

            let conditions: any[] = [];
            if (filter.codes) {
                let column = filter.columnName ? filter.columnName : 'code';
                let codes = filter.codes as string[];
                /**
                 * TODO Научить сервер обрабатывать запросы вида ["!", ["value", "=", 3]]
                 * */
                let operand = filter.isShow ? '=' : '!=';
                let binary = filter.isShow ? 'or' : 'and';
                codes.forEach((code) => {
                    if (conditions.length > 0) {
                        conditions.push(binary);
                    }
                    conditions.push([column, operand, code]);
                });
            }
            setFilterRemoteQuery(conditions);
        }
    }, [p.filterResponse]);

    const filterResponseVoid = (originalItems: IDictionaryData[], filter: IDictFilter) => {
        if (filter.filter && filter.filter != '') {
            var fObj = JSON.parse(filter.filter.replace(/\'/g, '"'));
            var filteredObject = [] as IDictionaryData[];
            for (var j = 0; j < originalItems.length; j++) {
                var item = originalItems[j];
                var filteredChild = filterPredicate(item, fObj);
                if (filteredChild) filteredObject.push(item);
            }

            return filteredObject;
        } else {
            return originalItems;
        }
    };

    const getUrlParam = (loadOptions: any) => {
        let params = '?';
        params += getLoadOptionsQuery(loadOptions);
        return params;
    };

    const gridSource = useMemo(() => {
        return new DataSource<IDictionaryData, string>({
            /**
             * TODO Перенести фильтрацию из метода load в пост-обработку (корректно работают внутренние счётчики)
             * */
            //postProcess: postProcessFunction,

            filter: p.isFormData || filterRemoteQuery.length == 0 ? null : filterRemoteQuery,
            requireTotalCount: true,
            key: 'code',
            async load(loadOptions: any) {
                let params = getUrlParam(loadOptions);
                let formValues = p.getFormValuesAsync && p.formValues && (await p.getFormValuesAsync(p.formValues!));
                if (formValues) {
                    params += '&formValues=' + JSON.stringify(formValues);
                } else {
                    params += '&formValues=""';
                }

                if (p.predicatesCache) {
                    params += '&predicates=' + p.predicatesCache;
                }
                if (p.isFormData) {
                    return DictionariesService.getGridFormdataItems(p.docId!, p.dictName, params).then(
                        async (response) => {
                            if (
                                p.filterResponse &&
                                (p.filterResponse.script || (p.filterResponse.filter && p.filterResponse.filter != ''))
                            ) {
                                let _data: IDictionaryData[] = [];
                                /**
                                 * TODO Добавить серверную фильтрацию для псевдосправочников
                                 * */
                                _data = filterResponseVoid(response.data.data, p.filterResponse);
                                if (p.filterResponse?.script) {
                                    _data = filterGridRowsByScript(_data, p.filterResponse?.script);
                                }
                                response.data.data = _data;
                                response.data.totalCount = _data.length;
                            }
                            let exDs = p.getExternalDataSource && (await p.getExternalDataSource(loadOptions));
                            if (exDs && exDs.length > 0) {
                                let data: IDictionaryData[] = [];
                                for (let index = 0; index < response.data.data.length; index++) {
                                    const element = response.data.data[index];
                                    if (
                                        exDs.findIndex((x) => {
                                            return x.code == element.code;
                                        }) == -1
                                    ) {
                                        data.push(element);
                                    }
                                }
                                exDs.forEach((element) => {
                                    data.push(element);
                                });
                                response.data.data = data;
                                response.data.totalCount = data.length;
                            }
                            return response.data;
                        },
                    );
                } else {
                    if (p.joinedDictionaries && p.joinedDictionaries.length > 0) {
                        let joinDictsParams = JSON.stringify(p.joinedDictionaries);
                        params += `&joinedDictionaries=${joinDictsParams}`;
                    }

                    return DictionariesService.getGridItems(p.dictName, params).then((response) => {
                        if (p.filterResponse) {
                            let length = response.data.data.length;
                            let _data: IDictionaryData[] = response.data.data;
                            /**
                             * Используется серверная фильтрация
                             * */
                            //_data = filterResponseVoid(response.data.data, filterResponse);
                            if (p.filterResponse?.script) {
                                _data = filterGridRowsByScript(_data, p.filterResponse?.script);
                            }
                            const _dataResult = _data.filter(
                                (thing, i, arr) => arr.findIndex((t) => t.code === thing.code) === i,
                            );
                            response.data.data = _dataResult;
                            if (response.data.totalCount !== 0) response.data.totalCount -= length - _dataResult.length;
                        }
                        return response.data;
                    });
                }
            },
        });
    }, [p.dictName, p.filterResponse, filterRemoteQuery]);

    /**
     *
     * */
    const filterGridRowsByScript = (data: IDictionaryData[], script: string) => {
        let _data: IDictionaryData[] = [];
        data.forEach((value) => {
            let result = filterGridRowByScript(value, script);
            if (result) {
                _data.push(value);
            }
        });
        return _data;
    };

    /**
     *
     * */
    const filterGridRowByScript = (data: IDictionaryData, script: string) => {
        /**
         * WARNING! Begin sections of functions for templates, do not rename
         * */
        let subFunc = ` 
        function code (){
            return data.code;
        };
    
        function getDateInt (data) {
            let date = stringToDate(data.split(' ')[0], 'dd.mm.yyyy');
            return date.getTime();
        };
    
        function getOnlyDateFromInt (data) {
            let date = new Date(+data);
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).getTime();
        };
    
        function field (name) {
            let value = data.fields.find((field) => field.name === name)?.value;
            return value;
        };
    
        function intersect (array1, array2){
            return array1 && array2 && array1.some((item) => array2.includes(item));
        };
        `;
        /**
         * WARNING! End sections of functions for templates
         * */

        return eval(subFunc + script);
    };

    const getDateInt = (data: string) => {
        let date = stringToDate(data.split(' ')[0], 'dd.mm.yyyy');
        return date.getTime();
    };

    const checkIsRowSelectable = (data?: IDictionaryData) => {
        if (p.selectableLevels) {
            if (p.selectableLevels === 'last') {
                return !data?.hasChild;
            }
            if (p.selectableLevels === 'first') {
                return data?.parent === '';
            }
        }
        return true;
    };

    const onGridEditorPreparing = (e: any) => {
        if (e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
    };

    const onSelectionChanged = (e: any) => {
        let property = 'code';
        if (!p.isMultiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                let item = e.selectedRowsData.pop();
                e.component.selectRows([item[property]], false);
                p.onSelectedKeys([item[property]]);
                p.onSelectedRowItems([item!]);
            } else if (e.selectedRowKeys.length == 0) {
                p.onSelectedKeys([]);
                p.onSelectedRowItems([]);
            }
        } else {
            p.onSelectedKeys(e.selectedRowKeys);
            p.onSelectedRowItems(e.selectedRowsData);
        }
    };

    const gridRef = useRef<DataGrid>(null);

    const getCodeColumn = () => {
        let attr = p.gridAttribute?.attrs.find((obj) => {
            return obj.key === 'code';
        });
        return attr ? (
            <Column key={'code'} dataField={'code'} caption={attr.name} width={attr.width} />
        ) : p.gridAttribute == undefined ? (
            <Column key={'code'} dataField={'code'} caption="Код" width="auto" />
        ) : (
            <></>
        );
    };
    const getWidth = () => {
        let w = 100;
        p.gridAttribute?.attrs.forEach((x) => {
            w = w + x.width;
        });
        return w;
    };

    const cellUrlRender = (param: any) => {
        if (p.isFormData && param.data.url && param.data.urlAttributes.indexOf(param.column.caption) !== -1) {
            return (
                <a target="_blank" href={param.data.url}>
                    {param.value}
                </a>
            );
        } else {
            return <>{param.value}</>;
        }
    };

    const calcBoolCell = (rowData: any) => {
        return rowData.condition === '1';
    };

    const onRowClick = (e: any) => {
        if (checkIsRowSelectable(e.data)) {
            if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
                e.component.deselectRows([e.key]);
            } else {
                e.component.selectRows([e.key], true);
            }
        }
    };

    const isPaginationEnabled = !(p.loadMode && p.loadMode === 'all');

    // сортируем столбцы в порядке как они заданы в шаблоне
    const gridAttributesKeys = p.gridAttribute?.attrs.map((val) => val.key) ?? [];
    const sortedDictAttributes = [...p.dictAttributes]?.sort(
        (a, b) =>
            (gridAttributesKeys.indexOf(a) === -1 ? Infinity : gridAttributesKeys.indexOf(a)) -
            (gridAttributesKeys.indexOf(b) === -1 ? Infinity : gridAttributesKeys.indexOf(b)),
    );

    return sortedDictAttributes ? (
        <DevExpressDataGrid
            width={getWidth}
            key={'grid'}
            dataSource={gridSource}
            hoverStateEnabled={true}
            columnHidingEnabled={false}
            columnMinWidth={30}
            showColumnHeaders={true}
            columnAutoWidth={false}
            allowColumnReordering={false}
            allowColumnResizing={true}
            columnResizingMode="widget"
            noDataText={'Нет строк'}
            ref={gridRef}
            onSelectionChanged={onSelectionChanged}
            onEditorPreparing={onGridEditorPreparing}
            defaultSelectedRowKeys={p.selected}
            remoteOperations={true}
            onRowClick={onRowClick}
            onCellHoverChanged={onCellHoverChanged}
        >
            <Selection mode="multiple" allowSelectAll={false} selectAllMode="page" />

            {getCodeColumn()}

            {sortedDictAttributes.map((schemeColumn, i) => {
                let attr = p.gridAttribute?.attrs.find((obj) => {
                    return obj.key === schemeColumn;
                });
                const index = p.dictAttributes.indexOf(schemeColumn);

                return attr === undefined ? (
                    p.gridAttribute === undefined ? (
                        <Column
                            key={index}
                            allowFiltering={true}
                            caption={schemeColumn}
                            dataField={`fields[${index}].value`}
                            dataType={'string'}
                            visible={true}
                            allowSorting={true}
                            filterOperations={['contains']}
                            encodeHtml={true}
                            cellRender={cellUrlRender}
                        />
                    ) : (
                        <React.Fragment key={index}></React.Fragment>
                    )
                ) : (
                    <Column
                        key={index}
                        allowFiltering={true}
                        caption={attr?.name}
                        dataField={`fields[${index}].value`}
                        dataType={attr?.type ? attr?.type : 'string'}
                        width={attr?.width}
                        visible={true}
                        allowSorting={true}
                        filterOperations={['contains']}
                        encodeHtml={true}
                        trueText={attr?.type && attr?.type === 'boolean' ? '1' : undefined}
                        falseText={attr?.type && attr?.type === 'boolean' ? '0' : undefined}
                        showEditorAlways={attr?.type && attr?.type === 'boolean' ? true : undefined}
                        //calculateCellValue={attr?.type && attr?.type === 'boolean' ? calcBoolCell : undefined}
                        cellRender={attr?.type && attr?.type !== 'boolean' ? undefined : cellUrlRender}
                    />
                );
            })}

            <FilterRow showOperationChooser={false} visible={true} applyFilter={'onClick'} />
            <ColumnFixing enabled={true} />
            <Sorting mode="multiple" showSortIndexes={true} />
            {isPaginationEnabled && <Scrolling mode="virtual" />}
            <Paging enabled={isPaginationEnabled} defaultPageSize={10} />
        </DevExpressDataGrid>
    ) : (
        <></>
    );
};

export default ListModeControl;
