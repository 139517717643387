import React, { forwardRef, ReactNode } from 'react';

import '../styles/iproc-scheme.css';

import DataGrid, { IDataGridOptions } from 'devextreme-react/ui/data-grid';

import ruMessages from 'devextreme/localization/messages/ru.json';
import { loadMessages, locale } from 'devextreme/localization';

export interface IDevExpressDataGridOptions extends IDataGridOptions {
    children?: ReactNode;
}

const DevExpressDataGrid = forwardRef<DataGrid, IDevExpressDataGridOptions>(
    (
        { children, showBorders = true, showRowLines = false, ...props }: IDevExpressDataGridOptions,
        ref: React.Ref<DataGrid>,
    ) => {
        loadMessages(ruMessages);
        locale('ru'); //locale(navigator.language);

        return (
            <DataGrid showBorders={showBorders} showRowLines={showRowLines} {...props} ref={ref}>
                {children}
            </DataGrid>
        );
    },
);

export default DevExpressDataGrid;
