import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './InputNumber.scss';
import Input, { IInputProps } from '../Input/Input';
import { numberWithSpaces } from '@utils/helpers';

export interface IInputNumberProps extends IInputProps {
    defaultValue?: string | number;
    separator?: string;
    floatPoints?: number;
    groupBy?: number;
    max?: number;
    onInputChange?: (value: string) => void;
}

const InputNumber = forwardRef<HTMLInputElement | null, IInputNumberProps>(
    (
        { max, defaultValue = '', separator, floatPoints = 0, groupBy, onInputChange, ...props }: IInputNumberProps,
        ref,
    ) => {
        const input = useRef<HTMLInputElement | null>(null);

        const defaultVal =
            defaultValue != undefined && defaultValue !== null ? defaultValue.toString().replace(',', '.') : '';
        // separator == ""
        //     ? defaultValue
        //     : defaultValue
        //           ?.toLocaleString("ru-Ru", {
        //               minimumFractionDigits: floatPoints,
        //               maximumFractionDigits: floatPoints,
        //           })
        //           .replace(",", ".");

        const [inputValue, setInputValue] = useState<string>('');
        const [value, setValue] = useState<string | number>(defaultVal);

        // -------------------------------------------------------------------------------------------------------------------

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value.replace(',', '.'));
        };

        const onValueChange = (e: any, withoutInvokeChanges?: boolean) => {
            let value = e;

            /** Исключить все буквы алфавита */
            if (isNaN(+value.replace(/\s/g, ''))) {
                //e?.preventDefault();
                return;
            }

            if (floatPoints === 0 && value.includes('.')) {
                const idx = value.indexOf('.');
                value = value.slice(0, idx);
            }

            /** Исключить повторение точек */
            const dotMap: Record<string, number> = { '.': 0 };

            for (let i = 0; i < value.length; i++) {
                if (!dotMap[value[i]]) {
                    dotMap[value[i]] = 1;
                } else {
                    dotMap[value[i]]++;
                }
            }

            if (dotMap['.'] > 1) {
                return;
            }

            const values = value.split('.');

            const value1: string = values[0].replace(/\s/g, '');
            let value2: string = values[1];

            let result = '';

            if (value1) {
                const integer = +value1;

                /** Исключить экспоненциальные значения и infinity */
                if (integer > Number.MAX_SAFE_INTEGER) {
                    return;
                }

                if (value2 && value2.toString().length > floatPoints) {
                    value2 = value2.slice(0, floatPoints);
                }

                const float = +value2;
                result = isNaN(float)
                    ? numberWithSpaces(integer, groupBy, separator)
                    : [numberWithSpaces(integer, groupBy, separator), value2].join('.');
            }

            if (withoutInvokeChanges === undefined || withoutInvokeChanges === false) {
                onInputChange && onInputChange(result);
            }

            setValue(result);
        };

        // -------------------------------------------------------------------------------------------------------------------

        const handleDefault = (v: string | number, withoutInvokeChanges: boolean) => {
            let val: string = v.toString().replace(/\s/g, '');
            if (floatPoints > 0 && !val.includes('.')) {
                val = val + '.00';
            }
            onValueChange(val, withoutInvokeChanges);
        };

        useEffect(() => {
            if (defaultVal !== undefined) {
                let val = defaultVal;
                handleDefault(val, true);
            }
        }, [defaultVal]);

        useEffect(() => {
            if (max && +value.toString().replace(/\s/g, '') > max) {
                const floatMax = floatPoints === 0 ? max.toString().split('.')[0] : max;
                handleDefault(floatMax, false);
            }
        }, [value]);

        useEffect(() => {
            if (value && value !== null) {
                setInputValue(value.toString().replace(/\s/g, ''));
            }
        }, [value]);

        const invoceChangeInput = () => {
            if (!input.current) {
                return;
            }

            let event;

            if (typeof Event === 'function') {
                event = new Event('change');
            } else {
                event = document.createEvent('Event');
                event.initEvent('change', true, true);
            }

            input.current.dispatchEvent(event);
        };

        useEffect(() => {
            invoceChangeInput();
        }, [inputValue]);

        // -------------------------------------------------------------------------------------------------------------------

        const onKeyPress = (e: React.KeyboardEvent) => {
            if (e.keyCode === 13 || e.charCode === 13 || e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
            }
            if (floatPoints !== undefined && floatPoints === 0 && (e.key === ',' || e.key === '.')) {
                e.stopPropagation();
                e.preventDefault();
            }
        };

        // -------------------------------------------------------------------------------------------------------------------

        return (
            <>
                <Input
                    ref={ref}
                    {...props}
                    value={value}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    onChange={onChange}
                    onValueChange={onValueChange}
                    onKeyPress={onKeyPress}
                    pattern={'^[\\.\\,\\s0-9]*$'}
                    // type="number"
                />

                <input
                    type="text"
                    className="rf-number-input__hidden"
                    name={props.name}
                    value={inputValue}
                    ref={input}
                    readOnly
                />
            </>
        );
    },
);

export default InputNumber;
