import React, { FC, useState } from 'react';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import './Folders.scss';
import Button from '@atoms/Button';
import Tree from '@atoms/Tree';
import { AllAdd, Dictionary, Directory, Home, Report, Services, Setting, Work } from '@/indexIcon';
import { IListElement, ITreeOption } from '@/types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Preloader from '@atoms/Preloader';
import { DocumentActionsService } from '@services/actions/DocumentActionsService';
import { LocationTypes } from '@models/actions/LocationTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocActionsExecFactory } from '@utils/actions/DocActionsExecFactory';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import Menu from '@atoms/Menu';
import { NoActionsItem } from '@models/actions/ActionConstants';

export interface IFoldersProps {
    /** сворачивание */
    isOpen: boolean;
}

const Folders: FC<IFoldersProps> = ({ isOpen = true }: IFoldersProps) => {
    const mainLayout = useTypedSelector((state) => state.mainLayout);
    const auth = useTypedSelector((state) => state.auth);
    const { toggleMenu } = useActions();
    const [isCreateDocActionsLoading, setIsCreateDocActionsLoading] = useState<boolean>();
    const [createDocActions, setCreateDocActions] = useState<IListElement[]>([]);
    const [actionExecutor, setActionExecutor] = useState<IActionExecutor>();
    const [searchParams, setSearchParams] = useSearchParams();

    const folders = useTypedSelector((state) => state.folders);
    const isEditWidgets = useTypedSelector((state) => state.widgets.isEdit);
    const location = useLocation();
    const navigate = useNavigate();
    const { fetchTasks, fetchReports, fetchDocuments, fetchDictionaries, setDashboardIsEdit } = useActions();
    const list: ITreeOption[] = [
        {
            value: 'dashboard',
            label: 'Главная',
            checked: false,
            icon: <Home size="s" />,
            url: '/',
        },
        {
            value: 'tasks',
            label: 'Мои задачи',
            checked: false,
            icon: <Work size="s" />,
            hasChildren: true,
            loading: folders.tasks.isLoading,
            children: folders.tasks.items,
            url: '/tasks',
        },
        {
            value: 'documents',
            label: 'Документы',
            checked: false,
            icon: <Directory size="s" />,
            hasChildren: true,
            loading: folders.documents.isLoading,
            children: folders.documents.items,
            url: '/documents',
        },
        {
            value: 'dictionaries',
            label: 'Справочники',
            hasChildren: true,
            checked: false,
            icon: <Dictionary size="s" />,
            loading: folders.dictionaries.isLoading,
            children: folders.dictionaries.items,
            url: '/dictionaries',
        },
        {
            value: 'reports',
            label: 'Отчётность',
            hasChildren: true,
            checked: false,
            icon: <Report size="s" />,
            loading: folders.reports.isLoading,
            children: folders.reports.items,
            url: '/reports',
        },
    ];

    if (auth.user.canView.adminPanel) {
        list.push({
            value: 'administrations',
            label: 'Администрирование',
            hasChildren: true,
            checked: false,
            icon: <Services size="s" />,
            loading: false,
            children: [
                {
                    value: 'addressBook',
                    label: 'Номенклатура закупщиков',
                    checked: false,
                    hasChildren: false,
                    url: '/administrations/addressBook',
                },
                {
                    value: 'notificationFiltering',
                    label: 'Фильтрация уведомлений',
                    checked: false,
                    hasChildren: false,
                    url: '/administrations/notificationFiltering',
                },
                {
                    value: 'incidents',
                    label: 'Инциденты',
                    checked: false,
                    hasChildren: false,
                    url: '/administrations/incident',
                },
            ],
            url: '/administrations',
        });
    }

    const loadItems = (o: ITreeOption) => {
        if (o.hasChildren && (o.children == undefined || o.children.length == 0)) {
            switch (o.value) {
                case 'tasks':
                    fetchTasks();
                    break;
                case 'documents':
                    fetchDocuments();
                    break;
                case 'dictionaries':
                    fetchDictionaries();
                    break;
                case 'reports':
                    fetchReports();
            }
        }
    };
    const onClickEditDashBoard = () => {
        setDashboardIsEdit(true);
    };
    const openClass = isOpen ? 'rf-folders--open' : 'rf-folders--close';

    const onCreateDocActionClick = (act: IBaseAction) => {
        let executor = DocActionsExecFactory.GetActionExecutor(act);
        if (!executor) throw new Error(`Не обнаружен executor для действия ${act.type}`);
        setActionExecutor(executor);
        executor.navigate = navigate;
        executor.location = location;
        executor.run(
            '',
            undefined,
            act,
            undefined,
            (succeed) => {
                setActionExecutor(undefined);
            },
            () => {
                setActionExecutor(undefined);
                setActionExecutor(executor);
            },
        );
    };

    const onCreateDocActionsOpening = () => {
        if (!isCreateDocActionsLoading) {
            setIsCreateDocActionsLoading(true);

            let searchType = searchParams.get('searchType');

            DocumentActionsService.fetchActionsByLocation(undefined, LocationTypes.PlanningMenu, searchType)
                .then((res) => {
                    let actionItems = res.data.map(
                        (act: IBaseAction, i: number) =>
                            ({
                                label: act.displayName,
                                value: i.toString(),
                                handler: () => onCreateDocActionClick(act),
                                showOpenInNewTabButton: act.options?.showOpenInNewTabButton ?? false,
                                newTabHandler: () => {
                                    let newAction = { ...act };
                                    let options = newAction.options ? { ...newAction.options } : {};
                                    options.newTab = true;
                                    newAction.options = options;
                                    onCreateDocActionClick(newAction);
                                },
                            }) as IListElement,
                    );
                    if (actionItems.length == 0) actionItems = [NoActionsItem];
                    setCreateDocActions(actionItems);
                })
                .finally(() => {
                    setIsCreateDocActionsLoading(false);
                });
        }
    };

    const onClickTree = (e: ITreeOption) => {
        if (!mainLayout.isMenuOpen) toggleMenu(true);
    };

    return auth.isAuth ? (
        <div className={`rf-folders  ${openClass}`}>
            {actionExecutor?.visualElement && actionExecutor?.visualElement()}
            <Menu list={createDocActions} position="bottom-start">
                <Button
                    size="s"
                    startAdornment={
                        isCreateDocActionsLoading ? (
                            <div className="busy-loader">
                                <Preloader size="s" />
                            </div>
                        ) : (
                            <AllAdd size="xxs" />
                        )
                    }
                    className="doc-button doc-button-margin "
                    onClick={() => {
                        onCreateDocActionsOpening();
                    }}
                >
                    Создать документ
                </Button>
            </Menu>
            <Tree onChange={loadItems} id="folders" open={false} list={list} onClick={onClickTree} />
            {location.pathname === '/' && (
                <Button
                    size="xl"
                    buttonType="text"
                    disabled={isEditWidgets}
                    textColor="neutral"
                    className="doc-button"
                    onClick={onClickEditDashBoard}
                    startAdornment={<Setting size="s" />}
                >
                    Настроить
                </Button>
            )}
        </div>
    ) : (
        <></>
    );
};

export default Folders;
