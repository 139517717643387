import React, { forwardRef, useEffect, useState } from 'react';
import './InputMoney.scss';
import Input, { IInputProps } from '../Input/Input';
import { moneyFieldCopyHandler } from '@utils/helpers';

export interface IInputMoneyProps extends IInputProps {
    defaultValue?: string | number;
    /** Идентификатор инпута */
    name?: string;
    /** Отключен или нет
     * @default false
     */
    disabled?: boolean;
    /** Переводит селект в невалидный статус
     * @default false
     */
    invalid?: boolean;
    /** Вызывается при изменении значения */
    onChangeValue?: (value: number | undefined) => void;
    /** формат отображения в зависимости от региона */
    locale?: string;
    /** количество символов после запятой*/
    floatPoints: number;
}

const InputMoney = forwardRef<HTMLInputElement | null, IInputMoneyProps>(
    (
        {
            defaultValue,
            locale = 'ru-RU',
            floatPoints,
            name,
            disabled,
            onChangeValue,
            invalid,
            ...props
        }: IInputMoneyProps,
        ref,
    ) => {
        const [inputValue, setInputValue] = useState<number>();
        const [displayValue, setDisplayValue] = useState<string>();

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setDisplayValue(e.target.value);
        };

        const onValueChange = (value: any) => {
            let numVal = value ? +value.replace(/\s/g, '').replace(',', '.') : undefined;

            let val = numVal
                ? numVal?.toLocaleString(locale, {
                      minimumFractionDigits: floatPoints,
                      maximumFractionDigits: floatPoints,
                  })
                : '';

            setDisplayValue(val);
            setInputValue(numVal);
            onChangeValue && onChangeValue(numVal);
        };

        useEffect(() => {
            let numVal = defaultValue ? +defaultValue.toString().replace(/\s/g, '').replace(',', '.') : undefined;

            let val = numVal
                ? numVal?.toLocaleString(locale, {
                      minimumFractionDigits: floatPoints,
                      maximumFractionDigits: floatPoints,
                  })
                : '';

            setDisplayValue(val);
            setInputValue(numVal);
        }, [defaultValue]);

        const onKeyPress = (e: React.KeyboardEvent) => {
            if (e.keyCode === 13 || e.charCode === 13 || e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
            }
            if (floatPoints !== undefined && floatPoints === 0 && (e.key === ',' || e.key === '.')) {
                e.stopPropagation();
                e.preventDefault();
            }
        };

        return (
            <>
                <Input
                    ref={ref}
                    {...props}
                    value={displayValue}
                    placeholder={props.placeholder}
                    disabled={disabled}
                    readOnly={props.readOnly}
                    onValueChange={onValueChange}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    pattern={'^[\\.\\,\\s0-9]*$'}
                    // type="number"
                    onCopy={moneyFieldCopyHandler}
                />

                <input data-testid="input" type="hidden" name={name} value={inputValue} />
            </>
        );
    },
);

export default InputMoney;
