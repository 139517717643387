import React, { ReactNode } from 'react';
import './ProgressBarCircle.scss';

export interface IProgressBarCircle {
    size: number;
    progress: number;
    trackWidth: number;
    trackColor: string;
    indicatorWidth: number;
    indicatorColor: string;
    indicatorCap?: 'butt' | 'round' | 'square' | 'inherit' | undefined;
    label?: ReactNode;
    spinnerMode?: boolean;
    spinnerSpeed?: number;
}

const ProgressBarCircle: React.FC<IProgressBarCircle> = ({
    size = 150,
    progress = 0,
    trackWidth = 10,
    trackColor = `#ddd`,
    indicatorWidth = 10,
    indicatorColor = `#07c`,
    indicatorCap = `round`,
    label,
    spinnerMode = false,
    spinnerSpeed = 1,
}: IProgressBarCircle) => {
    const center = size / 2,
        radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
        dashArray = 2 * Math.PI * radius,
        dashOffset = dashArray * ((100 - progress) / 100);

    return (
        <>
            <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
                <svg className="svg-pi" style={{ width: size, height: size }}>
                    <circle
                        className="svg-pi-track"
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={trackColor}
                        strokeWidth={trackWidth}
                    />
                    <circle
                        className={`svg-pi-indicator ${spinnerMode ? 'svg-pi-indicator--spinner' : ''}`}
                        style={{ animationDuration: (spinnerSpeed * 1000).toString() }}
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={indicatorColor}
                        strokeWidth={indicatorWidth}
                        strokeDasharray={dashArray}
                        strokeDashoffset={dashOffset}
                        strokeLinecap={indicatorCap}
                    />
                </svg>

                {label && <div className="svg-pi-label">{label}</div>}
            </div>
        </>
    );
};

export default ProgressBarCircle;
