import React, { useEffect } from 'react';
import { useTypedSelector } from '@hooks/useTypedSelector';
import Avatar from '@atoms/Avatar';
import './UserPanel.scss';
import { useActions } from '@hooks/useActions';

const UserPanel: React.FC = () => {
    const user = useTypedSelector((state) => state.auth.user);
    const userAvatar = useTypedSelector((state) => state.userAvatar);

    const { getUserAvatar } = useActions();

    useEffect(() => {
        if (!userAvatar.photoContent) getUserAvatar();
    }, []);

    return (
        <div>
            <div className="user-info-main">
                <div className="user-photo">
                    <Avatar
                        photo={
                            userAvatar.photoContent == null ? '' : `data:image/png;base64,${userAvatar.photoContent}`
                        }
                    />
                </div>
                <div className="user-fio-container">
                    <div className="user-fio-item">{user.firstName}</div>
                    <div className="user-fio-item">{user.middleName}</div>
                    <div className="user-fio-item">{user.lastName}</div>
                </div>
            </div>
            <div className="user-info-container">
                <ul className="dottet-ul">
                    {user.phone && <li className="user-info-item">{user.phone}</li>}
                    {user.email && <li className="user-info-item">{user.email}</li>}
                    {user.subdivision && <li className="user-info-item">{user.subdivision}</li>}
                    {user.position && <li className="user-info-item">{user.position}</li>}
                </ul>
            </div>
        </div>
    );
};

export default UserPanel;
