import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { ModalSize } from '@atoms/Modal/Modal';

export class DeleteExecutor implements IActionExecutor {
    private _modalProps?: IDeleteAttachModalProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: parentId ?? '',
            attachId: objId,
            rowData: rowData,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <DeleteAttachModal {...this._modalProps} /> : <div></div>;
    };
}

interface IDeleteAttachModalProps {
    docId: string;
    attachId: string;
    rowData: any;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const DeleteAttachModal: React.FC<IDeleteAttachModalProps> = (props: IDeleteAttachModalProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();

    const getMessage = () => {
        let attachIdsSplit = props.attachId.split(',').filter((item) => item);
        if (attachIdsSplit.length <= 1) {
            let parts = props.attachId.split('_');
            let rowData = Array.isArray(props.rowData) ? props.rowData[0] : props.rowData;
            return parts.length === 1
                ? `Удалить файл '${rowData.fields.AttachmentTitle}'?`
                : `Удалить версию файла ${rowData.fields.AttachmentTitle} (версия ${parts[1]})?`;
        } else {
            return 'Удалить выбранные файлы?';
        }
    };

    const messageString: string = getMessage();

    const messageBoxProp: IMessageDialogProps = {
        header: 'Удаление',
        message: messageString,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            setIsBusy(true);
            let serv = new AttachActionsService(props.docId, '-1');
            return serv
                .deleteAttach(props.attachId)
                .then(() => {
                    props.completeHandler && props.completeHandler(true);
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setIsBusy(false);
                });
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} isBusy={isBusy} errorText={errorText} />;
};
