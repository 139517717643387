import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';

import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { ModalSize } from '@atoms/Modal/Modal';

export class RemoveLinkExecutor extends DocBaseActionExecutor {
    private _modalProps?: IRemoveLinkExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let parts = this?._objId?.split('/');
        if (parts === undefined || parts.length != 2)
            throw new Error(`Не удалось распарсить ид документа и родители линка {parts}`);
        let ownerDocId = parts[0];
        let linkedDocId = parts[1];

        this._modalProps = {
            actKey: action.key,
            ownerDocId: ownerDocId,
            linkedDocId: linkedDocId,
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RemoveLinkExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IRemoveLinkExecutorProps {
    actKey: string;
    ownerDocId: string;
    linkedDocId: string;
    title: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const RemoveLinkExecutorModal: React.FC<IRemoveLinkExecutorProps> = (props: IRemoveLinkExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            setIsBusy(true);
            let service = new DocumentExecutionService(props.ownerDocId);
            return service
                .removeLink(props.linkedDocId, props.actKey)
                .then((res) => {
                    setIsBusy(false);
                    if (props.completeHandler) props.completeHandler(true);
                })
                .catch((error) => {
                    setErrorText(error);
                })
                .finally(() => {
                    setIsBusy(false);
                });
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} isBusy={isBusy} />;
};
