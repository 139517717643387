import React, { useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { AllClose } from '@/indexIcon';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import Preloader from '@atoms/Preloader';
import { DataGrid } from 'devextreme-react/ui/data-grid';

import './CopyAndEditAttachesExecutor.scss';

import { ModalSize } from '@atoms/Modal/Modal';
import { CopyAndEditAttachFilesBlock } from '@/components/molecules/AttachFilesBlock/CopyAndEditAttachFilesBlock';
import {
    ICopyAndEditAttachesAddResponse,
    ICopyAndEditAttachesData,
    ICopyAndEditAttachesResponse,
    ICopyAndEditAttachesScheme,
} from '@/models/attaches/ICopyAndEditAttaches';
import { CopyAndEditAttachesService } from '@/services/CopyAndEditAttachesService';
import { DocumentExecutionService } from '@/services/actions/DocumentExecutionService';
import Hint from '@/components/atoms/Hint';

export class CopyAndEditAttachesExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICopyAndEditAttachesExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            actionKey: action.key ?? '',
            displayName: action.displayName ?? '',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CopyAndEditAttachesExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICopyAndEditAttachesExecutorProps {
    docId: string;
    actionKey: string;
    displayName: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CopyAndEditAttachesExecutorModal: React.FC<ICopyAndEditAttachesExecutorProps> = ({
    docId,
    actionKey,
    displayName,
    okButtonText,
    cancelButtonText,
    completeHandler,
}: ICopyAndEditAttachesExecutorProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [scheme, setScheme] = useState<ICopyAndEditAttachesScheme>();
    const [data, setData] = useState<ICopyAndEditAttachesData>();
    const [selected, setSelected] = useState<ICopyAndEditAttachesAddResponse[]>([]);
    const [existAttaches, setExistAttaches] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        setIsBusy(true);
        CopyAndEditAttachesService.fetchScheme().then((x) => {
            setScheme(x.data);
        });
        CopyAndEditAttachesService.fetchData(docId, '-1', '').then((x) => {
            setData(x.data);
        });
    }, []);

    useEffect(() => {
        if (scheme && data) {
            const map = new Map();
            var list = data?.data.filter((x) => x.isExistAttach);
            list.forEach((item) => {
                map.set(item.key, item.existAttachKey);
            });

            setExistAttaches(map);
            setIsBusy(false);
        }
    }, [scheme, data]);

    const gridRef = useRef<DataGrid>(null);

    const onFilesSelected = (files: ICopyAndEditAttachesAddResponse[]) => {
        setSelected(files);
    };

    const onSubmit = () => {
        let addAttaches = selected.filter((file) => !existAttaches.has(file.key));

        let deleteAttaches = new Array();
        existAttaches.forEach((val, key) => {
            if (selected.findIndex((file) => file.key == key) == -1) {
                deleteAttaches.push(val);
            }
        });

        let response = {
            addAttaches: addAttaches,
            deleteAttaches: deleteAttaches,
        } as ICopyAndEditAttachesResponse;

        setIsBusy(true);
        let executor = new DocumentExecutionService(docId);
        executor
            .copyAndEditAttaches(actionKey, response)
            .then(() => {
                setIsBusy(false);
                if (completeHandler) completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={'max'}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>{displayName}</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (completeHandler) completeHandler(false);
                                }}
                                startAdornment={<AllClose size="xs" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {scheme && data && (
                        <CopyAndEditAttachFilesBlock scheme={scheme} data={data!} onFilesSelected={onFilesSelected} />
                    )}

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                size="s"
                                aria-label={okButtonText}
                                onClick={() => {
                                    onSubmit();
                                }}
                            >
                                {okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={cancelButtonText}
                                onClick={() => {
                                    if (completeHandler) completeHandler(false);
                                }}
                            >
                                {cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
