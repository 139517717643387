import React, { useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { AllClose } from '@/indexIcon';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import Preloader from '@atoms/Preloader';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import InputFile from '@atoms/InputFile';
import { IFileData } from '@/types';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid, Editing, Lookup } from 'devextreme-react/ui/data-grid';

import './AttachFilesExecutor.scss';

import { v4 as uuidv4 } from 'uuid';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import Hint from '@atoms/Hint';
import { checkFilesSize, formatBytes, getAcceptExtensionString } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { MAX_FILE_SIZE } from '@utils/constants';

export class AttachFilesExecutor extends DocBaseActionExecutor {
    private _modalProps?: IAttachFilesExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            action: action,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <AttachFilesExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IAttachFilesExecutorProps {
    docId: string;
    action: IBaseAction;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const AttachFilesExecutorModal: React.FC<IAttachFilesExecutorProps> = (props: IAttachFilesExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();

    const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);
    const [binaryFiles, setBinaryFiles] = useState<{ [fileId: string]: File }>({});

    const [filesSizeExceeded, setFilesSizeExceeded] = useState<boolean>(false);

    useEffect(() => {
        setIsBusy(true);
        let service = new AttachActionsService(props.docId, '-1');
        service.getAttachInfo(props.action.key).then((res) => {
            setAttachesCreateModel(res.data);
            setIsBusy(false);
        });
    }, []);

    useEffect(() => {
        if (filesSizeExceeded)
            setErrorText(`Превышен максимальный размер загружаемых файлов (${formatBytes(MAX_FILE_SIZE! * 1024)}).`);
        else setErrorText(undefined);
    }, [filesSizeExceeded]);

    const setFile = (files: IFileData[]) => {
        let newBinaryFiles = { ...binaryFiles };

        if (files && attachesCreateModel?.properties && attachesCreateModel?.categories) {
            for (var i = 0; i < files.length; i++) {
                let f = files[i].file;

                let fileGuid = uuidv4();

                //записываем в словарь
                if (newBinaryFiles && f) {
                    newBinaryFiles[fileGuid] = f;
                }

                let fileObj: any = {
                    _fileId: fileGuid,
                    _actType: 'add',
                    _filename: f.name,
                    _category_: attachesCreateModel?.categories[0]?.name,
                };
                for (var j = 0; j < attachesCreateModel?.properties?.length; j++) {
                    let col = attachesCreateModel?.properties[j];
                    let keyName = col.key;

                    switch (keyName) {
                        case 'AttachmentFileName':
                            fileObj[keyName] = f?.name;
                            break;
                        default:
                            let foundDefaultValue = attachesCreateModel?.defaultValues?.find((x) => x.attr == keyName);
                            if (foundDefaultValue) {
                                //значение по умолчанию
                                fileObj[keyName] = foundDefaultValue.val;
                            } else {
                                //если поле словарное - берем первое занчение из соотвествующего словаря
                                if (
                                    attachesCreateModel.dictionaries &&
                                    col.dictName &&
                                    attachesCreateModel.dictionaries[col.dictName]
                                ) {
                                    if (attachesCreateModel.dictionaries[col.dictName].length > 0)
                                        fileObj[keyName] = attachesCreateModel.dictionaries[col.dictName][0].code;
                                } else {
                                    fileObj[keyName] = undefined;
                                }
                            }
                    }
                }

                if (attachesCreateModel?.defaultValues) {
                    let customProps = attachesCreateModel.defaultValues.filter((x) =>
                        x.attr.startsWith('_custom_property_'),
                    );
                    customProps.forEach((customProp) => {
                        let customVal = customProp.val;

                        switch (customProp.val) {
                            case '@guid()':
                                customVal = uuidv4();
                                break;
                        }

                        fileObj[customProp.attr] = customVal;
                    });
                }

                setUploadingFiles((uploadingFiles) => [...uploadingFiles, fileObj]);
            }

            setBinaryFiles(newBinaryFiles);
        }

        gridRef.current?.instance.refresh();

        if (!checkFilesSize(Object.values<File>(newBinaryFiles), MAX_FILE_SIZE ? MAX_FILE_SIZE * 1024 : undefined)) {
            setFilesSizeExceeded(true);
        } else {
            setFilesSizeExceeded(false);
        }
    };

    const checkFilesForRestrictions = (): boolean => {
        let extErrors: string[] = [];
        if (uploadingFiles) {
            uploadingFiles.forEach((f) => {
                let fCat = f._category_;
                let fExt = f.AttachmentFileName?.split('.')?.pop()?.toLowerCase();
                let foundCat = attachesCreateModel?.categories.find((x) => x.name == fCat);
                if (fExt && foundCat && foundCat.allowedExtensions) {
                    if (!foundCat.allowedExtensions.split(',').some((x) => x.toLowerCase() == fExt)) {
                        extErrors.push(
                            `Файл '${f.AttachmentFileName}' не может находиться в категории '${foundCat.name}'`,
                        );
                    }
                }

                if (attachesCreateModel?.restrictions) {
                    attachesCreateModel?.restrictions.forEach((r) => {
                        if (f[r.ifAttachAttrName] == r.ifAttachAttrValue) {
                            //если условие, применять ли проверку - сработало, начинаем саму проверку
                            //Если не совпадает или заданный атрибут или перечень расширений - ругаемся
                            if (
                                (r.checkAttrName && f[r.checkAttrName] != r.checkAttrValue) ||
                                (r.checkExtensions &&
                                    !r.checkExtensions.split(',').some((x) => x.toLowerCase() == fExt)) ||
                                (r.checkFileSizeInBytes && r.checkFileSizeInBytes < binaryFiles[f._fileId].size)
                            ) {
                                extErrors.push(`Файл '${f.AttachmentFileName}' ${r.checkMessage}`);
                            }
                        }
                    });
                }
            });
        }

        if (extErrors.length > 0) {
            setErrorText(extErrors.join('; '));
            return false;
        } else {
            return true;
        }
    };

    const gridRef = useRef<DataGrid>(null);
    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>Присоединить файлы</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<AllClose size="xs" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <div className="AttachFiles__AllowedExtensionsGroup">
                        <p className="AttachFiles__AllowedExtensions">
                            <span>Список допустимых форматов: </span>
                            {attachesCreateModel?.categories && attachesCreateModel?.categories?.length > 0 ? (
                                attachesCreateModel?.categories?.length > 1 ? (
                                    <ul>
                                        {attachesCreateModel?.categories.map((cat) => (
                                            <li key={cat?.name}>
                                                <span>{cat?.name}:</span>
                                                <span>
                                                    {cat?.allowedExtensions
                                                        ? cat?.allowedExtensions
                                                        : 'ограничений нет'}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span>
                                        {attachesCreateModel?.categories[0].allowedExtensions
                                            ? attachesCreateModel?.categories[0].allowedExtensions
                                            : 'ограничений нет'}
                                    </span>
                                )
                            ) : (
                                <span>ограничений нет</span>
                            )}
                        </p>
                    </div>
                    <InputFile
                        className="AttachFiles__SelectFilesInput"
                        setFile={setFile}
                        placeholder="Выбрать файл..."
                        maxSize={MAX_FILE_SIZE}
                        multiple={true}
                        showChips={false}
                        clearFilesAfterSet={true}
                        accept={
                            attachesCreateModel?.categories && attachesCreateModel?.categories?.length > 0
                                ? attachesCreateModel?.categories.find(
                                      (cat) => cat?.allowedExtensions == null || !cat?.allowedExtensions?.trim(),
                                  )
                                    ? '*' // если среди категорий есть категория без ограничений
                                    : getAcceptExtensionString(
                                          attachesCreateModel?.categories
                                              .map((cat) => cat?.allowedExtensions)
                                              .filter((ext) => ext)
                                              .join(','), // конкатенируем допустимые категории
                                      )
                                : '*'
                        }
                    />

                    <DevExpressDataGrid
                        dataSource={uploadingFiles}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Файлы не выбраны'}
                        rowAlternationEnabled={true}
                        ref={gridRef}
                        onRowRemoved={(e) => {
                            let removedFileId = e.key._fileId;
                            let newBinaryFiles = { ...binaryFiles };
                            if (removedFileId && binaryFiles[removedFileId]) {
                                delete newBinaryFiles[removedFileId];
                                setBinaryFiles(newBinaryFiles);
                            }
                            setUploadingFiles([...uploadingFiles]);

                            if (
                                !checkFilesSize(
                                    Object.values<File>(newBinaryFiles),
                                    MAX_FILE_SIZE ? MAX_FILE_SIZE * 1024 : undefined,
                                )
                            ) {
                                setFilesSizeExceeded(true);
                            } else {
                                setFilesSizeExceeded(false);
                            }
                        }}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            allowAdding={false}
                            allowDeleting={true}
                            confirmDelete={false}
                        />

                        {attachesCreateModel?.properties?.map((col, i) => {
                            return (
                                <Column
                                    key={`col_${i}`}
                                    allowFiltering={true}
                                    caption={col.displayName}
                                    dataField={col.key}
                                    dataType={'string'}
                                    visible={true}
                                    allowSorting={false}
                                    filterOperations={['contains']}
                                    showEditorAlways={true}
                                >
                                    {attachesCreateModel?.dictionaries &&
                                        col.dictName &&
                                        attachesCreateModel?.dictionaries[col.dictName] && (
                                            <Lookup
                                                dataSource={attachesCreateModel.dictionaries[col.dictName]}
                                                displayExpr="code"
                                                valueExpr="code"
                                            />
                                        )}
                                </Column>
                            );
                        })}

                        {/*Отображаем столбец с аттачами только когда категорий больше чем одна*/}
                        {(attachesCreateModel?.categories?.length ?? 0) > 1 && (
                            <Column
                                key={'col_category'}
                                allowFiltering={true}
                                caption={'Категория'}
                                dataField={'_category_'}
                                dataType={'string'}
                                visible={true}
                                allowSorting={false}
                                filterOperations={['contains']}
                                showEditorAlways={true}
                            >
                                <Lookup
                                    dataSource={attachesCreateModel?.categories}
                                    displayExpr="name"
                                    valueExpr="name"
                                />
                            </Column>
                        )}
                    </DevExpressDataGrid>

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                disabled={!uploadingFiles || uploadingFiles.length === 0 || filesSizeExceeded}
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    gridRef.current?.instance.saveEditData();
                                    setErrorText(undefined);
                                    if (!checkFilesForRestrictions()) return;

                                    setIsBusy(true);

                                    let filesToUpload = [...uploadingFiles].map((x) => binaryFiles[x._fileId]);
                                    // копируем массив и исключаем свойство _fileId
                                    let attachFilesProps = JSON.parse(JSON.stringify(uploadingFiles)) as any[];
                                    attachFilesProps = attachFilesProps.map((fileProps) => {
                                        delete fileProps._fileId;
                                        return fileProps;
                                    });

                                    let service = new DocumentExecutionService(props.docId);
                                    service
                                        .attachFiles(props.action.key, attachFilesProps, filesToUpload)
                                        .then(() => {
                                            if (props.completeHandler) props.completeHandler(true);
                                        })
                                        .catch((error) => setErrorText(error))
                                        .finally(() => {
                                            setIsBusy(false);
                                        });
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
