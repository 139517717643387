import { ErrorMessage } from '@hookform/error-message';
import React, { ReactElement, ReactNode } from 'react';
import { FieldValues, FormState } from 'react-hook-form';
import FormGroup, { IFormGroup } from '@atoms/FormGroup/FormGroup';

export interface IFormErrorControlProps extends Omit<IFormGroup, 'invalid' | 'errorMessage'> {
    name: string;
    children: ReactNode;
    formState?: FormState<FieldValues>;
}

const FormControl = ({ name, children, formState, ...props }: IFormErrorControlProps) => {
    const { errors = {} } = formState || {};

    const hasErrors = () => {
        if (name.indexOf('.') && errors && Object.keys(errors).length !== 0) {
            let parts = name.split('.');
            let curElm: any = errors;
            parts.forEach((element) => {
                if (curElm !== undefined) {
                    let name = element.replace('[', '').replace(']', '');
                    curElm = curElm[name];
                }
            });
            return !!curElm;
        } else {
            return !!errors[name];
        }
    };

    const invalid = hasErrors();

    return (
        <FormGroup
            invalid={invalid}
            errorMessage={<ErrorMessage errors={errors} name={name} render={({ message }) => message} />}
            {...props}
        >
            {React.cloneElement(children as ReactElement<any>, { invalid })}
        </FormGroup>
    );
};

export default FormControl;
