import ProgressBarCircle from '@/components/atoms/ProgressBarCircle';
import Tooltip from '@/components/atoms/Tooltip';
import React, { FC } from 'react';

interface ISmartBusinessFinancialProfileBarProps {
    type?: 'circle' | 'line' | 'dashboard';
    width?: number;
    title: string;
    format: React.ReactNode;
    percent?: number;
    strokeColor?: string;
    strokeWidth?: number;
}

const SmartBusinessFinancialProfileBar: FC<ISmartBusinessFinancialProfileBarProps> = ({
    title,
    format,
    percent,
    strokeColor,
    width = 40,
    strokeWidth = 5,
}) => {
    return (
        <span>
            <Tooltip>
                <ProgressBarCircle
                    size={width}
                    progress={percent!}
                    trackWidth={strokeWidth}
                    trackColor={`#ddd`}
                    indicatorWidth={5}
                    indicatorColor={strokeColor!}
                    label={format}
                />
                <div>{title}</div>
            </Tooltip>
        </span>
    );
};

export default SmartBusinessFinancialProfileBar;
