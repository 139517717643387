import React, { useEffect, useState } from 'react';
import { VersionService } from '@services/VersionService';
import './VersionPanel.scss';
import { TITLE_PREFIX } from '@/App';

const VersionPanel: React.FC = () => {
    const [version, setVersion] = useState<string>('');
    useEffect(() => {
        VersionService.getVersion().then((v) => {
            setVersion(v.data);
        });
    }, []);

    return (
        <div className="version-container">
            <div>{TITLE_PREFIX}</div>
            <div>{version}</div>
        </div>
    );
};

export default VersionPanel;
