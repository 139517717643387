import { combineReducers, configureStore } from '@reduxjs/toolkit';
import AuthReducer from './reducers/AuthReducer';
import FoldersReducer from './reducers/FoldersReducer';
import SearchReducer from './reducers/SearchReducer';
import DocumentReducer from './reducers/DocumentReducer';
import WidgetsReducer from './reducers/WidgetsReducer';
import ReportReducer from './reducers/ReportReducer';
import MainLayoutReducer from './reducers/MainLayoutReducer';
import DictionaryReducer from './reducers/DictionaryReducer';
import RolesReducer from './reducers/RolesReducer';
import NotificationSettingsReducer from './reducers/NotificationSettingsReducer';
import UserAvatarReducer from '@/store/reducers/UserAvatarReducer';

const rootReducer = combineReducers({
    folders: FoldersReducer,
    mainLayout: MainLayoutReducer,
    auth: AuthReducer,
    search: SearchReducer,
    document: DocumentReducer,
    widgets: WidgetsReducer,
    report: ReportReducer,
    dictionary: DictionaryReducer,
    roles: RolesReducer,
    notificationSettings: NotificationSettingsReducer,
    userAvatar: UserAvatarReducer,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
