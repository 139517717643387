import React, { forwardRef, HTMLProps, ReactNode, useEffect, useMemo, useState } from 'react';
import './Input.scss';
import { AllClose } from '@/indexIcon';
import { IDebounceResult } from '@/types/projects.types';
import { useDebounce } from '@hooks/useDebounce';
import InputMask from '@mona-health/react-input-mask';
import { classnames } from '@utils/classnames';

export interface IInputProps extends Omit<HTMLProps<HTMLInputElement>, 'size' | 'ref'> {
    /** Возможность очистки поля по клику */
    onClear?: () => void;
    /** Дебаунс */
    debounce?: number;
    /** Иконка */
    icon?: ReactNode;
    variant?: 'base' | 'inline';
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /**
     * Добавляет инпуту белый фон
     * @default true
     */
    filled?: boolean;
    /** Контент для вставки в начало инпута */
    startAdornment?: ReactNode;
    /** Контент для вставки в конец инпута */
    endAdornment?: ReactNode;
    /** обработка нажатий с эффектом debounce */
    onDebounce?: (result: IDebounceResult) => void;
    onValueChange?: (value: any) => void;
    onInputClick?: (e: any) => void;
    /** ref контейнера инпута */
    // ref?: Ref<HTMLLabelElement>;
    /**
     * Добавить рамку
     * @default true
     *  */
    isBorder?: boolean;
    /**
     * Проверять ввод в соответствии с регулярным выражением
     * @example Для проверки на отсутствие спецсимволов в строке можно использовать `'^[\da-zA-Zа-яА-Я]*$'`
     */
    pattern?: string;
    mask?: string;
    withoutClasses?: boolean;
}

const Input = forwardRef<HTMLInputElement | null, IInputProps>(
    (
        {
            className,
            onClear,
            debounce = 300,
            icon,
            variant = 'base',
            startAdornment,
            endAdornment,
            disabled,
            readOnly,
            invalid,
            filled = true,
            onFocus,
            onBlur,
            onChange,
            onDebounce,
            onValueChange,
            isBorder = true,
            pattern,
            mask,
            defaultValue,
            value,
            onInputClick,
            withoutClasses = false,
            ...props
        }: IInputProps,
        ref,
    ) =>
        // ref
        {
            /** Значение поля */
            const [internalValue, setInternalValue] = useState<string>(
                defaultValue?.toString() || value?.toString() || '',
            );
            /** Находится ли инпут в состоянии фокуса */
            const [isFocused, setFocused] = useState(false);

            // Регулярное выражение для проверки ввода
            const regexp = useMemo(() => {
                if (pattern) {
                    return new RegExp(pattern);
                }

                return null;
            }, [pattern]);

            useEffect(() => {
                // Controlled component
                if (value !== undefined && value !== null) {
                    setInternalValue(value?.toString() || '');
                }
            }, [value]);
            // useEffect(() => {
            //     if (onValueChange) {
            //         onValueChange(internalValue);
            //     }
            // }, [isFocused]);
            // ------------------------------------------------------------------------------------------------------------------

            useDebounce(internalValue, debounce, (v) => {
                if (onDebounce) {
                    onDebounce({ debounceString: v.toString() });
                }
            });

            // ------------------------------------------------------------------------------------------------------------------
            /** Очистка поля ввода и сброс результатов поиска */
            const clearInput = () => {
                setInternalValue('');
                onDebounce && onDebounce({ debounceString: '' });
                onClear && onClear();
            };

            /** Кнопка поиска и сброса */
            const closeButton = onClear && internalValue.length > 0 && (
                <button type="button" className="rf-input__action" onClick={clearInput} aria-label="Сбросить">
                    <AllClose />
                </button>
            );

            // ------------------------------------------------------------------------------------------------------------------

            const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
                if (readOnly == undefined || readOnly == false) {
                    setFocused(true);

                    if (onFocus) {
                        onFocus(event);
                    }
                }
            };

            const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
                if (readOnly == undefined || readOnly == false) {
                    setFocused(false);
                    if (onValueChange) {
                        onValueChange(internalValue);
                    }
                    if (onBlur) {
                        onBlur(event);
                    }
                }
            };

            const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                if (readOnly || (regexp && !regexp.test(event.target.value))) {
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                }

                // Uncontrolled component
                if (value === undefined || value === null || value === '') {
                    setInternalValue(event.target.value);
                }

                if (onChange) {
                    onChange(event);
                }

                // if(onValueChange){
                //     onValueChange(event.target.value);
                // }
            };

            const onMouseOver = (e: any) => {
                // установливаем тултип, если значение в контроле переполнено
                if (e.target.clientWidth < e.target.scrollWidth || e.target.clientHeight < e.target.scrollHeight) {
                    e.target.setAttribute('title', e.target.value);
                } else {
                    e.target.removeAttribute('title');
                }
            };

            // ------------------------------------------------------------------------------------------------------------------

            // Делаем проверку на className для обратной совместимости.
            const isInvalid = invalid || (className && className.indexOf('invalid') !== -1);

            return (
                <div
                    //  ref={ref}
                    className={classnames({
                        'rf-input': true,
                        'rf-input--inline': variant === 'inline',
                        'rf-input--disabled': disabled,
                        'rf-input--readonly': readOnly && !withoutClasses,
                        'rf-input--focused': isFocused && !withoutClasses,
                        'rf-input--invalid': isInvalid,
                        'rf-input--filled': filled,
                        'rf-input--non-border': !isBorder,
                        className: !!className,
                    })}
                >
                    {!!startAdornment && (
                        <div className="rf-input__adornment rf-input__adornment--start">{startAdornment}</div>
                    )}
                    {mask ? (
                        <InputMask
                            //{...props}
                            value={value === undefined || value === null || value === '' ? internalValue : value}
                            // ref={inputRef}
                            className={'rf-input__field'}
                            type={props.type || 'text'}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={onInputChange}
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                            onClick={onInputClick}
                            mask={mask!}
                        />
                    ) : (
                        <input
                            {...props}
                            value={value === undefined || value === null ? internalValue : value}
                            ref={ref}
                            className={'rf-input__field'}
                            type={props.type || 'text'}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={onInputChange}
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                            onClick={onInputClick}
                            onMouseOver={onMouseOver}
                            // pattern={pattern}
                        />
                    )}

                    {!!endAdornment && (
                        <div className="rf-input__adornment rf-input__adornment--end">{endAdornment}</div>
                    )}
                    {icon ? (
                        <button type="button" className="rf-input__action">
                            {icon}
                        </button>
                    ) : (
                        closeButton
                    )}
                </div>
            );
        },
);

export default Input;
