import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import './TableData.scss';
import { IDictFilter, IDocumentTable, IFormValues } from '@models/Forms/IForms';
import { v4 as uuidv4 } from 'uuid';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    FilterRow,
    KeyboardNavigation,
    MasterDetail,
    Paging,
    RowDragging,
    Scrolling,
    Selection,
    Sorting,
} from 'devextreme-react/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DevExpressDataGrid from '../DevExpress/DataGrid/DevExpressDataGrid';
import { Toolbar } from 'devextreme-react/ui/toolbar';
import EditCellRenderSwitcher from './EditCellRenderSwitcher';
import { FormulaManager } from '@utils/FormulaManager';
import { AllAdd, StatusInfoOutline } from '@/indexIcon';
import { useWatch } from 'react-hook-form';
import Button from '../Button';
import RowEditButton from './RowEditButton';
import { IField } from '@models/IFormData';
import { IValidHandle } from '@models/IValidHandle';
import RowCopyButton from './RowCopyButton';
import ViewCellRenderSwitcher from './ViewCellRenderSwitcher';
import Tooltip from '../Tooltip';
import { classnames } from '@utils/classnames';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { deepEqual, getDiffObj, hashCode } from '@utils/helpers';
import { sendNotification } from '@molecules/Notifications';
import EditRowMultiButton from './EditRowMultiButton';
import Menu from '../Menu';
import { IListElement } from '@/types';
import DictpickerModal from '../Dictpicker/DictpickerModal/DictpickerModal';
import { IDictionaryData } from '@/models/dictionary/IDictionaryData';
import OpenDocButton from './OpenDocButton';

export interface ITableDataProps {
    value?: any[];
    table: IDocumentTable;
    allowUpdating: boolean;
    showScrollbar?: boolean;
    name: string;
    docId?: string;
    calculateRow: (row: any, column: any, table: IDocumentTable) => Promise<any>;
    onChangeCellValue: (row: any, column: any, table: IDocumentTable) => Promise<any>;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    editCellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    evalTableFormulaValue: (condition: string, rowData?: any, rowParent?: any) => Promise<boolean>;
    onInitNewRow?: (row: any, table: IDocumentTable) => Promise<void>;
    onChanged?: (e: any[], valid: boolean) => Promise<void>;
    getColumnWatches: (table?: IDocumentTable, rowParent?: any) => string[];
    getWatchesByFormula: (formula?: string, rowParent?: any) => string[];
    getParentFields: () => IField[];
    setParentField: (field: IField) => void;
    getFormValuesAsync?: (formdataParams: IFormValues) => Promise<Record<string, any>>;
    getFiltersAsync?: () => Promise<IDictFilter>;
    onSetFormDataNewRow?: (item: any, table: IDocumentTable, data: IDictionaryData) => Promise<void>;
}

const TableData = forwardRef<IValidHandle, ITableDataProps>(
    (
        {
            value,
            table,
            name,
            allowUpdating,
            showScrollbar = true,
            calculateRow,
            onChangeCellValue,
            cellRenderSwitcher,
            editCellRenderSwitcher,
            evalTableFormulaValue,
            onInitNewRow,
            onChanged,
            getColumnWatches,
            getWatchesByFormula,
            getParentFields,
            setParentField,
            docId,
            getFormValuesAsync,
            getFiltersAsync,
            onSetFormDataNewRow,
        }: ITableDataProps,
        ref,
    ) => {
        const changesStack = React.useRef<any[]>([]);
        useImperativeHandle(ref, () => ({
            valid() {
                let result = isValid.current;
                for (let tableName in detailsTablesRef.current) {
                    const tableLoc = detailsTablesRef.current[tableName];
                    if (tableLoc) {
                        result = result && tableLoc.valid();
                    }
                }
                return result;
            },
            getData() {
                let store = dataSource.store() as any;
                let items = store._array;
                return items;
            },
            setData(data: any[]) {
                if (changesStack.current.indexOf(data) === -1) {
                    changesStack.current.push(data);
                }
                if (changesStack.current.length == 1) {
                    updateStore(data);
                }
            },
        }));
        const detailsTables: { [id: string]: IDocumentTable } = {};
        const activated = React.useRef(false);
        const gridRef = useRef<DataGrid>(null);
        const renderMasterDetail = (tables: IDocumentTable[]) => {
            tables.forEach((table) => {
                detailsTables[table.key] = table;
            });
            return <MasterDetail key={table.key} enabled={true} render={renderDetailTable} />;
        };

        const [columns, setColumns] = useState<JSX.Element[]>();
        const valuesSubTables: { [id: string]: any } = {};
        const [requiredColumnKeys, setRequiredColumnKeys] = useState<{ [id: string]: string }>({});
        const [canAddRows, setCanAddRows] = useState<boolean>(true);
        const [showAddExternalRows, setShowAddExternalRows] = useState<boolean>(false);
        const [canRemoveRows, setCanRemoveRows] = useState<boolean>(true);
        const [hasEditRowMulti, setHaseditRowMulti] = useState<boolean>(false);
        const isValid = React.useRef(true);

        const detailsTablesRef = React.useRef<Record<string, IValidHandle>>({});

        const InitColumns = async () => {
            let result: JSX.Element[] = [];
            let reqKeys: { [id: string]: string } = {};
            for (let index = 0; index < table.tableColumn.length; index++) {
                const column = table.tableColumn[index];
                if (column.editRowMulti && !hasEditRowMulti) {
                    setHaseditRowMulti(true);
                }
                let vis = column.hidden || (await checkVisRules(column));
                if (vis) {
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }

                    result.push(await renderColumnGrid(column, `table_grid_${table.key}`));
                }
            }
            for (let index = 0; index < table.tableColumnDict.length; index++) {
                const column = table.tableColumnDict[index];
                if (column.editRowMulti && !hasEditRowMulti) {
                    setHaseditRowMulti(true);
                }
                let vis = await checkVisRules(column);
                if (vis) {
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }
                    result.push(await renderColumnGrid(column, `table_dictgrid_${table.key}`));
                }
            }
            for (let index = 0; index < table.tableColumnAbook.length; index++) {
                const column = table.tableColumnAbook[index];
                if (column.editRowMulti && !hasEditRowMulti) {
                    setHaseditRowMulti(true);
                }
                let vis = await checkVisRules(column);
                if (vis) {
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }
                    result.push(await renderColumnGrid(column, `table_abookgrid_${table.key}`));
                }
            }
            for (let index = 0; index < table.tableColumnCalc.length; index++) {
                const column = table.tableColumnCalc[index];
                if (column.editRowMulti && !hasEditRowMulti) {
                    setHaseditRowMulti(true);
                }
                let vis = await checkVisRules(column);
                if (vis) {
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }
                    result.push(await renderColumnGrid(column, `table_calcgrid_${table.key}`));
                }
            }
            for (let index = 0; index < table.tableColumnAutoComplete.length; index++) {
                const column = table.tableColumnAutoComplete[index];
                if (column.editRowMulti && !hasEditRowMulti) {
                    setHaseditRowMulti(true);
                }
                let vis = await checkVisRules(column);
                if (vis) {
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }
                    result.push(await renderColumnGrid(column, `table_autocompletegrid_${table.key}`));
                }
            }

            if (activated.current) {
                setColumns(result);
                setRequiredColumnKeys(reqKeys);
            }
            return null;
        };

        const InitCanAddRows = async () => {
            if (table?.addRowButtonRules && evalTableFormulaValue) {
                let res = await evalTableFormulaValue(table?.addRowButtonRules);
                setCanAddRows(res);
            }
        };

        const InitCanRemoveRows = async () => {
            if (table?.removeRowButtonRules && evalTableFormulaValue) {
                let res = await evalTableFormulaValue(table?.removeRowButtonRules);
                setCanRemoveRows(res);
            }
        };
        const watchesRemoveRows = useWatch({
            name: getWatchesByFormula(table?.removeRowButtonRules),
        });
        useEffect(() => {
            InitCanRemoveRows();
        }, [watchesRemoveRows]);

        const watches = useWatch({
            name: getColumnWatches(table),
        });

        const watchesAddRows = useWatch({
            name: getWatchesByFormula(table?.addRowButtonRules),
        });

        useEffect(() => {
            InitCanAddRows();
        }, [watchesAddRows]);

        useEffect(() => {
            InitCanAddRows();
            activated.current = true;
            InitColumns();
            return () => {
                activated.current = false;
            };
        }, []);

        useEffect(() => {
            if (watches) {
                if (!watches.every((element) => element === undefined)) {
                    InitColumns();
                }
            }
        }, [watches]);

        useEffect(() => {
            if (value) {
                calcDefValue(value);
                checkAndSetIsValid(value);
            }
        }, [value, name]);

        useEffect(() => {
            if (dataSource) {
                let store = dataSource.store() as any;
                let items = store._array;
                let arr: any[] = [];
                items.forEach((el: any) => {
                    arr.push(el);
                });
                if (arr.length > 0) {
                    checkAndSetIsValid(arr);
                }
            }
        }, [requiredColumnKeys]);

        const renderDetailTable = (param: any) => {
            let keys = Object.keys(detailsTables);

            return keys.map((key, i) => {
                let subTable = detailsTables[key];
                let val = param.data[key] ? param.data[key] : [];
                let saved = valuesSubTables[name + subTable.key + param.key];
                if (saved === undefined || !deepEqual(val, saved)) {
                    valuesSubTables[name + subTable.key + param.key] = val;
                } else {
                    val = saved;
                }
                let uniq = keys.length > 1 ? new Date().getTime() : '';
                console.log(name + subTable.key + param.key);
                return (
                    <TableData
                        key={name + subTable.key + param.key}
                        name={name + subTable.key + param.key + uniq}
                        ref={(element) => (detailsTablesRef.current[name + subTable.key + param.key] = element!)}
                        docId={docId}
                        table={subTable}
                        value={val}
                        getParentFields={getParentFields}
                        setParentField={setParentField}
                        allowUpdating={allowUpdating}
                        evalTableFormulaValue={async (rules: string, rowData?: any, rowParent?: any) => {
                            return await evalTableFormulaValue(rules, rowData, param.data);
                        }}
                        calculateRow={calculateRow}
                        onInitNewRow={onInitNewRow}
                        showScrollbar={false}
                        onChangeCellValue={onChangeCellValue}
                        cellRenderSwitcher={async (p: any, column: any, rowParent?: any) => {
                            return await cellRenderSwitcher(p, column, param.data);
                        }}
                        editCellRenderSwitcher={async (p: any, column: any, rowParent?: any) => {
                            return await editCellRenderSwitcher(p, column, param.data);
                        }}
                        getColumnWatches={(table?: IDocumentTable, rowParent?: any) => {
                            return getColumnWatches(table, param.data);
                        }}
                        getWatchesByFormula={(formula?: string, rowParent?: any) => {
                            return getWatchesByFormula(formula, param.data);
                        }}
                        onChanged={async (e, valid) => {
                            let store = dataSource.store() as any;
                            let objCopy = { ...param.data };

                            objCopy[key] = e;
                            objCopy = await calculateRow(objCopy, undefined, table);

                            valuesSubTables[name + subTable.key + param.key] = e;
                            store.push([{ type: 'update', data: objCopy, key: param.key }]);
                            onSaved(valid);
                        }}
                    />
                );
            });
        };
        const dataSource = useMemo(() => {
            return new DataSource({
                pushAggregationTimeout: 100,
                reshapeOnPush: true,
                store: new ArrayStore({
                    key: '|NUM',
                }),
                onChanged: () => {
                    invokeNextChanges();
                },
            });
        }, [name]);

        const invokeNextChanges = () => {
            changesStack.current.shift();
            if (changesStack.current.length > 0) {
                updateStore(changesStack.current[0]);
            }
        };

        const updateStore = (data: any[]) => {
            let store = dataSource.store();
            let key = store.key() as string;
            let items = [...(store as any)._array];
            let changes: Array<{
                type: 'insert' | 'update' | 'remove';
                data?: any;
                key?: any;
                index?: number;
            }> = [];
            let promiseColl: Promise<any>[] = [];
            if (data.length === 0) {
                (store as ArrayStore).clear();
            } else {
                data.forEach((element: any) => {
                    let keyVal = element[key];

                    store.byKey(keyVal).then(
                        (e: any) => {
                            let diffObj = getDiffObj(e, element);
                            let keyColl = Object.keys(diffObj);

                            keyColl.forEach((propKey) => {
                                let subtable = detailsTablesRef.current[name + propKey + keyVal];
                                let obj = (diffObj as any)[propKey];
                                if (subtable && obj) {
                                    subtable.setData(obj);
                                }
                            });

                            if (keyColl.length > 0) {
                                changes.push({ type: 'update', data: diffObj, key: keyVal });
                            }
                        },
                        () => {
                            promiseColl.push(store.insert(element));
                        },
                    );
                });
                items.forEach((item: any) => {
                    let index = data.findIndex((d) => d[key] === item[key]);
                    if (index === -1) {
                        promiseColl.push(store.remove(item[key]));
                    }
                });
            }
            if (changes.length > 0 || promiseColl.length > 0) {
                if (promiseColl.length > 0) {
                    Promise.all(promiseColl).then((values) => {
                        dataSource.reload().then(() => {
                            if (changes.length > 0) {
                                store.push(changes);
                            } else {
                                invokeNextChanges();
                            }
                        });
                    });
                } else {
                    if (changes.length > 0) {
                        store.push(changes);
                    }
                }
            } else {
                invokeNextChanges();
            }
        };

        const renderEditInFormActColumn = (table: IDocumentTable) => {
            return (
                <Column
                    key={`editInForm`}
                    width="36px"
                    //fixed={true}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        return (
                            <RowEditButton
                                getParentFields={getParentFields}
                                table={table}
                                docId={docId}
                                rowData={p.data}
                                displayFormula={table.editInFormFormula}
                                onSubmit={(data) => {
                                    onSubmit(data, p.data);
                                }}
                            />
                        );
                    }}
                />
            );
        };
        const renderCopyRowColumn = (table: IDocumentTable) => {
            return (
                <Column
                    key={`copyRow`}
                    width="36px"
                    // fixed={true}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        return (
                            <RowCopyButton
                                table={table}
                                docId={docId}
                                rowData={p.data}
                                onCopy={(data) => {
                                    onCopy(data);
                                }}
                            />
                        );
                    }}
                />
            );
        };

        const saveEditRowMulti = async (data: IField[], column: any) => {
            let keys = gridRef.current?.instance.getSelectedRowKeys();
            let store = dataSource.store();
            if (keys && keys?.length > 0) {
                let changes: Array<{
                    type: 'insert' | 'update' | 'remove';
                    data?: any;
                    key?: any;
                    index?: number;
                }> = [];

                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];

                    await store.byKey(key).then(
                        async (e: any) => {
                            let objCopy = { ...e };
                            data.forEach((fields: IField) => {
                                objCopy[fields.name] = fields.value;
                            });

                            await onChangeCellValue(objCopy, column, table).then(async () => {
                                await calculateRow(objCopy, column, table).then((data) => {
                                    objCopy = data;
                                });
                            });

                            let diffObj = getDiffObj(e, objCopy);
                            if (Object.keys(diffObj).length > 0) {
                                changes.push({ type: 'update', data: diffObj, key: key });
                            }
                        },
                        () => {
                            changes.push({
                                type: 'update',
                                data: {
                                    [data[0].name]: data[0].value,
                                },
                                key: key,
                            });
                        },
                    );
                }

                store.push(changes);
                onSaved();
            }
        };
        const canEditRowMulti = () => {
            let keys = gridRef.current?.instance.getSelectedRowKeys();
            if (keys && keys?.length > 0) {
                return true;
            } else {
                sendNotification({
                    message: 'Необходимо выбрать минимум одну строку ',
                    variant: 'red',
                });
            }
            return false;
        };

        const renderColumnGrid = async (column: any, path: string) => {
            return (
                <Column
                    key={`column${path}${column.key}`}
                    dataField={column.key}
                    caption={column.name}
                    width={column.width}
                    visible={!column.hidden}
                    minWidth={
                        // Если ширина в vw, то задаем мин ширину 5px
                        // У грида есть баг, он сравнивает width и minWidth только по цифре, без учета единиц
                        column.width ? (column.width.toString().endsWith('vw') ? 5 : undefined) : undefined
                    }
                    sortIndex={column.sortIndex}
                    sortOrder={column.sortOrder}
                    visibleIndex={column.order}
                    encodeHtml={true}
                    alignment={column.alignment}
                    headerCellRender={(p) => {
                        return (
                            <div className="title-column-box">
                                <div
                                    className={classnames(
                                        'title-column-caption',
                                        column.headerNoEllipsis && 'title-column-caption-noEllipsis',
                                    )}
                                >
                                    {p.column.caption}
                                </div>
                                {column.title && (
                                    <div className="title-column-title">
                                        <Tooltip openDelay={100} background="black" position="bottom">
                                            <StatusInfoOutline size="xxxs" />
                                            {column.title}
                                        </Tooltip>
                                    </div>
                                )}
                                {column.editRowMulti && (
                                    <div className="title-column-title">
                                        <EditRowMultiButton
                                            getParentFields={getParentFields}
                                            column={column}
                                            canEditRowMulti={canEditRowMulti}
                                            onSubmit={async (data: IField[]) => {
                                                await saveEditRowMulti(data, column);
                                            }}
                                            table={table}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    }}
                    cellRender={(p) => {
                        return (
                            <ViewCellRenderSwitcher data={p} column={column} cellRenderSwitcher={cellRenderSwitcher} />
                        );
                    }}
                    editCellComponent={(e: any) => {
                        return (
                            <EditCellRenderSwitcher
                                data={e.data}
                                column={column}
                                editCellRenderSwitcher={editCellRenderSwitcher}
                            />
                        );
                    }}
                ></Column>
            );
        };

        const calcDefValue = async (data: any[]) => {
            let store = dataSource.store() as any;
            let items = store._array;
            let arr: any[] = [];
            items.forEach((el: any) => {
                arr.push(el);
            });
            if (!deepEqual(value, arr)) {
                store.clear();
                if (data) {
                    for (let index = 0; index < data.length; index++) {
                        const obj = data[index];
                        let objCopy = { ...obj };
                        store.insert(objCopy);
                    }
                }
            }
        };

        const checkAndSetIsValid = async (data: any[]) => {
            let keys = Object.keys(requiredColumnKeys);
            if (keys && keys.length > 0) {
                isValid.current = await checkValidDataSource(data);
                console.log('checkAndSetIsValid: ', isValid.current);
            }
        };

        const onSubmit = async (data: IField[], row: any) => {
            for (let index = 0; index < data.length; index++) {
                const field = data[index];
                if (field.name.includes('|Document')) {
                    setParentField(field);
                } else {
                    row[field.name] = field.value;
                }
            }
            let dataGrid = gridRef.current?.instance;
            await dataGrid?.saveEditData();
        };

        const onCopy = async (rowData: any) => {
            let dataGrid = gridRef.current?.instance;
            let store = dataSource.store() as ArrayStore;
            let item = { ...rowData };
            item['|NUM'] = uuidv4();
            await store.insert(item);
            await dataGrid?.refresh();

            await onSaved();
        };

        const checkVisRules = async (column: any) => {
            return (
                column.visibilityRules == undefined ||
                column.visibilityRules == null ||
                (column.visibilityRules && (await evalTableFormulaValue(column.visibilityRules)))
            );
        };

        const checkValidDataSource = async (e: any) => {
            let coll = e as any[];
            let rowsCount = coll.length;

            let keys = Object.keys(requiredColumnKeys);
            for (let indexCol = 0; indexCol < keys.length; indexCol++) {
                for (let index = 0; index < rowsCount; index++) {
                    let key = keys[indexCol];
                    let rule = requiredColumnKeys[key];
                    let row = coll[index];
                    let req = rule === 'true' ? true : await evalTableFormulaValue(rule, row);
                    if (req) {
                        let val = row[key];
                        if (val === undefined || val === null || val === '') {
                            return false;
                        }
                    }
                }
            }
            return true;
        };

        let singleRowDeleteMng = new FormulaManager(table?.singleRowDeleteButtonRules!);

        const _listMenuAddRow: IListElement[] = [
            {
                value: '1',
                label: 'Добавить строку',
                handler: () => addRow(),
            },
            {
                value: '2',
                label: table?.addFormDataRows?.name,
                handler: () => {
                    setShowAddExternalRows(true);
                },
            },
        ];

        const _listMenuAddFormDataRow: IListElement[] = [
            {
                value: '2',
                label: table?.addFormDataRows?.name,
                handler: () => {
                    setShowAddExternalRows(true);
                },
            },
        ];

        const addExternalRows = async (data: IDictionaryData[]) => {
            let dataGrid = gridRef.current?.instance;
            let store = dataSource.store() as ArrayStore;
            for (let index = 0; index < data.length; index++) {
                let item: any = {};
                item['|NUM'] = uuidv4();
                const select = data[index];
                onSetFormDataNewRow && (await onSetFormDataNewRow(item, table, select));
                await store.insert(item);
            }

            await dataGrid?.refresh();

            await onSaved();
        };

        const addRow = async () => {
            let dataGrid = gridRef.current?.instance;
            let store = dataSource.store() as ArrayStore;
            let item: any = {};
            item['|NUM'] = uuidv4();
            if (onInitNewRow) {
                await onInitNewRow(item, table);
            }
            await store.insert(item);
            await dataGrid?.refresh();
            if (table.addRowFocusColumn) {
                let index = await store.totalCount({});
                dataGrid?.editCell(index - 1, table.addRowFocusColumn);
            }
            await onSaved();
        };

        const getSelectedRowElements = (keys: any[]) => {
            let arr: Element[] = [],
                idx: number,
                el: Element;
            if (gridRef.current) {
                let dataGrid = gridRef.current.instance;
                keys.forEach((key) => {
                    idx = dataGrid.getRowIndexByKey(key);
                    let row = dataGrid.getRowElement(idx);
                    if (row) {
                        el = row[0];
                    }
                    arr.push(el);
                });
            }
            return arr;
        };

        const removeRows = (items: any, rowsData: any) => {
            let store = dataSource.store();
            let key = store.key() as string;

            rowsData.forEach((row: any) => {
                const index = items.findIndex((item: any) => {
                    return item[key] === row[key];
                });

                if (index >= 0) {
                    items.splice(index, 1);
                }
            });
        };

        const addRowsToIdx = (items: any, rowsData: any, currIndexRow: any, toIndex: number) => {
            let store = dataSource.store();
            let key = store.key() as string;

            if (toIndex >= 0) {
                toIndex = currIndexRow
                    ? items.findIndex((item: any) => {
                          return item[key] === currIndexRow[key];
                      }) + 1
                    : toIndex;
                items.splice.apply(items, [toIndex, 0].concat(rowsData));
            }
        };

        const onSaved = async (validExternal?: boolean, needSort?: boolean) => {
            var sortArr: string[] = [];
            var columns = gridRef.current?.instance.state().columns;
            columns.forEach((col: any) => {
                if (col.sortIndex != null) {
                    sortArr[col.sortIndex] = col.dataField;
                }
            });

            let store = dataSource.store() as any;
            let items = store._array;
            let arr: any[] = [];
            items.forEach((el: any) => {
                arr.push(el);
            });
            if (needSort === undefined || (needSort !== undefined && needSort === true)) {
                if (sortArr.length > 0) {
                    sortArr.forEach((sort) => {
                        arr.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
                    });
                }
            }
            let valid = await checkValidDataSource(arr);
            let resultValid = validExternal !== undefined ? validExternal && valid : valid;
            console.log('onSaved: ', resultValid);

            isValid.current = resultValid;
            onChanged && (await onChanged(arr, resultValid));
        };

        const keyHash = useMemo(() => {
            return hashCode(table.key);
        }, [table.key]);

        const renderOpenDocActColumn = (table: IDocumentTable) => {
            return (
                <Column
                    key={`openDoc`}
                    width="36px"
                    fixed={false}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        return <OpenDocButton table={table} rowData={p.data} docId={docId} />;
                    }}
                />
            );
        };

        return columns ? (
            <div className="form-table-edit" data-testid={table.id ? `table-edit-${table.id}` : undefined}>
                <div className={classnames('form-table-edit-button', !showScrollbar && 'minW30')}>
                    {table?.addFormDataRows?.name ? (
                        <>
                            <Menu list={canAddRows ? _listMenuAddRow : _listMenuAddFormDataRow} position="top-start">
                                <div className="widgets-menu">
                                    <Button
                                        size="s"
                                        buttonType={'icon'}
                                        textColor="neutral"
                                        startAdornment={<AllAdd />}
                                        aria-label="Добавить строку"
                                    ></Button>
                                </div>
                            </Menu>
                        </>
                    ) : canAddRows ? (
                        <Button
                            size="s"
                            buttonType={'icon'}
                            textColor="neutral"
                            onClick={addRow}
                            startAdornment={<AllAdd />}
                            aria-label="Добавить строку"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="form-table-edit-content">
                    <DevExpressDataGrid
                        ref={gridRef}
                        id={`form-table-edit-${keyHash}`}
                        columnMinWidth={30}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        showBorders={true}
                        dataSource={dataSource}
                        onCellHoverChanged={onCellHoverChanged}
                        repaintChangesOnly={true}
                        remoteOperations={false}
                        cacheEnabled={true}
                        onRowUpdating={(e) => {
                            let keysNew = Object.keys(e.newData);
                            let visColumns = e.component.getVisibleColumns();
                            let col = visColumns.filter((col) => {
                                return col.dataField === keysNew[0];
                            });
                            if (keysNew.length > 0 && col.length > 0 && col[0].caption) {
                                let caption = col[0].caption;
                                let column: any = undefined;
                                let cols = table.tableColumn.filter((col) => {
                                    return col.key === keysNew[0] && col.name === caption;
                                });
                                column = cols.length > 0 ? cols[0] : undefined;
                                if (column == undefined) {
                                    let cols = table.tableColumnAbook.filter((col) => {
                                        return col.key === keysNew[0] && col.name === caption;
                                    });
                                    column = cols.length > 0 ? cols[0] : undefined;
                                }
                                if (column == undefined) {
                                    let cols = table.tableColumnCalc.filter((col) => {
                                        return col.key === keysNew[0] && col.name === caption;
                                    });
                                    column = cols.length > 0 ? cols[0] : undefined;
                                }
                                if (column == undefined) {
                                    let cols = table.tableColumnDict.filter((col) => {
                                        return col.key === keysNew[0] && col.name === caption;
                                    });
                                    column = cols.length > 0 ? cols[0] : undefined;
                                }
                                if (column == undefined) {
                                    let cols = table.tableColumnAutoComplete.filter((col) => {
                                        return col.key === keysNew[0] && col.name === caption;
                                    });
                                    column = cols.length > 0 ? cols[0] : undefined;
                                }

                                let objCopy = { ...e.oldData };
                                keysNew.forEach((key) => {
                                    type ObjectKey = keyof typeof objCopy;
                                    const attrNAme = key as ObjectKey;
                                    objCopy[attrNAme] = e.newData[key];
                                });
                                e.newData = objCopy;
                                e.cancel = onChangeCellValue(e.newData, column, table).then(async () => {
                                    await calculateRow(objCopy, column, table).then((data) => {
                                        e.newData = data;
                                    });
                                });
                            }
                        }}
                        onSaved={(e) => {
                            onSaved();
                        }}
                    >
                        <Editing
                            mode="cell"
                            newRowPosition="last"
                            refreshMode="repaint"
                            allowUpdating={allowUpdating}
                            allowAdding={false}
                            allowDeleting={(r: any) => {
                                //функция, которая определяет возможность удаления каждой конкретной строки
                                if (table?.singleRowDeleteButtonRules) {
                                    let formula: string = table?.singleRowDeleteButtonRules;
                                    for (const p in r.row.data) {
                                        let valToSubst = singleRowDeleteMng.formatFieldValueByType(r.row.data[p]);
                                        formula = formula.replace(`{${p}}`, valToSubst!);
                                    }

                                    //надо удалить все подстановки если не смогли подставить
                                    formula = formula.replace(/{.*?}/g, "''");

                                    let res = eval(formula);
                                    return res;
                                }
                                return canRemoveRows;
                            }}
                        />
                        <ColumnChooser enabled={false} />
                        <Toolbar visible={false} />
                        <KeyboardNavigation enterKeyDirection={'row'} enterKeyAction={'startEdit'} />
                        <Scrolling useNative={true} />
                        <Sorting mode="multiple" />
                        <Paging defaultPageSize={table.pageSize && table.pageSize > 0 ? table.pageSize : 20} />
                        <FilterRow showOperationChooser={true} visible={table.allowFiltersRow} />
                        {/* доп проверка на false чтобы вообще не рендерить столбец и не считать формулы из-за этого */}
                        {allowUpdating &&
                            table.editInFormFormula &&
                            table.editInFormFormula != 'false' &&
                            renderEditInFormActColumn(table)}
                        {table.previewDocByKey && renderOpenDocActColumn(table)}
                        {allowUpdating && table.copyRow && renderCopyRowColumn(table)}
                        {table.rowDragging && (
                            <RowDragging
                                autoScroll={true}
                                allowDropInsideItem={false}
                                dragDirection={'vertical'}
                                boundary={`#form-table-edit-${keyHash}`}
                                allowReordering={true}
                                onReorder={async (e: any) => {
                                    let ds = e.component.getDataSource();
                                    var sortArr: string[] = [];
                                    var columns = gridRef.current?.instance.state().columns;
                                    columns.forEach((col: any) => {
                                        if (col.sortIndex != null) {
                                            sortArr[col.sortIndex] = col.dataField;
                                        }
                                    });
                                    let store = ds.store() as any;
                                    let itemsColl = store._array;

                                    let items: any[] = [];
                                    itemsColl.forEach((el: any) => {
                                        items.push(el);
                                    });
                                    if (sortArr.length > 0) {
                                        sortArr.forEach((sort) => {
                                            items.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
                                        });
                                    }

                                    let toIndex = e.toIndex,
                                        fromIndex = e.fromIndex,
                                        selectedRowsData = e.component.getSelectedRowsData();

                                    if (fromIndex === toIndex) {
                                        return;
                                    }
                                    let currIndexRow: any;
                                    if (toIndex > fromIndex) {
                                        currIndexRow = items[toIndex];
                                    }

                                    if (selectedRowsData.length >= 1) {
                                        removeRows(items, selectedRowsData);
                                        addRowsToIdx(items, selectedRowsData, currIndexRow, toIndex);
                                    } else {
                                        items.splice(fromIndex, 1);
                                        items.splice(toIndex, 0, e.itemData);
                                    }

                                    store = ds.store() as ArrayStore;
                                    store.clear();
                                    items.forEach((item: any) => {
                                        store.insert(item);
                                    });
                                    //ds.reload();
                                    await onSaved(undefined, false);
                                    e.component.deselectAll();
                                }}
                                onDragStart={(e: any) => {
                                    let selectedRowKeys = e.component.getSelectedRowKeys(),
                                        selectedRowElements = getSelectedRowElements(selectedRowKeys),
                                        numSelected = selectedRowKeys.length;

                                    e.component._selectedRowElements = selectedRowElements;

                                    selectedRowElements.forEach((rowEl) => {
                                        rowEl.classList.add('dx-sortable-source');
                                    });
                                }}
                                onDragEnd={(e: any) => {
                                    e.component._selectedRowElements.forEach((rowEl: any) => {
                                        rowEl.classList.remove('dx-sortable-source');
                                    });
                                }}
                                showDragIcons={true}
                                dropFeedbackMode={'push'}
                            />
                        )}
                        {table.rowDragging && <Selection mode="multiple" showCheckBoxesMode={false} />}
                        {hasEditRowMulti && <Selection mode="multiple" showCheckBoxesMode={'always'} />}

                        <Column
                            type="buttons"
                            //fixed={true}
                            fixedPosition="left"
                            allowResizing={false}
                            allowHiding={false}
                            allowReordering={false}
                            visibleIndex={0}
                            visible={canRemoveRows || table?.singleRowDeleteButtonRules !== null}
                            width="36px"
                        />
                        {columns}
                        {table.tables && table.tables.length > 0 && renderMasterDetail(table.tables)}
                    </DevExpressDataGrid>
                </div>
                {showAddExternalRows && (
                    <DictpickerModal
                        docId={docId}
                        dictName={table?.addFormDataRows.name}
                        modalTitle={table?.addFormDataRows.name}
                        isFormData={true}
                        isMultiple={true}
                        predicatesCache={''}
                        loadMode={''}
                        selectableLevels={''}
                        visibleLevels={''}
                        getExternalDataSource={async (loadOptions: any) => {
                            return [];
                        }}
                        selected={['']}
                        getFormValuesAsync={getFormValuesAsync!}
                        getFiltersAsync={getFiltersAsync!}
                        gridAttribute={table?.addFormDataRows.gridAttribute}
                        onSubmitModal={addExternalRows}
                        onCloseModal={() => setShowAddExternalRows(false)}
                    />
                )}
                {/* {rowDetailsModal} */}
            </div>
        ) : (
            <></>
        );
    },
);

export default TableData;
