import React, { FC, useEffect, useRef, useState } from 'react';
import { AllClose } from '@/indexIcon';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import './AbookpickerModal.scss';
import { IAbookTab, IExternalSearchAbook, IGridModalAttributes } from '@models/Forms/IForms';
import { IAddressBookScheme } from '@models/addressbook/IAddressBookScheme';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { ITab } from '@/types';
import Tabs from '../../Tabs';
import ListModeControl from './ModeControls/ListModeControl';
import TreeModeControl from './ModeControls/TreeModeControl';
import AbookExternalSearchModal from './AbookExternalSearchModal';
import { IAbookpickerRefActions } from '../Abookpicker';
import AbookpickerItem from './AbookpickerItem';
import { AddressBookService } from '@services/AddressBookService';
import { getLoadOptionsQuery } from '@utils/dataGridUtils';

export interface IAbookSettings {
    gridAttribute?: IGridModalAttributes;
    externalSearch?: IExternalSearchAbook;
    selected: number[];
    title: string;
    isMultiple: boolean;
    sort?: string;
    formValues: string;
    getFormValues?: () => string;
    tabsSettings?: IAbookTab[];
    showChips: boolean;
}

export interface ISelectFromAbookDialogProp extends IAbookSettings {
    onCloseModal: () => void;
    onSubmitModal: (data: IAddressBookData[]) => void;
}

const AbookpickerModal: FC<ISelectFromAbookDialogProp> = (p: ISelectFromAbookDialogProp) => {
    const [scheme, setScheme] = useState<IAddressBookScheme>();
    const [selectedRowItems, setSelectedRowItems] = useState<IAddressBookData[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>(p.selected);
    const [tabs, setTabs] = useState<ITab[]>();
    const [showExternalSearch, setShowExternalSearch] = useState<boolean>(false);
    const [tabSettings, setTabSettings] = useState<IAbookTab>();

    const controlRef = useRef<IAbookpickerRefActions>(null);

    useEffect(() => {
        if (p.tabsSettings) {
            let _tabs: ITab[] = [];
            p.tabsSettings?.forEach((item) => {
                _tabs.push({
                    label: item.name,
                    handler: () => setTabSettings(item),
                });
            });
            setTabSettings(p.tabsSettings![0]);
            setTabs(_tabs);
        }
    }, [p.tabsSettings]);

    useEffect(() => {
        if (tabSettings) {
            setSelectedKeys([]);
            setSelectedRowItems([]);
        }
    }, [tabSettings]);

    const isNotEmpty = (value: string | null | undefined) => {
        return value !== undefined && value !== null && value !== '';
    };

    const getParams = (options: any) => {
        let params = '?';
        params += getLoadOptionsQuery(options);

        if (p.getFormValues) {
            params += '&formValues=' + p.getFormValues();
        } else {
            params += '&formValues=' + p.formValues;
        }

        return params;
    };

    const getSelectedKeys = async (selected: number[]) => {
        let result: number[] = [];

        await AddressBookService.fetchListSelectedKeys(tabSettings!.cache, selected).then((response) => {
            result = response.data;
        });

        return result;
    };

    const toggleShowExternalSearch = () => {
        setShowExternalSearch(!showExternalSearch);
    };

    const ModalFooter = ({
        onClose,
        onSubmit,
    }: {
        onClose: () => void;
        onSubmit: (data: IAddressBookData[]) => void;
    }) => (
        <div className={'dictModal_footer'}>
            {p.externalSearch && (
                <div>
                    <Button onClick={toggleShowExternalSearch} buttonType="light" size="s">
                        {p.externalSearch.buttonName}
                    </Button>
                </div>
            )}
            <div className={'left'}>
                <Button
                    onClick={() => {
                        let items = selectedRowItems;
                        if (p.sort === 'asc') {
                            items = [...selectedRowItems].sort((a, b) => (a.fullName < b.fullName ? -1 : 1));
                        } else if (p.sort === 'desc') {
                            items = [...selectedRowItems].sort((a, b) => (a.fullName > b.fullName ? -1 : 1));
                        }
                        onSubmit(items);
                        onClose();
                    }}
                    size="s"
                >
                    Выбрать
                </Button>
                <Button onClick={onClose} buttonType="light" size="s">
                    Отменить
                </Button>
            </div>
        </div>
    );

    const getControl = (mode: string) => {
        if (tabSettings) {
            switch (mode) {
                case 'tree': {
                    return (
                        <TreeModeControl
                            currentTab={tabSettings}
                            onSelectedRowItems={(val: IAddressBookData[]) => {
                                setSelectedRowItems(val);
                            }}
                            onSelectedKeys={(value: number[]) => {
                                setSelectedKeys(value);
                            }}
                            getParams={getParams}
                            selected={selectedKeys}
                            title={p.title}
                            isMultiple={p.isMultiple}
                            formValues={p.formValues}
                            controlRef={controlRef}
                        />
                    );
                }

                default:
                    return (
                        <ListModeControl
                            currentTab={tabSettings}
                            onSelectedRowItems={(val: IAddressBookData[]) => {
                                setSelectedRowItems(val);
                            }}
                            onSelectedKeys={(value: number[]) => {
                                setSelectedKeys(value);
                            }}
                            getParams={getParams}
                            getSelectedKeys={async (selected: number[]) => {
                                return await getSelectedKeys(selected);
                            }}
                            selected={selectedKeys}
                            title={p.title}
                            isMultiple={p.isMultiple}
                            formValues={p.formValues}
                            controlRef={controlRef}
                        />
                    );
            }
        }
    };

    return (
        <Modal
            className={'abookModal dx-dropdowneditor-overlay'}
            size="content"
            footer={<ModalFooter onClose={p.onCloseModal} onSubmit={p.onSubmitModal} />}
            header={
                <div className={classnames('box')}>
                    <div>
                        <div>{p.title}</div>
                    </div>
                    <div className={classnames('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<AllClose size="xs" />}
                        />
                    </div>
                </div>
            }
        >
            {/* onRemoveChip(index) */}
            <div className="abool-container">
                {p.showChips && selectedRowItems.length > 0 && (
                    <div className="rf-file-input__attachments">
                        {selectedRowItems.map((item: IAddressBookData, index: number) => (
                            <AbookpickerItem
                                key={index}
                                item={item}
                                showRemoveIcon={true}
                                onRemove={() => {
                                    controlRef.current?.unSelect(item);
                                }}
                            />
                        ))}
                    </div>
                )}
                <div className="abool-tabs">{tabs && tabs.length > 0 && <Tabs list={tabs!} />}</div>
                <div className="abool-grid">{tabSettings && getControl(tabSettings.mode)}</div>
            </div>
            {showExternalSearch && (
                <AbookExternalSearchModal
                    settings={p.externalSearch!}
                    onCloseModal={() => {
                        toggleShowExternalSearch();
                    }}
                    onSubmitModal={(key: number, data: IAddressBookData) => {
                        if (p.isMultiple) {
                            const concatedKey = selectedKeys.concat(key);
                            setSelectedKeys(concatedKey);
                        } else {
                            setSelectedKeys([key]);
                        }
                    }}
                    currentTab={tabSettings!}
                    getParams={getParams}
                />
            )}
        </Modal>
    );
};

export default AbookpickerModal;
