import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IFileInfo } from '@models/response/IFileInfo';
import { IDataGridDescriptionResponse } from '@models/response/IGridDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IGridResponse } from '@models/response/IGridResponse';
import { IDataGridService } from './IDataGridService';
import { IEisAsyncSendStatusModel } from '@/models/document/eis/IEisAsyncSendStatusModel';
import { IDocumentResult } from '@/models/document/IDocumentResult';

export class DocumentLoadEisLogService extends IDataGridService {
    putData(data: any): Promise<AxiosResponse<IDocumentResult, any>> {
        throw new Error('Method not implemented.');
    }
    async fetchScheme(data: any): Promise<AxiosResponse<IDataGridDescriptionResponse, any>> {
        return $api.get<IDataGridDescriptionResponse>(`/document/loadEisLogScheme/${data}`);
    }
    async fetchDetailData(data: any, detailData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        throw new Error('Method not implemented.');
    }
    async fetchData(data: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        return $api.post<IGridResponse>(`/document/loadEisLogData/${data}`, loadOptions);
    }
    async exportData(
        template: string,
        options: any,
        columns: IGridExportSettings[],
    ): Promise<AxiosResponse<IFileInfo>> {
        throw new Error('Method not implemented.');
    }

    // Получает статус асинхронной отправки документа
    async getEisAsyncSendStatus(documentId: string): Promise<AxiosResponse<IEisAsyncSendStatusModel>> {
        return $api.get(`/document/getEisAsyncSendStatus/${documentId}`);
    }
}
