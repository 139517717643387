export enum LocationTypes {
    Unknown = 0,
    SystemFolder = 1,
    ControlFolder = SystemFolder | 2,
    PerformerFolder = SystemFolder | 4,
    SearchResult = 8,
    DocumentView = 16,
    FolderView = 32,
    PlanningMenu = 64,
    DocumentLinks = 128,
    CompleteFolder = SystemFolder | 256,
    FavouritesFolder = SystemFolder | 512,
    DocumentTitleBar = 1024,
}
