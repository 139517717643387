import React, { FC, ReactNode } from 'react';
import Button from '@atoms/Button';
import Folders from '@molecules/Folders/Folders';
import { AllMenu } from '@/indexIcon';
import { useActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useTypedSelector';
import Notifications from '@molecules/Notifications';
import Errors from '@molecules/Errors';

export interface ILayoutMainProps {
    /** Наполнение */
    children: ReactNode | ReactNode[];
    /** Панель вверху */
    header?: ReactNode;
    /** Левое меню */
    sidebar?: ReactNode;
}

const LayoutMain: FC<ILayoutMainProps> = ({ children, header, sidebar }: ILayoutMainProps) => {
    //const [showMenu, toggleMenu] = useState<boolean>(true);
    const mainLayout = useTypedSelector((state) => state.mainLayout);

    const { toggleMenu } = useActions();

    const openClass = mainLayout.isMenuOpen ? 'sidebar--open' : 'sidebar--close';
    const openContainerClass = mainLayout.isMenuOpen ? 'content--open' : 'content--close';
    const toggleClick = () => {
        toggleMenu(!mainLayout.isMenuOpen);
    };

    return (
        <>
            <header>
                <Button
                    buttonType="text"
                    textColor="neutral"
                    size="m"
                    onClick={toggleClick}
                    className="menu-toggle-button"
                    startAdornment={<AllMenu size="xs" />}
                ></Button>
                {header}
            </header>

            <div className="main-container">
                <aside className={`sidebar ${openClass}`}>
                    <Folders isOpen={mainLayout.isMenuOpen} />
                </aside>
                <main className={`content ${openContainerClass}`}>{children}</main>
                <Notifications />
                <Errors />
            </div>
        </>
    );
};

export default LayoutMain;
