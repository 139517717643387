import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { IRowArr, IRows } from '@models/Forms/IForms';
import './SubForm.scss';
import { AllClose } from '@/indexIcon';
import Button from '@atoms/Button';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import { ITableForm } from '@models/Forms/ITableForm';
import { Controller, useForm } from 'react-hook-form';
import { IField, IFieldElem } from '@models/IFormData';
import { sendErrorMsg } from '@/components/molecules/Errors';
import { IErrorMessage } from '@/components/molecules/Errors/Errors';
import Modal from '@/components/atoms/Modal';
import { FormProviderControl, HookFormProvider } from '@/components/controls';
import { convertListToMap } from '@/utils/documentUtils';
import { IDocumentData } from '@/models/document/IDocumentData';
import { DropdownContainerContext } from '@/context/Contexts';

export interface ISubFormProps {
    form: IRowArr;
    onClose: () => void;
    onSubmit?: (data: IField[]) => void;
    isEdit: boolean;
    children?: ReactNode;
    docId?: string;
    submitButtonCaption?: string;
    modalCaption?: string;
    formFields: IDocumentData;
}

const SubForm: React.FC<ISubFormProps> = ({
    form,
    onClose,
    onSubmit,
    isEdit,
    children,
    docId,
    submitButtonCaption,
    modalCaption,
    formFields,
}: ISubFormProps) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const [rows, setRows] = useState<IRows>();
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    const methods = useForm<ITableForm>({
        mode: 'onChange', // "onBlur"
    });
    const disSubmit = React.useRef(false);

    useEffect(() => {
        let rows = { rows: form } as IRows;
        methods.reset(formFields);
        setFieldsData(convertListToMap(formFields?.fields));
        setRows(rows);
    }, [form]);

    const ModalFooter = useCallback(
        ({ onClose }: { onClose: () => void }) => (
            <div className={'subFormModal_footer'}>
                <div className={'left'}>
                    {isEdit && (
                        <Button type="submit" size="s" disabled={disSubmit.current}>
                            {submitButtonCaption ? submitButtonCaption : 'Сохранить'}
                        </Button>
                    )}
                    <Button onClick={onClose} buttonType="light" size="s">
                        {isEdit ? 'Отменить' : 'Ок'}
                    </Button>
                </div>
            </div>
        ),
        [form],
    );

    const dirtyValues = (dirtyFields: any, allValues: ITableForm) => {
        return Object.keys(dirtyFields.fields).map((key) => allValues.fields[+key]);
    };

    const onSubmitHandler = (data: ITableForm) => {
        disSubmit.current = true;
        let dirtyFields = methods.formState.dirtyFields;
        let dirtyData = dirtyValues(dirtyFields, data);
        onSubmit && onSubmit(dirtyData);
    };

    return (
        <Modal size="xl" key={'subForm'} custom={true}>
            <FormProviderControl
                id={'subForm'}
                formMethods={methods}
                className="doc-form"
                onSubmit={onSubmit ? onSubmitHandler : () => {}}
            >
                <HookFormProvider controller={Controller}>
                    <div className="rf-modal__wrapper">
                        <div className="rf-modal__header">
                            <div className="box">
                                {modalCaption ? modalCaption : 'Редактирование полей документа'}
                                <div className="left">
                                    <Button
                                        startAdornment={<AllClose size="xs" />}
                                        buttonType="text"
                                        textColor="neutral"
                                        size="xs"
                                        onClick={onClose}
                                    />
                                </div>
                            </div>
                        </div>

                        <DropdownContainerContext.Provider value={modalContentRef}>
                            <div className="rf-modal__content" ref={modalContentRef}>
                                <div className={'view-form-modal-body'}>
                                    {children}

                                    <div className="table-form-content">
                                        <FormBuilder
                                            fields={fieldsData!}
                                            docId={docId}
                                            setError={(errors?: IErrorMessage) => {
                                                if (errors) {
                                                    sendErrorMsg(errors);
                                                }
                                            }}
                                            rows={rows}
                                            isEdit={isEdit}
                                            isNew={false}
                                            formMethods={methods}
                                            includedFields={[]}
                                            uniqueKey={docId ? docId : '-1'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </DropdownContainerContext.Provider>

                        <div className={'rf-modal__footer'}>{<ModalFooter onClose={onClose} />}</div>
                    </div>
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default SubForm;
