import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IUser } from '@models/IUser';
import { IUserAvatar } from '@models/IUserAvatar';

export class UserService {
    static async getCurrentUser(): Promise<AxiosResponse<IUser>> {
        return $api.get<IUser>(`/user/profile`);
    }

    static async getCurrentUserAvatar(): Promise<AxiosResponse<IUserAvatar>> {
        return $api.get<IUserAvatar>(`/user/avatar`);
    }
}
