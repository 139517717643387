import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentField, ISetValue } from '@models/Forms/IForms';
import { handlerFieldReplaceWatch, handlerFieldWatch } from '@utils/documentUtils';
import {
    CheckboxBitControl,
    DatepickerControl,
    DateTimepickerControl,
    InputControl,
    InputMoneyControl,
    InputNumberControl,
    TextareaBase64Control,
    TextareaControl,
    YearPickerControl,
    YesNoControl,
} from '@controls/index';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import './Field.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { ChangesFieldValue, GetValueForSetValue } from '@utils/ChangesManager';
import { ValueType } from '@/types/ValueType';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import Moment from 'moment';
import { IPartialFormGroup } from '@controls/types';
import { IErrorMessage } from '@/components/molecules/Errors/Errors';
import { declensionOfSymbol } from '@utils/helpers';
import { ConfigService } from '@/configuration/services/configService';

export interface IFieldProps<TFieldValues extends object = object> {
    field?: IDocumentField;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: IErrorMessage) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
}

const Field = <TFieldValues extends object = object>({
    field,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    ...props
}: IFieldProps<TFieldValues>) => {
    let visibilityRules = field?.visibilityRules;
    let readOnlyRules = field?.readonly;
    let requiredRules = field?.required;
    let minRules = field?.min;
    let maxRules = field?.max;

    const visibilityMng = new FormulaManager(visibilityRules!);
    const readOnlyMng = new FormulaManager(readOnlyRules!);
    const requiredMng = new FormulaManager(requiredRules!);
    const minMng = new FormulaManager(minRules!);
    const maxMng = new FormulaManager(maxRules!);
    visibilityMng.Init(fields, formMethods);
    readOnlyMng.Init(fields, formMethods);
    requiredMng.Init(fields, formMethods);
    minMng.Init(fields, formMethods);
    maxMng.Init(fields, formMethods);

    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [minVal, setMinVal] = useState<any>();
    const [maxVal, setMaxVal] = useState<any>();
    const dataField = fields[field?.key!];
    const idField = dataField?.name;
    includedFields.push(dataField?.index);
    const fieldVal = dataField?.value;

    const config = ConfigService.get();
    const moneyCulture: string = config.application.moneyCulture ?? 'ru-Ru';

    const InitFormulas = async () => {
        let vis = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(vis);
        }

        if (requiredRules) {
            let req = await requiredMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setRequired(req);
            }
        }

        if (readOnlyRules) {
            let readonly = await readOnlyMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setReadOnly(readonly);
            }
        }

        if (minRules) {
            let d = await minMng.ReplaceFormulaValues(true, true, undefined, false);
            if (d) {
                setMinVal(d);
            }
        }
        if (maxRules) {
            let d = await maxMng.ReplaceFormulaValues(true, true, undefined, false);
            if (d) {
                setMaxVal(d);
            }
        }
        if (field?.valueType == ValueType.Boolean && (fieldVal == undefined || fieldVal == null) && (isNew || isEdit)) {
            formMethods.setValue(idField as any, 0 as any, { shouldDirty: true });
        }
        if (isNew && field?.invokeChangesOnNew && vis) {
            //console.log(field?.key);
            await ChangesFieldValue(
                field?.changes!,
                fieldVal,
                field?.key!,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                undefined,
                undefined,
                formMethods,
            );
        }
    };

    useEffect(() => {
        if (isEdit && field?.invokeChangesOnEdit && visibility) {
            if (field?.valueType == ValueType.Boolean && fieldVal == undefined && (isEdit || isNew)) {
                formMethods.setValue(idField as any, 0 as any, { shouldDirty: true });
            }
            ChangesFieldValue(
                field?.changes!,
                fieldVal,
                field?.key!,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                undefined,
                undefined,
                formMethods,
            );
        }
    }, [isEdit]);

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });
    const watchReadOnly = useWatch({
        name: readOnlyMng.GetWatchFields(),
    });
    const watchRequired = useWatch({
        name: requiredMng.GetWatchFields(),
    });
    const watchMinDate = useWatch({
        name: minMng.GetWatchFields(),
    });
    const watchMaxDate = useWatch({
        name: maxMng.GetWatchFields(),
    });
    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);
    useEffect(() => {
        if (readOnlyRules) {
            handlerFieldWatch(watchReadOnly, isEdit, isNew, readOnlyMng, setReadOnly, activated);
        }
    }, [watchReadOnly, isEdit, isNew]);
    useEffect(() => {
        if (requiredRules) {
            handlerFieldWatch(watchRequired, isEdit, isNew, requiredMng, setRequired, activated);
        }
    }, [watchRequired, isEdit, isNew]);
    useEffect(() => {
        if (minRules) {
            handlerFieldReplaceWatch(watchMinDate, isEdit, isNew, minMng, setMinVal);
        }
    }, [watchMinDate, isEdit, isNew]);
    useEffect(() => {
        if (maxRules) {
            handlerFieldReplaceWatch(watchMaxDate, isEdit, isNew, maxMng, setMaxVal);
        }
    }, [watchMaxDate, isEdit, isNew]);

    const getValue = (value: any) => {
        let fieldValue = value;

        if (field?.replacerFrom && field?.replacerTo && !(fieldValue === undefined || fieldValue === null)) {
            let strValue = fieldValue as string;
            if (strValue) {
                fieldValue = strValue.replaceAll(field?.replacerFrom!, field?.replacerTo!);
            }
        }

        if (field?.valueType == ValueType.Url && field?.pattern) {
            if (fieldValue === undefined || fieldValue === null) {
                return '';
            }
            let val = field?.pattern;
            val = val.replace('{value}', fieldValue as string);
            return val;
        } else {
            return fieldValue;
        }
    };

    const onSaveField = async (item: ISetValue, rowData?: any) => {
        let val = await GetValueForSetValue(item, undefined, fields, rowData, formMethods);
        let field = fields[item.key];
        if (fields && field) {
            formMethods.setValue(field.name as any, val, { shouldDirty: true });
        }
    };
    const onInputChange = async (value: string) => {
        await ChangesFieldValue(
            field?.changes!,
            value,
            field?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };
    const onInputNumberChange = async (value: number) => {
        await ChangesFieldValue(
            field?.changes!,
            value,
            field?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };
    const onCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let val = event.currentTarget.checked ? 1 : 0;
        await ChangesFieldValue(
            field?.changes!,
            val,
            field?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };
    const onTextareaChange = async (value: string) => {
        await ChangesFieldValue(
            field?.changes!,
            value,
            field?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };

    const onDateTimeChange = async (value: Date) => {
        await ChangesFieldValue(
            field?.changes!,
            value,
            field?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };
    const checkDateMaxMin = (value: any) => {
        if (required && (value === undefined || value === null || value === '')) {
            return 'Обязательное поле';
        }
        let val = new Date(value);
        val.setSeconds(0);
        val.setMilliseconds(0);
        if (field?.valueType == ValueType.Date) {
            val.setHours(0);
            val.setMinutes(0);
        }
        // console.log('field?.name', field?.name);
        // console.log('val', val);
        if (field?.max && value !== undefined && value !== null && value !== '') {
            let valMax = new Date(+maxVal);
            valMax.setSeconds(0);
            valMax.setMilliseconds(0);
            if (field?.valueType == ValueType.Date) {
                valMax.setHours(0);
                valMax.setMinutes(0);
            }
            //console.log('maxValDate', valMax);
            if (val.getTime() > valMax.getTime()) {
                if (field?.valueType == ValueType.Date) {
                    return 'Максимальное значение: ' + Moment(valMax).format('DD.MM.YYYY');
                } else {
                    return 'Максимальное значение: ' + Moment(valMax).format('DD.MM.YYYY HH:mm');
                }
            }
        }
        if (field?.min && value !== undefined && value !== null && value !== '') {
            //console.log('minValDate', minVal);
            let valMin = new Date(+minVal);
            valMin.setSeconds(0);
            valMin.setMilliseconds(0);
            if (field?.valueType == ValueType.Date) {
                valMin.setHours(0);
                valMin.setMinutes(0);
            }
            if (val.getTime() < valMin.getTime()) {
                if (field?.valueType == ValueType.Date) {
                    return 'Минимальное значение: ' + Moment(valMin).format('DD.MM.YYYY');
                } else {
                    return 'Минимальное значение: ' + Moment(valMin).format('DD.MM.YYYY HH:mm');
                }
            }
        }
        return true;
    };

    const fieldControlType = () => {
        if (field && (field.name === undefined || field.name === null)) {
            return <div></div>;
        }

        let rules = {
            ...{
                required: {
                    value: required ? required : false,
                    message: 'Обязательное поле',
                },
            },
            ...(field?.pattern &&
                field?.valueType != ValueType.Url && {
                    pattern: {
                        value: new RegExp(field?.pattern, 'g'),
                        message: 'Неверный формат',
                    },
                }),

            ...(field?.min &&
                (field?.valueType == ValueType.Text ||
                    field?.valueType == ValueType.LongText ||
                    field?.valueType == ValueType.Url) && {
                    minLength: {
                        value: Number(field?.min),
                        message: `Минимальная длина поля: ${field?.min} ${declensionOfSymbol(Number(field?.min))}`,
                    },
                }),
            ...(field?.max &&
                (field?.valueType == ValueType.Text ||
                    field?.valueType == ValueType.LongText ||
                    field?.valueType == ValueType.Url) && {
                    maxLength: {
                        value: Number(field?.max),
                        message: `Максимальная длина поля: ${field?.max} ${declensionOfSymbol(Number(field?.max))}`,
                    },
                }),
            ...(field?.max == undefined &&
                field?.valueType == ValueType.Text && {
                    maxLength: {
                        value: Number(255),
                        message: 'Максимальная длина поля: 255 символов',
                    },
                }),
        };
        if (
            rules.required == undefined &&
            formMethods.formState.errors &&
            (formMethods.formState.errors as any).fields &&
            (formMethods.formState.errors as any).fields[dataField?.index]
        ) {
            formMethods.clearErrors(idField as any);
        }
        switch (field?.valueType) {
            case ValueType.Boolean:
                return (
                    <CheckboxBitControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        readOnly={readOnly}
                        disabled={readOnly}
                        required={required}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        rules={rules}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onChange={onCheckboxChange}
                    />
                );
            case ValueType.YesNo:
                return (
                    <YesNoControl
                        label={field?.name}
                        trueText={field?.trueText}
                        falseText={field?.falseText}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        disabled={readOnly}
                        required={required}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        options={[]}
                        rules={rules}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onChangeVal={onInputNumberChange}
                    />
                );
            case ValueType.LongText:
                return (
                    <TextareaControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        readOnly={readOnly}
                        required={required}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        autoResize={field?.autoResize}
                        rules={rules}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onTextareaChange}
                        maxLength={field?.maxLength}
                    />
                );
            case ValueType.Base64Text:
                return (
                    <TextareaBase64Control
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        readOnly={readOnly}
                        required={required}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        autoResize={field?.autoResize}
                        rules={rules}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onTextareaChange}
                    />
                );
            case ValueType.Double:
                return (
                    <InputNumberControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        groupBy={0}
                        separator={''}
                        readOnly={readOnly}
                        required={required}
                        max={field?.max ? Number(maxVal!) : undefined}
                        min={field?.min ? Number(minVal!) : undefined}
                        floatPoints={field?.floatPoints ? field?.floatPoints : 2}
                        mask={field?.mask}
                        rules={rules}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onInputNumberChange}
                    />
                );
            case ValueType.Money:
                return (
                    <InputMoneyControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        readOnly={readOnly}
                        required={required}
                        max={field?.max ? Number(maxVal!) : undefined}
                        min={field?.min ? Number(minVal!) : undefined}
                        floatPoints={field?.floatPoints ? field?.floatPoints : 2}
                        locale={moneyCulture}
                        rules={rules}
                        name={idField}
                        mask={field?.mask}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onInputNumberChange}
                    />
                );
            case ValueType.Integer:
                return (
                    <InputNumberControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        groupBy={0}
                        separator={''}
                        readOnly={readOnly}
                        required={required}
                        max={field?.max ? Number(maxVal!) : undefined}
                        min={field?.min ? Number(minVal!) : undefined}
                        rules={rules}
                        name={idField}
                        floatPoints={0}
                        //type="number"
                        pattern={'^[\\d]+$'}
                        mask={field?.mask}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onInputNumberChange}
                    />
                );
            case ValueType.NoSecDateTime:
                return (
                    <DateTimepickerControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        readOnly={readOnly}
                        required={required}
                        defaultValue={fieldVal as any}
                        rules={{
                            validate: { checkDateMaxMin },
                        }}
                        name={idField}
                        maxDate={field?.max && maxVal && maxVal !== 'null' ? new Date(+maxVal!) : undefined}
                        minDate={field?.min && minVal && minVal !== 'null' ? new Date(+minVal!) : undefined}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        isNoSecFormat={true}
                        onChangeVal={onDateTimeChange}
                    />
                );
            case ValueType.Year:
                return (
                    <YearPickerControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        disabled={readOnly}
                        required={required}
                        max={field?.max ? Number(maxVal!) : 2050}
                        min={field?.min ? Number(minVal!) : 1950}
                        defaultValue={fieldVal as any}
                        rules={rules}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onChangeVal={onInputNumberChange}
                        options={[]}
                    />
                );
            case ValueType.DateTime:
                return (
                    <DateTimepickerControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        readOnly={readOnly}
                        required={required}
                        defaultValue={fieldVal as any}
                        rules={{
                            validate: { checkDateMaxMin },
                        }}
                        name={idField}
                        maxDate={field?.max && maxVal && maxVal !== 'null' ? new Date(+maxVal!) : undefined}
                        minDate={field?.min && minVal && minVal !== 'null' ? new Date(+minVal!) : undefined}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        isNoSecFormat={false}
                        onChangeVal={onDateTimeChange}
                    />
                );
            case ValueType.Date:
                return (
                    <DatepickerControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        readOnly={readOnly}
                        required={required}
                        defaultValue={fieldVal as any}
                        rules={{
                            validate: { checkDateMaxMin },
                        }}
                        name={idField}
                        maxDate={field?.max && maxVal && maxVal !== 'null' ? new Date(+maxVal!) : undefined}
                        minDate={field?.min && minVal && minVal !== 'null' ? new Date(+minVal!) : undefined}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onChangeVal={onDateTimeChange}
                        isValueAsString={field?.isValueAsString}
                    />
                );
            case ValueType.Url:
            case ValueType.Text:
                return (
                    <InputControl
                        label={field?.name}
                        tooltip={<ToolTipTitle title={field?.title} />}
                        formGroupProps={{ verticalBottomAlign: field?.verticalBottomAlign } as IPartialFormGroup}
                        readOnly={readOnly}
                        required={required}
                        rules={rules}
                        name={idField}
                        mask={field?.mask}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onInputChange}
                        pattern={field?.inputRegExp}
                    />
                );

            default:
                return (
                    <div>
                        {field?.valueType} --- {fieldVal && fieldVal.toString()} --- {typeof fieldVal}
                    </div>
                );
        }
    };

    return field && field.key && visibility && idField != undefined ? (
        <div className="form-field" data-testid={field.id ? `field-${field.id}` : undefined}>
            {isEdit || isNew ? (
                fieldControlType()
            ) : (
                <FieldWrapper
                    inLineFormat={field.isValue}
                    lable={field.valueType === ValueType.Boolean ? undefined : field.name}
                >
                    <DisplayField
                        type={field.valueType}
                        floatPoints={field.floatPoints}
                        lable={field.name}
                        value={getValue(fieldVal)}
                        trueText={field.trueText}
                        falseText={field.falseText}
                        showCopyButton={field.showCopyButton}
                    />
                </FieldWrapper>
            )}
        </div>
    ) : (
        <></>
    );
};

export default Field;
