export enum ProcessActionTypes {
    /// <summary>
    /// ����� � ��������
    /// </summary>
    RemoveFromControl,
    /// <summary>
    ///�������� ��������������� �� ��������
    /// </summary>
    ChangeController,
}
