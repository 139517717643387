import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ITreeOption } from '@/types';
import { IMenuItemInfo } from '@models/folders/IMenuItemInfo';

export class FoldersService {
    static async tasks(): Promise<AxiosResponse<ITreeOption[]>> {
        return $api.get<ITreeOption[]>(`/folders/tasks/submenu/Tasks`);
    }
    static async documents(): Promise<AxiosResponse<ITreeOption[]>> {
        return $api.get<ITreeOption[]>(`/folders/documents/submenu/Documents`);
    }
    static async dictionaries(): Promise<AxiosResponse<ITreeOption[]>> {
        return $api.get<ITreeOption[]>(`/folders/dictionaries/submenu/Dictionaries`);
    }
    static async reports(): Promise<AxiosResponse<ITreeOption[]>> {
        return $api.get<ITreeOption[]>(`/folders/reports/submenu/Reports`);
    }
    static async root(): Promise<AxiosResponse<ITreeOption[]>> {
        return $api.get<ITreeOption[]>('/folders/root');
    }

    static async fetchDocumentsInfo(name: string): Promise<AxiosResponse<IMenuItemInfo, any>> {
        return $api.get<IMenuItemInfo>(`/folders/documents/info/${name}/submenu/Documents`);
    }
    static async fetchTasksInfo(name: string): Promise<AxiosResponse<IMenuItemInfo, any>> {
        return $api.get<IMenuItemInfo>(`/folders/tasks/info/${name}/submenu/Tasks`);
    }
}
