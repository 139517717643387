import { AxiosResponse } from 'axios';
import { IGridResponse } from '@models/response/IGridResponse';
import { IDataGridDescriptionResponse } from '@models/response/IGridDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IFileInfo } from '@models/response/IFileInfo';

export abstract class IDataTreeService {
    abstract fetchScheme(data: any): Promise<AxiosResponse<IDataGridDescriptionResponse>>; // Mandatory
    abstract fetchData(data: any, parentData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse>>; // Mandatory
    abstract exportData(
        template: string,
        options: any,
        columns: IGridExportSettings[],
    ): Promise<AxiosResponse<IFileInfo>>;
}
